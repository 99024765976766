import { Store } from "vuex";
import { RootState } from "@/store/types";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    anyLoading(this: { $store: Store<RootState> }): boolean {
      return this.$store.getters["isAnyLoading"];
    },
  },
});
