import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_evonum_notification = _resolveComponent("evonum-notification")!

  return (_ctx.innerNotification)
    ? (_openBlock(), _createBlock(_component_evonum_notification, _mergeProps({ key: 0 }, _ctx.$attrs, {
        dismisser: _ctx.dismisser,
        "data-cy": "base-notification-from-store",
        class: "notification-from-store",
        notification: _ctx.innerNotification
      }), {
        default: _withCtx(() => [
          (_ctx.innerNotification && _ctx.innerNotification.component)
            ? (_openBlock(), _createBlock(_component_i18n_t, {
                key: 0,
                keypath: _ctx.innerNotification.component.path
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.innerNotification.component.slots, (slot) => {
                  return {
                    name: slot.slotName!,
                    fn: _withCtx(() => [
                      (slot.type == 'routerlink')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: slot.content,
                            to: slot.route
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(slot.content), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true)
                    ])
                  }
                })
              ]), 1032, ["keypath"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.innerNotification.message), 1)
              ], 64))
        ]),
        _: 1
      }, 16, ["dismisser", "notification"]))
    : _createCommentVNode("", true)
}