
//@ts-nocheck
import { defineComponent, h } from "vue";
//@ts-ignore
import { VIcon } from "vuetify/components";
import { EvonumIcon } from "@evo/evonum";

export default defineComponent({
  name: "partial-dialog-card-confirmation-icon-switcher",
  props: ["dialogProps", "iconChooser"],
  render(): any {
    let result;
    let icon = this.iconChooser(this.dialogProps);
    if (icon.startsWith("mdi-")) {
      result = h(VIcon, { icon });
    } else {
      result = h(EvonumIcon, { icon });
    }
    return result;
  },
});
