
import { defineComponent } from "vue";
import CommonMenuPopover from "@/components/common-menu-popover.vue";
import { useAuthorities } from "@/composables/users";
import {
  ROUTE_AUTHORITIES_DETAILS,
  ROUTE_AUTHORITIES_INVITATION,
  ROUTE_AUTHORITIES_SELECTION,
} from "@/router/routes";
import ViewportMixin from "@/mixins/viewport";
import { useLowHeight } from "@evo/evonum";

export default defineComponent({
  name: "the-header-authority-menu",
  mixins: [ViewportMixin],
  components: {
    CommonMenuPopover,
  },
  setup() {
    const { userAuthorities } = useAuthorities();
    const { isLowHeight } = useLowHeight();
    return {
      authorityIcon: "building",
      userAuthorities,
      isLowHeight,
    };
  },
  computed: {
    name(): string {
      return `${this.$store.state.users.authority?.name}`;
    },
    city(): string {
      return `${this.$store.state.users.authority?.postalCode}, ${this.$store.state.users.authority?.city}`;
    },
    currentAuthorityId(): string {
      return `${this.$store.state.users.authority?.id}`;
    },
    iconButtonHeight(): string | undefined {
      if (this.isLowHeight) {
        return undefined;
      } else {
        return "48px";
      }
    },
  },
  methods: {
    async editAuthority(): Promise<void> {
      await this.$router.push({
        name: ROUTE_AUTHORITIES_DETAILS,
        params: { authorityId: this.currentAuthorityId },
      });
    },
    async showInvitations(): Promise<void> {
      await this.$router.push({
        name: ROUTE_AUTHORITIES_INVITATION,
        params: { authorityId: this.currentAuthorityId },
      });
    },
    async showSelection(): Promise<void> {
      await this.$router.push({
        name: ROUTE_AUTHORITIES_SELECTION,
      });
    },
  },
});
