import axios, { InternalAxiosRequestConfig } from "axios";
import { Authenticator, Trimmer, ErrorHandler } from "./interceptors";

function getBaseUrl(suffixOrCompleteUrl: string) {
  if (suffixOrCompleteUrl.startsWith("http")) {
    return suffixOrCompleteUrl;
  } else {
    return `${window.location.protocol}//${window.location.host}/${suffixOrCompleteUrl}`;
  }
}

const baseUrl = getBaseUrl(
  `${
    process.env.VUE_APP_AWARDING_HOST ? process.env.VUE_APP_AWARDING_HOST : ""
  }${process.env.VUE_APP_AWARDING_URL}`
);

const AwardingClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/vnd.api+json, application/json",
  },
});

AwardingClient.interceptors.request.use((req): InternalAxiosRequestConfig => {
  Authenticator.intercept(req);
  Trimmer.intercept(req);
  return req;
});

AwardingClient.interceptors.response.use(
  (res) => res,
  (err) => {
    ErrorHandler.intercept(err);
  }
);

export { AwardingClient };
