import {
  getHumanReadableByMediaType,
  getIconByMediaType,
} from "@/utils/mimeTypes";
import filesize from "@/utils/filesize";
import { Actionable } from "@/model/Actionable";

export enum PositionValues {
  First = "FIRST",
  Last = "LAST",
  Before = "BEFORE",
  After = "AFTER",
}
export type DocumentPosition = PositionValues;

export type RawDocument = {
  id: string;
  title: string;
  fileSize: number;
  filename: string;
  mediaType: string;
  sectionId?: string;
} & Actionable;

export class Document implements Actionable {
  readonly id: string;
  readonly title: string;
  readonly fileSize: number;
  readonly filename: string;
  readonly filenameNoExtension: string;
  readonly hfileSize: string;
  readonly extension: string;
  readonly format: string;
  readonly icon: string;
  readonly sectionId?: string;
  uploading = false;
  actions = {};

  constructor(rawDoc: RawDocument) {
    this.id = rawDoc.id;
    this.title = rawDoc.title;
    this.fileSize = rawDoc.fileSize;
    this.filename = rawDoc.filename;
    const beginningOfExtension = rawDoc.filename.lastIndexOf(".");

    this.filenameNoExtension = rawDoc.filename.slice(
      0,
      beginningOfExtension == -1 ? rawDoc.filename.length : beginningOfExtension //wenn keine Extension, ist alles der Filename ohne Extension
    );
    if (beginningOfExtension == -1) {
      this.extension = "";
    } else {
      this.extension = rawDoc.filename
        .slice(beginningOfExtension + 1)
        .toLowerCase();
    }
    this.hfileSize = filesize.getHumanReadableFileSize(this.fileSize);
    this.format = getHumanReadableByMediaType(rawDoc.mediaType, this.extension);
    this.icon = getIconByMediaType(rawDoc.mediaType);
    this.actions = rawDoc.actions;
    this.sectionId = rawDoc.sectionId;
  }
}
