import { AwardingClient } from "@/utils/EvoClient";
import { waitForXBeforeResolve } from "@/utils/functions";
import { RenameSectionDto } from "../dto";

const ENDPOINT = "c/rename-section";

export default async function RenameSection(
  dto: RenameSectionDto
): Promise<void> {
  //mindestanimations zeit
  return waitForXBeforeResolve(AwardingClient.post(ENDPOINT, dto), 250);
}
