import { AwardingClient } from "@/utils/EvoClient";
import { PublishNoticeDto } from "../dto";

const ENDPOINT = "c/publish-notice";

export default async function publishNotice(
  dto: PublishNoticeDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
