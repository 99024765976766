import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  try {
    const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {} as any;
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  } catch (err) {
    return {} as any;
  }
}

export default createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages: loadLocaleMessages(),
  warnHtmlInMessage: "off",
  missing: (locale: any, key: any) => {
    const message = `Das Locale mit dem Key ${key} gibt es nicht, wird aber erwartet`;
    if (window.Cypress) {
      const error = new Error(message);
      error.name = "i18nError";
      throw error;
    } else {
      console.error(message);
    }
  },
}) as unknown as { global: any };
