import { AwardingClient } from "@/utils/EvoClient";
import { CreateShipmentDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/create-shipment";

export default async function createShipment(
  dto: CreateShipmentDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
