import { ConsistencyError } from "@/utils/errors";
import Store from "@/store";

class LoadingInvoker {
  loadPromises: Promise<void>[] = [];
  cleanActions: Set<string> = new Set();
  runningPromises: Record<string, boolean> = {};
  consistentyCheck: (() => boolean)[] = [];
  VueInstance: any;

  initialize(VueInstance: any) {
    this.VueInstance = VueInstance;
  }

  startProgressbar() {
    if (this.VueInstance) {
      this.VueInstance.$Progress.set(0);
      this.VueInstance.$Progress.start();
    }
  }

  increaseProgressbar(val: number) {
    if (this.VueInstance) {
      this.VueInstance.$Progress.increase(val);
    }
  }

  stopProgressbar() {
    if (this.VueInstance) {
      this.VueInstance.$Progress.finish();
    }
  }

  failProgressbar() {
    if (this.VueInstance) {
      this.VueInstance.$Progress.fail();
    }
  }

  addCheck(check: () => boolean) {
    this.consistentyCheck.push(check);
  }

  async syncLoadAction(action: string, ...args: any): Promise<void> {
    if (this.isPromiseAlreadySet(action)) {
      return;
    }
    const promise = Store.dispatch(action, ...args);
    try {
      await promise;
    } catch (e) {
      //wird in promise.all abgefangen
    }
    this.addLoadPromise(promise, action);
  }

  addLoadAction(action: string, ...args: any): void {
    if (this.isPromiseAlreadySet(action)) {
      return;
    }
    const promise = Store.dispatch(action, ...args);
    promise.catch(() => {});
    this.addLoadPromise(promise, action);
  }

  //alias für Listen-Routing
  dispatch(action: string, ...args: any): void {
    this.addLoadAction(action, ...args);
  }

  //Nur Leichtgewichtige Aktionen die z.B. einen Store säubern
  //Keine Netzwerkcalls o.Ä.
  addCleanAction(action: string) {
    this.cleanActions.add(action);
  }

  addLoadPromise(promise: Promise<any>, name?: string) {
    promise
      .then(() => this.increaseProgressbar(100 / this.loadPromises.length))
      .catch(() => {});
    if (name) {
      this.runningPromises[name] = true;
    }
    this.loadPromises.push(promise);
  }

  isPromiseAlreadySet(name: string): boolean {
    return this.runningPromises[name] || false;
  }

  getAllRunningAction(): string[] {
    return Object.keys(this.runningPromises);
  }

  reset() {
    this.loadPromises = [];
    this.consistentyCheck = [];
    this.cleanActions = new Set();
    this.runningPromises = {};
    //@ts-ignore
    window.isLoaded = false;
  }

  async waitForAll(): Promise<void> {
    try {
      await Promise.all(this.loadPromises);
      this.consistentyCheck.forEach((check) => {
        if (!check()) throw new ConsistencyError();
      });
      //@ts-ignore
      window.isLoaded = true;
    } catch (err) {
      this.failProgressbar();
      console.warn("Navigation error");
      throw err;
    }
  }

  async runAllCleanActions(): Promise<void> {
    if (this.cleanActions.size > 0) {
      try {
        await Promise.all(
          Array.from(this.cleanActions).map((x) => Store.dispatch(x))
        );
      } finally {
        this.cleanActions = new Set();
      }
    }
  }
}

export default new LoadingInvoker();
