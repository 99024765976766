import { DismissCallForCompetitionNotificationDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-call-for-competition-notification";

export default async function DismissCallForCompetitionNotification(
  dto: DismissCallForCompetitionNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
