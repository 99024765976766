import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "common-avatar__text text-subtitle-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_avatar = _resolveComponent("evonum-avatar")!

  return (_openBlock(), _createBlock(_component_evonum_avatar, {
    class: _normalizeClass(["common-avatar", {
      'common-avatar--highlighted-color': _ctx.highlighted,
      'common-avatar--default-color': !_ctx.highlighted,
    }]),
    "data-cy": "common-avatar-avatar",
    style: _normalizeStyle({ minWidth: `${_ctx.size}px` }),
    size: _ctx.size
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", { content: _ctx.firstTwoLettersOfName }, () => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.firstTwoLettersOfName), 1)
      ])
    ]),
    _: 3
  }, 8, ["class", "style", "size"]))
}