import { Actionable } from "./Actionable";

export type User = {
  id: string;
  email: string;
  name: string;
  firstname: string;
  phone: string;
  department?: string;
  agreePrivacyPolicy: boolean;
  agreeTermsOfUse: boolean;
  authorities: Authority[];
  authoritiesIds: string[];
  userRoles: UserRole[];
};

export type Context = {
  id: string;
} & Actionable<"removeUserFromContext" | "grantUserRole">;

type UserRole = {
  id: string;
  roleId: string;
  contextId: string;
};

export type Authority = {
  id: string;
  name: string;
  street: string;
  city: string;
  houseNo: string;
  postalCode: string;
  countryCode: string;
  invitations: UserInvitation[];
};

export type Role = {
  id: string;
  name: string;
  permissions: string[];
};

export type UserInvitation = {
  code: string;
  email: string;
  mailStatus: boolean;
};

export function hasPermission(
  user: User,
  roles: Record<string, Role>,
  permission: string
) {
  for (const userRole of user.userRoles) {
    const role = roles[userRole.roleId];
    if (role.permissions.includes(permission)) {
      return true;
    }
  }
}
