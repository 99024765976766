
import { defineComponent } from "vue";
import LabelableMixin from "@/mixins/labelable";
/**
 * Hilfesystem zum Anzeigen von Coachmarks
 */
export default defineComponent({
  name: "base-coach-mark",
  mixins: [LabelableMixin],
  props: {
    ariaLabel: String,
    announceContent: String,
    right: Boolean,
    small: Boolean,
  },
  methods: {
    menuShown() {
      this.$utils.announcePolite(this.announceContentToRead);
      this.$emit("coachmark-shown"); //z.B. Um Parent-ZIndex anzupassen
    },
    menuHidden() {
      this.$emit("coachmark-hidden");
    },
  },
  computed: {
    announceContentToRead(): string {
      return this.announceContent || this.heading + " " + this.text;
    },
  },
});
