import { AwardingClient } from "@/utils/EvoClient";
import { EditCallForCompetitionDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-call-for-competition";

export default async function editCallForCompetition(
  dto: EditCallForCompetitionDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
