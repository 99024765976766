export default class BiMap<K, V> {
  static NULL_SYMBOL = "null";
  map: Map<any, any> = new Map();
  reversemap: Map<any, any> = new Map();
  constructor(inputMap: Map<string | number, any> = new Map()) {
    inputMap.forEach((value, key) => {
      this.put({ key, value });
    });
  }

  put({ key, value }: { key: any; value: any }) {
    key = BiMap.NULL_SYMBOL == key ? null : key;
    value = BiMap.NULL_SYMBOL == value ? null : value;
    this.map.set(key, value);
    this.reversemap.set(value, key);
  }

  values(): Array<V> {
    return Array.from(this.map.values());
  }

  keys(): Array<K> {
    return Array.from(this.map.keys());
  }

  byValue(val: V): K {
    return this.reversemap.get(val);
  }

  byKey(key: K): V {
    return this.map.get(key);
  }
}
