
import MainThreeColumns from "@/layouts/MainThreeColumns.vue";
import RouteMixin from "@/mixins/route";
import { defineComponent } from "vue";

export default defineComponent({
  name: "500-view",
  mixins: [RouteMixin],
  components: {
    MainThreeColumns,
  },
});
