import {
  subDays,
  parseISO,
  isPast,
  isToday,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isBefore,
  startOfToday,
  subMilliseconds,
  formatDuration,
  differenceInCalendarDays,
} from "date-fns";
import { format as formatTZ } from "date-fns-tz";
import { de } from "date-fns/locale";
import i18n from "@/i18n";

const DEFAULT_WITHOUT_WEEKDAY = getLocalizedOrDefault(
  "common.date.formats.withoutWeekday",
  "dd.MM.yyyy - HH:mm 'Uhr'"
);

const DEFAULT_WITH_WEEKDAY = getLocalizedOrDefault(
  "common.date.formats.withWeekday",
  "eeee, dd.MM.yyyy HH:mm 'Uhr' (z)"
);
const DEFAULT_WITH_WEEKDAY_WITHOUT_TIME = getLocalizedOrDefault(
  "common.date.formats.withoutTime",
  "eeee, dd.MM.yyyy"
);
const DEFAULT_WITH_DATE_ONLY = getLocalizedOrDefault(
  "common.date.formats.withDateOnly",
  "dd.MM.yyyy"
);
const DEFAULT_WITH_TIME_ONLY = getLocalizedOrDefault(
  "common.date.formats.withTimeOnly",
  "HH:mm 'Uhr'"
);

const rtf = new Intl.RelativeTimeFormat();

function getLocalizedOrDefault(key: string, defaultValue: string): string {
  return i18n.global.te(key) ? (i18n.global.t(key) as string) : defaultValue;
}

function isMidnight(date: Date) {
  return (
    date.getHours() == 0 &&
    date.getMinutes() == 0 &&
    date.getSeconds() == 0 &&
    date.getMilliseconds() == 0
  );
}

export class DateString {
  differenceInDays: number;
  differenceInCalendarDays: number;
  differenceInHours: number;
  differenceInMinutes: number;
  constructor(
    private date: Date,
    private nowDate?: Date
  ) {
    if (this.nowDate == undefined) {
      this.nowDate = new Date();
    }
    const sub1Date = subMilliseconds(this.date, 1);
    this.differenceInCalendarDays = differenceInCalendarDays(
      this.date,
      this.nowDate
    );
    this.differenceInDays = differenceInDays(sub1Date, this.nowDate);
    this.differenceInHours = differenceInHours(sub1Date, this.nowDate);
    this.differenceInMinutes = differenceInMinutes(sub1Date, this.nowDate);
  }

  asDays(): {
    days: string;
    label: string;
    floatDays: number;
  } {
    const floatDays = this.differenceInMinutes / 1440;
    const [days, label] = formatDuration(
      // bei 0 Tagen soll trotzdem 1 Tag angezeigt werden
      { days: Math.floor(Math.max(floatDays, floatDays > 0 ? 1 : 0)) },
      {
        locale: de,
        format: ["days"],
        zero: true,
      }
    ).split(" ");
    return { days, label, floatDays };
  }

  asDeadline(): string {
    const timeElements = [];

    if (this.differenceInDays > 0) {
      const result = rtf.formatToParts(this.differenceInDays, "days");
      timeElements.push(result[1].value + result[2].value);
    }
    if (this.differenceInHours % 24 > 0) {
      const result = rtf.formatToParts(this.differenceInHours % 24, "hours");
      timeElements.push(result[1].value + result[2].value);
    }
    if (this.differenceInDays == 0 && this.differenceInMinutes % 60 > 0) {
      const result = rtf.formatToParts(
        this.differenceInMinutes % 60,
        "minutes"
      );
      timeElements.push(result[1].value + result[2].value);
    }
    if (
      this.differenceInDays == 0 &&
      this.differenceInHours % 24 == 0 &&
      this.differenceInMinutes % 60 == 0
    ) {
      timeElements.push(
        i18n.global.t("common.date.parts.lessThanMinute") as string
      );
    }
    return (
      (i18n.global.t("common.date.parts.endsIn") as string) +
      " " +
      timeElements.join(", ")
    );
  }
}

export default {
  parse(parsee: string): Date {
    return parseISO(parsee);
  },
  firstDayOfTheWeek: 1,
  isToday,
  isPast(date: Date | string | null): boolean {
    if (date == null) {
      return false;
    }
    return isPast("string" === typeof date ? this.parse(date) : date);
  },
  isAtLeastYesterday(date: Date | string): boolean {
    date = "string" === typeof date ? this.parse(date) : date;
    return isBefore(date, startOfToday());
  },
  formatDateOnly(date: Date | string | null | undefined): string {
    if (date == null) {
      return "";
    }
    if (typeof date == "string" || date instanceof String) {
      date = new Date(date);
    }
    return this.format(date, DEFAULT_WITH_DATE_ONLY as string, {
      withMidnight: false,
    });
  },
  formatTimeOnly(date: Date | string | null | undefined): string {
    if (date == null) {
      return "";
    }
    if (typeof date == "string" || date instanceof String) {
      date = new Date(date);
    }
    return this.format(date, DEFAULT_WITH_TIME_ONLY as string, {
      withMidnight: false,
    });
  },
  formatWithoutWeekday(date: Date | string | null | undefined): string {
    if (date == null) {
      return "";
    }
    if (typeof date == "string" || date instanceof String) {
      date = new Date(date);
    }
    return this.format(date, DEFAULT_WITHOUT_WEEKDAY as string);
  },
  formatWithWeekday(
    date: Date | string | null | undefined,
    {
      withTime = true,
      withMidnight = true,
    }: { withTime: boolean; withMidnight: boolean } = {
      withTime: true,
      withMidnight: true,
    }
  ): string {
    if (date == null) {
      return "";
    }
    if (typeof date == "string" || date instanceof String) {
      date = new Date(date);
    }
    if (withTime) {
      return this.format(date, DEFAULT_WITH_WEEKDAY as string, {
        withMidnight,
      });
    } else {
      return this.format(date, DEFAULT_WITH_WEEKDAY_WITHOUT_TIME as string, {
        withMidnight,
      });
    }
  },
  format(
    date: Date,
    dateFormatString: string,
    { withMidnight = true }: { withMidnight: boolean } = { withMidnight: true }
  ): string {
    let result = "";
    if (withMidnight && isMidnight(date)) {
      result = formatTZ(
        subDays(date, 1),
        dateFormatString.replaceAll("HH", "kk"),
        {
          locale: de,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );
    } else {
      result = formatTZ(date, dateFormatString, {
        locale: de,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
    return result;
  },
  differenceToToday(dateA: Date): DateString {
    return new DateString(dateA);
  },
  difference(dateA: Date, dateB: Date): DateString {
    return new DateString(dateA, dateB);
  },
  isDifferenceLessThan(
    dateA: Date,
    dateB: Date,
    difference: number,
    { allowBelowZero = false } = { allowBelowZero: false }
  ) {
    const diff = dateA.getTime() - dateB.getTime();
    return (diff > 0 || allowBelowZero) && diff < difference;
  },
  getYYYYMMDDFromUTC(utcDate?: string): string | undefined {
    if (!utcDate) {
      return undefined;
    } else {
      const parsed = parseISO(utcDate);
      return this.format(parsed, "yyyy-MM-dd");
    }
  },
  getHHFromUTC(utcDate?: string): string {
    if (!utcDate) {
      return "24";
    } else {
      const parsed = parseISO(utcDate);
      return this.format(parsed, "HH");
    }
  },
  getmmFromUTC(utcDate?: string): string {
    if (!utcDate) {
      return "00";
    } else {
      const parsed = parseISO(utcDate);
      return this.format(parsed, "mm");
    }
  },
  formatISO(date?: Date): string | undefined {
    if (date == undefined) {
      return undefined;
    } else {
      return date.toISOString().replace(/....Z/, "Z");
    }
  },
};
