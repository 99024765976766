import { AwardingClient } from "../client";
import {
  Filter,
  getBestMatchPageFromResult,
  isQueryUnexpectedlyEmpty,
  QueryResponse,
} from "../query";
import UrlBuilder from "../urlBuilder";
import deserializer from "@/utils/EvoClient/deserializer";
import deepMerge from "lodash.merge";
import { Paginationable, RootState } from "@/store/types";
import store from "@/store";

export type QueryClient = { get(url: string): any };
export type QuerySparseField = { type: string; value: string[] };
export type QueryPagination = Pick<
  Paginationable["pagination"],
  "sort" | "page" | "retryOnUnexpectedEmpty"
> & { noKeepMeta?: boolean };
export type QueryExecutor<T, Q extends QueryArgs = QueryArgs> = (
  q?: Q
) => Promise<QueryResponse<T>>;
export type QueryArgs<
  Fil = Filter[],
  Inc = readonly string[],
  Sor extends readonly string[] = readonly string[],
> = {
  filter?: Fil;
  includes?: Inc;
  sort?: Sor[number];
  params?: Record<string, string>;
  pagination?: QueryPagination;
  endpointArgs?: any;
  noDefaultFilter?: boolean;
  fields?: QuerySparseField[];
};
//Die Generics dienen eher als Type Aliase, d.h. ich garantiere dass possibleFilters genau der Type ist, der T ist.
type ListQueryFunctionGeneratorOpts<
  Fil extends readonly string[] = readonly string[],
  Incl extends readonly string[] = readonly string[],
> = {
  addIdsOfRelationshipsToResult?: string[]; // fügt die Ids der Daten der hier angegebenen Relationships in das Ergebnis ein
  alwaysIncludes?: Incl[number][]; //includes, die bei jeder Anfrage inkludiert werden
  alwaysFilter?: LiteralFilter<Fil[number]>[]; //filter, die bei jeder Anfrage gelten
  alwaysFields?: { type: string; value: string[] }[]; //Sparse Fields, field ist type, z.B. von einer relationship oder dem allgemeinen Attribute
  client?: QueryClient;
  defaultFilter?: LiteralFilter<Fil[number]>[]; //Filter, die nur gelten, wenn keine anderne Filter gesetzt sind
};

const defaults: ListQueryFunctionGeneratorOpts<any, any> = {
  addIdsOfRelationshipsToResult: [],
  client: AwardingClient,
};

const defaultQ = {
  filter: [],
  includes: [] as any,
  pagination: undefined as any,
  params: {},
} as QueryArgs<any, any, any>;

type LiteralFilter<T extends string> = {
  field: T;
  value?: string | ((rootState: RootState) => string);
};

export type LiteralFilterArray<T extends any[]> = LiteralFilter<T[number]>[];

export default function ListQueryFunctionGenerator<
  ResultType extends unknown[],
  PossibleFilter extends readonly string[] = readonly string[],
  PossibleIncludes extends readonly string[] = readonly string[],
  PossibleSorts extends readonly string[] = readonly string[],
>(
  endpoint: string | ((args: any) => string),
  {
    alwaysIncludes = [],
    alwaysFilter = [],
    alwaysFields = [],
    addIdsOfRelationshipsToResult = [],
    defaultFilter,
    client = AwardingClient,
  }: ListQueryFunctionGeneratorOpts<PossibleFilter, PossibleIncludes> = defaults
): QueryExecutor<
  ResultType,
  QueryArgs<
    LiteralFilter<PossibleFilter[number]>[],
    PossibleIncludes[number][],
    PossibleSorts
  >
> {
  return async function queryExecutor(
    q: QueryArgs<
      LiteralFilter<PossibleFilter[number]>[],
      PossibleIncludes[number][],
      PossibleSorts
    > = defaultQ as any
  ): Promise<QueryResponse<ResultType>> {
    let _defaultfilter = defaultFilter;
    if (q.noDefaultFilter) {
      _defaultfilter = undefined;
    }
    const _endpoint =
      typeof endpoint == "string" ? endpoint : endpoint(q.endpointArgs);
    const url = getUrl(
      _endpoint,
      q as any,
      alwaysIncludes,
      alwaysFilter,
      alwaysFields,
      _defaultfilter
    );
    let result = await client.get(url);
    if (
      q.pagination?.retryOnUnexpectedEmpty &&
      isQueryUnexpectedlyEmpty(result.data)
    ) {
      result = await AwardingClient.get(
        getUrl(
          _endpoint,
          deepMerge(q, {
            pagination: {
              page: getBestMatchPageFromResult(result.data),
            } as any,
          }),
          alwaysIncludes,
          alwaysFilter,
          alwaysFields,
          _defaultfilter
        )
      );
      if (isQueryUnexpectedlyEmpty(result.data)) {
        console.warn(
          "Making second additional call, since query result was unexpectedly empty"
        );
        result = await AwardingClient.get(
          getUrl(
            _endpoint,
            deepMerge(q, {
              pagination: null,
            }),
            alwaysIncludes,
            alwaysFilter,
            alwaysFields,
            _defaultfilter
          )
        );
      }
    }

    return await deserializer.deserialize(result.data, {
      keepMeta: !q.pagination?.noKeepMeta,
      relations: addIdsOfRelationshipsToResult,
    });
  };
}

function getUrl(
  ENDPOINT: string,
  q: QueryArgs<any, any, any>,
  alwaysIncludes: ListQueryFunctionGeneratorOpts["alwaysIncludes"] = [],
  alwaysFilters: ListQueryFunctionGeneratorOpts["alwaysFilter"] = [],
  alwaysFields: ListQueryFunctionGeneratorOpts["alwaysFields"] = [],
  defaultFilter: ListQueryFunctionGeneratorOpts["defaultFilter"]
): string {
  const urlBuilder = UrlBuilder.fromBaseUrl(ENDPOINT);
  let filters = [...alwaysFilters, ...(q.filter || [])];
  const fields = [...alwaysFields, ...(q.fields || [])];
  if (defaultFilter && filters.length == 0) {
    filters = defaultFilter;
  }
  const sort = q.sort || (q.pagination && q.pagination.sort);
  if (filters.length > 0) {
    filters.forEach((f) => {
      if (isRelationshipFilter(f)) {
        const [relationship, field] = f.field.split("::");
        urlBuilder.addRelationshipFilter(
          relationship,
          field,
          typeof f.value == "function" ? f.value(store.state) : f.value
        );
      } else {
        if (f.value !== null) {
          urlBuilder.addFilter(
            f.field,
            typeof f.value == "function" ? f.value(store.state) : f.value,
            { operator: f.operator }
          );
        }
      }
    });
  }
  urlBuilder.addParamsList(q.params);
  [...alwaysIncludes, ...(q.includes || [])].forEach((x) =>
    urlBuilder.addIncludes(x)
  );

  if (sort) {
    urlBuilder.addSort(sort);
  }
  if (q.pagination) {
    urlBuilder.addPagination(q.pagination!.page);
  }
  if (fields.length > 0) {
    for (const field of fields) {
      urlBuilder.addFields(field.type, field.value.join(","));
    }
  }

  return urlBuilder.toString();
}

function isRelationshipFilter(filter: Filter): boolean {
  return filter.field.includes("::");
}
