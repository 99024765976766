import { inject } from "vue";

export function useMenuInjections() {
  const isMenu = inject("isMenu", false);
  const closeFocusId = inject<string>("closeFocusId", "");

  return {
    isMenu,
    closeFocusId,
  };
}

export function useErrorBoundaryInjections() {
  const errorBoundaryId = inject("errorBoundaryId", "");

  return {
    errorBoundaryId,
  };
}
