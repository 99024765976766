
import { defineComponent } from "vue";
import LoadingMixin from "@/mixins/loading";
import { Lockable } from "@/model/Lockable";
import { LockChecker } from "@/service/types";
import { RESET_LOADING_FOR_ACTION } from "@/store/modules/loading.actions";
import { PropType } from "vue";

const fibonaccis = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55];
const HOW_LONG_TILL_SHOW_MESSAGES = 3000;

export interface PollResult {
  result: any;
  totalPollingTime: number;
}

/**
 * Eine render-free Komponente, welche Polling übernimmt. <br>
 * Pollt in Fibonacci-Schritten solange, bis die Eigenschaft `locked` des übergebenen `Lockable` `false` ist.
 */

export default defineComponent({
  name: "base-poll-action",
  mixins: [LoadingMixin],
  props: {
    /**
     * Ein Lockchecker, z.B. der AwardProcedureService.
     */
    checker: {
      type: Object as PropType<LockChecker>,
      required: true,
    },
    /**
     * Ein Lockable. z.B. eine AwardProcedure oder ein CallForTenders
     */
    lockable: {
      type: Object as PropType<Lockable>,
      required: true,
    },
    /**
     * Mindestzeit die gewartet wird, bis ein Ergebnis geliefert wird.
     * Damit soll ein 'Flackern' von Spinnern etc. verhindert werden.
     */
    timeTillFirstResult: {
      type: Number, //z.B. für Mindestzeit für Spinner o.Ä. damit es nicht flickert
      default: 1000,
    },
    /**
     * Anzahl an Millisekunden, die als Basis für das Timeout zwischen Pollversuchen dient. Wird mit der Fibonacci-Sequenz aufsteigend multipliziert.<br>
     * Bei `1000` wird z.B. `1s, 1s, 2s, 3s, 5s, 8s, 13s, 21s, 34s, 55s, 55s, 55s` etc. gewartet.
     */
    timeout: {
      type: Number,
      default: 1000,
    },
  },
  render(): any {
    return null;
  },
  data() {
    return {
      timeoutId: NaN,
      firstPollTimeoutId: NaN,
      fiboIndex: -1,
      startTime: -1,
    };
  },
  created() {
    this.resetPoll();
    if (this.lockable && this.lockable.locked) {
      this.poll();
    }
  },
  unmounted() {
    this.resetPoll();
  },
  methods: {
    async poll() {
      this.fiboIndex =
        this.fiboIndex + 1 == fibonaccis.length
          ? this.fiboIndex
          : this.fiboIndex + 1;
      try {
        let result = await this.checker.checkLock();
        await this.handleLockResult({
          result: result,
          totalPollingTime: Date.now() - this.startTime,
        } as PollResult);
      } finally {
        if (this.lockable.locked) {
          this.timeoutId = setTimeout(
            this.poll,
            this.timeout * fibonaccis[this.fiboIndex]
          );
        }
      }
    },
    resetPoll() {
      clearTimeout(this.timeoutId);
      clearTimeout(this.firstPollTimeoutId);
      this.fiboIndex = -1;
      this.startTime = Date.now();
    },
    async handleLockResult({ result, totalPollingTime }: PollResult) {
      if (isDone()) {
        /**
         * Feuert, wenn Polling komplett beendet ist.
         */
        this.$emit("finish");
        await this.$store.dispatch(RESET_LOADING_FOR_ACTION);
      } else if (
        isTimeUntilNotificationsShouldBeShownOver.call(this) ||
        this.anyLoading == false
      ) {
        if (this.anyLoading == true) {
          await this.$store.dispatch(RESET_LOADING_FOR_ACTION);
        }
        await this.checker.updateLockInStore(result);
        this.$emit("show-info");
      }

      function isDone() {
        return result.locked == false;
      }

      function isTimeUntilNotificationsShouldBeShownOver(this: any) {
        return (
          this.anyLoading == true &&
          totalPollingTime > HOW_LONG_TILL_SHOW_MESSAGES
        );
      }
    },
  },
  computed: {
    currentlyLocked(): boolean {
      return this.lockable.locked;
    },
  },
  watch: {
    currentlyLocked(val) {
      this.resetPoll();
      if (val) {
        /**
         * Feuert, wenn Polling gestartet wird (`locked` ist `true`)
         */
        this.$emit("start");
        this.firstPollTimeoutId = setTimeout(
          this.poll,
          this.timeTillFirstResult
        );
      }
    },
  },
});
