import { MarkOfferAdmissionStatusDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "c/mark-offer-admission-status";

export default async function markOfferAdmissionStatus(
  offerDto: MarkOfferAdmissionStatusDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, offerDto);
}
