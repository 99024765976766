export const RESET_CALL_FOR_COMPETITION_STORE =
  "Entfernt alle Daten aus dem Call for Competition-Store";
export const CREATE_CALL_FOR_COMPETITION = "Erzeugt einen Call for Competition";
export const UPDATE_CALLS_FOR_COMPETITION =
  "Refresht die Calls for Competition Liste";
export const DELETE_CALL_FOR_COMPETITION = "Löschen einen Teilnahmewettbewerb";
export const EDIT_CALL_FOR_COMPETITION = "Editiert die Call For Competition";
export const PUBLISH_CALL_FOR_COMPETITION =
  "Veröffentliche ein Call For Competition";

export const UPDATE_LOCK = "Update das Call for Competition Lock";
export const CHECK_LOCK = "Prüfe das Call For Competition Lock";

export const DISMISS_NOTIFICATION =
  "Call-For-Competition Notification bestätigen";
export const CHANGE_REQUEST_FOR_PARTICIPATION_PERIOD =
  "Ändere die Participation Period";

export const COMPLETE_CALL_FOR_COMPETITION = "Call For Competition abschließen";
export const OPEN_REQUESTS_FOR_PARTICIPATION =
  "Öffne alle Requests for Participation gleichzeitig";
