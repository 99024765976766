import { AwardUnit } from "@/model/AwardUnit";
import ListQueryFunctionGenerator from "@/utils/EvoClient/query/ListQueryFunctionGenerator";
import { CommonFilters } from "../query";

const ENDPOINT = "award-units";

export type PossibleAwardUnitsFilters = [
  "awardProcedure",
  "offer",
  "requestForParticipation",
];
export type PossibleAwardUnitsIncludes = ["awardProcedure"];
export type PossibleAwardUnitsSorts = ["lotNumber", "-lotNumber"];

export const supportedFilter = {
  awardProcedure: CommonFilters.awardProcedure,
};

export default ListQueryFunctionGenerator<
  AwardUnit[],
  PossibleAwardUnitsFilters,
  PossibleAwardUnitsIncludes,
  PossibleAwardUnitsSorts
>(ENDPOINT, {
  defaultFilter: [supportedFilter.awardProcedure],
});
