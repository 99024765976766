import { AwardingClient } from "@/utils/EvoClient";
import { DeleteDocumentFromShipmentDto } from "../dto";

const ENDPOINT = "c/delete-tender-document";

export default async function DeleteTenderDocument(
  dto: DeleteDocumentFromShipmentDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
