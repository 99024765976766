import { RequestInterceptor } from "./types";

export default {
  intercept(request) {
    trimRequestData(request.data);
  },
} as RequestInterceptor;

function trimRequestData(data: Record<string, any>): void {
  if (data) {
    Object.keys(data).forEach((x) => {
      //Kann nicht ohne weiteres mit trim verbunden werden, da Trim rekursiv ist
      if (x.startsWith("_")) {
        delete data[x];
        return;
      }
    });
    trimRecordData(data);
  }
}

function trimRecordData(data: Record<string, any>): void {
  Object.keys(data).forEach((x) => {
    if (typeof data[x] == "string") {
      data[x] = data[x].trim();
    } else if (
      typeof data[x] == "object" &&
      data[x] != null &&
      !Array.isArray(data[x]) &&
      Object.keys(data[x]).length > 0
    ) {
      trimRecordData(data[x]);
    } else if (Array.isArray(data[x])) {
      trimArray(data[x]);
    }
  });
}

function trimArray(data: Array<any>) {
  for (let index = 0; index < data.length; index++) {
    if (typeof data[index] == "string") {
      data[index] = data[index].trim();
    } else if (
      typeof data[index] == "object" &&
      data[index] != null &&
      !Array.isArray(data[index]) &&
      Object.keys(data[index]).length > 0
    ) {
      trimRecordData(data[index]);
    } else if (Array.isArray(data[index])) {
      trimArray(data[index]);
    }
  }
}
