import { RouteRecordRaw } from "vue-router";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import LoadingService from "@/service/LoadingService";
import i18n from "@/i18n";
import {
  UPDATE_CURRENT_NOTICE,
  UPDATE_NOTICES,
} from "@/store/modules/notice.actions";
import Breadcrumbs from "@/router/breadcrumbs";
import { ROUTE_NOTICE_DETAILS, ROUTE_NOTICE_LIST } from "@/router/routes";

const Notices = () =>
  import(
    /* webpackChunkName: "notices-chunk" */ "@/views/notices/notice-frame.vue"
  );
const NoticeList = () =>
  import(
    /* webpackChunkName: "notices-chunk" */
    "@/views/notices/" + "notice-list.vue"
  );
const NoticeDetails = () =>
  import(
    /* webpackChunkName: "notices-chunk" */
    "@/views/notices/" + "notice-details.vue"
  );

export default {
  path: "notices",
  component: Notices,
  beforeEnter(to, from, next) {
    LoadingService.addLoadAction(UPDATE_NOTICES, {
      awardProcedureId: to.params.awardProcedureId,
    });
    LoadingService.addLoadAction(
      GET_AWARD_PROCEDURE_AS_CURRENT,
      to.params.awardProcedureId
    );
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.notices"),
  },
  children: [
    {
      path: "",
      name: ROUTE_NOTICE_LIST,
      component: NoticeList,
      meta: {
        breadCrumbs: Breadcrumbs.Notices,
        title: i18n.global.t("route-titles.notices"),
        loadRoute(dispatcher, { awardProcedureId }) {
          //Da wir das Listen neu Laden auch außerhalb des Routers ausführen müssen
          //wenn wir auf der Liste Elemente löschen
          //Lagern wir das Laden allgemein aus
          return [
            dispatcher.dispatch(UPDATE_NOTICES, { awardProcedureId }),
            dispatcher.dispatch(
              GET_AWARD_PROCEDURE_AS_CURRENT,
              awardProcedureId
            ),
          ];
        },
      },
      beforeEnter(to, from, next) {
        to.meta!.loadRoute!(LoadingService, to.params);
        next();
      },
    },
    {
      path: ":noticeId",
      name: ROUTE_NOTICE_DETAILS,
      component: NoticeDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CURRENT_NOTICE, {
          noticeId: to.params.noticeId,
        });
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.notices"),
        breadCrumbs: Breadcrumbs.Notices,
        parent: ROUTE_NOTICE_LIST,
      },
    },
  ] as RouteRecordRaw[],
} as RouteRecordRaw;
