
import { defineComponent } from "vue";
import { GlobalDisplayError } from "@/store/modules/notification.types";
import ErrorHandlerService from "@/service/ErrorHandlerService";

export default defineComponent({
  name: "base-global-error-output",
  data() {
    return {
      shaking: false,
    };
  },
  props: {
    silent: Boolean,
    alertId: String,
  },
  methods: {
    shake(): void {
      setTimeout(() => {
        this.shaking = true;
      }, 120);
    },
    focusDialogIfOpened(ev: KeyboardEvent): void {
      const mainDialogContent = document.getElementById(
        this.$ids.SHOWN_MAIN_DIALOG
      );
      if (mainDialogContent) {
        ev.preventDefault();
        const allButtonsInDialog = mainDialogContent.querySelectorAll(
          "button:not([disabled])"
        );
        if (ev.shiftKey) {
          const lastButton = allButtonsInDialog[allButtonsInDialog.length - 1];
          (lastButton as HTMLButtonElement).focus();
        } else {
          const firstButton = allButtonsInDialog[0];
          (firstButton as HTMLButtonElement).focus();
        }
      }
    },
    announceError(message: string): void {
      //Das ist nur nötig, wenn er erneut auftritt und nicht weggeklickt wurde, da er beim initialen Auftauchen schon vorgelesen wird
      if (!this.silent) {
        this.$utils.announceAssertive(message);
      }
    },
  },
  computed: {
    error(): GlobalDisplayError {
      return this.$store.state.notifications.globalError;
    },
    dynamicClass(): Record<string, boolean> {
      return {
        shake: this.shaking,
      };
    },
    dynamicStyle(): Record<string, string> {
      return {
        visibility: this.silent ? "hidden" : "inherit",
      };
    },
    hasError: {
      get(): boolean {
        return this.$store.getters["hasGlobalError"];
      },
      set(val: boolean): void {
        if (!val) {
          ErrorHandlerService.resetGlobalError();
        }
      },
    },
  },
  watch: {
    error(val: GlobalDisplayError, oldval: GlobalDisplayError): void {
      this.shaking = false;
      if (oldval.timestamp != -1 && val.timestamp != -1) {
        //Ein Fehler wurde ein weiteres mal ausgelöst, ohne dass der alte Fehler weggeklickt wurde / behoben wurde
        this.shake();
        this.announceError(val.message);
      }
    },
  },
});
