export const SAVE_TOKEN_DATA = "Verändert die Daten des aktuellen Users";
export const CLEAR_CURRENT_USER = "Entfernt die Daten des aktuellen Users";

export const SAVE_CURRENT_USER =
  "Speicher die Daten des aktuellen Benutzers im Store";
export const SAVE_CURRENT_AUTHORITY =
  "Speicher die Daten der aktuellen Vergabestelle im Store";

export const SAVE_UNASSIGNED_USERS =
  "Speicher die Daten der Vergabestellenliste im Store";
export const SAVE_AUTHORITY_ROLES =
  "Speicher die Daten der Vergabestellenrollenliste im Store";
export const SAVE_CONTEXT_USERS =
  "Speicher die Daten der Kontextbenutzerliste im Store";
export const SAVE_CONTEXT = "Speicher die Daten des Kontextes im Store";
