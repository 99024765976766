import { AwardingClient } from "@/utils/EvoClient";
import { RemoveInviteeDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/remove-invitee";

export default async function removeInvitee(
  dto: RemoveInviteeDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
