//@ts-nocheck
import { createVuetify } from "vuetify";
import { de } from "vuetify/locale";
import "vuetify/styles";

export const opts = {
  theme: {
    disable: true,
  },
  locale: {
    defaultLocale: "de",
    messages: { de },
  },
};

export default new createVuetify(opts);
