import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { CallForTenders } from "@/model/CallForTenders";
import UrlBuilder from "../urlBuilder";
import { AwardUnit } from "@/model/AwardUnit";

// prettier-ignore
const ENDPOINT = (callForTendersId: string) => `calls-for-tenders/${callForTendersId}`;

export default async function getCallForTenders(
  callForTendersId: string,
  { includeLots = true } = { includeLots: true }
): Promise<CallForTenders> {
  const url = UrlBuilder.fromBaseUrl(ENDPOINT(callForTendersId));
  if (includeLots) {
    url.addIncludes("awardUnits");
    url.addFields("awardUnit", "lotNumber,id,title");
  }
  const result = await AwardingClient.get(url.toString());

  const deserialzed = await deserializer.deserialize(result.data, {
    relations: ["invitees", "awardUnits"],
  });

  if (includeLots) {
    //aktuell werden die Includes im Backend nach Erzeugung sortiert.
    deserialzed.awardUnits.sort((a: AwardUnit, b: AwardUnit) => {
      if (a != null && b != null) {
        return a.lotNumber! - b.lotNumber!;
      }
    });
  }

  return deserialzed as CallForTenders;
}
