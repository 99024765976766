import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_chip = _resolveComponent("evonum-chip")!

  return (_ctx.chipProps)
    ? (_openBlock(), _createBlock(_component_evonum_chip, {
        key: 0,
        class: "common-chip-state",
        overrideStyling: _ctx.chipProps.styling
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.chipProps.text), 1)
        ]),
        _: 1
      }, 8, ["overrideStyling"]))
    : _createCommentVNode("", true)
}