
import { defineComponent, PropType } from "vue";
import ViewportMixin from "@/mixins/viewport";
import PartialCardListSelectSmall from "@/components/partials/partial-card-list-select-small.vue";

export default defineComponent({
  name: "partial-card-list-sort",
  mixins: [ViewportMixin],
  inheritAttrs: false,
  props: {
    items: { required: true, type: Array as PropType<Array<any>> },
    loading: {},
    modelValue: {},
  },
  components: {
    PartialCardListSelectSmall,
  },
  data() {
    return {
      bottomSheetActive: false,
    };
  },
  methods: {
    close() {
      this.bottomSheetActive = false;
    },
  },
  watch: {
    isExtraSmall(val) {
      if (!val) {
        this.bottomSheetActive = false;
      }
    },
  },
});
