import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Message } from "@/model/Message";
import { convertAttachments } from "@/model/Attachment";

// prettier-ignore
const ENDPOINT = (conversationId: string) =>`conversations/${conversationId}/messages`

export default async function getConversation(
  conversationId: string
): Promise<Message[]> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(conversationId))
      .addIncludes("attachments")
      .addSort("sentAt")
      .toString()
  );

  const deserialized = await deserializer.deserialize(result.data);
  deserialized.forEach((x: any) => {
    x.attachments = convertAttachments(x.attachments);
  });
  return deserialized;
}
