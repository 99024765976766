import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"display":"flex","align-items":"center"},
  class: "base-allowedaction-group-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_button_group_button = _resolveComponent("evonum-button-group-button")!
  const _component_base_coach_mark_action = _resolveComponent("base-coach-mark_action")!
  const _component_base_disabler = _resolveComponent("base-disabler")!

  return (_openBlock(), _createBlock(_component_base_disabler, {
    action: _ctx.action,
    actionable: _ctx.actionable,
    allowedValue: _ctx.allowedValue,
    "stay-rendered": ""
  }, {
    default: _withCtx(({ canPerformAction }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_evonum_button_group_button, _mergeProps(_ctx.$attrs, {
          buttonLike: _ctx.buttonLike,
          selected: _ctx.groupValue === _ctx.value,
          style: {"flex":"1 0 auto"},
          readonly: _ctx.readonly || !canPerformAction || _ctx.disabled,
          value: _ctx.value,
          disabled: !canPerformAction || _ctx.disabled
        }), {
          default: _withCtx(() => [
            _createElementVNode("span", null, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]),
          _: 2
        }, 1040, ["buttonLike", "selected", "readonly", "value", "disabled"]),
        _createVNode(_component_base_coach_mark_action, {
          action: _ctx.action,
          i18nVariant: _ctx.i18nVariant,
          "data-cy": "base-allowedaction-group-button-coachmark",
          actionable: _ctx.actionable,
          allowedValue: _ctx.allowedValue
        }, null, 8, ["action", "i18nVariant", "actionable", "allowedValue"])
      ])
    ]),
    _: 3
  }, 8, ["action", "actionable", "allowedValue"]))
}