
import { defineComponent, h } from "vue";
import { getUniqueId } from "@/utils/ids";
import ErrorHandler from "@/service/ErrorHandlerService";
import {
  START_LOADING_FOR_ACTION,
  STOP_LOADING_FOR_ACTION,
} from "@/store/modules/loading.actions";
import {
  SET_ERRORS_FOR_COMPONENT,
  SET_WARNING_FOR_COMPONENT,
} from "@/store/modules/notification.actions";
import { ServerValidationError } from "@/utils/errors";
import deepClone from "lodash.clonedeep";
import ForceDialog from "@/components/partials/partial-command-action-force-dialog.vue";
import { SET_DIRTY_OVERRIDE } from "@/store/actions";

export default defineComponent({
  name: "base-command-action",
  emits: [
    "sync-success",
    "register-load-id",
    "run-exit",
    "polling-start",
    "error",
    "polling",
    "loading",
  ],
  props: {
    action: { type: String, default: () => "" },
    /**
     * Ob ein direkter aufruf der "run" Methode die übergebenen Args als Args sieht
     */
    acceptArgsFromDirectCall: Boolean,
    args: {
      default: (): any => ({}), //string oder basecommandactionsarg
    },
    runId: {
      type: String,
      default: () => getUniqueId(),
    },
    /**
     * Schicke Loading event nur wenn diese Komponente ihn selbst ausgelöst hat
     */
    loadingOnOwnTrigger: Boolean,
    /**
     * Wie lange ein Command mindestens laden soll, um z.B. Animationen nicht nur
     * extrem kurz anzuzeigen.
     */
    minLoadTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      files: [],
      running: false,
      forceDialog: {
        show: false,
        errors: [] as any,
        force: false,
      },
      realRunId: "",
    };
  },
  mounted() {
    this.$emit("register-load-id", this.runId);
  },
  render(): any {
    const slot = this.$slots.default?.({
      isLoading: this.isLoading,
      run: this.run,
      files: this.files,
    });

    //Wenn die Action forcen könnte, bereite schonmal den Dialog vor...
    if (this.$actionmeta.getInfo(this.action!).force) {
      return [
        slot,
        h(ForceDialog as any, {
          showDialog: this.forceDialog.show, //...aber zeige ihn nicht an (show ist false per default)
          dialogErrors: this.forceDialog.errors,
          action: this.action,
          //Bei bestätigen setze Force True und blende dich aus
          onConfirmed: () => {
            this.forceDialog.force = true;
            this.forceDialog.show = false;
            this._run(undefined);
          },
          onCancelled: () => {
            this._endRun();
          },
          //Bei Abbrechen blendet er sich über die default Dialog-Mechanismen aus
          //Deswegen gibt es hier kein "cancel" o.Ä.
        }),
      ];
    }
    return slot;
  },
  computed: {
    isLoading(): boolean {
      return (
        this.$store.state.loading.all[this.runId] === true &&
        (this.running || !this.loadingOnOwnTrigger)
      );
    },
    internals(): Partial<{
      _fixErrorBoundaryId: string;
      _fixRunId: string;
      _closeFocusId: string;
    }> {
      if (this.args && this.args._internals) {
        return {
          ...this.args._internals,
        };
      } else {
        return {};
      }
    },
    realBoundaryId(): string {
      return (
        this.internals._fixErrorBoundaryId || (this as any).errorBoundaryId
      );
    },
  },
  inject: ["errorBoundaryId"],
  methods: {
    async run(args: any) {
      if (
        this.$actionmeta.getInfo(this.action).fileChoice &&
        this.args?._files == undefined &&
        args?._files == undefined
      ) {
        this._selectFile(this.acceptArgsFromDirectCall ? args : undefined);
      } else {
        if (this.acceptArgsFromDirectCall) {
          await this._run(args);
        } else {
          await this._run(undefined);
        }
      }
    },
    _selectFile(args: any): void {
      const multiple = this.$actionmeta.getInfo(this.action).fileChoice
        ?.multiple;
      let ele = document.getElementById(
        this.$ids.GLOBAL_FILE_UPLOAD
      ) as HTMLInputElement;
      ele.value = "";
      if (multiple) {
        ele.setAttribute("multiple", "true");
      } else {
        ele.removeAttribute("multiple");
      }
      ele.oninput = async (event: any) => {
        this.files = event.target.files ?? [];
        if (this.files.length > 0) {
          await this._run(args);
        }
      };
      ele.click();
    },
    _enrichArgs(this: any, _args = this.args): any {
      let fileChoice =
        this.$actionmeta.getInfo(this.action).fileChoice &&
        _args._files == undefined;
      let args = deepClone(_args);
      if (fileChoice) {
        args = Object.assign({}, _args);
        args._files = this.files;
      }
      if (this.$actionmeta.getInfo(this.action).force) {
        args.force = this.forceDialog.force; //Beim bestätigen Force durchlauf ist dies hier true, weswegen es dann geforct wird
      }
      return args;
    },
    async _endRun() {
      this.running = false;
      if (this.$actionmeta.getInfo(this.action).overrideDirty) {
        await this.$store.dispatch(SET_DIRTY_OVERRIDE, false);
      }
      await this.$store.dispatch(STOP_LOADING_FOR_ACTION, this.realRunId);
      this.$emit("run-exit");
    },
    async _run(passedArgs: any) {
      let startTime = Date.now();
      this.running = true;
      let async = this.$actionmeta.getInfo(this.action).async;
      let storeAction = this.$actionmeta.getInfo(this.action).storeAction;
      const setDirtyOverride = this.$actionmeta.getInfo(
        this.action
      ).overrideDirty;
      const noHandleErrors = this.$actionmeta.getInfo(
        this.action
      ).handlesErrorsOnItsOwn;
      let args = this.acceptArgsFromDirectCall
        ? this._enrichArgs(passedArgs)
        : this._enrichArgs();
      const internals = this.acceptArgsFromDirectCall
        ? Object.assign({}, this.internals, passedArgs?._internals)
        : this.internals;
      this.realRunId = internals._fixRunId || this.runId;
      const realBoundaryId =
        internals._fixErrorBoundaryId || this.realBoundaryId;
      delete args._internals;
      this.$emit("register-load-id", this.realRunId);

      //Der ForceWert, der übertragen wird, würde schon in args.force stehen
      //Daher können wir hier die Componente zurücksetzen
      this.forceDialog.force = false;
      this.forceDialog.show = false;

      if (!noHandleErrors) {
        ErrorHandler.handleError(realBoundaryId);
      }
      if (setDirtyOverride) {
        await this.$store.dispatch(SET_DIRTY_OVERRIDE, true);
      }
      await this.$store.dispatch(START_LOADING_FOR_ACTION, this.realRunId);
      try {
        let result = await this.$store.dispatch(storeAction, args);
        if (!async) {
          this.$emit("sync-success", result);
          this.running = false;
          setTimeout(
            async () => {
              await this.$store.dispatch(
                STOP_LOADING_FOR_ACTION,
                this.realRunId
              );
              this.$emit("run-exit");
            },
            this.minLoadTime - (Date.now() - startTime)
          );
        } else {
          this.$emit("polling-start");
        }
      } catch (err) {
        if (err instanceof ServerValidationError && !noHandleErrors) {
          try {
            //Der Force Dialog wird angezeigt u. eingeblendet
            //Falls er bestätigt wird, wird direkt ein weiterer
            //Durchlaufe getriggert, wo args.force gleich true gesetzt wird
            this.forceDialog.errors = err.getIfWarningsOnlyOrThrow();
            this.forceDialog.show = true;
            this.$store.dispatch(SET_WARNING_FOR_COMPONENT, {
              id: realBoundaryId,
              warnings: this.forceDialog.errors,
            });
          } catch (err: any) {
            let errors = err.getPrintableErrors();
            this.$emit("error", errors);
            this.$store.dispatch(SET_ERRORS_FOR_COMPONENT, {
              id: realBoundaryId,
              errors,
              action: this.action,
            });
          }
        } else {
          await this._endRun();
        }
        //Falls Force Dialog angezeigt wird, verzögere das Beenden auf die Entscheidung dort
        //Falls der Force akzeptiert wird, kommt er nach dem Zweiten Backend Call wieder hier an
        //Nur mit "show":false
        //Falls er gecancelt wird, wird oben in 'cancelled' der run beendet
        //Das hat einige Vorteile, unter anderem dass der Button weiter spinnt etc.
        //Der größte Vorteil ist allerdings, dass ein potentielles Menü in dem der auslösende Button ist nicht zugeht
        //Wodurch sich der Dialog erst rendern kann
        if (!this.forceDialog.show) {
          await this._endRun();
        }
      }
    },
  },
  watch: {
    isLoading: {
      handler(val) {
        this.$emit("loading", val);
      },
    },
  },
});
