import { AwardingClient } from "@/utils/EvoClient";
import { EditAwardProcedureDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-award-procedure";

export default async function editAwardProcedure(
  eapDTO: EditAwardProcedureDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, eapDTO)) as AxiosResponse).data
    .id;
}
