import { resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex mr-2",
  style: {"width":"var(--evonum-button-height)","justify-content":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_avatar = _resolveComponent("common-avatar")!
  const _component_evonum_button_icon = _resolveComponent("evonum-button-icon")!
  const _component_evonum_card_field = _resolveComponent("evonum-card-field")!
  const _component_evonum_menu_popover_item = _resolveComponent("evonum-menu-popover-item")!
  const _component_evonum_menu_popover_divider = _resolveComponent("evonum-menu-popover-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_evonum_icon = _resolveComponent("evonum-icon")!
  const _component_common_menu_popover = _resolveComponent("common-menu-popover")!

  return (_openBlock(), _createBlock(_component_common_menu_popover, {
    "data-cy": "benutzer-extra-menu",
    class: "the-header-user-menu",
    style: {"--evonum-menu-offset-top":"var(--evo-header-menu-top)","--evonum-menu-offset-right":"0","--evonum-menu-item-padding":"0 8px","--evonum-button-height":"44px"},
    absolute: "",
    appendedLabel: _ctx.username
  }, {
    activator: _withCtx(({ props, label, buttonId, on, closeIcon }) => [
      _createVNode(_component_evonum_button_icon, _mergeProps({
        style: [{ '--evonum-button-height': _ctx.isLowHeight ? undefined : '48px' }, {"--v-theme-overlay-multiplier":"3"}]
      }, _toHandlers(on), props, {
        "data-cy": "user-menu-activator",
        "aria-label": label,
        id: buttonId,
        icon: closeIcon,
        "large-overlay": !_ctx.isLowHeight
      }), {
        default: _withCtx(() => [
          _createVNode(_component_common_avatar, {
            participantName: _ctx.twoLetters,
            size: _ctx.isLowHeight ? 38 : 48
          }, null, 8, ["participantName", "size"])
        ]),
        _: 2
      }, 1040, ["style", "aria-label", "id", "icon", "large-overlay"])
    ]),
    default: _withCtx(() => [
      (_ctx.isUserInUserService)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_evonum_menu_popover_item, {
              style: {"width":"max-content","max-width":"320px"},
              class: "the-header-user-menu__inner-avatar mb-2 mt-sm-2"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_common_avatar, {
                  class: "mr-2",
                  participantName: _ctx.twoLetters,
                  size: 44
                }, null, 8, ["participantName"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_evonum_card_field, {
                  heading: _ctx.fullname,
                  text: _ctx.username,
                  variant: "EMPHASIZE_HEADING"
                }, null, 8, ["heading", "text"])
              ]),
              _: 1
            }),
            (!_ctx.isExtraSmall)
              ? (_openBlock(), _createBlock(_component_evonum_menu_popover_divider, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_evonum_menu_popover_item, {
              "close-on-click": "",
              density: "compact",
              onClick: _ctx.editUser,
              style: {"min-width":"max-content"},
              "data-cy": "edit-user-action"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("users.menu.edit-user")), 1)
              ]),
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  style: {"width":"var(--evonum-button-height)"},
                  icon: "mdi-clipboard-text-outline",
                  class: "the-header__icon mr-2"
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_evonum_menu_popover_item, {
        "close-on-click": "",
        density: "compact",
        onClick: _ctx.logout,
        style: {"min-width":"max-content"},
        "data-cy": "logout-user-action"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.logout")), 1)
        ]),
        prepend: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_evonum_icon, {
              icon: "log-out",
              class: "the-header__icon"
            })
          ])
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["appendedLabel"]))
}