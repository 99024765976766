
import { FilterGroup, FilterOption } from "@/model/FilterGroup";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "partial-card-list-filter",
  props: {
    filterGroup: {
      type: Object as PropType<FilterGroup>,
      required: true,
    },
    prefixName: {
      type: String, //Store Prefix
    },
    currentFilterKey: {
      type: String,
    },
    isMobile: {
      type: Boolean,
    },
  },
  computed: {
    currentActiveFilter(): FilterOption {
      return (
        this.filterGroup.filters.find((x) => x.key == this.currentFilterKey) ??
        this.filterGroup.defaultFilter
      );
    },
    currentValue(): symbol | string | null {
      return this.currentActiveFilter.key;
    },
    currentHint(): string {
      return this.currentActiveFilter.description || "";
    },
    filterGroupOptions(): Array<{ label: string; value: any; key: any }> {
      return this.filterGroup.filters.map((x: any) => ({
        value: x.key,
        label: x.label,
        key: x.name,
      }));
    },
  },
});
