import store from "@/store";
import { SET_DIRTY_FOR_ID } from "@/store/actions";

const dirtyCheckers = new Map<symbol, DirtyChecker>();

type DirtyChecker = () => boolean;

export default {
  setDirty(id: string, isDirty: boolean): Promise<void> {
    return store.dispatch(SET_DIRTY_FOR_ID, { id, isDirty });
  },
  isDirty(): boolean {
    if (dirtyCheckers.size > 0) {
      return (
        Array.from(dirtyCheckers.values()).some((check) => check()) ||
        store.getters.anyDirty
      );
    } else {
      return store.getters.anyDirty;
    }
  },
  /**
   * Hauptsächlich gedacht für Dinge, die nicht über den Store im richtigen Moment
   * laufen können, z.B. die Prüfung des aktuell aktiven Feldes im Formular bei
   * komplettem schließen, weil der "blur" event zu lange dauert.
   * @param check
   * @returns Symbol, mit dem der Dirty Check wieder removed werden kann
   */
  addDirtyCheck(check: DirtyChecker): symbol {
    const key = Symbol();
    dirtyCheckers.set(key, check);
    return key;
  },
  removeDirtyCheck(key: symbol) {
    return dirtyCheckers.delete(key);
  },
};
