
import Focusable from "@/mixins/focusable";
import { defineComponent, ref, computed } from "vue";
import { useElementSize, watchDebounced } from "@vueuse/core";

export default defineComponent({
  name: "partial-card-list-pagination",
  inheritAttrs: false,
  mixins: [Focusable],
  setup(props) {
    const input = ref(null);
    const maxVisible = ref(props.totalVisible);
    const { width } = useElementSize(input);
    watchDebounced(
      width,
      (value) => {
        maxVisible.value = Math.floor(value / 44) - 2; //44 ist die Breite eines Buttons
      },
      { debounce: 100 }
    );
    const actualVisible = computed(() => {
      return Math.min(maxVisible.value, props.totalVisible) - 1; // -1 für gleiches Verhalten zu Vuetify 2
    });
    return { width, input, actualVisible };
  },
  props: {
    length: Number,
    totalVisible: {
      type: Number,
      default: 7,
    },
  },
});
