import { AwardingClient } from "@/utils/EvoClient";
import { CreateNoticeDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/create-notice";

export default async function createNotice(
  dto: CreateNoticeDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
