import {
  UPDATE_NOTICES,
  UPDATE_CURRENT_NOTICE,
  CREATE_NOTICE,
  EDIT_NOTICE,
  DELETE_NOTICE,
  PUBLISH_NOTICE,
  CHECK_LOCK,
  UPDATE_LOCK,
  DISMISS_NOTICE_NOTIFICATION,
  RESET_NOTICE_STORE,
  VALIDATE_NOTICE,
} from "@/store/modules/notice.actions";
import {
  SET_ALL_NOTICES,
  SET_CURRENT_NOTICE,
  SET_LOCK,
} from "@/store/modules/notice.mutations";
import { NoticeStoreState } from "@/store/modules/notice.types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import Client from "@/utils/EvoClient";
import {
  DeleteNoticeDto,
  DismissNoticeNotificationDto,
  PublishNoticeDto,
} from "@/utils/EvoClient/dto";
import i18n from "@/i18n";
import StoreNotificationService from "@/service/StoreNotificationService";
import { Lock } from "@/model/Lock";
import router from "@/router";
import { unref } from "vue";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import { Notice } from "@/model/Notice";
import { NoticeType } from "@/model/NoticeType";
import { ROUTE_NOTICE_LIST } from "@/router/routes";
import { PossibleNoticeFiltersArray } from "@/utils/EvoClient/query/GetAllNotices";

const store = {
  state: {
    lists: {
      CONTRACT: [],
      CONTRACT_AWARD: [],
      PRIOR_INFORMATION: [],
      PRIOR_INFORMATION_SHORTEN_TIME_LIMITS: [],
    },
    current: null,
  } as NoticeStoreState,
  actions: {
    async [RESET_NOTICE_STORE]({ commit }) {
      commit(SET_ALL_NOTICES, { notices: [] });
      commit(SET_CURRENT_NOTICE, null);
    },
    async [EDIT_NOTICE]({ dispatch }, args) {
      await Client.EditNotice(args);
      await dispatch(UPDATE_CURRENT_NOTICE, { noticeId: args.noticeId });
    },
    async [CREATE_NOTICE](
      { rootState, dispatch },
      { awardProcedureId, noticeType, updateAwardProcedure = false }
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const id = await Client.CreateNotice({
        awardProcedureId,
        noticeType,
      });
      if (updateAwardProcedure) {
        //createNotice Action hängt an Award Procedure
        await dispatch(GET_AWARD_PROCEDURE_AS_CURRENT, awardProcedureId);
      }
      await dispatch(UPDATE_NOTICES, {
        awardProcedureId,
      });
      return id;
    },

    async [UPDATE_NOTICES](
      { rootState, commit },
      { awardProcedureId, noticeType } = {}
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;

      const filter: PossibleNoticeFiltersArray = [
        {
          field: "awardProcedure",
          value: awardProcedureId,
        },
      ];
      noticeType && filter.push({ field: "noticeType", value: noticeType });
      const notices = await Client.GetAllNotices({ filter: filter });
      commit(SET_ALL_NOTICES, { notices, noticeType });
    },

    async [VALIDATE_NOTICE]({ dispatch }, { noticeId }) {
      await Client.ValidateNotice({ noticeId: noticeId });
      await dispatch(UPDATE_CURRENT_NOTICE, { noticeId });
    },

    async [UPDATE_CURRENT_NOTICE]({ commit, state }, { noticeId }) {
      noticeId = noticeId || state.current!.id;
      let notice = null;
      if (noticeId != null) {
        notice = await Client.GetNotice(noticeId);
      }
      commit(SET_CURRENT_NOTICE, notice);
    },

    async [DELETE_NOTICE]({ dispatch }, id: string) {
      await Client.DeleteNotice({
        noticeId: id,
      } as DeleteNoticeDto);
      const message = i18n.global.t(
        "award-procedures.details.notifications.notice-deleted"
      ) as string;
      const currentRoute = unref(router.currentRoute);
      if (ROUTE_NOTICE_LIST == currentRoute.name) {
        await Promise.all(
          currentRoute.meta!.loadRoute!({ dispatch }, currentRoute.params)
        );
      } else {
        await router.push({ name: ROUTE_NOTICE_LIST });
      }
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "notice-list",
        message
      );
    },

    async [PUBLISH_NOTICE]({ dispatch }, { id, updateInList }) {
      await Client.PublishNotice({
        noticeId: id,
      } as PublishNoticeDto);
      await dispatch(UPDATE_LOCK, {
        lock: { locked: true },
        specificId: updateInList ? id : false,
      });
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetNoticeLock(id);
    },
    async [UPDATE_LOCK](
      { commit },
      { lock, specificId }: { lock: Lock; specificId: string | undefined }
    ) {
      commit(SET_LOCK, { lock, specificId });
    },
    async [DISMISS_NOTICE_NOTIFICATION](_, id: string) {
      return await Client.DismissNoticeNotification({
        noticeId: id,
      } as DismissNoticeNotificationDto);
    },
  },
  mutations: {
    [SET_ALL_NOTICES](
      state,
      { notices, noticeType }: { notices: Notice[]; noticeType?: NoticeType }
    ) {
      if (noticeType) {
        state.lists[noticeType] = [];
      } else {
        resetAllLists(state);
      }
      notices.forEach((notice: Notice) => {
        state.lists[notice.noticeType].push(notice);
      });
    },
    [SET_CURRENT_NOTICE](state, current) {
      state.current = current;
    },
    [SET_LOCK](state, { lock, specificId }) {
      if (specificId) {
        for (const list of Object.values(state.lists)) {
          const index = list.findIndex(
            (notice: Notice) => notice.id == specificId
          );
          if (index >= 0) {
            list.splice(index, 1, { ...list[index], ...lock });
            break;
          }
        }
      } else {
        state["current"] = { ...state.current, ...lock };
      }
    },
  },
} as Module<NoticeStoreState, RootState>;

/*export default PaginationMixin< NoticeStoreState >(store, {
  actionWhichUpdatesAll: UPDATE_NOTICES,
  mutationWhichUpdatesAll: SET_ALL_NOTICES,
  defaultSort: offerSorter[0].value,
  prefix: name,
});*/

export default store;

function resetAllLists(state: any) {
  (Object.keys(state.lists) as NoticeType[]).forEach((x: NoticeType) => {
    state.lists[x] = [];
  });
}
