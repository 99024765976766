
import { h, defineComponent } from "vue";
export default defineComponent({
  props: {
    prop: {},
    passThrough: {
      type: Boolean,
      default: true,
    },
    immediateInit: {
      type: Boolean,
    },
  },
  render() {
    return h(
      "div",
      {},
      this.$slots.default!({
        prop: this.proxy,
      })
    );
  },
  data() {
    return {
      proxy: this.immediateInit ? this.prop : undefined,
    };
  },
  watch: {
    prop: {
      handler() {
        //dieser ganze Trick funktioniert nur, weil wir das Array (oder Object)
        //im Store überschreiben, wenn wir es
        //aus dem Backend holen.
        //D.h. "proxy" ist eine Referenz auf die Alte Version,
        if (this.passThrough) {
          this.proxy = this.prop;
        }
      },
      immediate: true,
    },
    passThrough(val) {
      if (val) {
        this.proxy = this.prop;
      }
    },
  },
});
