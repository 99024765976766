import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "base-multi-checkbox" }
const _hoisted_2 = { class: "base-multi-checkbox__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_checkbox = _resolveComponent("evonum-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_evonum_checkbox, {
          key: item[_ctx.itemValue],
          "onUpdate:modelValue": ($event: any) => (_ctx.handleInput($event, item[_ctx.itemValue])),
          "model-value": _ctx.currentValues[item[_ctx.itemValue]],
          label: item[_ctx.itemText],
          class: "my-1"
        }, {
          label: _withCtx(({ label }) => [
            _renderSlot(_ctx.$slots, "text", {
              item: item,
              text: label
            })
          ]),
          _: 2
        }, 1032, ["onUpdate:modelValue", "model-value", "label"]))
      }), 128))
    ])
  ]))
}