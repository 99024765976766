import {
  SET_CALLS_FOR_TENDER,
  SET_CURRENT_CALL_FOR_TENDERS,
  SET_LOCK,
} from "./callForTenders.mutations";
import {
  UPDATE_CALLS_FOR_TENDERS,
  CREATE_CALL_FOR_TENDERS,
  CHECK_LOCK,
  UPDATE_LOCK,
  PUBLISH_CALL_FOR_TENDERS,
  DISMISS_NOTIFICATION,
  COMPLETE_CALL_FOR_TENDERS,
  EDIT_CALL_FOR_TENDERS,
  DELETE_CALL_FOR_TENDERS,
  CHANGE_SUBMISSION_PERIOD,
  RESET_CALL_FOR_TENDERS_STORE,
  ADD_INVITEE,
  REMOVE_INVITEE,
  INVITE_ALL_ADMITTED_PARTICIPANTS,
  UPDATE_CURRENT_CALL_FOR_TENDERS,
  CHANGE_CALL_FOR_TENDERS,
  OPEN_OFFERS,
  INVITE_TO_CALL_FOR_TENDERS,
} from "./callForTenders.actions";
import { RootState } from "@/store/types";
import { Module } from "vuex";
import {
  CallForTendersStoreState,
  CreateCallForTendersArgs,
} from "./callForTenders.types";
import Client from "@/utils/EvoClient";
import { CallForTenders } from "@/model/CallForTenders";
import { Lock } from "@/model/Lock";
import {
  DeleteCallForTendersDto,
  DismissCallForTendersNotificationDto,
  EditCallForTendersDto,
  AddInviteeDto,
  RemoveInviteeDto,
  PublishCallForTendersDto,
  CompleteCallForTendersDto,
  ChangeSubmissionPeriodDto,
  OpenOffersDto,
} from "@/utils/EvoClient/dto";
import StoreNotificationService from "@/service/StoreNotificationService";
import router from "@/router";
import i18n from "@/i18n";
import { UPDATE_PARTICIPANTS } from "@/store/modules/participant.actions";
import { participantFilterGroups } from "@/model/Participant";
import { CallForTendersState } from "@/model/CallForTendersState";
import { ROUTE_CALL_FOR_TENDERS_LIST } from "@/router/routes";

export default {
  state: {
    all: [],
    current: null,
  } as CallForTendersStoreState,
  actions: {
    async [INVITE_TO_CALL_FOR_TENDERS]({ dispatch }, args) {
      await Client.InviteToCallForTenders(args);
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [OPEN_OFFERS]({ dispatch }, args: OpenOffersDto) {
      await Client.OpenOffers(args);
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: args.callForTendersId,
      });
    },
    async [CHANGE_CALL_FOR_TENDERS](_, { callForTendersId, title }) {
      await Client.ChangeCallForTenders({ callForTendersId, title });
    },
    [RESET_CALL_FOR_TENDERS_STORE]({ commit }) {
      commit(SET_CALLS_FOR_TENDER, []);
      commit(SET_CURRENT_CALL_FOR_TENDERS, "");
    },
    async [CREATE_CALL_FOR_TENDERS](
      { rootState, dispatch },
      {
        awardProcedureId,
        submissionPeriod,
        awardUnitIds,
      }: CreateCallForTendersArgs
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const cftid = await Client.CreateCallForTenders({
        awardProcedureId,
        submissionPeriod,
        awardUnitIds,
      });
      await dispatch(UPDATE_CALLS_FOR_TENDERS, {
        awardProcedureId,
        includeLots: awardUnitIds && awardUnitIds.length > 0,
      });
      return cftid;
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetCallForTendersLock(id);
    },
    async [PUBLISH_CALL_FOR_TENDERS](
      { dispatch },
      args: PublishCallForTendersDto
    ) {
      await Client.PublishCallForTenders(args);
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [COMPLETE_CALL_FOR_TENDERS](
      { dispatch },
      args: CompleteCallForTendersDto
    ) {
      await Client.CompleteCallForTenders(args);
      await StoreNotificationService.setOffersMovedNotification();
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: args.callForTendersId,
      });
    },
    async [UPDATE_LOCK]({ commit }, lock: Lock) {
      commit(SET_LOCK, lock);
    },
    async [DISMISS_NOTIFICATION](_, callForTendersId: string) {
      return await Client.DismissCallForTendersNotification({
        callForTendersId,
      } as DismissCallForTendersNotificationDto);
    },
    async [UPDATE_CURRENT_CALL_FOR_TENDERS](
      { commit, rootState, dispatch },
      {
        callForTendersId,
        noParticipantFilterRefresh,
        updateParticipants = true,
        inviteAfterStart = false,
      } = {
        updateParticipants: true,
        inviteAfterStart: false,
      }
    ) {
      if (!callForTendersId) {
        commit(SET_CURRENT_CALL_FOR_TENDERS, null);
        return;
      }
      const callForTenders = await Client.GetCallForTenders(callForTendersId);
      if (callForTenders) {
        commit(SET_CURRENT_CALL_FOR_TENDERS, callForTenders);
        const secondaryCalls = [];
        if (
          updateParticipants &&
          rootState.awardProcedures.current?.procedureType?.restricted
        ) {
          let participantFilterGroup;
          if (
            callForTenders.state == CallForTendersState.Draft ||
            inviteAfterStart
          ) {
            if (callForTenders.negotiation) {
              participantFilterGroup = participantFilterGroups.negotiation;
            } else {
              participantFilterGroup = participantFilterGroups.qualification;
            }
          } else {
            participantFilterGroup =
              participantFilterGroups.participantsWithOffers;
          }
          secondaryCalls.push(
            dispatch(UPDATE_PARTICIPANTS, {
              filterGroup: noParticipantFilterRefresh
                ? undefined
                : participantFilterGroup,
            })
          );
        } else {
          secondaryCalls.push(
            dispatch(UPDATE_PARTICIPANTS, {
              filterGroup: participantFilterGroups.participantsWithOffers,
            })
          );
        }
        await Promise.all(secondaryCalls);
      }
    },
    async [UPDATE_CALLS_FOR_TENDERS](
      { rootState, commit },
      { awardProcedureId, includeLots = false } = { includeLots: false }
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      let includes, fields;
      if (includeLots) {
        includes = ["awardUnits" as const];
        fields = [{ type: "awardUnit", value: ["lotNumber", "id"] }];
      }
      const callsForTenders = await Client.GetAllCallsForTenders({
        filter: [{ field: "awardProcedure", value: awardProcedureId }],
        includes,
        fields,
      });

      commit(SET_CALLS_FOR_TENDER, callsForTenders);
      return callsForTenders;
    },
    async [EDIT_CALL_FOR_TENDERS]({ dispatch }, args: EditCallForTendersDto) {
      await Client.EditCallForTenders(args);
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: args.callForTendersId,
      });
    },
    async [DELETE_CALL_FOR_TENDERS](_, args: DeleteCallForTendersDto) {
      await Client.DeleteCallForTenders(args);
      const message = i18n.global.t(
        "award-procedures.details.notifications.call-for-tenders-deleted"
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "call-for-tenders-list",
        message
      );
      await router.push({ name: ROUTE_CALL_FOR_TENDERS_LIST });
    },
    async [CHANGE_SUBMISSION_PERIOD](
      { dispatch },
      { callForTendersId, submissionPeriod }: ChangeSubmissionPeriodDto
    ) {
      await Client.ChangeSubmissionPeriod({
        callForTendersId,
        submissionPeriod,
      });
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [ADD_INVITEE]({ dispatch, state }, args: AddInviteeDto) {
      await Client.AddInvitee(args);
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: state.current!.id,
        noParticipantFilterRefresh: true,
      });
    },
    async [INVITE_ALL_ADMITTED_PARTICIPANTS]({ dispatch, state }, args) {
      await Client.InviteAllAdmittedParticipants(args);
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: state.current!.id,
        noParticipantFilterRefresh: true,
      });
    },
    async [REMOVE_INVITEE]({ dispatch, state }, args: RemoveInviteeDto) {
      await Client.RemoveInvitee(args);
      await dispatch(UPDATE_CURRENT_CALL_FOR_TENDERS, {
        callForTendersId: state.current!.id,
        noParticipantFilterRefresh: true,
      });
    },
  },
  mutations: {
    [SET_CALLS_FOR_TENDER](state, calls: CallForTenders[]) {
      state.all = calls;
    },
    [SET_CURRENT_CALL_FOR_TENDERS](state, current: CallForTenders) {
      state.current = current;
    },
    [SET_LOCK](state, lock: Lock) {
      state["current"] = { ...state.current, ...lock } as any;
    },
  },
} as Module<CallForTendersStoreState, RootState>;
