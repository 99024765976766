import { AwardingClient } from "@/utils/EvoClient";
import { ChangeRequestForParticipationPeriodDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/change-request-for-participation-period";

export default async function changeRequestForParticipationPeriod(
  dto: ChangeRequestForParticipationPeriodDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
