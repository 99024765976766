import { AwardingClient } from "@/utils/EvoClient";
import { MarkAwardProcedureCancelStatusDto } from "../dto";

const ENDPOINT = "c/mark-award-procedure-cancel-status";

export default async function markAwardProcedureCancelStatus(
  mapcsDTO: MarkAwardProcedureCancelStatusDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, mapcsDTO);
}
