
import { defineComponent } from "vue";
import ChangeDetectionMixinFactory from "@/mixins/factories/changeDetection";

/**
 * Eine [base-dialog-card_confirmation](#!/base-dialog-card_confirmation) für Aktions die etwas umbenennen.
 * Die notwendigen Komponenten werden über den *ActionMetaService* konfiguriert
 * und durch den Typ *Confirmation* beschrieben.
 * Wird in den [base-allowedaction-button](#!/base-allowedaction-button)s verwendet.
 *
 * @mixin Variantable
 */
export default defineComponent({
  name: "base-dialog-card_rename",
  emits: ["confirmed"],
  mixins: [
    ChangeDetectionMixinFactory({
      changingProp: "model",
      originalProp: "originalName",
      activeAtStart: true,
      primitives: true,
    }),
  ],
  props: {
    /**
     * TODO: wird das noch verwendet?
     */
    shown: Boolean,
    /**
     * Funktion, die aufgerufen wird, wenn der Dialog geschlossen wird.
     */
    close: Function,
    /**
     * Beschriftung des Titels, welcher den Default
     * *common.actions.${action}.confirmation.headline* unter Berücksichtigung der Variante überschreibt.
     */
    heading: String,
    /**
     * Beschriftung des Bestätigen-Buttons, welcher den Default
     * *common.actions.${action}.confirmation.confirmationButtonLabel* unter Berücksichtigung der Variante überschreibt.
     */
    confirmButtonLabel: String,
    /**
     * Ursprünglicher Wert, der verändert werden soll.
     */
    originalName: String,
    /**
     * Beschriftung für den Wert.
     */
    textFieldLabel: String,
    /**
     * Falls true, muss der Wert nicht verändert werden.
     */
    allowOriginalName: {
      type: Boolean,
      default: false,
    },
    /**
     * Zusätzlich anzuzeigenden Komponenten.
     */
    optionalComponents: {},
  },
  data() {
    return {
      model: this.originalName,
      rules: {
        title: [
          (val: string) =>
            (val || "").length > 0 || this.$t("common.errors.required"),
          (val: string) =>
            val?.trim().length > 0 ||
            this.$t("common.errors.noWhitespaceOnly", {
              field: this.$t("common.labels.title"),
            }),
          (val: string) =>
            val?.trim() !== this.originalName || this.allowOriginalName || "",
        ],
      },
    };
  },
  beforeUnmount() {
    this.setDirty(false);
    this.deactivateChangeDetection({ isUnchanged: true });
  },
  methods: {
    confirmed() {
      this.$emit("confirmed", this.model);
      return true;
    },
    _close() {
      this.close!();
    },
  },
  computed: {
    isValid(): boolean {
      let valid = this.rules.title.every((x: any) => x(this.model!) === true); //=== weil die normalen Validatoren statt false nen String zurückgeben
      return valid;
    },
  },
});
