import { AwardingClient } from "@/utils/EvoClient";
import { GrantUserRoleDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "users/c/grant-user-roles";

export default async function grantUserRole(
  dto: GrantUserRoleDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
