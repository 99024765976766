import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";
import { RequestForParticipation } from "@/model/RequestForParticipation";
import { RootState } from "@/store/types";
import { OptionType } from "@/model/FilterGroup";

const ENDPOINT = "requests-for-participation";

export const supportedFilter = {
  callForCompetition: {
    field: "callForCompetition",
    value: (state: RootState) => state.callsForCompetition.currentId,
    type: OptionType.FILTER,
  },
  awardUnits: {
    field: "awardUnits",
    value: [] as string[],
    type: OptionType.FILTER,
  },
} as const;

export type PossibleRequestsForParticipationFilters =
  (keyof typeof supportedFilter)[];

export type PossibleRequestsForParticipationIncludes = [
  "callForCompetition",
  "documents",
  "participant",
];
export type PossibleRequestsForParticipationSorts = [
  "participant.name",
  "-participant.name",
  "submittedAt",
  "-submittedAt",
];

export default ListQueryFunctionGenerator<
  RequestForParticipation[],
  PossibleRequestsForParticipationFilters,
  PossibleRequestsForParticipationIncludes,
  PossibleRequestsForParticipationSorts
>(ENDPOINT, {
  alwaysIncludes: ["participant"],
  addIdsOfRelationshipsToResult: ["callForCompetition", "awardUnits"],
  defaultFilter: [supportedFilter.callForCompetition],
});
