import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "a11y-skip" }
const _hoisted_2 = ["id", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.focusMainId && _ctx.focusMainId(...args)), ["prevent"])),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.focusMenuOnMobile && _ctx.focusMenuOnMobile(...args)), ["shift"]), ["tab"])),
      id: 'to-' + _ctx.targetId,
      href: '#' + _ctx.targetId
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.$t("aria.common.skipHeaderLink")), 1)
      ])
    ], 40, _hoisted_2)
  ]))
}