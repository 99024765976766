
import ActionableMixin from "@/mixins/actionable";
import { actionableHasAction, isActionableAllowed } from "../../utils/actions";
import { defineComponent, mergeProps } from "vue";
import { PropType, h } from "vue";
import { isEmptyObject } from "@/utils/typeCheckers";

/**
 * Dieser Wrapper um eine andere Komponente kann verwendet werden,
 * um in Abhängigkeit von einer AllowedAction zu aktivieren/deaktivieren.
 *
 * @mixin Actionable
 */
export default defineComponent({
  name: "base-disabler",
  mixins: [ActionableMixin],
  emits: ["actionPossibleChanged", "canPerformActionChanged"],
  render(): any {
    if ((this.stayRendered || this.isActionPossible) && this.$slots.default) {
      /**
       * @slot Default-Slot mit dem Inhalt, der Optional gerendert werden soll
       * @binding {boolean} canPerformAction zeigt an, ob die Aktion vorhanden ist.
       * @binding {String} activeAction die erste Aktion angibt, die aktiv ist.
       */
      if (this.directRender && isEmptyObject(this.$attrs)) {
        return this.$slots.default({
          canPerformAction: this.canPerformAction,
          activeAction: this.activeAction,
        });
      } else {
        return h(
          "div",
          mergeProps(this.$attrs, { class: "base-disabler__content" }),
          this.$slots.default!({
            canPerformAction: this.canPerformAction,
            activeAction: this.activeAction,
          })
        );
      }
    }
    return [];
  },
  computed: {
    isActionPossible(): boolean {
      try {
        if (this.action) {
          return actionableHasAction(this.actionable!, this.action);
        } else if (this.actions) {
          return this.actions
            .map((action) => {
              return actionableHasAction(this.actionable!, action);
            })
            .reduce((accumulator, currentValue) => {
              return accumulator || currentValue;
            });
        } else {
          console.warn(
            "No action passed! Neither by 'action' nor by 'actions'. Will disable."
          );
          return false;
        }
      } catch {
        console.warn(
          "Object passed to base-disabler is not Actionable. Will disable."
        );
        return false;
      }
    },
    canPerformAction(): boolean {
      try {
        if (this.action) {
          return isActionableAllowed(
            this.actionable!,
            this.action,
            this.allowedValue
          );
        } else if (this.actions) {
          return this.actions
            .map((action) => {
              return isActionableAllowed(
                this.actionable!,
                action,
                this.allowedValue
              );
            })
            .reduce((accumulator, currentValue) => {
              return accumulator || currentValue;
            });
        } else {
          console.warn(
            "No action passed! Neither by 'action' nor by 'actions'. Will disable."
          );
          return false;
        }
      } catch {
        console.warn(
          "Object passed to base-disabler is not Actionable. Will disable."
        );
        return false;
      }
    },
    activeAction(): string | undefined {
      try {
        if (this.action) {
          if (
            isActionableAllowed(
              this.actionable!,
              this.action,
              this.allowedValue
            )
          ) {
            return this.action;
          } else {
            return undefined;
          }
        } else if (this.actions) {
          const filtered = this.actions.filter((action) =>
            isActionableAllowed(this.actionable!, action, this.allowedValue)
          );
          if (filtered.length > 0) {
            return filtered[0];
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      } catch {
        return undefined;
      }
    },
  },
  watch: {
    isActionPossible: {
      immediate: true,
      handler(val): void {
        this.$emit("actionPossibleChanged", val);
      },
    },
    canPerformAction: {
      immediate: true,
      handler(val): void {
        this.$emit("canPerformActionChanged", val);
      },
    },
  },
  props: {
    /**
     * Alternativ mehrere Aktionen. Es reicht, wenn eine der Aktionen ALLOWED ist.
     */
    actions: {
      type: Array as PropType<string[]>,
      required: false,
    },
    /**
     * Soll der Inhalt gerendert werden, obwohl die Aktion NOT_ALLOWED ist?
     */
    stayRendered: {
      type: Boolean,
      default: false,
    },
    /**
     * Direct Render, Neu in Vue3 da mehrere Children erlaubt sind
     */
    directRender: {
      type: Boolean,
      default: true,
    },
  },
});
