
import { defineComponent, PropType } from "vue";
import { Actionable } from "@/model/Actionable";

export default defineComponent({
  name: "base-allowedactions-checkbox",
  props: {
    allowedValue: {
      type: [String, Object],
    },
    uncheckedAction: {
      type: String,
      required: true,
    },
    uncheckedText: {
      type: String,
    },
    checkedText: {
      type: String,
    },
    uncheckedArgs: {},
    checkedAction: {
      type: String,
      required: true,
    },
    checkedArgs: {},
    actionable: {
      type: Object as PropType<Actionable>,
    },
    currentValue: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    readonlyLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    asSwitch: {
      type: Boolean,
    },
    noReadOnly: {
      type: Boolean,
    },
  },
  computed: {
    commandAction(): string | undefined {
      return this.currentValue ? this.checkedAction : this.uncheckedAction;
    },
    commandArgs(): Record<string, any> {
      return {
        [this.checkedAction]: this.checkedArgs,
        [this.uncheckedAction]: this.uncheckedArgs,
      };
    },
  },
});
