import { Actionable } from "./Actionable";
import filesize from "@/utils/filesize";
import { getHumanReadableByMediaType } from "@/utils/mimeTypes";

export type RawAttachment = {
  id: string;
  fileSize: number;
  filename: string;
  mediaType: string;
} & Actionable;

export class Attachment implements Actionable {
  readonly id: string;
  readonly fileSize: number;
  readonly filename: string;
  readonly filenameNoExtension: string;
  readonly hfileSize: string;
  readonly extension: string;
  readonly format: string;
  uploading = false;
  actions = {};

  constructor(rawAttachment: RawAttachment) {
    this.id = rawAttachment.id;
    this.fileSize = rawAttachment.fileSize;
    this.filename = rawAttachment.filename;
    const beginningOfExtension = rawAttachment.filename.lastIndexOf(".");

    this.filenameNoExtension = rawAttachment.filename.slice(
      0,
      beginningOfExtension == -1
        ? rawAttachment.filename.length
        : beginningOfExtension //wenn keine Extension, ist alles der Filename ohne Extension
    );
    if (beginningOfExtension == -1) {
      this.extension = "";
    } else {
      this.extension = rawAttachment.filename
        .slice(beginningOfExtension + 1)
        .toLowerCase();
    }
    this.hfileSize = filesize.getHumanReadableFileSize(this.fileSize);
    this.format = getHumanReadableByMediaType(
      rawAttachment.mediaType,
      this.extension
    );
    this.actions = rawAttachment.actions;
  }
}

export function convertAttachments(
  attachments: RawAttachment[] | undefined | null
): Attachment[] {
  if (!attachments) {
    return [];
  }
  return attachments.flatMap((x) => {
    if (x == null) {
      return [];
    } else {
      return [new Attachment(x)];
    }
  });
}
