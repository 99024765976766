import uploadFile, {
  ProgressCallback,
} from "@/utils/EvoClient/unique/UploadFile";

// prettier-ignore
const ENDPOINT = (shipmentId: string) => `shipments/${shipmentId}/upload-uri`;

export default async function uploadToShipment(
  file: File,
  shipmentId: string,
  progressCb: ProgressCallback
): Promise<any> {
  return await uploadFile(file, ENDPOINT(shipmentId), progressCb);
}
