import { AwardingClient } from "@/utils/EvoClient";
import { RemoveUserFromContextDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "users/c/remove-user-from-context";

export default async function removeUserFromContext(
  dto: RemoveUserFromContextDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
