import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_command_action_force_dialog_confirmation = _resolveComponent("partial-command-action-force-dialog-confirmation")!
  const _component_evonum_dialog = _resolveComponent("evonum-dialog")!

  return (_openBlock(), _createBlock(_component_evonum_dialog, {
    width: 800,
    showDialogOverride: _ctx.showDialog,
    dialogLabel: _ctx.$t('common.labels.forceCommand.headline'),
    hasButton: false,
    onCloseDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancelled'))),
    modal: ""
  }, {
    button: _withCtx(() => []),
    default: _withCtx(({ close }) => [
      _createVNode(_component_partial_command_action_force_dialog_confirmation, {
        close: close,
        action: _ctx.action,
        onConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirmed'))),
        onCancelled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancelled'))),
        dialogErrors: _ctx.dialogErrors
      }, null, 8, ["close", "action", "dialogErrors"])
    ]),
    _: 1
  }, 8, ["showDialogOverride", "dialogLabel"]))
}