import store from "@/store";
import i18n from "@/i18n";
import { SET_STORE_NOTIFICATION } from "@/store/modules/notification.actions";
import { SetStoreNotificationArgs } from "@/store/modules/notification.types";
import { NotificationSlotTypes, SlotNotification } from "@/model/Notification";
import { ROUTE_TABLE_OF_OFFERS_DETAILS } from "@/router/routes";

class StoreNotificationService {
  async setOffersMovedNotification() {
    await store.dispatch(SET_STORE_NOTIFICATION, {
      id: "offersMoved",
      notification: {
        type: "info",
        dismissibleByUser: true,
        component: {
          path: "calls-for-tenders.details.offers-moved",
          slots: [
            {
              type: NotificationSlotTypes.routerlink,
              content: i18n.global.t("common.labels.tableOfOffers"),
              slotName: "tableOfOffers",
              route: {
                name: ROUTE_TABLE_OF_OFFERS_DETAILS,
              },
            },
          ],
        },
      } as SlotNotification,
    } as SetStoreNotificationArgs);
  }
  async setSuccessfulDeleteNotification(id: string, message: string) {
    await store.dispatch(SET_STORE_NOTIFICATION, {
      id,
      notification: {
        type: "success",
        dismissibleByUser: true,
        message,
      },
    } as SetStoreNotificationArgs);
  }
}

export default new StoreNotificationService();
