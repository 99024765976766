
import Focusable from "@/mixins/focusable";
import { getUniqueId } from "@/utils/ids";
import { defineComponent } from "vue";

export default defineComponent({
  name: "partial-card-list-select-small",
  inheritAttrs: false,
  mixins: [Focusable],
  mounted() {
    if (this.widthByText) {
      let longestTextLenght = 0;
      this.items.forEach((x: any) => {
        if (x.title.length > longestTextLenght) {
          longestTextLenght = x.title.length;
        }
      });
      //@ts-ignore
      this.$el.style!.setProperty(
        "--evonum-select-small-sort-width",
        longestTextLenght + 1 + "ch"
      );
    }
  },
  watch: {
    items(val: { title: string; value: string }[]) {
      let longestTextLenght = 0;
      val.forEach((x) => {
        if (x.title.length > longestTextLenght) {
          longestTextLenght = x.title.length;
        }
      });
      const oldValue = Number.parseInt(
        this.$el
          .style!.getPropertyValue("--evonum-select-small-sort-width")
          ?.slice(0, -2) || "0"
      );
      if (oldValue < longestTextLenght) {
        //@ts-ignore
        this.$el.style!.setProperty(
          "--evonum-select-small-sort-width",
          longestTextLenght + 1 + "ch"
        );
      }
    },
  },
  props: {
    uniqueId: {
      type: String,
      default: () => getUniqueId(),
    },
    prepend: {
      type: Boolean,
      default: true,
    },
    widthByText: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    modelValue: {},
  },
});
