
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "base-multi-checkbox",
  props: {
    items: {
      type: Array as PropType<any>,
      required: true,
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemText: {
      type: String,
      default: "text",
    },
    currentValues: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
  methods: {
    handleInput(val: boolean, id: string) {
      this.$emit("change", [id, val]);
    },
  },
});
