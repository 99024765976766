import { AwardingClient } from "@/utils/EvoClient";
import { ChangeCallForTendersDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/change-call-for-tenders";

export default async function changeCallForTenders(
  dto: ChangeCallForTendersDto
): Promise<void> {
  (await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse;
}
