
import { defineComponent } from "vue";
import { PropType } from "vue";

export enum VARIANT {
  MULTILINELISTITEM = "MULTILINELISTITEM",
  ONELINELISTITEM = "ONELINELISTITEM",
}

/**
 * Aktuell ist der Skeleton-Loader beschränkt auf den Inhalt von Card-Lists
 * Für einen fancygeren Skeleton-Loader könnte die Vuetify Componente verwendet werden
 */

// function renderAvatar(h: CreateElement): VNode {
//   return h("div", {
//     staticStyle: {
//       width: "36px",
//       height: "36px",
//       backgroundColor: "var(--grey-200)",
//       borderRadius: "50%",
//     },
//   });
// }

export default defineComponent({
  name: "base-skeleton-loader",
  props: {
    /**
     * Darstellungsvariante
     */
    variant: {
      type: String as PropType<VARIANT>,
      default: VARIANT.MULTILINELISTITEM,
    },
    /**
     * Anzahl der Items
     */
    listLength: {
      type: Number,
      default: 3,
    },
  },
  //   render(h): any {
  //     const children: VNode[] = [renderAvatar(h)];
  //     return h("div", { staticClass: "base-skeleton-loader" }, children);
  //   },
});
