import { App } from "vue";
import ids from "@/utils/ids";
import utils from "@/utils/utils";
import ActionMetaService from "@/service/ActionMetaService";
import { Store } from "vuex";
import { RootState } from "./store/types";

export function addGlobalProperties(app: App) {
  app.config.globalProperties.$ids = ids;
  app.config.globalProperties.$utils = utils;
  app.config.globalProperties.$actionmeta = ActionMetaService;
  app.config.globalProperties.noSimulation = false;
  app.config.globalProperties.PARALLEL_CFT = false;
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $ids: typeof ids;
    $utils: typeof utils;
    $actionmeta: typeof ActionMetaService;
    noSimulation: boolean;
    PARALLEL_CFT: boolean;
    $store: Store<RootState>;
  }
}
