import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_global_error_interior = _resolveComponent("partial-global-error-interior")!

  return (_ctx.hasError)
    ? (_openBlock(), _createBlock(_component_partial_global_error_interior, {
        key: 0,
        alertId: _ctx.$ids.GLOBAL_ERROR,
        style: {"position":"fixed","top":"0","left":"0","width":"100%","z-index":"2401"}
      }, null, 8, ["alertId"]))
    : _createCommentVNode("", true)
}