export const DISMISS_ERRORS = "Dismisse alle Fehler für eine Komponente";
export const SET_ERRORS_FOR_COMPONENT = "Setze Fehler für eine Komponente";
export const DISMISS_ALL_ERRORS = "Setze alle Fehler zurück";
export const SET_WARNING_FOR_COMPONENT = "Setze Warnings für eine Komponente";

export const DISMISS_STORE_NOTIFICATION =
  "Dismisse Notification für eine Komponente";
export const SET_STORE_NOTIFICATION = "Setze Notification für eine Komponente";

export const DISMISS_GLOBAL_ERROR = "Dismisse den global Error";
export const SET_GLOBAL_ERROR = "Setzt den global Error";
