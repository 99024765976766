import { AwardingClient } from "@/utils/EvoClient";
import { MarkMessagesAsReadDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/mark-messages-as-read";

export default async function markMessagesAsRead(
  dto: MarkMessagesAsReadDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
