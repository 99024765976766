import { AwardingClient } from "@/utils/EvoClient";
import { CreateCallForTendersDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/create-call-for-tenders";

export default async function createCallForTenders(
  cctDto: CreateCallForTendersDto
): Promise<string> {
  const res = (await AwardingClient.post(ENDPOINT, cctDto)) as AxiosResponse;
  return res.data.id;
}
