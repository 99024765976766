import { Module } from "vuex";
import { OVERWRITE_FILTER_FIELD, RESET_FILTER } from "../actions";
import { RootState, Filterable } from "../types";
import {
  DynamicFilter,
  FilterOption,
  IncludeFilter,
  OptionType,
} from "@/model/FilterGroup";

function isInclude(filter: DynamicFilter): filter is IncludeFilter {
  return filter.type === OptionType.INCLUDE;
}

function returnFittingOverride(
  filter: any,
  override: any
): false | string | null {
  if (
    !override ||
    (override.operator !== undefined && filter.operator != override.operator)
  ) {
    return false;
  }
  return override.value;
}

function calculateFilterOptionValue(
  fo: FilterOption["value"] | undefined,
  state: Filterable,
  rootState: RootState
): any[] | undefined {
  if (fo == undefined) {
    return undefined;
  }
  return fo.map((x: any) => {
    const override = returnFittingOverride(
      x,
      state.fieldValueOverrides[x.field]
    );
    const result = {
      ...x,
      value:
        override === false
          ? typeof x.value === "function"
            ? x.value(rootState)
            : x.value
          : override,
    };
    return result;
  });
}

export default function FilterMixin<T extends { all: any }>(
  module: Module<T, RootState>,
  {
    prefix,
  }: {
    prefix: string;
  }
): Module<T & Filterable, RootState> {
  return {
    getters: {
      ...module.getters,
      [`${prefix}:isFilterSet`](state) {
        return Object.keys(state.filter).length > 0;
      },
      [`${prefix}:filter`](state, getters) {
        return getters[`${prefix}:flatFilter`].filter(
          (x: DynamicFilter) =>
            x.type == OptionType.FILTER ||
            x.type == OptionType.RELATIONSHIPFILTER
        );
      },
      [`${prefix}:flatFilter`](state) {
        return Object.entries(state.filter).flatMap(([key, filterArray]) => {
          if (state.exclusive !== "") {
            if (key != state.exclusive) {
              return [];
            } else {
              return filterArray;
            }
          }
          return filterArray;
        });
      },
      [`${prefix}:activeFilterGroups`](state) {
        return state.activeGroup;
      },
      [`${prefix}:includes`](state, getters) {
        return getters[`${prefix}:flatFilter`].flatMap(
          (filter: DynamicFilter) => {
            if (isInclude(filter)) {
              return [filter.value];
            } else {
              return [];
            }
          }
        );
      },
      [`${prefix}:filterMap`](state) {
        return state.filter;
      },
    },
    actions: {
      async [RESET_FILTER]({ commit }) {
        commit(`${prefix}:UnsetAllFilters`);
      },
      async [`${prefix}:${OVERWRITE_FILTER_FIELD}`](
        { commit },
        { fields, values, filterKey }
      ) {
        commit(`${prefix}:OverwriteFilterField`, { fields, values, filterKey });
      },
      ...module.actions,
      async [`${prefix}:setFilter`](
        { commit, rootState, state },
        {
          filter,
          filterGroup,
          clearOverrides,
        }: {
          filter: Pick<FilterOption, "key" | "value" | "exclusive">;
          filterGroup: string;
          clearOverrides?: boolean;
        }
      ) {
        if (clearOverrides) {
          commit(`${prefix}:ClearOverrides`);
        }
        commit(`${prefix}:SetFilter`, {
          filterKey: filter.key,
          filterValue: calculateFilterOptionValue(
            filter.value,
            state,
            rootState
          ),
          filterGroup,
          exclusive: filter.exclusive,
          clearOverrides,
        });
      },
    },
    state: {
      ...module.state,
      filter: {},
      activeGroup: {},
      fieldValueOverrides: {},
      exclusive: "",
    } as T & Filterable,
    mutations: {
      ...module.mutations,
      [`${prefix}:ClearOverrides`](state) {
        state.fieldValueOverrides = {};
      },
      [`${prefix}:SetFilter`](
        state,
        { filterKey, filterValue, filterGroup, exclusive }
      ) {
        if (exclusive) {
          state.exclusive = filterKey;
        } else if (state.exclusive == state.activeGroup[filterGroup]) {
          //resette, wenn ein Nicht-Exklusiver Filter der gleichen Gruppe verwendet wird
          state.exclusive = "";
        }

        if (state.activeGroup[filterGroup]) {
          delete state.filter[state.activeGroup[filterGroup]];
          delete state.activeGroup[filterGroup];
        }
        state.filter[filterKey] = filterValue;
        state.activeGroup[filterGroup] = filterKey;
      },
      [`${prefix}:OverwriteFilterField`](state, { filterKey, fields, values }) {
        const filter = state.filter[filterKey];
        for (const field of fields) {
          state.fieldValueOverrides[field] = values[field];
        }
        if (filter) {
          filter.forEach((x: any, index) => {
            if (
              x.field &&
              fields.includes(x.field) &&
              (values[x.field].operator === undefined ||
                x.operator == values[x.field].operator)
            ) {
              filter[index].value = values[x.field].value;
            }
          });
        }
      },
      [`${prefix}:UnsetAllFilters`](state) {
        state.filter = {};
        state.activeGroup = {};
        state.fieldValueOverrides = {};
        state.exclusive = "";
      },
    },
  };
}
