import { TableOfOffersStoreState } from "./modules/tableOfOffers.types";
import { NotificationState } from "./modules/notification.types";
import { SpecificationsState } from "./modules/specifications.types";
import { AwardProcedureState } from "./modules/awardProcedure.types";
import { CallForTendersStoreState } from "./modules/callForTenders.types";
import { OfferStoreState } from "@/store/modules/offer.types";
import { ParticipantStoreState } from "@/store/modules/participant.types";
import { LoadingState } from "@/store/modules/loading.types";
import { ShipmentStoreState } from "@/store/modules/shipment.types";
import { TenderDocumentStoreState } from "@/store/modules/tenderDocument.types";
import { SectionStoreState } from "@/store/modules/section.types";
import { RequestForParticipationStoreState } from "./modules/requestForParticipation.types";
import { NoticeStoreState } from "./modules/notice.types";
import { ConversationStoreState } from "./modules/conversation.types";
import { AwardUnitStoreState } from "./modules/awardUnit.types";
import { CommonStoreState } from "./modules/common.types";
import { CallForCompetitionStoreState } from "@/store/modules/callForCompetition.types";
import { DynamicFilter } from "@/model/FilterGroup";
import { UsersStoreState } from "@/store/modules/users.types";

export type Paginationable<Sort = string> = {
  pagination: {
    total: number;
    totalPages: number;
    page: {
      number: number;
      size: number;
    };
    sort?: Sort;
    retryOnUnexpectedEmpty: boolean;
    totalUnfilteredCount?: number;
  };
};

export type Filterable = {
  filter: Record<string, DynamicFilter[]>; //string ist ein bestimmter Filter; (z.B. Aufgeforderte Teilnehmer)
  activeGroup: Record<string, string>; //welcher Filter für welche Gruppe activ ist (welcher aus der Gruppe ["aufgeforderte", "alle"] ist aktiv)
  fieldValueOverrides: Record<string, string>; //kann Felder überschreiben, z.B. isQualified. Wird verwendet, solange vorhanden
  exclusive: string; //wenn gesetzt, wird nur dieser Filter angewendet
};

export interface RootState extends EvoState {
  awardProcedures: AwardProcedureState & Paginationable;
  specifications: SpecificationsState;
  callsForTenders: CallForTendersStoreState;
  requestForParticipation: RequestForParticipationStoreState &
    Paginationable &
    Filterable;

  notices: NoticeStoreState;
  conversations: ConversationStoreState & Paginationable & Filterable;
  awardUnits: AwardUnitStoreState & Paginationable & Filterable;
  common: CommonStoreState;
  callsForCompetition: CallForCompetitionStoreState;
  tableOfOffers: TableOfOffersStoreState & Paginationable;
  offers: OfferStoreState & Paginationable;
  participants: ParticipantStoreState & Paginationable & Filterable;
  notifications: NotificationState;
  loading: LoadingState;
  shipments: ShipmentStoreState;
  tenderDocuments: TenderDocumentStoreState;
  sections: SectionStoreState;

  users: UsersStoreState;
}

export type RequestState = {
  polling: boolean;
};

export default class EvoState {
  isGlobalSplashscreenVisible = false;
  userData = {
    hints: {},
  };
  dirty = {
    override: false, // während (die meisten) Actions laufen wird dieser auf true gesetzt, damit bei Erfolg navigiert werden kann
    components: {} as Record<string, boolean>,
  };
  editing = {
    forms: {} as Record<string, boolean>,
  };
  isNavMenuVisible = false;
  cardsInEditMode = 0;
  isFirstLoad = true;
  isFileDrag = false;
  isNavigating = false;
  moveMode = {
    active: false,
    group: "",
    id: "",
  };
}
