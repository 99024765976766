
import MainThreeColumns from "@/layouts/MainThreeColumns.vue";
import RouteMixin from "@/mixins/route";
import { defineComponent } from "vue";

export default defineComponent({
  name: "404-view",
  mixins: [RouteMixin],
  props: ["errors"],
  components: {
    MainThreeColumns,
  },
  computed: {
    error(): any {
      if (this.errors && this.errors.length > 0) {
        return this.errors[0];
      } else {
        return {
          title: this.$t("error-pages.404.headline") as string,
          detail: this.$t("error-pages.404.text") as string,
        };
      }
    },
    title(): string {
      return this.error.title;
    },
    detail(): string {
      return this.error.detail;
    },
  },
});
