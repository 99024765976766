import { RouteLocationRaw } from "vue-router";

// Vergabe-Spezifikation
export interface Notification {
  type: "info" | "warning" | "error" | "success" | "silent" | "empty";
  message: string;
  dismissibleByUser?: boolean;
}

export interface SlotNotification extends Notification {
  component?: {
    path: string;
    slots: LinkNotificationSlot[];
  };
}

export const enum NotificationSlotTypes {
  "routerlink" = "routerlink",
}

export interface LinkNotificationSlot {
  type: NotificationSlotTypes.routerlink;
  route: RouteLocationRaw;
  slotName: string; //Platzhaltername in locale
  content: string;
}
