import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row flex-wrap",
  style: {"gap":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_card_list_filters_filter = _resolveComponent("partial-card-list-filters-filter")!
  const _component_partial_card_list_filters = _resolveComponent("partial-card-list-filters")!
  const _component_base_card_list_pagination = _resolveComponent("base-card-list_pagination")!
  const _component_evonum_card = _resolveComponent("evonum-card")!

  return (_openBlock(), _createBlock(_component_evonum_card, {
    key: "is-list",
    heading: _ctx.hasItems || _ctx.filtering ? _ctx.heading : undefined,
    counter: _ctx.editing ? undefined : _ctx.c_counter || _ctx.c_numberOfItems,
    transparentBackground: _ctx.showTransparentEmptyCard,
    noToolbar: _ctx.noToolbar,
    class: _normalizeClass({ 'evonum-card--editing': _ctx.editing })
  }, {
    superbar: _withCtx(() => [
      _renderSlot(_ctx.$slots, "superbar")
    ]),
    chipAppend: _withCtx(() => [
      _renderSlot(_ctx.$slots, "chipAppend")
    ]),
    subbar: _withCtx(() => [
      _renderSlot(_ctx.$slots, "subbar"),
      (!_ctx.noToolbar && !_ctx.noFilter)
        ? _renderSlot(_ctx.$slots, "filter", {
            key: 0,
            filterMap: _ctx.c_filterMap,
            activeFilterGroups: _ctx.c_activeFilterGroups,
            updateList: _ctx.updateList
          }, () => [
            (
            (_ctx.filterGroups && _ctx.filterGroups.length > 0) || _ctx.$slots['appendFilter']
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterGroups, (filterGroup) => {
                    return (_openBlock(), _createBlock(_component_partial_card_list_filters, {
                      filterGroup: filterGroup,
                      key: filterGroup.name,
                      onFilterChanged: _ctx.updateList,
                      filterMap: _ctx.c_filterMap,
                      disabled: _ctx.filterDisabled[filterGroup.name],
                      filterArgs: _ctx.filterArgs,
                      activeFilterGroups: _ctx.c_activeFilterGroups,
                      prefixName: _ctx.prefixName
                    }, {
                      default: _withCtx(({ isMobile, setFilter, filterKey }) => [
                        _createVNode(_component_partial_card_list_filters_filter, {
                          onChange: ($event: any) => (setFilter($event)),
                          prefixName: _ctx.prefixName,
                          currentFilterKey: filterKey,
                          filterGroup: filterGroup,
                          isMobile: isMobile
                        }, null, 8, ["onChange", "prefixName", "currentFilterKey", "filterGroup", "isMobile"])
                      ]),
                      _: 2
                    }, 1032, ["filterGroup", "onFilterChanged", "filterMap", "disabled", "filterArgs", "activeFilterGroups", "prefixName"]))
                  }), 128)),
                  _renderSlot(_ctx.$slots, "appendFilter", {
                    filterMap: _ctx.c_filterMap,
                    activeFilterGroups: _ctx.c_activeFilterGroups,
                    updateList: _ctx.updateList
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      _renderSlot(_ctx.$slots, "actions")
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_base_card_list_pagination, _mergeProps({
        prefix: _ctx.prefixName,
        sorter: _ctx.sorter!,
        args: _ctx.listArgs,
        "list-aria-label": _ctx.listLabel,
        onRefreshCache: _ctx.refreshFilter,
        onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loading', $event))),
        emptyListHeading: _ctx.listEmptyHeading,
        emptyListHeadingTag: _ctx.listEmptyHeadingTag,
        filteredEmptyText: _ctx.filteredEmptyText,
        filteredEmptyHeading: _ctx.filteredEmptyHeading,
        "empty-list-image-aspect-height": _ctx.listEmptyImageAspectHeight,
        "empty-list-image-aspect-width": _ctx.listEmptyImageAspectWidth,
        emptyListText: _ctx.listEmptyText,
        emptyListImage: _ctx.listEmptyImage,
        updateAction: _ctx.updateAction,
        "freeze-items": _ctx.freezeItems
      }, _ctx.$attrs, {
        "data-cy": "base-card-list-wrapped-pagination-inlinelist",
        ref: "list"
      }), {
        list: _withCtx(() => [
          _renderSlot(_ctx.$slots, "list")
        ]),
        default: _withCtx(({ item }) => [
          _renderSlot(_ctx.$slots, "default", { item: item }, () => [
            _createElementVNode("li", null, _toDisplayString(item), 1)
          ])
        ]),
        _: 3
      }, 16, ["prefix", "sorter", "args", "list-aria-label", "onRefreshCache", "emptyListHeading", "emptyListHeadingTag", "filteredEmptyText", "filteredEmptyHeading", "empty-list-image-aspect-height", "empty-list-image-aspect-width", "emptyListText", "emptyListImage", "updateAction", "freeze-items"])
    ]),
    _: 3
  }, 8, ["heading", "counter", "transparentBackground", "noToolbar", "class"]))
}