import { AwardingClient } from "@/utils/EvoClient";
import { DeleteParticipantDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/delete-participant";

export default async function deleteParticipant(
  dto: DeleteParticipantDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
