
import { defineComponent } from "vue";
import ViewportMixin from "@/mixins/viewport";

export default defineComponent({
  name: "partial-card-list-item_dragdrop-loading",
  mixins: [ViewportMixin],
  props: {
    headline: String,
    percentUploaded: Number,
    totalUploaded: String,
    bytesUploaded: String,
    success: Boolean,
  },
});
