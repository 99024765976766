import { AwardingClient } from "@/utils/EvoClient";
import { PublishCallForTendersDto } from "../dto";

const ENDPOINT = "c/publish-call-for-tenders";

export default async function publishCallForTenders(
  cftDTO: PublishCallForTendersDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, cftDTO);
}
