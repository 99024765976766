import { CURRENT_LOCALE } from "./../config/index";
import { filesize } from "filesize";

function getDecimalSeparatorForLocale(locale: string) {
  const numberWithDecimalSeparator = 1.1;

  return numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
}

const DECIMAL_SEPERATOR = getDecimalSeparatorForLocale(CURRENT_LOCALE);

export default {
  getHumanReadableFileSize(
    bytes: number,
    {
      forceMB = false,
      showUnit = true,
    }: { forceMB?: boolean; showUnit?: boolean } = {
      forceMB: false,
      showUnit: true,
    }
  ): string {
    if (bytes == undefined) {
      return "unknown size";
    }
    const sizeObj = filesize(bytes, {
      locale: CURRENT_LOCALE,
      output: "object",
      standard: "jedec",
      base: 2,
      exponent: forceMB ? 2 : -1,
    }) as any as { value: string; symbol: string };
    const sizeOfPreDecimal = sizeObj.value.indexOf(DECIMAL_SEPERATOR);
    let NumberPart = "";
    if (!forceMB) {
      if (sizeObj.symbol == "KB") {
        if (sizeOfPreDecimal != -1) {
          NumberPart = (
            Number.parseInt(sizeObj.value.split(DECIMAL_SEPERATOR)[0]) + 1
          ).toString();
        } else {
          NumberPart = sizeObj.value;
        }
      } else {
        if (sizeOfPreDecimal == 3) {
          //Abschneiden wenn dreistellige Anzahl an Einheit (z.B. bei 926.1 KB, aber nicht bei 26.149 KB);
          NumberPart = sizeObj.value.slice(0, 3);
        } else if (sizeOfPreDecimal == -1) {
          //Überhaupt kein Komma
          NumberPart = sizeObj.value;
        } else {
          //Komma mitnehmen
          NumberPart = sizeObj.value.slice(0, 4);
        }
      }
    } else {
      if (bytes > 1000000000) {
        const position = sizeObj.value.indexOf(DECIMAL_SEPERATOR);
        NumberPart = sizeObj.value.slice(
          0,
          position >= 0 ? position : sizeObj.value.length
        );
      } else {
        if (sizeObj.value.includes(DECIMAL_SEPERATOR)) {
          let decimalPart = sizeObj.value.slice(
            sizeObj.value.indexOf(DECIMAL_SEPERATOR) + 1
          );
          decimalPart =
            decimalPart.length == 1 ? decimalPart + "0" : decimalPart;
          NumberPart =
            sizeObj.value.slice(
              0,
              sizeObj.value.indexOf(DECIMAL_SEPERATOR) + 1
            ) + decimalPart;
        } else {
          NumberPart = sizeObj.value + DECIMAL_SEPERATOR + "00";
        }
      }
    }
    return NumberPart + (showUnit ? " " + sizeObj.symbol : "");
  },
};
