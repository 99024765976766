import { AwardingClient } from "@/utils/EvoClient";
import { EditAwardUnitDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-award-unit";

export default async function editAwardUnit(
  dto: EditAwardUnitDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
