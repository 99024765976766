import { AwardingClient } from "@/utils/EvoClient";
import { CompleteAwardProcedureDto } from "../dto";

const ENDPOINT = "c/complete-award-procedure";

export default async function completeAwardProcedure(
  capDTO: CompleteAwardProcedureDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, capDTO);
}
