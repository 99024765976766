import { RouteRecordRaw } from "vue-router";
import { INITIALIZE_CLIENT } from "@/store/actions";
import {
  GET_AWARD_PROCEDURE_AS_CURRENT,
  GET_AWARD_PROCEDURE_STATUS,
} from "@/store/modules/awardProcedure.actions";
import LoadingService from "@/service/LoadingService";
import i18n from "@/i18n";
import Shipments from "./shipments";
import AwardUnits from "./award-units";
import Participants from "./participants";
import TableOfOffers from "./table-of-offers";
import CallsForCompetition from "./calls-for-competition";
import CallsForTenders from "./calls-for-tenders";
import Notices from "./notices";
import Conversations from "./conversations";
import { ROUTE_AWARD_PROCEDURE_DETAILS } from "@/router/routes";
import Permissions from "./permissions";

const AwardProcedures = () =>
  import(
    /* webpackChunkName: "award-procedure-chunk" */ "@/views/award-procedure-frame.vue"
  );
const AwardProcedureDetails = () =>
  import(
    /* webpackChunkName: "award-procedure-chunk" */ "@/views/award-procedures/award-procedure-details.vue"
  );

export default {
  path: "/award-procedures/:awardProcedureId",
  component: AwardProcedures,
  async beforeEnter(to, from, next) {
    await LoadingService.syncLoadAction(INITIALIZE_CLIENT);
    await LoadingService.syncLoadAction(
      GET_AWARD_PROCEDURE_AS_CURRENT,
      to.params.awardProcedureId
    );
    next();
  },
  children: [
    {
      path: "",
      component: AwardProcedureDetails,
      name: ROUTE_AWARD_PROCEDURE_DETAILS,
      meta: {
        title: i18n.global.t("route-titles.awardProcedureDetails"),
      },
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(
          GET_AWARD_PROCEDURE_AS_CURRENT,
          to.params.awardProcedureId
        );
        LoadingService.addLoadAction(
          GET_AWARD_PROCEDURE_STATUS,
          to.params.awardProcedureId
        );
        next();
      },
    },
    Shipments,
    AwardUnits,
    Participants,
    TableOfOffers,
    CallsForCompetition,
    CallsForTenders,
    Notices,
    Conversations,
    Permissions,
  ],
} as RouteRecordRaw;
