import {
  UPDATE_AWARD_UNITS,
  UPDATE_CURRENT_AWARD_UNIT,
  CREATE_AWARD_UNIT,
  EDIT_AWARD_UNIT,
  RESET_AWARD_UNIT_STORE,
  DELETE_AWARD_UNIT,
  CHECK_LOCK,
  UPDATE_LOCK,
  DISMISS_AWARD_UNIT_NOTIFICATION,
  CHANGE_AWARD_UNIT,
  name,
} from "@/store/modules/awardUnit.actions";
import {
  SET_ALL_AWARD_UNITS,
  SET_CURRENT_AWARD_UNIT,
  SET_LOCK,
} from "@/store/modules/awardUnit.mutations";
import { AwardUnitStoreState } from "@/store/modules/awardUnit.types";
import { Module } from "vuex";
import { Paginationable, RootState } from "@/store/types";
import Client from "@/utils/EvoClient";
import { AwardUnit, awardUnitSorter } from "@/model/AwardUnit";
import {
  DeleteAwardUnitDto,
  DismissAwardUnitNotificationDto,
  EditAwardUnitDto,
} from "@/utils/EvoClient/dto";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import PaginationMixin from "@/store/mixins/pagination";
import FilterMixin from "@/store/mixins/filter";
import i18n from "@/i18n";
import StoreNotificationService from "@/service/StoreNotificationService";
import router from "@/router";
import { ROUTE_AWARD_UNIT_LIST } from "@/router/routes";
import { Lock } from "@/model/Lock";

const store = {
  state: {
    all: [],
    current: null,
  } as AwardUnitStoreState,
  actions: {
    async [CHANGE_AWARD_UNIT](
      { dispatch },
      { awardUnitId, title, description }
    ) {
      await Client.ChangeAwardUnit({ awardUnitId, title, description });
      await dispatch(UPDATE_LOCK, { lock: { locked: true } });
    },
    async [CREATE_AWARD_UNIT](
      { rootState, dispatch },
      {
        awardProcedureId,
        title,
        description,
        lotNumber,
        _updateCurrent = false,
        updateAwardProcedure = false,
      }
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const id = await Client.CreateAwardUnit({
        awardProcedureId,
        title,
        description,
        lotNumber:
          typeof lotNumber === "string"
            ? Number.parseInt(lotNumber)
            : lotNumber,
      });
      if (updateAwardProcedure) {
        await dispatch(GET_AWARD_PROCEDURE_AS_CURRENT, awardProcedureId);
      }
      await dispatch(UPDATE_AWARD_UNITS);
      if (_updateCurrent) {
        await dispatch(UPDATE_CURRENT_AWARD_UNIT, {
          awardUnitId: id,
        });
      }
      return id;
    },
    async [EDIT_AWARD_UNIT]({ dispatch }, args: EditAwardUnitDto) {
      await Client.EditAwardUnit({
        ...args,
        lotNumber:
          typeof args.lotNumber === "string"
            ? Number.parseInt(args.lotNumber)
            : args.lotNumber,
      });
      await dispatch(UPDATE_CURRENT_AWARD_UNIT, args);
    },
    async [DELETE_AWARD_UNIT](_, args: DeleteAwardUnitDto) {
      await Client.DeleteAwardUnit(args);
      const message = i18n.global.t(
        "award-procedures.details.notifications.award-unit-deleted"
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "award-unit-list",
        message
      );
      await router.push({ name: ROUTE_AWARD_UNIT_LIST });
    },
    async [UPDATE_AWARD_UNITS](
      { rootState, commit, getters, dispatch },
      {
        requestForParticipationId,
        offerId,
        fields = [],
        pagination = getters[`${name}:pagination`],
        sort,
      } = {}
    ) {
      let filter;
      if (requestForParticipationId) {
        filter = [
          {
            field: "requestForParticipation",
            value: requestForParticipationId,
          },
        ];
      } else if (offerId) {
        filter = [
          {
            field: "offer",
            value: offerId,
          },
        ];
      } else {
        filter = [...getters[`${name}:filter`]];
      }
      const awardUnits = await Client.GetAllAwardUnits({
        pagination,
        filter: filter,
        includes: [...getters[`${name}:includes`]],
        fields,
        sort,
      });
      commit(SET_ALL_AWARD_UNITS, awardUnits);
      if (
        rootState.awardProcedures.current!.lotDivision === false &&
        awardUnits.length == 1
      ) {
        //Wenn wir uns eh schon die komplette Liste mit allen Feldern geholt haben
        //können wir die AU aus der Liste holen
        if (fields == null || fields.length == 0) {
          commit(SET_CURRENT_AWARD_UNIT, awardUnits[0]);
        } else {
          const firstAwardUnitId = awardUnits[0].id;
          await dispatch(UPDATE_CURRENT_AWARD_UNIT, {
            awardUnitId: firstAwardUnitId,
          });
        }
      }
    },
    async [UPDATE_CURRENT_AWARD_UNIT](
      { commit },
      { awardUnitId }: { awardUnitId: string }
    ) {
      let awardUnit = null;
      if (awardUnitId != null) {
        awardUnit = await Client.GetAwardUnit(awardUnitId);
      }
      commit(SET_CURRENT_AWARD_UNIT, awardUnit);
    },
    [RESET_AWARD_UNIT_STORE]({ commit }) {
      commit(SET_ALL_AWARD_UNITS, []);
      commit(SET_CURRENT_AWARD_UNIT, null);
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetAwardUnitLock(id);
    },
    async [UPDATE_LOCK]({ commit }, { lock }: { lock: Lock }) {
      commit(SET_LOCK, { lock });
    },
    async [DISMISS_AWARD_UNIT_NOTIFICATION](_, id: string) {
      return await Client.DismissAwardUnitNotification({
        awardUnitId: id,
      } as DismissAwardUnitNotificationDto);
    },
  },
  mutations: {
    [SET_ALL_AWARD_UNITS](state, all: AwardUnit[]) {
      state.all = all;
    },
    [SET_CURRENT_AWARD_UNIT](state, current: AwardUnit) {
      state.current = current;
    },
    [SET_LOCK](state, { lock }) {
      state.current = { ...state.current, ...lock };
    },
  },
} as Module<AwardUnitStoreState, RootState>;

const paginated = PaginationMixin<AwardUnitStoreState>(store, {
  actionWhichUpdatesAll: UPDATE_AWARD_UNITS,
  mutationWhichUpdatesAll: SET_ALL_AWARD_UNITS,
  defaultSort: awardUnitSorter[0].value,
  prefix: name,
});

export default FilterMixin<AwardUnitStoreState & Paginationable>(paginated, {
  prefix: name,
});
