import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Authority } from "@/model/User";

// prettier-ignore
const ENDPOINT = (authorityId: string) => `users/authorities/${authorityId}`;

export default async function getAuthority(
  authorityId: string
): Promise<Authority> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(authorityId)).toString()
  );
  return deserializer.deserialize(result.data);
}
