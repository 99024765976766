import { Notification } from "@/model/Notification";
import EvoState from "../types";
import { Module } from "vuex";
import {
  NotificationState,
  GlobalDisplayError,
  SetStoreNotificationArgs,
} from "./notification.types";
import {
  SET_ERRORS_FOR_COMPONENT,
  DISMISS_ERRORS,
  DISMISS_GLOBAL_ERROR,
  SET_GLOBAL_ERROR,
  SET_STORE_NOTIFICATION,
  DISMISS_STORE_NOTIFICATION,
  DISMISS_ALL_ERRORS,
  SET_WARNING_FOR_COMPONENT,
} from "./notification.actions";
import {
  UPDATE_ALL_ERRORS,
  UPDATE_ERRORS,
  UPDATE_GLOBAL_ERROR,
  UPDATE_NOTIFICATIONS,
  UPDATE_WARNINGS,
} from "./notification.mutations";
import { PrintableError } from "@/utils/errors";

type UPDATE_ERROR_ARGS = {
  id: string;
  errors: PrintableError[];
  action?: string;
};

type UPDATE_WARNING_ARGS = {
  id: string;
  warnings: PrintableError[];
};

const Global_Error_Null_Object: GlobalDisplayError = {
  timestamp: -1,
  message: "",
};

export default {
  state: {
    errors: {},
    warnings: {},
    actions: {},
    globalError: Global_Error_Null_Object,
    notifications: {},
  } as NotificationState,
  actions: {
    async [SET_ERRORS_FOR_COMPONENT]({ commit }, args: UPDATE_ERROR_ARGS) {
      commit(UPDATE_ERRORS, args);
    },
    async [SET_WARNING_FOR_COMPONENT]({ commit }, args: UPDATE_WARNING_ARGS) {
      commit(UPDATE_WARNINGS, args);
    },
    async [DISMISS_ERRORS]({ commit }, id: string) {
      commit(UPDATE_ERRORS, { id, errors: [] } as UPDATE_ERROR_ARGS);
      commit(UPDATE_WARNINGS, { id, warnings: [] } as UPDATE_WARNING_ARGS);
    },
    async [DISMISS_ALL_ERRORS]({ commit }) {
      commit(UPDATE_ALL_ERRORS, { actions: {}, errors: {}, warnings: {} });
    },
    [SET_STORE_NOTIFICATION]({ commit }, args: SetStoreNotificationArgs) {
      commit(UPDATE_NOTIFICATIONS, args);
    },
    [DISMISS_STORE_NOTIFICATION]({ commit }, id: string) {
      commit(UPDATE_NOTIFICATIONS, { id, notification: null });
    },
    [DISMISS_GLOBAL_ERROR]({ commit }) {
      commit(UPDATE_GLOBAL_ERROR, Global_Error_Null_Object);
    },
    [SET_GLOBAL_ERROR]({ commit }, error: GlobalDisplayError) {
      commit(UPDATE_GLOBAL_ERROR, error);
    },
  },
  getters: {
    hasGlobalError(state): boolean {
      return state.globalError.timestamp > Global_Error_Null_Object.timestamp;
    },
    hasError(state): (componentName: string) => boolean {
      return (componentName: string) => {
        return state.errors[componentName]?.length > 0;
      };
    },
  },
  mutations: {
    [UPDATE_ERRORS](state, { id, errors, action }: UPDATE_ERROR_ARGS) {
      state.errors[id] = errors;
      state.actions[id] = action!;
    },
    [UPDATE_WARNINGS](state, { id, warnings }: UPDATE_WARNING_ARGS) {
      state.warnings[id] = warnings;
    },
    [UPDATE_ALL_ERRORS](state, { errors, actions, warnings }: any) {
      state["errors"] = errors;
      state["actions"] = actions;
      state["warnings"] = warnings;
    },
    [UPDATE_NOTIFICATIONS](
      state,
      { id, notification }: { id: string; notification: Notification | null }
    ) {
      state.notifications[id] = notification!;
    },
    [UPDATE_GLOBAL_ERROR](state, error: GlobalDisplayError) {
      state.globalError = error;
    },
  },
} as Module<NotificationState, EvoState>;
