import { createStore } from "vuex";
import EvoState, { RootState } from "./types";
import {
  INITIALIZE_CLIENT,
  SET_GLOBAL_LOADING,
  SET_NAV_MENU_VISIBLE,
  ENABLE_EDIT_MODE,
  ENABLE_MOVE_MODE,
  DISABLE_MOVE_MODE,
  DISABLE_EDIT_MODE,
  SET_IS_FIRST_LOAD,
  ENABLE_FILE_DRAG,
  DISABLE_FILE_DRAG,
  SET_IS_NAVIGATING,
  SET_USER_DATA,
  LEAVE_AWARD_PROCEDURE,
  SET_DIRTY_FOR_ID,
  RESET_DIRTY,
  RESET_EDIT_MODE,
  SET_DIRTY_OVERRIDE,
} from "./actions";
import {
  SHOW_GLOBAL_SPLASHSCREEN,
  HIDE_GLOBAL_SPLASHSCREEN,
  SHOW_NAVIGATION_MENU,
  HIDE_NAVIGATION_MENU,
  SET_EDIT_MODE,
  SET_FIRSTLOAD,
  SET_FILE_DRAG,
  SET_MOVE_MODE,
  SET_IS_NAV,
  WRITE_USER_DATA,
  SET_DIRTY,
  RESET_ALL_DIRTY,
  SET_DIRTY_OVERRIDE_TO_VALUE,
  RESET_ALL_EDIT_MODE,
} from "./mutations";
import hookDispatchForErrorhandling from "@/utils/errors";
import awardProcedures from "./modules/awardProcedure.module";
import common from "./modules/common.module";
import awardUnits from "./modules/awardUnit.module";
import specifications from "./modules/specifications.module";
import callsForTenders from "./modules/callForTenders.module";
import callsForCompetition from "./modules/callForCompetition.module";
import requestForParticipation from "./modules/requestForParticipation.module";
import tableOfOffers from "./modules/tableOfOffers.module";
import offers from "./modules/offer.module";
import participants from "./modules/participant.module";
import { name as ParticipantsName } from "./modules/participant.actions";
import notifications from "./modules/notification.module";
import loading from "./modules/loading.module";
import shipments from "./modules/shipment.module";
import tenderDocuments from "./modules/tenderDocument.module";
import sections from "./modules/section.module";
import notices from "./modules/notice.module";
import conversations from "./modules/conversation.module";
import users from "./modules/users.module";

import deepSet from "@/utils/deepSet";
import { GET_SPECIFICATIONS } from "./modules/specifications.actions";
import { RESET_AWARD_PROCEDURE_CURRENT } from "@/store/modules/awardProcedure.actions";
import { RESET_SHIPMENT_STORE } from "@/store/modules/shipment.actions";
import { RESET_CALL_FOR_COMPETITION_STORE } from "@/store/modules/callForCompetition.actions";
import { RESET_CALL_FOR_TENDERS_STORE } from "@/store/modules/callForTenders.actions";
import { RESET_NOTICE_STORE } from "@/store/modules/notice.actions";
import { RESET_SECTION_STORE } from "@/store/modules/section.actions";
import { RESET_TENDER_DOCUMENT_STORE } from "@/store/modules/tenderDocument.actions";
import { RESET_TABLE_OF_OFFERS_STORE } from "@/store/modules/tableOfOffers.actions";
import { RESET_AWARD_UNIT_STORE } from "@/store/modules/awardUnit.actions";

const defaultState = new EvoState();
const store = createStore({
  state: defaultState as RootState,
  getters: {
    anyDirty(state) {
      return (
        !state.dirty.override &&
        Object.values(state.dirty.components).some((isDirty) => isDirty)
      );
    },
    isEditModeEnabled(state) {
      return Object.values(state.editing.forms).some((isEditing) => isEditing);
    },
  },
  mutations: {
    [SHOW_GLOBAL_SPLASHSCREEN](state) {
      state.isGlobalSplashscreenVisible = true;
    },
    [HIDE_GLOBAL_SPLASHSCREEN](state) {
      state.isGlobalSplashscreenVisible = false;
    },
    [SHOW_NAVIGATION_MENU](state) {
      state.isNavMenuVisible = true;
    },
    [HIDE_NAVIGATION_MENU](state) {
      state.isNavMenuVisible = false;
    },
    [SET_EDIT_MODE](state, { id, value }: { id: string; value: boolean }) {
      state.editing.forms[id] = value;
    },
    [RESET_ALL_EDIT_MODE](state) {
      state.editing.forms = {};
    },
    [SET_FIRSTLOAD](state, firstLoad: boolean) {
      state.isFirstLoad = firstLoad;
    },
    [SET_MOVE_MODE](state, { group, id, active }) {
      state.moveMode.active = active;
      state.moveMode.group = group;
      state.moveMode.id = id;
    },
    [SET_DIRTY](state, { id, isDirty }: { id: string; isDirty: boolean }) {
      state.dirty.components[id] = isDirty;
    },
    [SET_DIRTY_OVERRIDE_TO_VALUE](state, payload) {
      state.dirty.override = payload;
    },
    [RESET_ALL_DIRTY](state) {
      state.dirty.override = false;
      state.dirty.components = {};
    },
    [SET_FILE_DRAG](state, val) {
      state.isFileDrag = val;
    },
    [SET_IS_NAV](state, val) {
      state.isNavigating = val;
    },
    [WRITE_USER_DATA](state, { key, value }) {
      if (!key) {
        state.userData = value;
      } else {
        deepSet(state.userData, key, value, {
          create: true,
          isArray: false,
        });
      }
    },
  },
  actions: {
    async GetUserData({ dispatch }) {
      //Das sollte ins backend wandern und die Pro-Nutzer Daten aktualiseren
      const userData = window.localStorage.getItem("evo");
      if (userData) {
        await dispatch(SET_USER_DATA, {
          value: JSON.parse(userData),
          write: false,
        });
      } else {
        await dispatch(SET_USER_DATA, { value: defaultState.userData });
      }
    },
    async [INITIALIZE_CLIENT]({ rootState, dispatch }) {
      if (!rootState.specifications.initialized) {
        return Promise.all([
          dispatch(GET_SPECIFICATIONS),
          dispatch("GetUserData"),
        ]);
      }
    },
    [SET_IS_FIRST_LOAD]({ commit }, value = false) {
      commit(SET_FIRSTLOAD, value);
    },
    [SET_IS_NAVIGATING]({ commit }, val) {
      commit(SET_IS_NAV, val);
    },
    async [SET_GLOBAL_LOADING]({ commit }, show: boolean) {
      if (show) {
        commit(SHOW_GLOBAL_SPLASHSCREEN);
      } else {
        commit(HIDE_GLOBAL_SPLASHSCREEN);
      }
    },
    async [SET_NAV_MENU_VISIBLE]({ commit }, show: boolean) {
      if (show) {
        commit(SHOW_NAVIGATION_MENU);
      } else {
        commit(HIDE_NAVIGATION_MENU);
      }
    },
    async [SET_USER_DATA]({ commit, state }, { key, value, write = true }) {
      commit(WRITE_USER_DATA, { key, value });
      if (write) {
        //hier sollte ein Backend-Call stattfinden, der Nutzerinformationen speichert
        window.localStorage.setItem("evo", JSON.stringify(state.userData));
      }
    },
    [SET_DIRTY_FOR_ID](
      { commit },
      { id, isDirty }: { id: string; isDirty: boolean }
    ) {
      commit(SET_DIRTY, { id, isDirty });
      if (isDirty) {
        commit(SET_DIRTY_OVERRIDE_TO_VALUE, false); //wenn ich explizit dirty setze, möchte ich keinen Override mehr
      }
    },
    [SET_DIRTY_OVERRIDE]({ commit }, override) {
      commit(SET_DIRTY_OVERRIDE_TO_VALUE, override);
    },
    [RESET_DIRTY]({ commit }) {
      commit(RESET_ALL_DIRTY);
    },
    [RESET_EDIT_MODE]({ commit }) {
      commit(RESET_ALL_EDIT_MODE);
    },
    [ENABLE_EDIT_MODE]({ commit }, { id }) {
      commit(SET_EDIT_MODE, { id, value: true });
    },
    [DISABLE_EDIT_MODE]({ commit }, { id }) {
      commit(SET_EDIT_MODE, { id, value: false });
    },
    [ENABLE_MOVE_MODE]({ commit }, { group, id }) {
      commit(SET_MOVE_MODE, { id, group, active: true });
    },
    [DISABLE_MOVE_MODE]({ commit }) {
      commit(SET_MOVE_MODE, { id: "", group: "", active: false });
    },
    [ENABLE_FILE_DRAG]({ commit }) {
      commit(SET_FILE_DRAG, true);
    },
    [DISABLE_FILE_DRAG]({ commit }) {
      commit(SET_FILE_DRAG, false);
    },
    [LEAVE_AWARD_PROCEDURE]({ dispatch }) {
      dispatch(RESET_NOTICE_STORE);
      dispatch(RESET_TENDER_DOCUMENT_STORE);
      dispatch(RESET_SECTION_STORE);
      dispatch(RESET_SHIPMENT_STORE);
      dispatch(RESET_CALL_FOR_COMPETITION_STORE);
      dispatch(RESET_CALL_FOR_TENDERS_STORE);
      dispatch(RESET_TABLE_OF_OFFERS_STORE);
      dispatch(RESET_AWARD_UNIT_STORE);

      dispatch(RESET_AWARD_PROCEDURE_CURRENT);
    },
  },
  modules: {
    awardProcedures,
    awardUnits,
    specifications,
    callsForTenders,
    callsForCompetition,
    offers,
    tableOfOffers,
    [ParticipantsName]: participants,
    notifications,
    loading,
    requestForParticipation,
    notices,
    conversations,
    common,
    shipments,
    tenderDocuments,
    sections,
    users,
  },
});

hookDispatchForErrorhandling(store);

export default store;
