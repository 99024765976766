//@ts-nocheck

export function setRelationshipIdForEntity(
  resultEntity,
  responseEntity,
  relationship
) {
  let key, value;
  if (!responseEntity.relationships[relationship]) {
    return;
  }
  if (Array.isArray(responseEntity.relationships[relationship].data)) {
    key = relationship + "Ids";
    value = getRelationshipIds(responseEntity, relationship, true);
  } else {
    key = relationship + "Id";
    value = getRelationshipIds(responseEntity, relationship, false);
  }
  resultEntity[key] = value;
}

export function getRelationshipIds(responseEntity, relationship, isArray) {
  return isArray
    ? responseEntity.relationships[relationship].data.map((x) => x.id)
    : responseEntity.relationships[relationship].data.id;
}

export function handleDeepRelationships(
  resultEntity,
  relationship,
  includesById
) {
  const [parent, child] = relationship.split(".");
  if (Array.isArray(resultEntity[parent])) {
    resultEntity[parent].forEach((subEntity) => {
      subEntity &&
        setRelationshipIdForEntity(
          subEntity,
          includesById[subEntity.id],
          child
        );
    });
  } else {
    setRelationshipIdForEntity(
      resultEntity[parent],
      includesById[resultEntity[parent].id],
      child
    );
  }
}
