export const CREATE_PARTICIPANT = "Fügt einen Teilnehmer hinzu";
export const UPDATE_PARTICIPANTS = `Updatet alle Teilnehmer`;
export const UPDATE_CURRENT_PARTICIPANT =
  "Aktualisiert den aktuellen Teilnehmer";
export const MARK_PARTICIPANT_ADMISSION_STATUS = "Markiere einen Teilnehmer";
export const EDIT_PARTICIPANT = "Bearbeitet einen Teilnehmer";
export const DELETE_PARTICIPANT = "Löscht einen Teilnehmer";
export const MARK_PARTICIPANT_QUALIFICATION_STATUS =
  "Durchführung der Eignung pro Los";
export const name = "participants";
