import { AwardingClient } from "@/utils/EvoClient";
import { MarkParticipantQualificationStatusDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/mark-participant-qualification-status";

export default async function markParticipantQualificationStatus(
  dto: MarkParticipantQualificationStatusDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
