
import { defineComponent } from "vue";
import ActionableMixin from "@/mixins/actionable";
import VariantableMixin from "@/mixins/variantable";
import PartialAllowedactionButtonDialogWrapper from "@/components/partials/partial-allowedaction-button-dialog-wrapper.vue";
import LoadingMixin from "@/mixins/loading";
import { useMenuInjections } from "@/composables/injections";

/**
 * Diese Komponente rendered actions als Buttons.
 * Sie zeigt eine [base-coach-mark](#basecoachmark) an, wenn das Backend constraints mitliefert.
 * Sie verwendet den action-meta-service um die nötigen Informationen über
 * die Auszuführende Action zu erhalten, welche ihr als string `action` übergeben wird.
 * Um einen Confirmation-Dialog auszulösen ist lediglich nötig, dass man dem Button 'hasDialog':true setzt
 * und im action-meta-service die entsprechende confirmation setzt.
 *
 * @mixin Actionable
 */
export default defineComponent({
  name: "base-allowedaction-button",
  mixins: [LoadingMixin, ActionableMixin, VariantableMixin],
  expose: ["run"],
  setup() {
    const { isMenu, closeFocusId } = useMenuInjections();
    return { isMenu, closeFocusId };
  },
  emits: [
    "actionPossibleChanged",
    "canPerformActionChanged",
    "register-load-id",
    "run-exit",
    "polling-start",
    "sync-success",
    "loading",
  ],
  inheritAttrs: false,
  components: {
    PartialAllowedactionButtonDialogWrapper,
  },
  props: {
    /**
     * Die Parameter, welche an die Auszuführende Action übergeben werden müssen.
     * Beispielsweise die `currentOffer.id` für die Action `openOffer`.
     * Kann jeder beliebige Typ sein.
     */
    args: {
      type: [String, Object, Number], //any
      default: () => ({}),
    },
    /**
     * Wird ein Dialog genutzt? Wenn der Slot nicht gefüllt ist, wird ein confirmation-dialog angenommen.
     */
    hasDialog: {
      type: Boolean,
      default: false,
    },
    /**
     * Disabled den Button anstelle zu Spinnen wenn geladen wird.
     */
    disableOnLoad: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    /**
     * Render als Textbutton
     */
    text: {
      type: Boolean,
      default: false,
    },
    /*
     * Keine Anzeige eines Coachmarks.
     */
    noCoachmark: {
      type: Boolean,
      default: false,
    },
    /**
     * Zeige nur ladeindikator an, wenn der Button selbst ausgelöst wurde.
     */
    loadingOnOwnTrigger: Boolean,
    /**
     * Props für den Confirmation Dialog zur Verwendung in der Internationalisierung
     */
    dialogProps: Object,
    /**
     * Eine eigene Run-ID für die Command-Action
     */
    customRunId: {
      type: String,
    },
    /**
     * Eigenes Arialabel, wenn nicht der Standard über common.actions.[action].longTitle greifen soll.
     */
    ariaLabelOverride: {
      type: String,
    },
    minLoadTime: {
      type: Number,
      default: 0,
    },
    stayRendered: {
      type: Boolean,
      default: false,
    },
    /**
     * Kann das Action Disable übersteuern (blendet auch Coachmark aus)
     * Z.b. für das Senden von Nachrichten, da die Logik wann ich es darf
     * mit dem Client verbunden ist.
     * Das heißt, dass die Logik wann er ausgeschaltet ist über die disabled
     * prop, reingereicht werden muss. Sonst ist er immer erlaubt.
     */
    overrideActionDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    loading(val) {
      this.$emit("loading", val);
    },
  },
  computed: {
    ariaLabel(): string | undefined {
      if (this.ariaLabelOverride) {
        return this.ariaLabelOverride;
      } else if (this.$te(`common.actions.${this.action}.longTitle`)) {
        return this.$t(`common.actions.${this.action}.longTitle`) as string;
      } else {
        return undefined;
      }
    },
    actionTitle(): string {
      return this.$t(
        `common.actions.${this.action}${this.i18nVariant}.title`
      ).toString();
    },
    _closeFocusId(this: any): string {
      if (this.args._internals?._closeFocusId) {
        return this.args._internals._closeFocusId;
      }
      return this.closeFocusId;
    },
    dialogWidth(): any {
      return this.$actionmeta.getInfo(this.action!).confirmation?.width || 600;
    },
    enable(): boolean {
      return this.loading;
    },
    disableButtonOnLoad(): boolean {
      return this.loading && this.disableOnLoad;
    },
    showSpinner(): boolean {
      return this.loading && !this.disableOnLoad;
    },
    textOrPrimary(): string {
      return this.isMenu
        ? "evonum-menu-popover-item"
        : this.text
        ? "evonum-button-text"
        : "evonum-button-primary";
    },
    isFilled(): boolean | undefined {
      return this.text ? undefined : !this.isMenu;
    },
    forceDisabled(): boolean {
      return (
        this.$store.getters.isEditModeEnabled ||
        this.disabled ||
        (!this.loading && this.anyLoading)
      );
    },
    extListeners(): any {
      // eslint-disable-next-line
      const { onClick, ...listener } = Object.fromEntries(
        Object.entries(this.$attrs).filter(([key]) => key.startsWith("on"))
      );
      return listener;
    },
  },
  methods: {
    run({ directRun = false } = { directRun: false }) {
      if (directRun) {
        (this.$refs["commandAction"] as any).run();
      } else {
        (this.$refs["button"] as any).$el.click();
      }
    },
  },
});
