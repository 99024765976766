import { AwardingClient } from "@/utils/EvoClient";
import { waitForXBeforeResolve } from "@/utils/functions";
import { MoveDocumentToShipmentPositionDto } from "../dto";

const ENDPOINT = "c/move-tender-document";

export default function MoveTenderDocument(
  dto: MoveDocumentToShipmentPositionDto
): Promise<void> {
  return waitForXBeforeResolve<void>(AwardingClient.post(ENDPOINT, dto), 500); //warte auf Verschiebeanimationsende
}
