import { AwardingClient } from "@/utils/EvoClient";
import { SaveMessageDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/save-message";

export default async function saveMessage(
  dto: SaveMessageDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
