export const INITIALIZE_CLIENT =
  "Initialisiere alle nötigen Werte für den Start des Clients";

export const SET_GLOBAL_LOADING =
  "Setze, ob der Globale Splashscreen angezeigt werden soll";

export const SET_NAV_MENU_VISIBLE =
  "Setze, ob das Nav Menu gezeigt werden soll";

export const SET_IS_NAVIGATING =
  "Setzte eine Flag die angibt, ob gerade navigiert wird";

export const RESET_EDIT_MODE = "setzt den Editiermodus zurück";
export const ENABLE_EDIT_MODE = "Aktiviere den Editiermodus";
export const DISABLE_EDIT_MODE = "Deaktiviere den Editiermodus";
export const ENABLE_MOVE_MODE = "Aktiviere den Verschiebemodus für eine Gruppe";
export const DISABLE_MOVE_MODE =
  "Deaktiviere den Verschiebemodus für eine Gruppe";

export const RESET_PAGINATION = "Resettet alle Paginations";
export const RESET_FILTER = "Resettet alle Filter";

export const OVERWRITE_FILTER_FIELD = "Überschreibe ein Filterfeld Wert";

export const SET_IS_FIRST_LOAD = "Setzt First Load auf übergebenen Wert";

export const ENABLE_FILE_DRAG = "Setzte den File Drag auf true";
export const DISABLE_FILE_DRAG = "Setze den File Drag auf false";

export const SET_USER_DATA = "Setzt einen Wert im LocalStorage";

export const LEAVE_AWARD_PROCEDURE =
  "Setzt das aktuelle Verfahren komplett zurück";

export const SET_DIRTY_FOR_ID = "Setzt die Komponente mit Id auf Dirty";
export const RESET_DIRTY = "Setzt Dirty zurück";
export const SET_DIRTY_OVERRIDE = "Setzt den Dirty Override";
