import { QueryResponse } from "@/utils/EvoClient/query";
import { Module } from "vuex";
import { RESET_PAGINATION } from "../actions";
import { RootState, Paginationable } from "../types";
import deepMerge from "lodash.merge";
import {
  QueryExecutor,
  QueryPagination,
} from "@/utils/EvoClient/query/ListQueryFunctionGenerator";

export const UpdateTotalsOnlyPagination: QueryPagination = {
  page: {
    number: 0,
    size: 1,
  },
  retryOnUnexpectedEmpty: false,
};

export const AllElementsPagination: QueryPagination = {
  page: {
    number: 0,
    size: 100000,
  },
  retryOnUnexpectedEmpty: false,
};

//siehe evo-877
export const AllElementsWithoutMetaPagination: QueryPagination = {
  ...AllElementsPagination,
  noKeepMeta: true,
};

function getDefaultPagination(sort: string, { defaultPageSize = 5 } = {}) {
  return {
    total: NaN,
    totalPages: NaN,
    page: {
      number: 0,
      size: defaultPageSize,
    },
    sort: sort,
    retryOnUnexpectedEmpty: true,
    totalUnfilteredCount: 0,
  };
}

export default function paginationMixin<T extends { all: any }>(
  module: Module<T, RootState>,
  {
    mutationWhichUpdatesAll,
    actionWhichUpdatesAll,
    prefix,
    defaultSort = "",
    defaultPageSize = 5,
    getTotalUnfilteredCount,
  }: {
    mutationWhichUpdatesAll: string;
    actionWhichUpdatesAll: string;
    prefix: string;
    defaultSort?: string;
    defaultPageSize?: number;
    getTotalUnfilteredCount?: QueryExecutor<unknown, any>;
  }
): Module<T & Paginationable, RootState> {
  return {
    getters: {
      ...module.getters,
      [`${prefix}:pagination`](state) {
        return state.pagination;
      },
      [`${prefix}:all`](state) {
        return state.all;
      },
    },
    actions: {
      ...module.actions,
      async [actionWhichUpdatesAll](_, ...args) {
        const promises = [];
        //Für Gefilterte Listen, wo es möglich ist, dass man das Kind so ändert, dass es nichtmehr in
        //dem Filter landet, von dem man ausging (conversation, ap, ...)
        if (getTotalUnfilteredCount && _.rootState.isNavigating) {
          promises.push(
            getTotalUnfilteredCount({
              pagination: UpdateTotalsOnlyPagination,
            }).then((res) => {
              _.commit(
                `${prefix}:SetTotalUnfilteredCount`,
                res._meta?.page.totalElements
              );
            })
          );
        }
        promises.push(
          (module.actions![actionWhichUpdatesAll] as CallableFunction)!(
            _,
            ...args
          )
        );
        await Promise.all(promises);
      },
      [`${prefix}:updatePagination`](
        { commit },
        pagination: Paginationable["pagination"]
      ) {
        commit(`${prefix}:SetPagination`, pagination);
      },
      async [`${prefix}:update`](_, ...args) {
        await _.dispatch(actionWhichUpdatesAll, ...args);
      },
      async [RESET_PAGINATION]({ commit }) {
        commit(
          `${prefix}:SetPagination`,
          getDefaultPagination(defaultSort, { defaultPageSize })
        );
      },
      async [`${prefix}:${RESET_PAGINATION}`]({ commit }) {
        commit(
          `${prefix}:SetPagination`,
          getDefaultPagination(defaultSort, { defaultPageSize })
        );
      },
    },
    state: {
      ...module.state,
      pagination: getDefaultPagination(defaultSort, { defaultPageSize }),
    } as T & Paginationable,
    mutations: {
      ...module.mutations,
      [mutationWhichUpdatesAll](state, response: QueryResponse<unknown>) {
        if (response._meta) {
          state.pagination.total = response._meta!.page.totalElements;
          state.pagination.totalPages = response._meta!.page.totalPages;
          state.pagination.page.size = response._meta!.page.size;
          state.pagination.page.number = response._meta!.page.number;
          delete response._meta;
        }
        module.mutations![mutationWhichUpdatesAll](state, response);
      },
      [`${prefix}:SetTotalUnfilteredCount`](state, count) {
        state.pagination.totalUnfilteredCount = count;
      },
      [`${prefix}:SetPagination`](
        state,
        pagination: Paginationable["pagination"]
      ) {
        state.pagination.page = deepMerge(
          state.pagination.page,
          pagination.page
        );
        state.pagination.total = pagination.total || state.pagination.total;
        state.pagination.sort = pagination.sort || state.pagination.sort;
      },
    },
  };
}
