import { AwardingClient } from "@/utils/EvoClient";
import { ChangeSubmissionPeriodDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/change-submission-period";

export default async function changeSubmissionPeriod(
  ecftDTO: ChangeSubmissionPeriodDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, ecftDTO)) as AxiosResponse).data
    .id;
}
