import { AwardingClient } from "@/utils/EvoClient";
import { EnableSectionsDto } from "@/utils/EvoClient/dto";

const ENDPOINT = "c/enable-sections";

export default async function EnableSections(
  dto: EnableSectionsDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
