import { Actionable } from "./Actionable";
import { Participant } from "./Participant";
import { CallForTenders } from "@/model/CallForTenders";
import i18n from "@/i18n";
import {
  OfferAdmissionStatus,
  OfferAdmissionStatusValues,
} from "./OfferAdmissionStatus";
import { OfferAwardStatus, OfferAwardStatusValues } from "./OfferAwardStatus";
import Bimap from "@/utils/bimap";
import { Sorter } from "./Sorter";
import { Document } from "./Document";
import { OpenableHistory } from "@/model/History";
import { OpenableState } from "@/model/OpenableState";
import { AwardUnit } from "@/model/AwardUnit";

export type Offer = {
  id: string;
  callForTendersId: string;
  awardUnitsIds: string[];
  awardUnits?: Partial<AwardUnit>[];
  participant: Participant;
  callForTenders: CallForTenders;
  document: Document;
  inTableOfOffers: boolean;
  admissionStatuses: Record<string, OfferAdmissionStatus>;
  awardStatuses: Record<string, OfferAwardStatus>;
} & Actionable &
  OpenableHistory &
  OpenableState;

export const LOCALIZED_OFFER_ADMISSION_STATUS_BIMAP = new Bimap<string, string>(
  new Map([
    [
      OfferAdmissionStatusValues.NotMarked,
      i18n.global.t("offers.details.admission-status.not-marked"),
    ],
    [
      OfferAdmissionStatusValues.AdmittedToRanking,
      i18n.global.t("offers.details.admission-status.admitted-to-ranking"),
    ],
    [
      OfferAdmissionStatusValues.RejectedFromRanking,
      i18n.global.t("offers.details.admission-status.rejected-from-ranking"),
    ],
    [
      OfferAdmissionStatusValues.NotOffered,
      i18n.global.t("offers.details.admission-status.not-offered"),
    ],
  ])
);

export const LOCALIZED_OFFER_AWARD_STATUS_BIMAP = new Bimap<string, string>(
  new Map([
    [
      OfferAwardStatusValues.NotMarked,
      i18n.global.t("offers.details.award-status.not-marked"),
    ],
    [
      OfferAwardStatusValues.NotConsidered,
      i18n.global.t("offers.details.award-status.not-considered"),
    ],
    [
      OfferAwardStatusValues.Awarded,
      i18n.global.t("offers.details.award-status.awarded"),
    ],
  ])
);

const SORT_OPTIONS = [
  i18n.global.t("common.sortOptions.participantDesc") as any,
  i18n.global.t("common.sortOptions.participantAsc") as any,
];

export const offerSorter = [
  { title: SORT_OPTIONS[0], value: "participant.name,submittedAt" },
  { title: SORT_OPTIONS[1], value: "-participant.name,submittedAt" },
] as Sorter;
