import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (callForTendersId: string) => `calls-for-tenders/${callForTendersId}`;

export default async function getCallForTendersLock(
  callForTendersId: string
): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(callForTendersId))
      .addLockQuery("callForTenders", {
        additionalFields: ["title", "invitees"],
      })
      .toString()
  );
  const cftLock = await deserializer.deserialize(result.data);
  delete cftLock.awardUnits; //sonst werden die AwardUnits mit null überschrieben
  return cftLock;
}
