import { defineComponent } from "vue";

export default defineComponent({
  props: {
    /**
     * Variante, die bei den Locales verwendet werden soll.
     */
    i18nVariant: {
      type: String,
      default: "",
    },
  },
});
