import { AwardingClient } from "@/utils/EvoClient";
import { InviteToCallForTendersDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/invite-to-call-for-tenders";

export default async function inviteToCallForTenders(
  dto: InviteToCallForTendersDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
