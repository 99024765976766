import { AwardingClient } from "@/utils/EvoClient";
import { CreateCallForCompetitionDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/create-call-for-competition";

export default async function createCallForCompetition(
  cccDto: CreateCallForCompetitionDto
): Promise<string> {
  const res = (await AwardingClient.post(ENDPOINT, cccDto)) as AxiosResponse;
  return res.data.id;
}
