export const SHOW_GLOBAL_SPLASHSCREEN =
  "Zeigt einen Splashscreen über allen Elementen";
export const HIDE_GLOBAL_SPLASHSCREEN =
  "Versteckt den Splashscreen über allen Elmenten, wenn er an war";

export const SHOW_NAVIGATION_MENU = "Zeige das Nav Menu";
export const HIDE_NAVIGATION_MENU = "Verstecke das Nav Menu";
export const SET_EDIT_MODE = "Setzt den Editiermodus";
export const RESET_ALL_EDIT_MODE = "Setzt den Editiermodus zurück";
export const SET_MOVE_MODE = "Setze den Verschiebemodus";

export const SET_FIRSTLOAD = "Setzt den First Load Mode";

export const SET_FILE_DRAG = "Setzt den File Drag Modus";
export const SET_IS_NAV = "Setzt Nav Mode";

export const WRITE_USER_DATA = "Setzt einen Wert in Localstorage";

export const SET_DIRTY = "Setzt eine Komponente auf Dirty";
export const RESET_ALL_DIRTY = "Setzt alle Dirty Flags zurück";
export const SET_DIRTY_OVERRIDE_TO_VALUE = "Setzt den Dirty Override";
