export const name = "awardunit";
export const RESET_AWARD_UNIT_STORE =
  "Entfernt alle Daten aus dem AwardUnits-Store";
export const UPDATE_AWARD_UNITS = "Lädt alle AwardUnits";
export const UPDATE_CURRENT_AWARD_UNIT =
  "Aktualisiert den/das aktuelle AwardUnit";
export const CREATE_AWARD_UNIT = "Erzeugt eine Leistungsbeschreibung";
export const EDIT_AWARD_UNIT = "Bearbeitet die Leistungsbeschreibung";
export const CHANGE_AWARD_UNIT = "Führe CHANGE_AWARD_UNIT Kommando aus";
export const DELETE_AWARD_UNIT = "Löscht die Leistungsbeschreibung";

export const CHECK_LOCK = "Prüft das Lock der Award Unit";
export const UPDATE_LOCK = "Setzt das Lock der Award Unit";
export const DISMISS_AWARD_UNIT_NOTIFICATION =
  "Dismissed die Notification der Award Unit";
