import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_menu_popover = _resolveComponent("evonum-menu-popover")!

  return (_openBlock(), _createBlock(_component_evonum_menu_popover, _mergeProps({
    "any-loading": _ctx.anyLoading,
    "no-toggle-scroll-lock": !_ctx.toggleScrollLock,
    ref: "menu"
  }, _ctx.$attrs), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)))
        ])
      }
    })
  ]), 1040, ["any-loading", "no-toggle-scroll-lock"]))
}