import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Context } from "@/model/User";

// prettier-ignore
const ENDPOINT = (contextId: string) => `users/contexts/${contextId}`;

export default async function getOffer(contextId: string): Promise<Context> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(contextId)).toString()
  );
  const offer = await deserializer.deserialize(result.data);
  return offer;
}
