import { Store } from "vuex";
import EvoState from "@/store/types";
import ErrorHandleService from "@/service/ErrorHandlerService";
import i18n from "@/i18n";
import router from "@/router";

export default function hookDispatchForErrorhandling(
  store: Store<EvoState>
): void {
  const dispatch = store.dispatch;

  //Es muss any sein, da Buchstäblich alles reinkommen kann
  store.dispatch = async (...args: any) => {
    try {
      return await dispatch.apply(store, args);
    } catch (error) {
      if (error instanceof HandleableError) {
        //wir werfen nur den Fehler noch höher, wenn wir gerade eine Action ausführen
        //oder Navigieren
        const shouldThrow =
          "getPrintableErrors" in error || //manchmal führen wir auch commands außerhalb aus
          store.getters.isAnyLoading ||
          store.state.isNavigating;
        error.handle(shouldThrow);
      } else {
        console.error("Unknown Error caught");
        console.error(error);
      }
    }
  };
}

abstract class HandleableError extends Error {
  abstract handle(shouldThrow?: boolean): void;
}

export class ResetApplicationError extends HandleableError {
  constructor(private errors: any) {
    super(i18n.global.t("common.errors.resetApplicationError") as string);
  }

  handle() {
    router.push({ name: "Error404", params: { errors: this.errors } });
  }
}

export class NetworkError extends HandleableError {
  code: number;
  url: string;
  constructor(code: number, url: string) {
    super(i18n.global.t("common.errors.noInetConnection") as string);
    this.name = "NetworkError";
    this.code = code;
    this.url = url;
  }

  handle(shouldThrow = false) {
    ErrorHandleService.setGlobalError(this.message);
    if (shouldThrow) {
      throw this;
    }
  }
}

export class InternalServerError extends HandleableError {
  code: string;
  url: string;
  displayCode: string;
  constructor(code: string, url: string) {
    super(i18n.global.t("common.errors.serverError") as string);
    this.name = "InternalServerError";
    this.displayCode = code;
    this.code = code;
    this.url = url;
  }

  handle(shouldThrow = false) {
    ErrorHandleService.setGlobalError(this.message, this.displayCode);
    if (shouldThrow) {
      throw this;
    }
  }
}

export class AccessDeniedError extends HandleableError {
  code: number;
  url: string;
  displayCode: number;
  constructor(code: number, url: string) {
    // :TODO:  korrekte Locale
    super(i18n.global.t("common.errors.notRegisteredError") as string);
    this.name = "NotRegisteredError";
    this.displayCode = code;
    this.code = code;
    this.url = url;
  }

  handle(shouldThrow = false) {
    if (shouldThrow) {
      throw this;
    }
  }
}

export class GatewayError extends HandleableError {
  code: number;
  url: string;
  displayCode: number;
  constructor(code: number, url: string) {
    super(i18n.global.t("common.errors.noConnectionToServer") as string);
    this.name = "GatewayError";
    this.displayCode = code;
    this.code = code;
    this.url = url;
  }

  handle(shouldThrow = false) {
    ErrorHandleService.setGlobalError(this.message, this.displayCode);
    if (shouldThrow) {
      throw this;
    }
  }
}

export class ConsistencyError extends HandleableError {
  constructor() {
    super(i18n.global.t("common.errors.consistencyError") as string);
    this.name = "ConsistencyError";
  }

  handle(shouldThrow = false) {
    if (shouldThrow) {
      throw this;
    }
  }
}

export class ServerValidationError extends HandleableError {
  errors: any[];
  url: string;
  code: number;
  constructor(message: string, errors: any[], code: number, url: string) {
    super(message);
    this.errors = errors;
    this.name = "ServerValidationError";
    this.code = code;
    this.url = url;
  }

  getIfWarningsOnlyOrThrow(): PrintableError[] {
    const errors = this.getPrintableErrors();
    if (errors.length > 0 && errors.every((x) => x.warning)) {
      return errors;
    } else {
      throw this;
    }
  }

  getPrintableErrors(): PrintableError[] {
    return this.errors.map((error: any) => {
      let message;
      if (hasSourcePointer(error)) {
        message = `${error.title} '${error.source.pointer.slice(1)}': ${
          error.detail
        }`;
      } else if (error.title || error.detail) {
        message = `${error.title}: ${error.detail}`;
      } else {
        message = i18n.global.t("common.errors.unknownError", error);
      }
      return {
        code: error.code,
        message,
        title: error.title,
        detail: error.detail,
        warning: error.meta?.warning || false,
        hasSourcePointer: true,
      };
    });
  }

  handle(shouldThrow = false) {
    //handled in component
    if (shouldThrow) {
      throw this;
    }
  }
}

//Zum schmeißen im FE, sollte so funktionieren wie BE Fehler
export class NotFoundError extends HandleableError {
  errors: any[];
  code = 404;
  constructor({ detail, title }: Partial<PrintableError> = {}) {
    super();
    this.errors = [
      {
        code: "NotFoundError",
        message: "-",
        title: title,
        detail: detail,
        warning: false,
      },
    ];
  }

  handle(shouldThrow = false) {
    //handled in component
    if (shouldThrow) {
      throw this;
    }
  }
}

//Ein extrem Simpler Fehler, der in der Error Boundary angezeigt wird
//Hauptsächlich für Frontend Probleme
export class GenericFrontendError extends HandleableError {
  messages: string[];
  constructor(messages: string[]) {
    super();
    this.messages = messages;
  }
  getPrintableErrors(): PrintableError[] {
    return this.messages.map((x) => ({
      code: "GenericFrontendError",
      message: x,
      title: "-",
      detail: "-",
      warning: false,
    }));
  }
  handle() {}
}

export type PrintableError = {
  code: string;
  message: string;
  title: string;
  detail: string;
  warning: boolean;
  hasSourcePointer?: boolean;
  _action?: string; //falls durch Action ausgelöst
};

function hasSourcePointer(error: { source?: { pointer?: string } }): boolean {
  return (
    error &&
    error.source != null &&
    error.source.pointer != null &&
    error.source.pointer.length >= 1
  );
}
