import { OptionType } from "@/model/FilterGroup";
import { Paginationable, RootState } from "@/store/types";

export function getBestMatchPageFromResult(
  result: any
): Paginationable["pagination"]["page"] {
  return {
    size: result.meta.page.size,
    number: Math.max(0, result.meta.page.totalPages - 1),
  };
}

export function isQueryUnexpectedlyEmpty(result: any) {
  if (result.meta?.page?.totalElements > 0 && result.data.length == 0) {
    return true;
  } else {
    return false;
  }
}

export type Pagination = {
  size: number;
  page: number;
};

export type Filter = {
  field: string;
  value?: string | ((rootState: RootState) => string);
};

export type QueryArgs = {
  pagination?: Paginationable["pagination"];
  filter?: Filter[];
  includes?: { value: string }[];
  params?: Record<string, string>;
};

export type QueryResponse<T> = T & {
  _meta?: {
    page: {
      totalElements: number;
      size: number;
      number: number;
      totalPages: number;
    };
  };
};

export const CommonFilters = {
  awardProcedure: {
    field: "awardProcedure",
    value: (state: RootState) => state.awardProcedures.current!.id,
    type: OptionType.FILTER,
  },
} as const;
