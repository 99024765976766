import { RouteRecordRaw } from "vue-router";
import {
  ROUTE_USERS_REGISTER,
  ROUTE_USERS_EDIT_USER,
  ROUTE_AUTHORITIES_INVITATION,
} from "@/router/routes";
import { GET_USER_AS_CURRENT } from "@/store/modules/users.actions";
import { UPDATE_COUNTRIES } from "@/store/modules/common.actions";
import LoadingService from "@/service/LoadingService";
import i18n from "@/i18n";
import store from "@/store";

const UsersFrame = () =>
  import(/* webpackChunkName: "users-chunk" */ "@/views/users/users-frame.vue");
const UsersRegistration = () =>
  import(/* webpackChunkName: "users-chunk" */ "@/views/users/register.vue");
const UsersDetails = () =>
  import(
    /* webpackChunkName: "users-chunk" */ "@/views/users/users-details.vue"
  );

export default {
  path: "/users",
  name: "Users",
  component: UsersFrame,
  meta: {
    title: i18n.global.t("route-titles.users"),
  },
  children: [
    {
      path: "register",
      name: ROUTE_USERS_REGISTER,
      component: UsersRegistration,
      beforeEnter(to, from, next) {
        if (store.state.users.user?.authorities?.length ?? 0 > 0) {
          const code = to.query.code;
          const authorityId = store.state.users.user!.authorities[0].id;
          next({
            name: ROUTE_AUTHORITIES_INVITATION,
            params: { authorityId },
            query: { authority: authorityId, code: code },
          });
        } else {
          LoadingService.addLoadAction(UPDATE_COUNTRIES);
          next();
        }
      },
      meta: {
        notRequireAuthority: true,
      },
    },
    {
      path: "edit-user",
      name: ROUTE_USERS_EDIT_USER,
      component: UsersDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(GET_USER_AS_CURRENT);
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.users"),
        notRequireAuthority: true,
      },
    },
  ],
} as RouteRecordRaw;
