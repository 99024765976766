
import { defineComponent } from "vue";
import { ActionConstraint } from "@/model/Actionable";
import ActionableMixin from "@/mixins/actionable";
import VariantableMixin from "@/mixins/variantable";
import ViewportMixin from "@/mixins/viewport";
import { deepEqual } from "fast-equals";

/**
 * Hilfesystem für AllowedActions mit Constraints. Bei Klick,
 * wird auf **DESKTOP** ein Kontextmenü mittels einer
 * [base-floating-card](#!/base-floating-card) aufgeklappt, welches
 * Informationen darüber enthält, warum eine Aktion nicht durchgeführt
 * werden kann. Bei **MOBILE** wird die gleiche Information
 * über ein [evonum-bottom-sheet](#!/evonum-bottom-sheet) dargestellt.
 *
 * @mixin Actionable
 */
export default defineComponent({
  name: "base-coach-mark_action",
  mixins: [ViewportMixin, ActionableMixin, VariantableMixin],
  computed: {
    hasAction(): boolean {
      return this.actionable!.actions[this.action!] !== undefined;
    },
    hasConstraints(): boolean {
      return this.getActionConstraints.length > 0;
    },
    getActionConstraints(): ActionConstraint[] {
      const constraints =
        this.actionable!.actions[this.action!]?.constraints || [];
      return constraints.filter(({ value }) => {
        if (typeof value === "string") {
          return typeof this.allowedValue == "string"
            ? value === this.allowedValue
            : this.allowedValue?.value == value;
        } else if (typeof value === "object") {
          return typeof this.allowedValue == "string"
            ? Object.values(value || {}).includes(this.allowedValue)
            : deepEqual(value, this.allowedValue);
        } else if (value == null) {
          return true;
        }
      });
    },
    announceContent(): string {
      let result = this.$t(
        `common.actions.${this.action}${this.i18nVariant}.coachmarkHint`
      ) as string;
      this.getActionConstraints.forEach((c) => {
        result += " " + c.title;
      });
      return result;
    },
  },
});
