import { AwardingClient } from "@/utils/EvoClient";
import { CancelAwardProcedureDto } from "../dto";

const ENDPOINT = "c/cancel-award-procedure";

export default async function cancelAwardProcedure(
  capDTO: CancelAwardProcedureDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, capDTO);
}
