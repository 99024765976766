import { App } from "vue";
export { INITIAL_FOCUS_ATTRIBUTE_SELECTOR } from "@evo/evonum";

declare global {
  interface Window {
    duringTouchOrMouse?: boolean;
    logFormValidationErrors: boolean;
  }
}

export default function registerDirectives(app: App) {
  app.directive("commitToolTip", {
    beforeMount: function (el) {
      el.title = `${process.env.VUE_APP_GIT_BRANCH} ${process.env.VUE_APP_GIT_COMMIT}`;
    },
  });
}
