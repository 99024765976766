export function isPlainObject(
  obj: any
): obj is Record<string | symbol | number, any> {
  return isObject(obj) && !isArray(obj);
}

export function isObject(obj: any): boolean {
  return obj !== null && typeof obj === "object";
}

export function isArray(obj: any): obj is Array<any> {
  return Array.isArray(obj);
}

//schneller als Object.keys(obj).length === 0
//allerdings overkill meist
export function isEmptyObject(obj: any): boolean {
  for (const i in obj) {
    return false;
  }
  return true;
}

export function isTextAreaOrInput(
  element: HTMLElement
): element is HTMLInputElement {
  const tagName = element.tagName.toLowerCase();
  if (tagName === "textarea") return true;
  if (tagName !== "input") return false;
  const type = (element.getAttribute("type") || "").toLowerCase();
  const inputTypes = [
    "text",
    "password",
    "number",
    "email",
    "tel",
    "url",
    "search",
    "date",
    "datetime",
    "datetime-local",
    "time",
    "month",
    "week",
  ];
  return inputTypes.indexOf(type) >= 0;
}

export function isFunction(
  funcToCheck: any
): funcToCheck is (...args: any) => any {
  return typeof funcToCheck === "function";
}
const positiveInteger = new RegExp("^[1-9][0-9]*$");

export function isPositiveInteger(value: any): boolean {
  if (typeof value === "number") {
    return value > 0 && Number.isInteger(value);
  } else if (typeof value === "string") {
    return positiveInteger.test(value);
  }
  return false;
}

export function isInteger(value: any): boolean {
  if (typeof value === "number") {
    return Number.isInteger(value);
  } else if (typeof value === "string") {
    return value.trim().length != 0 && Number.isInteger(Number(value));
  }
  return false;
}

export function isNumber(value: any): boolean {
  if (typeof value === "number") {
    return true;
  } else if (typeof value === "string") {
    return value.trim().length != 0 && !Number.isNaN(Number(value));
  }
  return false;
}
