import { AddInviteeDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "c/add-invitee";

export default async function addInvitee(
  inviteDto: AddInviteeDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, inviteDto);
}
