import { ProcedureType } from "@/model/ProcedureType";
import { Regulation } from "@/model/Regulation";
import { TypeOfService } from "@/model/TypeOfService";
import { Specification } from "@/model/Specification";

import { alphanumericalSort } from "@/utils/sorts";
import { AwardProcedure } from "./AwardProcedure";

export default class SpecificationList {
  items: Specification[];
  constructor(items: Specification[]) {
    this.items = items;
  }
  itemCount(): number {
    return this.items.length;
  }
  typeOfServices(): TypeOfService[] {
    const keys: Map<string, TypeOfService> = new Map();
    this.items
      .map((i) => i.typeOfService)
      .forEach((item) => {
        keys.set(item.id, item);
      });
    return Array.from(keys.values()).sort((a, b) =>
      alphanumericalSort(a.name, b.name)
    );
  }
  regulations(): Regulation[] {
    const keys: Map<string, Regulation> = new Map();
    this.items
      .map((i) => i.regulation)
      .forEach((item) => {
        keys.set(item.id, item);
      });
    return Array.from(keys.values()).sort((a, b) =>
      alphanumericalSort(a.vollerName, b.vollerName)
    );
  }
  procedureTypes(): ProcedureType[] {
    const keys: Map<string, ProcedureType> = new Map();
    this.items
      .map((i) => i.procedureType)
      .forEach((item) => {
        keys.set(item.id, item);
      });
    return Array.from(keys.values()).sort((a, b) =>
      alphanumericalSort(a.name, b.name)
    );
  }
  filterByTypeOfService(typeOfService: TypeOfService): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.typeOfService.id === typeOfService.id)
    );
  }
  filterByTypeOfServiceId(typeOfServiceId: string): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.typeOfService.id === typeOfServiceId)
    );
  }
  filterByRegulation(regulation: Regulation): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.regulation.id === regulation.id)
    );
  }
  filterByRegulationId(regulationId: string): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.regulation.id === regulationId)
    );
  }
  filterByProcedureType(procedureType: ProcedureType): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.procedureType.id === procedureType.id)
    );
  }
  filterByProcedureTypeId(procedureTypeId: string): SpecificationList {
    return new SpecificationList(
      this.items.filter((item) => item.procedureType.id === procedureTypeId)
    );
  }
  filterByAwardProcedureProperties(awardProcedure: AwardProcedure) {
    return new SpecificationList(
      this.items.filter((item) => {
        const showSpecificationWhenFullElectronicProcessing =
          item.procedureType.fullElectronicProcessing ?? true;
        if (
          !showSpecificationWhenFullElectronicProcessing &&
          awardProcedure.fullElectronicProcessing
        ) {
          return false;
        }
        return true;
      })
    );
  }
}
