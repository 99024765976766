import { Shipment } from "@/model/Shipment";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "shipments";

export type PossibleShipmentsFilters = ["awardProcedure"];
export type PossibleShipmentsIncludes = ["awardProcedure"];

export default ListQueryFunctionGenerator<
  Shipment[],
  PossibleShipmentsFilters,
  PossibleShipmentsIncludes
>(ENDPOINT);
