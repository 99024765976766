import { AwardingClient } from "@/utils/EvoClient";
import { SendMessageDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/send-message";

export default async function sendMessage(
  dto: SendMessageDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
