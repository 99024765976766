import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { RequestForParticipation } from "@/model/RequestForParticipation";
import { Document } from "@/model/Document";

// prettier-ignore
const ENDPOINT = (requestForParticipationId: string) => `requests-for-participation/${requestForParticipationId}`;

export default async function getRequestForParticipation(
  requestForParticipationId: string
): Promise<RequestForParticipation> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(requestForParticipationId))
      .addIncludes("participant,document")
      .toString()
  );
  const rfp = await deserializer.deserialize(result.data, {
    relations: ["callForCompetition", "awardUnits"],
  });
  if (rfp.document) {
    rfp.document = new Document(rfp.document);
  }
  return rfp;
}
