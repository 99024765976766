import { RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import LoadingService from "@/service/LoadingService";
import Breadcrumbs from "@/router/breadcrumbs";
import { UPDATE_CURRENT_OFFER } from "@/store/modules/offer.actions";
import {
  UPDATE_CALLS_FOR_TENDERS,
  UPDATE_CURRENT_CALL_FOR_TENDERS,
} from "@/store/modules/callForTenders.actions";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import {
  ROUTE_CALL_FOR_TENDERS_DETAILS,
  ROUTE_CALL_FOR_TENDERS_LIST,
  ROUTE_OFFER_DETAILS,
} from "@/router/routes";
import Store from "@/store";
import { UPDATE_AWARD_UNITS } from "@/store/modules/awardUnit.actions";

const CallsForTenders = () =>
  import(
    /* webpackChunkName: "call-for-tenders-chunk" */ "@/views/calls-for-tenders/call-for-tenders-frame.vue"
  );
const CallForTendersList = () =>
  import(
    /* webpackChunkName: "call-for-tenders-chunk" */ "@/views/calls-for-tenders/call-for-tenders-list.vue"
  );
const CallForTendersDetails = () =>
  import(
    /* webpackChunkName: "call-for-tenders-chunk" */ "@/views/calls-for-tenders/call-for-tenders-details.vue"
  );
const OfferDetails = () =>
  import(
    /* webpackChunkName: "offers-chunk" */ "@/views/offers/offer-details.vue"
  );

function isCftDevidedByLots() {
  return (
    Store.state.awardProcedures.current!.lotDivision === true &&
    Store.state.awardProcedures.current!.procedureType?.restricted
  );
}

export default {
  path: "calls-for-tenders",
  component: CallsForTenders,
  async beforeEnter(to, from, next) {
    LoadingService.addLoadAction(UPDATE_CALLS_FOR_TENDERS, {
      awardProcedureId: to.params.awardProcedureId,
      includeLots: isCftDevidedByLots(),
    });
    next();
  },
  children: [
    {
      path: "",
      name: ROUTE_CALL_FOR_TENDERS_LIST,
      component: CallForTendersList,
      meta: {
        breadCrumbs: Breadcrumbs.CallForTenders,
        title: i18n.global.t("route-titles.callsForTenders"),
        loadRoute(dispatcher, { awardProcedureId }) {
          //Da wir das Listen neu Laden auch außerhalb des Routers ausführen müssen
          //wenn wir auf der Liste Elemente löschen
          //Lagern wir das Laden allgemein aus
          const isCftByLots = isCftDevidedByLots();
          const promises = [
            dispatcher.dispatch(UPDATE_CALLS_FOR_TENDERS, {
              awardProcedureId,
              includeLots: isCftByLots,
            }),
            dispatcher.dispatch(
              GET_AWARD_PROCEDURE_AS_CURRENT,
              awardProcedureId
            ),
          ];
          return promises;
        },
      },
      beforeEnter(to, from, next) {
        to.meta!.loadRoute!(LoadingService, to.params);
        next();
      },
    },
    {
      path: ":callForTendersId",
      name: ROUTE_CALL_FOR_TENDERS_DETAILS,
      component: CallForTendersDetails,
      async beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CURRENT_CALL_FOR_TENDERS, {
          callForTendersId: to.params.callForTendersId,
        });
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.callsForTenders"),
        breadCrumbs: Breadcrumbs.CallForTenders,
        parent: ROUTE_CALL_FOR_TENDERS_LIST,
      },
    },
    {
      path: ":callForTendersId/offers/:offerId",
      name: ROUTE_OFFER_DETAILS,
      component: OfferDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CURRENT_CALL_FOR_TENDERS, {
          callForTendersId: to.params.callForTendersId,
        });
        LoadingService.addLoadAction(UPDATE_CURRENT_OFFER, to.params.offerId);
        LoadingService.addLoadAction(UPDATE_AWARD_UNITS);
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.CallForTenders,
        title: i18n.global.t("route-titles.offer"),
        parent: ROUTE_CALL_FOR_TENDERS_DETAILS,
      },
    },
  ],
} as RouteRecordRaw;
