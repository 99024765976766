import { RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import LoadingService from "@/service/LoadingService";
import Breadcrumbs from "@/router/breadcrumbs";
import { UPDATE_CURRENT_OFFER } from "@/store/modules/offer.actions";
import { UPDATE_TABLE_OF_OFFERS } from "@/store/modules/tableOfOffers.actions";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import {
  ROUTE_TABLE_OF_OFFERS_DETAILS,
  ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS,
} from "@/router/routes";
import { UPDATE_AWARD_UNITS } from "@/store/modules/awardUnit.actions";

const TableOfOffers = () =>
  import(
    /* webpackChunkName: "table-of-offers-chunk" */ "@/views/table-of-offers/table-of-offers-frame.vue"
  );
const TableOfOffersDetails = () =>
  import(
    /* webpackChunkName: "table-of-offers-chunk" */ "@/views/table-of-offers/table-of-offers-details.vue"
  );
const TableOfOffersOfferDetails = () =>
  import(
    /* webpackChunkName: "offers-chunk" */ "@/views/offers/offer-details.vue"
  );

export default {
  path: "table-of-offers",
  component: TableOfOffers,
  beforeEnter(to, from, next) {
    LoadingService.addLoadAction(UPDATE_TABLE_OF_OFFERS, {
      awardProcedureId: to.params.awardProcedureId,
    });
    LoadingService.addLoadAction(
      GET_AWARD_PROCEDURE_AS_CURRENT,
      to.params.awardProcedureId
    );
    next();
  },
  children: [
    {
      path: "",
      name: ROUTE_TABLE_OF_OFFERS_DETAILS,
      component: TableOfOffersDetails,
      meta: {
        breadCrumbs: Breadcrumbs.TableOfOffers,
        title: i18n.global.t("route-titles.tableOfOffers"),
      },
    },
    {
      path: "offers/:offerId",
      name: ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS,
      component: TableOfOffersOfferDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CURRENT_OFFER, to.params.offerId);
        LoadingService.addLoadAction(UPDATE_AWARD_UNITS);
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.TableOfOffers,
        title: i18n.global.t("route-titles.offer"),
        parent: ROUTE_TABLE_OF_OFFERS_DETAILS,
      },
    },
  ],
} as RouteRecordRaw;
