import { AwardingClient } from "@/utils/EvoClient";
import { DismissShipmentNotificationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-shipment-notification";

export default async function dismissShipmentNotification(
  dnDTO: DismissShipmentNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dnDTO)) as AxiosResponse).data
    .id;
}
