import { AwardingClient } from "@/utils/EvoClient";
import { PublishCallForCompetitionDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/publish-call-for-competition";

export default async function PublishCallForCompetition(
  dto: PublishCallForCompetitionDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
