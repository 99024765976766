
import { defineComponent, h } from "vue";
const nonEventNameCharsRE = /\W+/;
const modifiersRE = /^[~!&]*/;
const names = {
  "!": "capture",
  "~": "once",
  "&": "passive",
} as Record<string, string>;

type Listener = (ev: any) => any;

function extractEventOptions(eventDescriptor: string) {
  const [modifiers] = eventDescriptor.match(modifiersRE)!;

  return modifiers.split("").reduce((options: any, modifier: any) => {
    options[names[modifier]] = true;
    return options;
  }, {});
}

export default defineComponent({
  name: "base-global-event-listeners",
  data() {
    return {
      listeners: [] as any,
      isActive: true,
    };
  },
  render() {
    //in Mixin umwandeln
    return h("span");
  },
  activated() {
    //für keep-alive
    this.isActive = true;
  },
  deactivated() {
    this.isActive = false;
  },
  mounted() {
    this.listeners = [];
    Object.keys(this.$attrs).forEach((event) => {
      if (!event.startsWith("on")) return;
      const eventName = event.slice(2).toLowerCase();
      let vueListeners = this.$attrs[event] as Array<Listener> | Listener;
      if (!Array.isArray(vueListeners)) {
        vueListeners = [vueListeners];
      }
      for (let vueListener of vueListeners) {
        const handler = (e: Event) => {
          this.isActive && vueListener(e);
        };
        const listenerOptions = [
          eventName.replace(nonEventNameCharsRE, ""),
          handler,
          extractEventOptions(eventName),
        ] as const;
        document.addEventListener(...listenerOptions);
        this.listeners.push(listenerOptions);
      }
    });
  },
  beforeUnmount() {
    this.listeners.forEach(
      (listenerOptions: Parameters<typeof document.addEventListener>) => {
        document.removeEventListener(
          ...(listenerOptions as Parameters<typeof document.addEventListener>)
        );
      }
    );
  },
});
