import { AwardingClient } from "@/utils/EvoClient";
import { AddDocumentToShipmentDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/add-tender-document";

export default async function AddTenderDocument(
  dto: AddDocumentToShipmentDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
