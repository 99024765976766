import { AwardingClient } from "@/utils/EvoClient";
import { EditShipmentDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-shipment";

export default async function editShipment(
  dto: EditShipmentDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
