import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Offer } from "@/model/Offer";
import { Document } from "@/model/Document";

// prettier-ignore
const ENDPOINT = (offerId: string) => `offers/${offerId}`;

export default async function getOffer(offerId: string): Promise<Offer> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(offerId))
      .addIncludes("participant,document,callForTenders,awardUnits")
      .addFields("awardUnit", "lotNumber,id")
      .toString()
  );
  const offer = await deserializer.deserialize(result.data);
  if (offer.document) {
    offer.document = new Document(offer.document);
  }
  return offer;
}
