import { DismissCallForTendersNotificationDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-call-for-tenders-notification";

export default async function DismissCallForTendersNotification(
  dapnDTO: DismissCallForTendersNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dapnDTO)) as AxiosResponse).data
    .id;
}
