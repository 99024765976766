import { FilterOperator, OptionType } from "@/model/FilterGroup";
import { Participant } from "@/model/Participant";
import { RootState } from "@/store/types";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "users/users";

export const supportedFilter = {
  "userRoles.context": {
    field: "userRoles.context",
    value: (state: RootState) => state.awardProcedures.current!.id,
    type: OptionType.FILTER,
  },
  authorities: {
    field: "authorities",
    value: (state: RootState) => state.users.authority!.id,
    type: OptionType.FILTER,
  },
  "not.userRoles.context": {
    field: "userRoles.context",
    value: (state: RootState) => state.awardProcedures.current!.id,
    type: OptionType.FILTER,
    operator: FilterOperator.NOT,
  },
} as const;

export const supportedIncludes = {
  userRoles: {
    value: "userRoles" as const,
    type: OptionType.INCLUDE,
  },
};

export default ListQueryFunctionGenerator<
  Participant[],
  (keyof typeof supportedFilter)[],
  (keyof typeof supportedIncludes)[]
>(ENDPOINT, {
  addIdsOfRelationshipsToResult: ["userRoles", "userRoles.role"],
});
