import { Actionable, ActionConstraints } from "@/model/Actionable";
import { deepEqual } from "fast-equals";

export function actionableHasAction(
  actionable: Actionable,
  action: string
): boolean {
  if (actionable.actions == null) {
    throw TypeError("Passed Object is not Actionable");
  }
  const actions = getActionsFromActionable(actionable);
  return Object.keys(actions).includes(action);
}

function getActionsFromActionable(
  actionable: Actionable
): Record<string, ActionConstraints> {
  return actionable.actions;
}

function getActionValue(
  actionable: Actionable,
  action: string
): ActionConstraints {
  const actions = getActionsFromActionable(actionable);
  return actions[action];
}

export function isActionAllowed(action: ActionConstraints): boolean {
  return action && action.status == "ALLOWED";
}

export function isActionableAllowed(
  actionable: Actionable,
  action: string,
  allowedValue?: string | Record<string, string>
): boolean {
  if (actionableHasAction(actionable, action)) {
    if (isActionAllowed(getActionValue(actionable, action))) {
      const allowedValues =
        getActionsFromActionable(actionable)[action].allowedValues;
      if (allowedValue != undefined && allowedValues != undefined) {
        return typeof allowedValue === "string"
          ? allowedValues
              .flatMap((x) => Object.values(x))
              .includes(allowedValue)
          : allowedValues.some((test) => deepEqual(test, allowedValue));
      } else {
        return true;
      }
    }
  }
  return false;
}
