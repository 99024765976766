
import { defineComponent } from "vue";
import { PrintableError } from "@/utils/errors";
import PartialCommandActionForceDialogConfirmation from "@/components/partials/partial-command-action-force-dialog-confirmation.vue";
import { PropType } from "vue";

export default defineComponent({
  name: "partial-command-action-force-dialog",
  components: {
    PartialCommandActionForceDialogConfirmation,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
    },
    dialogErrors: {
      type: Array as () => PrintableError[],
      required: true,
    },
    cancel: {
      type: Function as PropType<() => void>,
    },
  },
});
