
import { defineComponent } from "vue";
import CommonMenuPopover from "@/components/common-menu-popover.vue";
import { LOGOUT } from "@/store/modules/users.actions";
import { ROUTE_USERS_EDIT_USER } from "@/router/routes";
import CommonAvatar from "@/components/common-avatar.vue";
import ViewportMixin from "@/mixins/viewport";
import { useLowHeight } from "@evo/evonum";

export default defineComponent({
  name: "the-header-user-menu",
  mixins: [ViewportMixin],
  components: {
    CommonMenuPopover,
    CommonAvatar,
  },
  setup() {
    const { isLowHeight } = useLowHeight();
    return {
      isLowHeight,
    };
  },
  computed: {
    username(): string {
      return this.$store.state.users.tokenData?.username || "";
    },
    fullname(): string {
      return `${this.$store.state.users.user?.firstname} ${this.$store.state.users.user?.name}`;
    },
    isUserInUserService(): boolean {
      return this.$store.state.users.user !== null;
    },
    twoLetters(): string {
      if (
        !this.$store.state.users.user?.firstname ||
        !this.$store.state.users.user?.name
      )
        return this.username;
      return (
        this.$store.state.users.user.firstname[0] +
        this.$store.state.users.user.name[0]
      );
    },
  },
  methods: {
    async editUser(): Promise<void> {
      await this.$router.push({ name: ROUTE_USERS_EDIT_USER });
    },
    logout(): void {
      this.$store.dispatch(LOGOUT);
    },
  },
});
