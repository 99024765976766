import { AwardingClient } from "@/utils/EvoClient";
import { RemoveMessageAttachmentDto } from "../dto";

const ENDPOINT = "c/remove-message-attachments";

export default async function RemoveMessageAttachment(
  dto: RemoveMessageAttachmentDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
