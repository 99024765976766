import i18n from "@/i18n";
import { Actionable } from "@/model/Actionable";
import Bimap from "@/utils/bimap";
import {
  ParticipantAdmissionStatus,
  ParticipantAdmissionStatusValues,
} from "@/model/ParticipantAdmissionStatus";
import { Sorter } from "./Sorter";
import { FilterGroup, FilterOption, GenericFilter } from "./FilterGroup";
import { Offer } from "@/model/Offer";
import { ParticipationState } from "@/model/ParticipationState";
import { InvitableHistory } from "@/model/History";
import {
  supportedFilter,
  supportedIncludes,
} from "@/utils/EvoClient/query/GetAllParticipants";
import {
  QualificationStatus,
  QualificationStatusValues,
} from "@/model/QualificationStatus";
import { RequestForParticipation } from "@/model/RequestForParticipation";
import { Conversation } from "./Conversation";

export type Participant = {
  id: string;
  name: string;
  street?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  invited?: boolean; // Wird im Frontend zum Auffordern hinzugefügt
  invitationCode: string;
  email?: string;
  participationState: ParticipationState;
  admissionStatus: ParticipantAdmissionStatus;
  awardProcedureId?: string;
  conversation?: Conversation;
  conversationId?: string;
  offers?: Offer[];
  requestsForParticipation?: RequestForParticipation[];
  qualificationStatuses: Record<string, QualificationStatus>;
} & Actionable &
  InvitableHistory;

const SORT_OPTIONS = [
  i18n.global.t("common.sortOptions.participantDesc") as any,
  i18n.global.t("common.sortOptions.participantAsc") as any,
  i18n.global.t("common.sortOptions.participantAdmissionStatus") as any,
];

export const LOCALIZED_PARTICIPANT_ADMISSION_STATUS_BIMAP = new Bimap<
  string,
  string
>(
  new Map([
    [
      ParticipantAdmissionStatusValues.NotMarked,
      i18n.global.t("participants.shared.admission-status.not-marked"),
    ],
    [
      ParticipantAdmissionStatusValues.Admitted,
      i18n.global.t("participants.shared.admission-status.admitted"),
    ],
    [
      ParticipantAdmissionStatusValues.Rejected,
      i18n.global.t("participants.shared.admission-status.rejected"),
    ],
  ])
);

export const LOCALIZED_QUALIFICATION_STATUS_BIMAP = new Bimap<string, string>(
  new Map([
    [
      QualificationStatusValues.NotMarked,
      i18n.global.t("participants.details.qualification-status.not-marked"),
    ],
    [
      QualificationStatusValues.Qualified,
      i18n.global.t("participants.details.qualification-status.qualified"),
    ],
    [
      QualificationStatusValues.Unqualified,
      i18n.global.t("participants.details.qualification-status.unqualified"),
    ],
    [
      QualificationStatusValues.NotApplied,
      i18n.global.t("participants.details.qualification-status.not-applied"),
    ],
  ])
);

export const participantNameOnlySorter = [
  {
    title: SORT_OPTIONS[0],
    value: "name",
  },
  {
    title: SORT_OPTIONS[1],
    value: "-name",
  },
] as Sorter;

export const participantSorter = [
  {
    title: SORT_OPTIONS[0],
    value: "name",
  },
  {
    title: SORT_OPTIONS[1],
    value: "-name",
  },
  {
    title: SORT_OPTIONS[2],
    value: "admissionStatus,name",
  },
] as Sorter;

export const NOT_OFFERED_FILTER_KEY = "PARTICIPANT-OFFER-NOT-OFFERED";
export const OFFER_LOSE_FILTERED_KEY = "OFFER_LOSE_FILTERED";

function createChangedCopyOfFilterOption(
  filter: FilterOption<GenericFilter>,
  { label, description }: Partial<FilterOption<GenericFilter>> = {}
): FilterOption<GenericFilter> {
  return Object.assign({}, filter, { label, description });
}

const participantFilterGroups = {
  all: {
    name: "TEILNEHMER",
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "PARTICIPANT_ALL",
        label: i18n.global.t("calls-for-tenders.details.filters.all.label"),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.all.description"
        ),
        value: [supportedFilter.awardProcedure],
      },
    ],
  } as FilterGroup<GenericFilter>,
  negotiation: {
    name: "TEILNEHMER",
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "NEG_PARTICIPANT_RELEVANT",
        label: i18n.global.t(
          "calls-for-tenders.details.filters.qualified.label"
        ),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.qualified.description"
        ),
        value: [
          supportedFilter["isInvitedOrInvitable.callForTenders"],
          supportedFilter["isInvitedOrInvitable.awardUnit"],
        ],
      },
      {
        key: "NEG_PARTICIPANT_INVITED",
        label: i18n.global.t("calls-for-tenders.details.filters.invitee.label"),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.invitee.description"
        ),
        value: [
          supportedFilter["isInvited.callForTenders"],
          supportedFilter["isInvited.awardUnit"],
        ],
      },
      {
        key: "NEG_PARTICIPANT_NOT_INVITED",
        label: i18n.global.t(
          "calls-for-tenders.details.filters.not-invitee.label"
        ),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.not-invitee.description"
        ),
        value: [
          supportedFilter["isInvitable.callForTenders"],
          supportedFilter["not.isInvited.callForTenders"], //nur, wenn es sich um die ganze Einholung dreht, nicht losweise
          supportedFilter["isInvitable.awardUnit"],
        ],
      },
    ],
  } as FilterGroup<GenericFilter>,
  participantsWithOffers: {
    name: "TEILNEHMER",
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "PARTICIPANT-OFFER-INVITED",
        label: i18n.global.t("calls-for-tenders.details.filters.invited.label"),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.invited.description"
        ),
        value: [
          supportedFilter.callsForTenders,
          supportedFilter["offers::callForTenders"],
          supportedIncludes.offers,
        ],
      },
      {
        key: "PARTICIPANT-OFFER-OFFERED",
        label: i18n.global.t("calls-for-tenders.details.filters.offered.label"),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.offered.description"
        ),
        value: [
          supportedFilter["offers.callForTenders"],
          supportedFilter["offers::callForTenders"],
          supportedIncludes.offers,
        ],
      },
      {
        key: NOT_OFFERED_FILTER_KEY,
        label: i18n.global.t(
          "calls-for-tenders.details.filters.not-offered.label"
        ),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.not-offered.description"
        ),
        value: [
          supportedFilter.callsForTenders,
          supportedFilter["not.offers.callForTenders"],
          supportedFilter["offers::callForTenders"],
          supportedIncludes.offers,
        ],
      },
    ],
  } as FilterGroup,
  qualification: {
    name: "TEILNEHMER",
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "PARTICIPANT_QUALIFIED",
        label: i18n.global.t(
          "calls-for-tenders.details.filters.qualified.label"
        ),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.qualified.description"
        ),
        value: [
          supportedFilter.isQualified,
          supportedFilter.awardProcedure,
          supportedFilter.admissionStatus,
        ],
      },
      {
        key: "PARTICIPANT_INVITED",
        label: i18n.global.t("calls-for-tenders.details.filters.invitee.label"),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.invitee.description"
        ),
        value: [
          supportedFilter["isInvited.callForTenders"],
          supportedFilter["isInvited.awardUnit"],
        ],
      },
      {
        key: "PARTICIPANT_NOT_INVITED",
        label: i18n.global.t(
          "calls-for-tenders.details.filters.not-invitee.label"
        ),
        description: i18n.global.t(
          "calls-for-tenders.details.filters.not-invitee.description"
        ),
        value: [
          supportedFilter["isInvitable.callForTenders"],
          supportedFilter["not.isInvited.callForTenders"], //nur, wenn es sich um die ganze Einholung dreht, nicht losweise
          supportedFilter["isInvitable.awardUnit"],
        ],
      },
    ],
  } as FilterGroup<GenericFilter>,
  rfpLots: {
    name: "LOSE",
    heading: i18n.global.t(
      "calls-for-competition.details.filters.heading"
    ) as any,
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "LOSE_ALL",
        label: i18n.global.t(
          "calls-for-competition.details.filters.call-for-competition.label"
        ) as any,
        value: [supportedFilter.awardProcedure],
      },
      {
        key: "LOSE_FILTERED",
        label: i18n.global.t(
          "calls-for-competition.details.filters.award-units.label"
        ) as any,
        value: [
          supportedFilter["requestsForParticipation.awardUnits"],
          supportedFilter.awardProcedure,
        ],
      },
    ],
  } as FilterGroup<GenericFilter>,
  offerLots: {
    name: "OFFER_LOSE",
    heading: i18n.global.t(
      "calls-for-competition.details.filters.heading"
    ) as any,
    get defaultFilter() {
      return this.filters[0];
    },
    filters: [
      {
        key: "OFFER_LOSE_ALL",
        label: i18n.global.t(
          "calls-for-competition.details.filters.call-for-competition.label"
        ) as any,
        value: [],
      },
      {
        key: OFFER_LOSE_FILTERED_KEY,
        exclusive: true,
        label: i18n.global.t(
          "calls-for-competition.details.filters.award-units.label"
        ) as any,
        value: [
          supportedFilter["offers.awardUnits"],
          supportedFilter["offers.callForTenders"],
          supportedFilter["offers::callForTenders"],
          supportedIncludes.offers,
        ],
      },
    ],
  } as FilterGroup<GenericFilter>,
  lateNegotiation: {} as FilterGroup<GenericFilter>,
  lateQualification: {} as FilterGroup<GenericFilter>,
};

const invitedParticipantsFilterText = {
  label: i18n.global.t("calls-for-tenders.details.filters.late-invited.label"),
  description: i18n.global.t(
    "calls-for-tenders.details.filters.late-invited.description"
  ),
};

const notInvitedParticipantsFilterText = {
  label: i18n.global.t(
    "calls-for-tenders.details.filters.late-not-invited.label"
  ),
  description: i18n.global.t(
    "calls-for-tenders.details.filters.late-not-invited.description"
  ),
};

participantFilterGroups.lateNegotiation = {
  ...participantFilterGroups.negotiation,
  filters: [
    participantFilterGroups.negotiation.filters[0],
    createChangedCopyOfFilterOption(
      participantFilterGroups.negotiation.filters[1],
      invitedParticipantsFilterText
    ),
    createChangedCopyOfFilterOption(
      participantFilterGroups.negotiation.filters[2],
      notInvitedParticipantsFilterText
    ),
  ],
};

participantFilterGroups.lateQualification = {
  ...participantFilterGroups.qualification,
  filters: [
    participantFilterGroups.qualification.filters[0],
    createChangedCopyOfFilterOption(
      participantFilterGroups.qualification.filters[1],
      invitedParticipantsFilterText
    ),
    createChangedCopyOfFilterOption(
      participantFilterGroups.qualification.filters[2],
      notInvitedParticipantsFilterText
    ),
  ],
};

export { participantFilterGroups };
