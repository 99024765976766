
import { defineComponent } from "vue";
import TheProgressBar from "@/components/the-progress-bar.vue";
//@ts-ignore
import { useLowHeight } from "@evo/evonum";
import TheHeaderUserMenu from "@/components/the-header-user-menu.vue";
import TheHeaderAuthorityMenu from "@/components/the-header-authority-menu.vue";
import ViewportMixin from "@/mixins/viewport";
export default defineComponent({
  name: "the-header",
  mixins: [ViewportMixin],
  setup() {
    const { isLowHeight } = useLowHeight();
    return {
      isLowHeight,
    };
  },
  computed: {
    username(): string {
      return this.$store.state.users.tokenData?.username || "";
    },
  },
  components: {
    TheProgressBar,
    TheHeaderUserMenu,
    TheHeaderAuthorityMenu,
  },
  data() {
    return {
      noSimulation: true,
    };
  },
  created() {
    setTimeout(() => {
      this.noSimulation = false;
    }, 1000);
  },
});
