import { TypeOfService } from "./TypeOfService";
import { Regulation } from "./Regulation";
import { ProcedureType } from "./ProcedureType";
import { Stateable } from "./Stateable";
import { Notificationable } from "./Notificationable";
import { Actionable } from "./Actionable";
import { AwardProcedureState } from "./AwardProcedureState";
import i18n from "@/i18n";
import { Sorter } from "./Sorter";
import { Specification } from "./Specification";
import { CompletableHistory } from "@/model/History";

export type AwardProcedure = {
  id: string;
  title: string;
  shortDescription?: string;
  fileNumber?: string;
  typeOfService?: TypeOfService;
  regulation?: Regulation;
  procedureType?: ProcedureType;
  mostRecentDeadline: string | null;
  mostRecentDeadlineType: "SUBMISSION" | "REQUEST_FOR_PARTICIPATION" | null;
  specification?: Specification;
  cancelled: boolean;
  cancelledAt?: string;
  fullElectronicProcessing: boolean;
  lotDivision?: boolean;
  internalDocumentation?: string;
  pendingChanges?: {
    title?: AwardProcedure["title"];
    fileNumber?: AwardProcedure["fileNumber"];
    shortDescription?: AwardProcedure["shortDescription"];
  };
} & Stateable<AwardProcedureState> &
  Actionable &
  CompletableHistory &
  Notificationable;

export function getDefaultSort(tab: AwardProcedureState) {
  switch (tab) {
    case AwardProcedureState.Active:
      return "-publishedAt";
    case AwardProcedureState.Draft:
      return "-createdAt";
    case AwardProcedureState.Completed:
      return "-completedAt";
    default:
      return "title";
  }
}

const commonSorter = [
  {
    title: i18n.global.t("common.sortOptions.title"),
    value: "title",
  },
  {
    title: i18n.global.t("common.sortOptions.fileNumber"),
    value: "fileNumber",
  },
] as Sorter;

export const awardProcedureSorterDraft = [
  {
    title: i18n.global.t("common.sortOptions.createdAtDesc"),
    value: "-createdAt",
  },
  {
    title: i18n.global.t("common.sortOptions.createdAtAsc"),
    value: "createdAt",
  },
  ...commonSorter,
] as Sorter;

export const awardProcedureSorterActive = [
  {
    title: i18n.global.t("common.sortOptions.publishedAtDesc"),
    value: "-publishedAt",
  },
  {
    title: i18n.global.t("common.sortOptions.publishedAtAsc"),
    value: "publishedAt",
  },
  ...commonSorter,
  {
    title: i18n.global.t("common.sortOptions.mostRecentDeadline"),
    value: "mostRecentDeadline",
  },
] as Sorter;

export const awardProcedureSorterCompleted = [
  {
    title: i18n.global.t("common.sortOptions.completedAtDesc"),
    value: "-completedAt",
  },
  {
    title: i18n.global.t("common.sortOptions.completedAtAsc"),
    value: "completedAt",
  },
  ...commonSorter,
] as Sorter;
