import {
  MarkOfferAdmissionStatusDto,
  MarkOfferAwardStatusDto,
} from "./../../utils/EvoClient/dto";
import {
  MARK_OFFER_ADMISSION_STATUS,
  MARK_OFFER_AWARD_STATUS,
  name,
} from "./offer.actions";
import { OfferStoreState } from "@/store/modules/offer.types";
import {
  UPDATE_CURRENT_OFFER,
  UPDATE_OFFERS,
} from "@/store/modules/offer.actions";
import Client from "@/utils/EvoClient";
import {
  SET_ALL_OFFERS,
  SET_CURRENT_OFFER,
} from "@/store/modules/offer.mutations";
import { Offer, offerSorter } from "@/model/Offer";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import PaginationMixin from "@/store/mixins/pagination";
import { UPDATE_TABLE_OF_OFFERS } from "./tableOfOffers.actions";

const store = {
  state: {
    all: [],
    current: null,
  } as OfferStoreState,
  actions: {
    async [UPDATE_OFFERS](
      { commit, getters, rootState },
      { callForTendersId, tableOfOffersId } = {}
    ) {
      let field, value, includes, fields;
      if (tableOfOffersId) {
        field = "tableOfOffers" as const;
        value = tableOfOffersId;
        includes = ["callForTenders" as const, "awardUnits" as const];
        fields = [{ type: "awardUnit", value: ["lotNumber", "id"] }];
      } else {
        field = "callForTenders" as const;
        value = callForTendersId || rootState.callsForTenders.current!.id;
      }
      const offers = await Client.GetAllOffers({
        filter: [{ field, value }],
        pagination: getters[`${name}:pagination`],
        includes,
        fields,
      });
      commit(SET_ALL_OFFERS, offers);
    },
    async [MARK_OFFER_ADMISSION_STATUS](
      { dispatch, state },
      offerDto: MarkOfferAdmissionStatusDto
    ) {
      await Client.MarkOfferAdmissionStatus(offerDto);
      await Promise.all([
        dispatch(UPDATE_TABLE_OF_OFFERS),
        dispatch(UPDATE_CURRENT_OFFER, state.current?.id),
      ]);
    },
    async [MARK_OFFER_AWARD_STATUS](
      { dispatch, state },
      offerDto: MarkOfferAwardStatusDto
    ) {
      await Client.MarkOfferAwardStatus(offerDto);
      await Promise.all([
        dispatch(UPDATE_TABLE_OF_OFFERS),
        dispatch(UPDATE_CURRENT_OFFER, state.current?.id),
      ]);
    },
    async [UPDATE_CURRENT_OFFER]({ commit }, offerId: string) {
      let offer = null;
      if (offerId != null) {
        offer = await Client.GetOffer(offerId);
      }
      commit(SET_CURRENT_OFFER, offer);
    },
  },
  mutations: {
    [SET_ALL_OFFERS](state, offers: Offer[]) {
      state.all = offers;
    },
    [SET_CURRENT_OFFER](state, offer: Offer) {
      state.current = offer;
    },
  },
} as Module<OfferStoreState, RootState>;

const paginated = PaginationMixin<OfferStoreState>(store, {
  actionWhichUpdatesAll: UPDATE_OFFERS,
  mutationWhichUpdatesAll: SET_ALL_OFFERS,
  prefix: name,
  defaultSort: offerSorter[0].value,
});

export default paginated;
