
import { defineComponent } from "vue";
import {
  GenericFrontendError,
  PrintableError,
  ServerValidationError,
} from "@/utils/errors";
import {
  DISMISS_ERRORS,
  SET_ERRORS_FOR_COMPONENT,
} from "../../store/modules/notification.actions";
import { getUniqueId } from "@/utils/ids";

/**
 * Eine error-boundary stellt eine Grenze für ServerValidationError dar.
 * ServerValidationError, die innerhalb der Boundary geworfen werden,
 * dringen nicht nach außen. Die Fehler werden über den Store verwaltet
 * und können über ihn auch wieder gelöscht werden. Die Anzeige der
 * Fehler innerhalb einer Boundary können über
 * [base-error-boundary-error-list](#!/base-error-boundary-error-list)
 * oder [evonum-error-list](#!/evonum-error-list) angezeigt werden.
 */
export default defineComponent({
  name: "base-error-boundary",
  emits: ["error", "dismiss", "register-error-id"],
  props: {
    /**
     * ID der Boundary.
     */
    errorBoundaryId: {
      type: String,
      default: () => getUniqueId(),
    },
  },
  created() {
    /**
     * Event mit der Id der Boundary, wenn diese *created* wird.
     * @property {string} id Id der Boundary
     */
    this.$emit("register-error-id", this.errorBoundaryId);
  },
  methods: {
    dismiss(): void {
      this.$store.dispatch(DISMISS_ERRORS, this.errorBoundaryId);
    },
  },
  errorCaptured(err) {
    //If not production, log error
    if (process.env.NODE_ENV !== "production") {
      console.warn(err);
    }

    if (
      err instanceof ServerValidationError ||
      err instanceof GenericFrontendError
    ) {
      const errors = err.getPrintableErrors();
      this.$store.dispatch(SET_ERRORS_FOR_COMPONENT, {
        id: this.errorBoundaryId,
        errors,
      });
      return false;
    }
  },
  provide(): any {
    return {
      errorBoundaryId: this.errorBoundaryId,
    };
  },
  computed: {
    errors(): PrintableError[] {
      return this.$store.state.notifications.errors[this.errorBoundaryId] || [];
    },
    action(): string | undefined {
      return this.$store.state.notifications.actions[this.errorBoundaryId];
    },
  },
  watch: {
    errors(val) {
      if (val.length > 0) {
        /**
         * Event, wenn neue Fehler registriert wurden.
         * @property {Array} val der aufgetretene Fehler
         */
        this.$emit("error", val);
      }
    },
  },
});
