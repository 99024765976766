import { RootState } from "@/store/types";

export type DynamicFilter = RelationshipFilter | GenericFilter | IncludeFilter;

export type DynamicFilterValue =
  | ((store: RootState) => string)
  | string
  | string[]
  | null;

export type IncludeFilter = {
  value: DynamicFilterValue;
  type: OptionType.INCLUDE;
};

export type RelationshipFilter = {
  value: DynamicFilterValue;
  type: OptionType.RELATIONSHIPFILTER;
  field: string;
};

export type GenericFilter = {
  value?: DynamicFilterValue;
  field?: string;
  type: OptionType.FILTER;
};

export function isRelationshipFilter(
  filter: DynamicFilter
): filter is RelationshipFilter {
  return filter.type == OptionType.RELATIONSHIPFILTER;
}

export function isGenericFilter(
  filter: DynamicFilter
): filter is GenericFilter {
  return filter.type == OptionType.FILTER;
}

export function isIncludeFilter(
  filter: DynamicFilter
): filter is IncludeFilter {
  return filter.type == OptionType.INCLUDE;
}

export const enum OptionType {
  FILTER, //setzt filter[field]=value.
  INCLUDE, //setzt include=value,
  RELATIONSHIPFILTER, //setzt filter[relationship][field]=value
}

export const enum FilterOperator {
  NOT, //setzt filter:not
  OR, //setzt filter:or
}

export type FilterGroup<FilterKind = DynamicFilter> = {
  name: string; //i18n name des Feldes, z.B. "Zulassung",
  heading?: string; //i18n Überschrift für die Aufklapp-Karte. Default ist "Filter"
  defaultFilter: FilterOption;
  filters: FilterOption<FilterKind>[]; // Die einzelen Filteroptionen, z.B. "Zugelassen", "Nicht Zugelassen"
};

export type FilterOption<FilterKind = DynamicFilter> = {
  key: string; //Der Key des Filters,
  label: string; //Der Label des entsprechenden Radiobuttons
  description?: string; //Die Beschreibung des entsprechenden Radiobuttons
  exclusive?: boolean; //Wenn true, dann wird nur dieser Filter beachtet
  value: Array<FilterKind>; //der Wert, der für die Filtergruppe gesetzt werden soll, wenn gewählt
};
