export const UPDATE_CONVERSATIONS = "Lädt alle Conversations";
export const UPDATE_CURRENT_CONVERSATION =
  "Aktualisiert den/das aktuelle Conversation";
export const OPEN_CURRENT_CONVERSATION =
  "Navigiert zu der/dam aktuelle Conversation";
export const SAVE_MESSAGE = "Speichert den Entwurf einer Nachricht";
export const SEND_MESSAGE = "Sende Entwurf an das Portal";
export const DELETE_MESSAGE = "Löschen den Entwurf";
export const CHECK_LOCK = "Prüfe Conversation Lock";
export const UPDATE_LOCK = "Aktualisiere Conversation Lock";
export const DISMISS_CONVERSATION_NOTIFICATION =
  "Bestätigte Conversation Benachrichtigung";
export const MARK_MESSAGES_AS_READ =
  "Alle übergebenen Message-IDs als gelesen markieren";
export const ADD_MESSAGE_ATTACHMENT =
  "Hänge einen Anhang an eine Benachrichtigung";
export const REMOVE_MESSAGE_ATTACHMENT =
  "Entfernt einen Anhang an eine Benachrichtigung";
export const REMOVE_LOCAL_ATTACHMENT =
  "Entfernt ein Attachment aus dem Local Store. Fehlerbereinigung";
export const name = "conversation";
