import { Offer } from "@/model/Offer";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "offers";

export type PossibleOffersFilters = ["callForTenders", "tableOfOffers"];
export type PossibleOffersIncludes = [
  "awardProcedure",
  "callForTenders",
  "document",
  "participant",
  "awardUnits",
];
export type PossibleOffersSorts = [
  "submittedAt",
  "-submittedAt",
  "admissionStatus",
  "-admissionStatus",
  "awardStatus",
  "-awardStatus",
  "participant.name",
  "-participant.name",
];

export default ListQueryFunctionGenerator<
  Offer[],
  PossibleOffersFilters,
  PossibleOffersIncludes,
  PossibleOffersSorts
>(ENDPOINT, {
  addIdsOfRelationshipsToResult: ["callForTenders"],
  alwaysIncludes: ["participant"],
});
