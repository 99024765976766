import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import Breadcrumbs from "@/router/breadcrumbs";
import LoadingService from "@/service/LoadingService";
import { UPDATE_CALLS_FOR_COMPETITION } from "@/store/modules/callForCompetition.actions";
import Store from "@/store";
import { UPDATE_CURRENT_REQUEST_FOR_PARTICIPATION } from "@/store/modules/requestForParticipation.actions";
import {
  ROUTE_CALL_FOR_COMPETITION_DETAILS,
  ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS,
} from "@/router/routes";
import { UPDATE_AWARD_UNITS } from "@/store/modules/awardUnit.actions";
import { AllElementsWithoutMetaPagination } from "@/store/mixins/pagination";

const CallsForCompetition = () =>
  import(
    /* webpackChunkName: "call-for-competition-chunk" */ "@/views/calls-for-competition/call-for-competition-frame.vue"
  );

const CallForCompetitionOverview = () =>
  import(
    /* webpackChunkName: "call-for-competition-chunk" */ "@/views/calls-for-competition/call-for-competition-details.vue"
  );
const CallForCompetitionRequestForParticipationDetails = () =>
  import(
    /* webpackChunkName: "request-for-participation-chunk" */ "@/views/requests-for-participation/request-for-participation-details.vue"
  );

export default {
  path: "calls-for-competition",
  component: CallsForCompetition,
  async beforeEnter(to, from, next) {
    await LoadingService.syncLoadAction(UPDATE_CALLS_FOR_COMPETITION, {
      awardProcedureId: to.params.awardProcedureId,
    });
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.callForCompetition"),
  },
  children: [
    {
      path: ":callForCompetitionId?",
      name: ROUTE_CALL_FOR_COMPETITION_DETAILS,
      component: CallForCompetitionOverview,
      async beforeEnter(to, from, next) {
        await Promise.all(
          to.meta!.loadRoute!(
            {
              dispatch: (...args) => LoadingService.syncLoadAction(...args),
            },
            to.params
          )
        );
        if (
          to.params.callForCompetitionId ||
          !Store.state.callsForCompetition.currentId
        ) {
          next();
        } else {
          next({
            name: ROUTE_CALL_FOR_COMPETITION_DETAILS,
            params: {
              callForCompetitionId:
                Store.state.callsForCompetition.currentId || undefined,
            },
          } as RouteLocationRaw);
        }
      },
      meta: {
        title: i18n.global.t("route-titles.callForCompetition"),
        breadCrumbs: Breadcrumbs.CallForCompetition,
        loadRoute(dispatcher, { awardProcedureId }) {
          //Da wir neu Laden auch außerhalb des Routers ausführen müssen
          //wenn wir hier Elemente löschen
          //Lagern wir das Laden allgemein aus
          //Dann kann es auch im Store verwendet werden, ohne doppelte Pflege
          const promises = [
            dispatcher.dispatch(UPDATE_CALLS_FOR_COMPETITION, {
              awardProcedureId,
            }),
          ];
          if (Store.state.awardProcedures.current!.lotDivision === true) {
            promises.push(
              dispatcher.dispatch(UPDATE_AWARD_UNITS, {
                fields: [
                  { type: "awardUnit", value: ["id", "title", "lotNumber"] },
                ],
                sort: "lotNumber",
                pagination: AllElementsWithoutMetaPagination,
              })
            );
          }
          return promises;
        },
      },
    },
    {
      path: ":callForCompetitionId/requests-for-participation/:requestForParticipationId",
      name: ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS,
      component: CallForCompetitionRequestForParticipationDetails,
      beforeEnter(to, from, next) {
        LoadingService.addCheck(() => {
          if (Store.state.requestForParticipation.current != null) {
            return (
              Store.state.callsForCompetition.currentId ==
              Store.state.requestForParticipation.current.callForCompetitionId
            );
          }
          return true;
        });
        LoadingService.addLoadAction(
          UPDATE_CURRENT_REQUEST_FOR_PARTICIPATION,
          to.params.requestForParticipationId
        );
        if (Store.state.awardProcedures.current!.lotDivision === true) {
          LoadingService.addLoadAction(UPDATE_AWARD_UNITS, {
            requestForParticipationId: to.params.requestForParticipationId,
          });
        }
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.CallForCompetition,
        title: i18n.global.t("route-titles.requestForParticipation"),
        parent: ROUTE_CALL_FOR_COMPETITION_DETAILS,
      },
    },
  ],
} as RouteRecordRaw;
