import { AwardingClient } from "@/utils/EvoClient";
import { DeleteCallForTendersDto } from "../dto";

const ENDPOINT = "c/delete-call-for-tenders";

export default async function deleteCallForTenders(
  dapDTO: DeleteCallForTendersDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dapDTO);
}
