import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (awardProcedureId: string) => `award-procedures/${awardProcedureId}`;

export default async function getAwardProcedureLock(
  awardProcedureId: string
): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(awardProcedureId))
      .addLockQuery("awardProcedure")
      .toString()
  );
  return deserializer.deserialize(result.data);
}
