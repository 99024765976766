export default {
  AwardUnits: Symbol(),
  Participants: Symbol(),
  CallForTenders: Symbol(),
  CallForCompetition: Symbol(),
  TableOfOffers: Symbol(),
  TenderDocuments: Symbol(),
  Notices: Symbol(),
  Conversations: Symbol(),
  Permissions: Symbol(),
};
