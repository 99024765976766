
import { defineComponent } from "vue";
import Store from "@/store";
import { NotificationDismisser } from "@/service/types";
import { DISMISS_STORE_NOTIFICATION } from "@/store/modules/notification.actions";
import { Notification, SlotNotification } from "@/model/Notification";
import { PropType } from "vue";

class LocalDismisser implements NotificationDismisser {
  constructor(private notficationId: string) {}
  async dismissNotification(): Promise<void> {
    return Store.dispatch(DISMISS_STORE_NOTIFICATION, this.notficationId);
  }
}

/**
 * Eine [evonum-notification](#!/evonum-notification), die eine Notification aus dem Store anzeigt.
 */
export default defineComponent({
  name: "base-notification-from-store",
  inheritAttrs: false,
  props: {
    /**
     * Man kann eine äußere Notification, oder eine NotificationSlotID reingeben
     */
    notification: {
      type: Object as PropType<Notification>,
    },
    /**
     * Soll die Notification beim Zerstören der Komponente automatisch gelöscht werden?
     */
    dismissOnDestroy: {
      type: Boolean,
      default: true,
    },
    /**
     * Id der Notification im Store.
     */
    notificationSlotId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dismisser: new LocalDismisser(this.notificationSlotId),
    };
  },
  computed: {
    innerNotification(): SlotNotification {
      return (
        this.notification ||
        this.$store.state.notifications.notifications[this.notificationSlotId]
      );
    },
  },
  beforeUnmount() {
    if (this.dismissOnDestroy) {
      this.$nextTick(() => this.dismisser.dismissNotification());
    }
  },
});
