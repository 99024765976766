import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (conversationId: string) => `conversations/${conversationId}`;

export default async function getConversationLock(
  conversationId: string,
  { additionalFields = [] }: { additionalFields?: string[] } = {}
): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(conversationId))
      .addLockQuery("conversation", { additionalFields })
      .toString()
  );
  return deserializer.deserialize(result.data);
}
