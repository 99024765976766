import { isFunction, isObject } from "@/utils/typeCheckers";

export default function deepSet(
  obj: any,
  path: any,
  value: any,
  options: { create?: any; isArray?: boolean } = {}
) {
  const properties = path.split(".");
  let currentObject = obj;
  let property;

  const { create = true, isArray = false } = options;

  while (properties.length) {
    property = properties.shift();

    if (!currentObject) break;

    if (!isObject(currentObject[property]) && create && properties.length > 0) {
      currentObject[property] = {};
    }

    if (!properties.length) {
      if (isFunction(value)) {
        currentObject[property] = value(currentObject[property]);
      } else {
        if (isArray) {
          const myArray = currentObject[property];
          if (myArray === undefined) {
            currentObject[property] = [value];
          } else {
            currentObject[property].push(value);
          }
        } else {
          currentObject[property] = value;
        }
      }
    }
    currentObject = currentObject[property];
  }
}
