import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = {
  class: "d-flex mr-2",
  style: {"justify-content":"center","width":"var(--evonum-button-height)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_evonum_card_field = _resolveComponent("evonum-card-field")!
  const _component_evonum_button_text = _resolveComponent("evonum-button-text")!
  const _component_evonum_button_icon = _resolveComponent("evonum-button-icon")!
  const _component_evonum_menu_popover_item = _resolveComponent("evonum-menu-popover-item")!
  const _component_evonum_menu_popover_divider = _resolveComponent("evonum-menu-popover-divider")!
  const _component_common_menu_popover = _resolveComponent("common-menu-popover")!

  return (_ctx.$store.state.users.authority)
    ? (_openBlock(), _createBlock(_component_common_menu_popover, {
        key: 0,
        "data-cy": "authority-menu",
        class: "the-header-authority-menu",
        style: _normalizeStyle([{
      '--evonum-menu-offset-right': _ctx.isMobile ? '0px' : undefined,
    }, {"--evonum-menu-offset-top":"var(--evo-header-menu-top)","--evonum-button-height":"44px","--evonum-menu-item-padding":"0 8px"}]),
        absolute: "",
        appendedLabel: _ctx.$t('users.menu.authority')
      }, {
        activator: _withCtx(({ props, buttonId, on, label }) => [
          _createElementVNode("div", {
            style: _normalizeStyle([{"display":"flex","justify-content":"right","align-items":"center"}, {
          'min-width': _ctx.isExtraSmall ? undefined : '215px',
          'min-height': _ctx.isLowHeight ? undefined : '48px',
        }])
          }, [
            (!_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_evonum_button_text, _mergeProps({
                  key: 0,
                  inline: "",
                  style: {"--v-theme-overlay-multiplier":"1.5","max-width":"320px","color":"var(--b_grey-50) !important"},
                  class: { 'px-0': _ctx.isMobile, 'px-1 mr-4': !_ctx.isMobile }
                }, _toHandlers(on), props, {
                  "data-cy": "authority-menu-activator",
                  id: buttonId,
                  "aria-label": label
                }), {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-domain",
                        size: "36",
                        class: "text-grey-100 mr-4"
                      }),
                      _createVNode(_component_evonum_card_field, {
                        heading: _ctx.$t('users.menu.authority'),
                        text: _ctx.name,
                        ellipsing: "",
                        style: {"max-width":"245px","text-align":"left"},
                        variant: "WHITE_HEADER"
                      }, null, 8, ["heading", "text"])
                    ])
                  ]),
                  _: 2
                }, 1040, ["class", "id", "aria-label"]))
              : (_openBlock(), _createBlock(_component_evonum_button_icon, _mergeProps({
                  key: 1,
                  style: [{ '--evonum-button-height': _ctx.iconButtonHeight }, {"--v-theme-overlay-multiplier":"1.5"}]
                }, _toHandlers(on), props, {
                  "data-cy": "authority-menu-activator",
                  id: buttonId,
                  "aria-label": label,
                  "large-overlay": !_ctx.isLowHeight
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      icon: "mdi-domain",
                      size: "36",
                      class: "text-grey-100"
                    })
                  ]),
                  _: 2
                }, 1040, ["style", "id", "aria-label", "large-overlay"]))
          ], 4)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_evonum_menu_popover_item, {
            class: "mb-2 mt-sm-2",
            style: {"width":"max-content","max-width":"320px"},
            "data-cy": "edit-user-action"
          }, {
            prepend: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_icon, {
                  icon: "mdi-domain",
                  size: "36",
                  class: "the-header__icon"
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_evonum_card_field, {
                heading: _ctx.name,
                text: _ctx.city,
                variant: "EMPHASIZE_HEADING"
              }, null, 8, ["heading", "text"])
            ]),
            _: 1
          }),
          (!_ctx.isExtraSmall)
            ? (_openBlock(), _createBlock(_component_evonum_menu_popover_divider, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_evonum_menu_popover_item, {
            "close-on-click": "",
            density: "compact",
            onClick: _ctx.editAuthority,
            style: {"min-width":"max-content"},
            "data-cy": "edit-authority-action"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("users.menu.edit-authority")), 1)
            ]),
            prepend: _withCtx(() => [
              _createVNode(_component_v_icon, {
                style: {"width":"var(--evonum-button-height)"},
                icon: "mdi-clipboard-text-outline",
                class: "the-header__icon mr-2"
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_evonum_menu_popover_item, {
            "close-on-click": "",
            density: "compact",
            onClick: _ctx.showInvitations,
            style: {"min-width":"max-content"},
            "data-cy": "invitation-action"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("users.menu.invitations")), 1)
            ]),
            prepend: _withCtx(() => [
              _createVNode(_component_v_icon, {
                style: {"width":"var(--evonum-button-height)"},
                icon: "mdi-account-multiple-plus",
                class: "mr-2 the-header__icon"
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_evonum_menu_popover_item, {
            "close-on-click": "",
            density: "compact",
            onClick: _ctx.showSelection,
            disabled: _ctx.userAuthorities.length <= 1,
            style: {"min-width":"max-content"},
            "data-cy": "change-authority"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("users.menu.change-authority")), 1)
            ]),
            prepend: _withCtx(() => [
              _createVNode(_component_v_icon, {
                style: {"width":"var(--evonum-button-height)"},
                icon: "mdi-swap-horizontal",
                class: "mr-2 the-header__icon"
              })
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]),
        _: 1
      }, 8, ["style", "appendedLabel"]))
    : _createCommentVNode("", true)
}