import { AwardingClient } from "@/utils/EvoClient";
import { DeleteAwardProcedureDto } from "../dto";

const ENDPOINT = "c/delete-award-procedure";

export default async function deleteAwardProcedure(
  dapDTO: DeleteAwardProcedureDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dapDTO);
}
