import { AwardingClient } from "./client";
import EditCallForCompetition from "./command/EditCallForCompetition";
import PublishCallForCompetition from "./command/PublishCallForCompetition";
import ChangeRequestForParticipationPeriod from "./command/ChangeRequestForParticipationPeriod";
import CompleteCallForCompetition from "./command/CompleteCallForCompetition";
import MarkRequestForParticipationAdmissionStatus from "./command/MarkRequestForParticipationAdmissionStatus";
import RemoveInvitee from "./command/RemoveInvitee";
import CreateParticipant from "./command/CreateParticipant";
import EditParticipant from "./command/EditParticipant";
import InviteAllAdmittedParticipants from "./command/InviteAllAdmittedParticipants";
import CreateNotice from "./command/CreateNotice";
import GetNotice from "./query/GetNotice";
import GetAllNotices from "./query/GetAllNotices";
import EditNotice from "./command/EditNotice";
import DeleteNotice from "./command/DeleteNotice";
import PublishNotice from "./command/PublishNotice";
import GetNoticeLock from "./query/GetNoticeLock";
import DismissNoticeNotification from "./command/DismissNoticeNotification";
import EditInternalDocumentation from "./command/EditInternalDocumentation";
import SaveMessage from "./command/SaveMessage";
import SendMessage from "./command/SendMessage";
import DeleteMessage from "./command/DeleteMessage";
import DismissConversationNotification from "./command/DismissConversationNotification";
import MarkMessagesAsRead from "./command/MarkMessagesAsRead";
import AddMessageAttachment from "./command/AddMessageAttachment";
import RemoveMessageAttachment from "./command/RemoveMessageAttachment";
import ChangeBasicData from "./command/ChangeBasicData";
import PublishAwardProcedure from "./command/PublishAwardProcedure";
import ChangeCallForTenders from "./command/ChangeCallForTenders";
import CreateAwardUnit from "./command/CreateAwardUnit";
import EditAwardUnit from "./command/EditAwardUnit";
import ChangeAwardUnit from "./command/ChangeAwardUnit";
import DismissAwardUnitNotification from "./command/DismissAwardUnitNotification";
import MarkParticipantQualificationStatus from "./command/MarkParticipantQualificationStatus";
import GrantUserRole from "./command/GrantUserRole";
import RemoveUserFromContext from "./command/RemoveUserFromContext";
import JoinOrganisation from "./command/JoinOrganisation";
import OpenOffers from "./command/OpenOffers";
import OpenRequestsForParticipation from "./command/OpenRequestsForParticipation";
import InviteToCallForTenders from "./command/InviteToCallForTenders";
import GetAllAwardProcedures from "./query/GetAllAwardProcedures";
import GetAwardProcedureStatus from "./query/GetAwardProcedureStatus";
import CreateAwardProcedure from "./command/CreateAwardProcedure";
import EditAwardProcedure from "./command/EditAwardProcedure";
import DismissAwardProcedureNotification from "./command/DismissAwardProcedureNotification";
import CreateCallForTenders from "./command/CreateCallForTenders";
import CompleteCallForTenders from "./command/CompleteCallForTenders";
import GetSpecifications from "./query/GetSpecifications";
import GetAwardProcedure from "./query/GetAwardProcedure";
import GetAwardProcedureLock from "./query/GetAwardProcedureLock";
import GetAllCallsForTenders from "./query/GetAllCallsForTenders";
import GetAllOffers from "./query/GetAllOffers";
import GetCallForTenders from "./query/GetCallForTenders";
import GetCallForTendersLock from "./query/GetCallForTendersLock";
import PublishCallForTenders from "./command/PublishCallForTenders";
import DismissCallForTendersNotification from "./command/DismissCallForTendersNotification";
import MarkOfferAdmissionStatus from "./command/MarkOfferAdmissionStatus";
import MarkOfferAwardStatus from "./command/MarkOfferAwardStatus";
import GetOffer from "./query/GetOffer";
import GetTableOfOffers from "./query/GetTableOfOffers";
import GetAllParticipants from "./query/GetAllParticipants";
import GetParticipant from "./query/GetParticipant";
import MarkParticipantStatus from "./command/MarkParticipantStatus";
import AddInvitee from "./command/AddInvitee";
import EditCallForTenders from "./command/EditCallForTenders";
import DeleteCallForTenders from "./command/DeleteCallForTenders";
import ChangeSubmissionPeriod from "./command/ChangeSubmissionPeriod";
import DeleteAwardProcedure from "./command/DeleteAwardProcedure";
import CompleteAwardProcedure from "./command/CompleteAwardProcedure";
import CancelAwardProcedure from "./command/CancelAwardProcedure";
import MarkAwardProcedureCancelStatus from "./command/MarkAwardProcedureCancelStatus";
import GetAllDocumentsForShipment from "./query/GetAllDocumentsForShipment";
import AddTenderDocument from "./command/AddTenderDocument";
import CreateShipment from "./command/CreateShipment";
import PublishShipment from "./command/PublishShipment";
import GetAllShipments from "./query/GetAllShipments";
import GetShipment from "./query/GetShipment";
import GetShipmentLock from "./query/GetShipmentLock";
import DismissShipmentNotification from "./command/DismissShipmentNotification";
import EditShipment from "./command/EditShipment";
import DeleteShipment from "./command/DeleteShipment";
import DeleteTenderDocument from "./command/DeleteTenderDocument";
import RenameTenderDocument from "./command/RenameTenderDocument";
import MoveTenderDocument from "./command/MoveTenderDocument";
import GetAllSections from "./query/GetAllSections";
import EnableSections from "./command/EnableSections";
import DisableSections from "./command/DisableSections";
import RenameSection from "./command/RenameSection";
import CreateSection from "./command/CreateSection";
import DeleteSection from "./command/DeleteSection";
import MoveSection from "./command/MoveSection";
import DeleteParticipant from "./command/DeleteParticipant";
import CreateCallForCompetition from "./command/CreateCallForCompetition";
import GetAllCallsForCompetition from "./query/GetAllCallsForCompetition";
import DeleteCallForCompetition from "./command/DeleteCallForCompetition";
import GetCallForCompetitionLock from "./query/GetCallForCompetitionLock";
import DismissCallForCompetitionNotification from "./command/DismissCallForCompetitionNotification";
import GetAllRequestsForParticipation from "./query/GetAllRequestsForParticipation";
import GetRequestForParticipation from "./query/GetRequestForParticipation";
import GetAllConversations from "./query/GetAllConversations";
import GetConversation from "./query/GetConversation";
import GetConversationLock from "./query/GetConversationLock";
import GetConversationDraft from "./query/GetConversationDraft";
import GetConversationMessages from "./query/GetConversationMessages";
import GetAllAwardUnits from "./query/GetAllAwardUnits";
import GetAwardUnit from "./query/GetAwardUnit";
import DeleteAwardUnit from "./command/DeleteAwardUnit";
import GetAwardUnitLock from "./query/GetAwardUnitLock";
import ValidateNotice from "./command/ValidateNotice";

import RegisterWithAuthority from "./command/RegisterWithAuthority";
import RegisterWithInvitation from "./command/RegisterWithInvitation";
import EditUser from "./command/EditUser";
import GetUser from "./query/GetUser";
import EditAuthority from "./command/EditAuthority";
import GetAuthority from "./query/GetAuthority";
import InviteUser from "./command/InviteUser";
import GetStatic from "./query/GetStatic";
import GetRolesOfContextType from "./query/GetRolesOfContextType";
import GetAllUsers from "./query/GetAllUsers";
import GetContext from "./query/GetContext";

export { AwardingClient };

const client = {
  //group:users
  JoinOrganisation,
  RemoveUserFromContext,
  GrantUserRole,
  RegisterWithAuthority,
  RegisterWithInvitation,
  InviteUser,
  GetUser,
  EditUser,
  GetAuthority,
  EditAuthority,
  GetRolesOfContextType,
  GetAllUsers,
  GetContext,

  //group:static
  GetStatic,

  //group:award-procedure
  CreateAwardProcedure,
  ChangeBasicData,
  PublishAwardProcedure,
  EditAwardProcedure,
  EditInternalDocumentation,
  DismissAwardProcedureNotification,
  GetAllAwardProcedures,
  GetSpecifications,
  GetAwardProcedure,
  GetAwardProcedureLock,
  DeleteAwardProcedure,
  CompleteAwardProcedure,
  CancelAwardProcedure,
  MarkAwardProcedureCancelStatus,
  GetAwardProcedureStatus,

  //group:call-for-competition
  OpenRequestsForParticipation,
  CreateCallForCompetition,
  GetAllCallsForCompetition,
  DeleteCallForCompetition,
  EditCallForCompetition,
  GetCallForCompetitionLock,
  PublishCallForCompetition,
  DismissCallForCompetitionNotification,
  ChangeRequestForParticipationPeriod,
  CompleteCallForCompetition,
  MarkRequestForParticipationAdmissionStatus,

  //group:call-for-tenders
  InviteToCallForTenders,
  OpenOffers,
  ChangeCallForTenders,
  CreateCallForTenders,
  GetAllCallsForTenders,
  GetCallForTenders,
  GetCallForTendersLock,
  PublishCallForTenders,
  DismissCallForTendersNotification,
  CompleteCallForTenders,
  EditCallForTenders,
  DeleteCallForTenders,
  ChangeSubmissionPeriod,
  AddInvitee,
  InviteAllAdmittedParticipants,
  RemoveInvitee,

  //group:notice
  CreateNotice,
  GetNotice,
  GetAllNotices,
  DeleteNotice,
  PublishNotice,
  GetNoticeLock,
  DismissNoticeNotification,
  EditNotice,
  ValidateNotice,

  //group:offer
  GetAllOffers,
  GetOffer,
  MarkOfferAdmissionStatus,
  MarkOfferAwardStatus,

  //group:participant
  MarkParticipantQualificationStatus,
  CreateParticipant,
  GetAllParticipants,
  GetParticipant,
  MarkParticipantStatus,
  EditParticipant,
  DeleteParticipant,

  //group:request-for-participation
  GetAllRequestsForParticipation,
  GetRequestForParticipation,

  //group:section
  GetAllSections,
  RenameSection,
  CreateSection,
  DeleteSection,
  MoveSection,

  //group:shipment
  GetAllShipments,
  CreateShipment,
  PublishShipment,
  GetShipment,
  GetShipmentLock,
  GetAllDocumentsForShipment,
  DismissShipmentNotification,
  EditShipment,
  DeleteShipment,
  EnableSections,
  DisableSections,

  //group:table-of-offers
  GetTableOfOffers,

  //group:tender-document
  AddTenderDocument,
  DeleteTenderDocument,
  RenameTenderDocument,
  MoveTenderDocument,

  //group:conversation
  AddMessageAttachment,
  RemoveMessageAttachment,
  GetAllConversations,
  GetConversation,
  GetConversationLock,
  SaveMessage,
  SendMessage,
  DeleteMessage,
  DismissConversationNotification,
  MarkMessagesAsRead,
  GetConversationDraft,
  GetConversationMessages,

  //group:award-unit
  ChangeAwardUnit,
  DismissAwardUnitNotification,
  EditAwardUnit,
  CreateAwardUnit,
  DeleteAwardUnit,
  GetAllAwardUnits,
  GetAwardUnit,
  GetAwardUnitLock,
};

if (window.Cypress != null) {
  //@ts-ignore
  window.evoClient = client;
}

export default client;
