
import { defineComponent, PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";
//@ts-ignore
import { useLowHeight } from "@evo/evonum";

export default defineComponent({
  name: "common-content-wrapper",
  setup() {
    const { isLowHeight } = useLowHeight();
    return {
      isLowHeight,
    };
  },
  props: {
    backTo: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
  computed: {
    navigatedThroughApp(): boolean {
      return !this.$store.state.isFirstLoad;
    },
  },
});
