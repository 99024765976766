//@ts-nocheck

import { Deserializer } from "jsonapi-serializer";
import Store from "@/store";
import deepGet from "lodash.get";
import {
  handleDeepRelationships,
  setRelationshipIdForEntity,
} from "./relationships";
import { calculateFullNameOfRegulation } from "@/utils/functions";

const backend2store = {
  typeOfService: "specifications.typeOfServiceValues",
  regulation: "specifications.regulationValues",
  procedureType: "specifications.procedureTypeValues",
  specification: "specifications.list.items",
};

const handler = {
  get: function (staticConfig, request) {
    if (request in staticConfig) {
      return staticConfig[request];
    }
    if (backend2store[request]) {
      return {
        valueForRelationship: (rel) => {
          return deepGet(Store.state, backend2store[request]).find(
            (x) => x.id == rel.id
          );
        },
      };
    }
  },
};

const staticConfig = { keyForAttribute: (x) => x };

const configProxy = new Proxy(staticConfig, handler);
const deserializer = new Deserializer(configProxy);

type deserializerArgs = {
  keepMeta?: boolean;
  relations?: any[];
};

export default {
  async deserialize(
    response: any,
    { keepMeta = false, relations = [] }: deserializerArgs = {
      keepMeta: false,
      relations: [],
    }
  ): QueryResponse<any> {
    const result = await deserializer.deserialize(response);
    let includesByIdCache;
    if (Array.isArray(result)) {
      result.forEach((entity: any, index: number) => {
        relations.forEach((name) => {
          if (name.includes(".")) {
            includesByIdCache =
              includesByIdCache || generateIncludesByIdCache();
            handleDeepRelationships(entity, name, includesByIdCache);
          } else {
            setRelationshipIdForEntity(entity, response.data[index], name);
          }
        });
      });
    } else {
      relations.forEach((name) => {
        if (name.includes(".")) {
          includesByIdCache = includesByIdCache || generateIncludesByIdCache();
          handleDeepRelationships(result, name, includesByIdCache);
        } else {
          setRelationshipIdForEntity(result, response.data, name);
        }
      });
    }
    if (keepMeta) {
      result._meta = response.meta;
    }
    return result;

    function generateIncludesByIdCache() {
      if (response.included) {
        return response.included.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
      } else {
        return {};
      }
    }
  },
};
export const unresolvingDeserializer = new Deserializer(staticConfig);

export async function deserializeSpecification(
  data: string
): Promise<Specification[]> {
  const deserialized = (await unresolvingDeserializer.deserialize(
    data
  )) as Specification[];
  deserialized.forEach((item) => {
    item.regulation.vollerName = calculateFullNameOfRegulation(item.regulation);
  });
  return deserialized;
}
