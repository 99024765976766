import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { style: {"max-width":"75ch"} }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_button_text = _resolveComponent("evonum-button-text")!
  const _component_evonum_dialog_card = _resolveComponent("evonum-dialog-card")!

  return (_openBlock(), _createBlock(_component_evonum_dialog_card, {
    "data-cy": "base-dialog-card-confirmation",
    heading: _ctx.heading || _ctx.confirmationHeading,
    class: "confirmation-dialog"
  }, _createSlots({
    actions: _withCtx(() => [
      _createVNode(_component_evonum_button_text, {
        "data-cy": "base-dialog-card-confirmation-button-cancel",
        "data-input-focus-target": "close",
        onClick: _withModifiers(_ctx.cancelDialog, ["stop"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.labels.cancel")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.formId)
        ? (_openBlock(), _createBlock(_component_evonum_button_text, {
            key: 0,
            "data-cy": "base-dialog-card-confirmation-button-confirm",
            onClick: _ctx.confirmDialog,
            type: "submit",
            form: _ctx.formId,
            disabled: !_ctx.isValid
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmButtonLabel || _ctx.confirmationButtonText), 1)
            ]),
            _: 1
          }, 8, ["onClick", "form", "disabled"]))
        : (_openBlock(), _createBlock(_component_evonum_button_text, {
            key: 1,
            "data-cy": "base-dialog-card-confirmation-button-confirm",
            disabled: !_ctx.isValid,
            onClick: _ctx.confirmDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmButtonLabel || _ctx.confirmationButtonText), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", { lines: _ctx.confirmationLines }, () => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmationLines, (line, index) => {
            return (_openBlock(), _createElementBlock("p", {
              class: _normalizeClass(line.class),
              key: index
            }, [
              (line.component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(line.component), {
                    key: 0,
                    dialogProps: _ctx.i18nProps,
                    iconChooser: line.iconChooser,
                    class: "pr-1",
                    style: {"min-width":"24px !important"}
                  }, null, 8, ["dialogProps", "iconChooser"]))
                : _createCommentVNode("", true),
              (line.content)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(line.content, _ctx.i18nProps)), 1))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ]),
      _renderSlot(_ctx.$slots, "post-default")
    ]),
    _: 2
  }, [
    (_ctx.confirmationComponents && _ctx.confirmationComponents.aside)
      ? {
          name: "aside",
          fn: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.confirmationComponents.aside.component), _normalizeProps(_guardReactiveProps(_ctx.confirmationComponents.aside.args)), null, 16))
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.confirmationComponents && _ctx.confirmationComponents.preDefault)
      ? {
          name: "pre-default",
          fn: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.confirmationComponents.preDefault.component), _normalizeProps(_guardReactiveProps(_ctx.confirmationComponents.preDefault.args)), null, 16))
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["heading"]))
}