import { RouteRecordRaw } from "vue-router";
import LoadingService from "@/service/LoadingService";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "@/store/modules/awardProcedure.actions";
import {
  UPDATE_AWARD_UNITS,
  UPDATE_CURRENT_AWARD_UNIT,
} from "@/store/modules/awardUnit.actions";
import {
  ROUTE_AWARD_UNIT_DETAILS,
  ROUTE_AWARD_UNIT_LIST,
} from "@/router/routes";
import Breadcrumbs from "@/router/breadcrumbs";
import i18n from "@/i18n";

const AwardUnits = () =>
  import(
    /* webpackChunkName: "award-unit-chunk" */ "@/views/award-units/award-unit-frame.vue"
  );
const AwardUnitsList = () =>
  import(
    /* webpackChunkName: "award-unit-chunk" */ "@/views/award-units/award-unit-list.vue"
  );

const AwardUnitsDetails = () =>
  import(
    /* webpackChunkName: "award-unit-chunk" */ "@/views/award-units/award-unit-details.vue"
  );

export default {
  path: "award-units",
  component: AwardUnits,
  async beforeEnter(to, from, next) {
    await LoadingService.syncLoadAction(UPDATE_AWARD_UNITS);
    await LoadingService.syncLoadAction(
      GET_AWARD_PROCEDURE_AS_CURRENT,
      to.params.awardProcedureId
    );
    next();
  },
  children: [
    {
      path: "",
      name: ROUTE_AWARD_UNIT_LIST,
      component: AwardUnitsList,
      meta: {
        breadCrumbs: Breadcrumbs.AwardUnits,
        title: i18n.global.t("route-titles.award-units"),
        loadRoute(dispatcher, { awardProcedureId }) {
          //Da wir das Listen neu Laden auch außerhalb des Routers ausführen müssen
          //wenn wir auf der Liste Elemente löschen
          //Lagern wir das Laden allgemein aus
          return [
            dispatcher.dispatch(
              GET_AWARD_PROCEDURE_AS_CURRENT,
              awardProcedureId
            ),
            dispatcher.dispatch(UPDATE_AWARD_UNITS),
          ];
        },
      },
      beforeEnter(to, from, next) {
        to.meta!.loadRoute!(LoadingService, to.params);
        next();
      },
    },
    {
      path: ":awardUnitId",
      name: ROUTE_AWARD_UNIT_DETAILS,
      component: AwardUnitsDetails,
      beforeEnter(to, from, next) {
        if (to.params.awardUnitId) {
          LoadingService.addLoadAction(UPDATE_CURRENT_AWARD_UNIT, {
            awardUnitId: to.params.awardUnitId,
          });
        }
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.AwardUnits,
        title: i18n.global.t("route-titles.award-units"),
      },
    },
  ],
} as RouteRecordRaw;
