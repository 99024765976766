import { Module } from "vuex";
import { RootState } from "@/store/types";
import { SectionStoreState } from "@/store/modules/section.types";
import { PositionValues } from "@/model/Document";
import Client from "@/utils/EvoClient";
import {
  CREATE_SECTION,
  DELETE_SECTION,
  MOVE_SECTION,
  REMOVE_SECTION_FROM_POSITION,
  RENAME_SECTION,
  RESET_SECTION_STORE,
  UPDATE_SECTIONS,
} from "@/store/modules/section.actions";
import {
  REMOVE_FROM_POSITION,
  SAVE_INTO_POSITION,
  SET_ALL_SECTIONS,
} from "@/store/modules/section.mutations";
import { Section } from "@/model/Section";
import { MoveSectionToPosition, RenameSectionDto } from "@/utils/EvoClient/dto";
import { UPDATE_TENDER_DOCUMENTS } from "./tenderDocument.actions";

export default {
  state: {
    all: [],
  } as SectionStoreState,
  actions: {
    [RESET_SECTION_STORE]({ commit }) {
      commit(SET_ALL_SECTIONS, []);
    },
    async [UPDATE_SECTIONS]({ commit }, shipmentId: string) {
      const sections = await Client.GetAllSections({
        endpointArgs: shipmentId,
      });
      commit(SET_ALL_SECTIONS, sections || []);
    },
    async [CREATE_SECTION]({ dispatch }, dto: any) {
      await Client.CreateSection(dto);
      await dispatch(UPDATE_TENDER_DOCUMENTS, {
        updateSections: true,
        updateShipment: true,
        shipmentId: dto.shipmentId,
      });
    },
    async [DELETE_SECTION]({ rootState, dispatch }, { shipmentId, sectionId }) {
      await Client.DeleteSection({ shipmentId, sectionId });
      dispatch(UPDATE_TENDER_DOCUMENTS, {
        updateShipment: true,
        updateSections: true,
        shipmentId: rootState.shipments.current!.id,
      });
    },
    async [REMOVE_SECTION_FROM_POSITION]({ commit }, loadId: string) {
      commit(REMOVE_FROM_POSITION, loadId);
    },
    async [RENAME_SECTION]({ dispatch, rootState }, dto: RenameSectionDto) {
      await Client.RenameSection(dto);
      await dispatch(UPDATE_SECTIONS, rootState.shipments.current!.id);
    },
    async [MOVE_SECTION]({ dispatch, rootState }, dto: MoveSectionToPosition) {
      try {
        await Client.MoveSection(dto);
      } finally {
        await dispatch(UPDATE_SECTIONS, rootState.shipments.current!.id);
      }
    },
  },
  mutations: {
    [SET_ALL_SECTIONS](state, sections: Section[]) {
      state.all = sections;
    },
    [REMOVE_FROM_POSITION](state, id) {
      const index = state.all.findIndex((x) => x.id == id);
      state.all.splice(index, 1);
    },
    [SAVE_INTO_POSITION](state, { id, dto }) {
      const section = {
        id,
        title: dto.title,
        actions: {},
      } as Section;
      if (dto.position == PositionValues.First) {
        state.all.unshift(section);
      } else if (dto.position == PositionValues.Last) {
        state.all.push(section);
      } else if (dto.position == PositionValues.Before) {
        const index = state.all.findIndex((x) => x.id == dto.sibling);
        state.all.splice(index, 0, section);
      } else if (dto.position == PositionValues.After) {
        const index = state.all.findIndex((x) => x.id == dto.sibling);
        state.all.splice(index + 1, 0, section);
      }
    },
  },
} as Module<SectionStoreState, RootState>;
