import { AwardingClient } from "@/utils/EvoClient";
import { DeleteCallForCompetitionDto } from "../dto";

const ENDPOINT = "c/delete-call-for-competition";

export default async function deleteCallForCompetition(
  dcfcDTO: DeleteCallForCompetitionDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dcfcDTO);
}
