import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import UrlBuilder from "../urlBuilder";
import { Shipment } from "@/model/Shipment";

// prettier-ignore
const ENDPOINT = (shipmentId: string) => `shipments/${shipmentId}`;

export default async function getShipment(
  shipmentId: string
): Promise<Shipment> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(shipmentId)).toString()
  );
  return deserializer.deserialize(result.data);
}
