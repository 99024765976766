import { CallForTenders } from "@/model/CallForTenders";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "calls-for-tenders";
export type PossibleCallsForTendersFilters = ["awardProcedure"];
export type PossibleCallsForTendersIncludes = ["awardProcedure", "awardUnits"];

export default ListQueryFunctionGenerator<
  CallForTenders[],
  PossibleCallsForTendersFilters,
  PossibleCallsForTendersIncludes
>(ENDPOINT, {
  addIdsOfRelationshipsToResult: ["invitees"],
});
