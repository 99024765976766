import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (shipmentId: string) => `shipments/${shipmentId}`;

export default async function getShipmentLock(
  shipmentId: string
): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(shipmentId))
      .addLockQuery("shipment")
      .toString()
  );
  return deserializer.deserialize(result.data);
}
