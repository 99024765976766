import { App } from "vue";
import vuetify from "./plugins/vuetify";
import evonum from "@evo/evonum";
//@ts-ignore
import progressbar from "./plugins/progressbar";
import registerBase from "./components/base";
import registerDirectives from "@/directives";
import registerCustomIcons from "./custom-icon-imports";
//@ts-ignore
import replaceAll from "string.prototype.replaceall";
import i18n from "./i18n";
//@ts-ignore
//import debugmixin from "@/mixins/debug";

replaceAll.shim();
export default function (app: App) {
  registerDirectives(app);
  registerBase(app);
  registerCustomIcons(app);
  app.use(vuetify);
  app.use(i18n as any);
  app.use(evonum, { i18n });
  //app.mixin(debugmixin(true));
  app.use(progressbar, {
    color: "var(--evo-interactive)",
    failedColor: "#C92655", // --error-400: #C92655;
    thickness: "4px",
    position: "absolute",
    autoFinish: false,
    transition: { speed: "0.4s", opacity: "0.6s", termination: 300 },
  });
}
