
import { defineComponent, h, VNode, Comment, cloneVNode } from "vue";
// eslint-disable-next-line local-rules/no-base-import
import CommonMenuPopover from "@/components/common-menu-popover.vue";
import Store from "@/store";

const DO_NOT_EXPOSE_TAGS = ["evonum-menu-popover", "common-details-dialog"];

function cloneNodeWithKey(vnode: any) {
  return cloneVNode(vnode, {
    key: vnode.props.action,
  });
}

function isVisible(vnode: any) {
  return Object.keys(vnode.props.actionable.actions).includes(
    vnode.props.action
  );
}

function doNotExpose(vnode: any) {
  return DO_NOT_EXPOSE_TAGS.some((x) => vnode!.type!.name.startsWith(x));
}

function isActionButton(vnode: any) {
  return !!vnode?.props?.actionable;
}

function isLoading(vnode: any) {
  return Store.state.loading.all[vnode.props!.action];
}

function hasPriority(priority: string = "null", vnode: any) {
  return priority === vnode?.props?.action;
}

/**
 * Diese Komponente erwartet [base-allowedaction-buttons](#baseallowedactionbutton)
 * als Kinder eines `action`-slots. Alle Buttons nach dem ersten werden dann
 * im Overflow gerendert.
 */
export default defineComponent({
  name: "base-allowedaction-overflow",
  props: {
    /**
     * Blended das Kontextmenü ein
     */
    hasContextMenu: {
      type: Boolean,
      default: false,
    },
    /**
     * Zeigt nur das Menü an ohne den ersten als Button anzuzeigen
     */
    menuOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Was wird an das Label des Overflow Buttons gehangen?
     */
    appendedLabel: {
      type: String,
    },
    /**
     * Ladezustand
     */
    loading: {
      type: Boolean,
    },
    /**
     * Button Id für Overflow Menü
     */
    buttonId: {
      type: String,
    },
    /**
     * Disable MEnü
     */
    disabled: {
      type: Boolean,
    },
    contentStayRendered: {
      type: Boolean,
      default: false,
    },
    /**
     * Farbe des Icon Buttons
     */
    buttonColor: {
      type: String,
    },
    /**
     * Action, die Angezeigt werden soll, wenn sie Sichtbar ist
     */
    priorityAction: {
      type: String,
    },
  },
  render(): any {
    if (this.menuOnly) {
      return h(
        "div",
        { class: ["d-flex"] },
        h(
          CommonMenuPopover as any,
          {
            loading: this.loading,
            buttonId: this.buttonId,
            buttonColor: this.buttonColor,
            appendedLabel: this.appendedLabel,
            disabled: this.disabled,
            hideOnAnyLoading: true,
            contentStayRendered: this.contentStayRendered,
            down: false,
            class: [
              "base-allowedaction-overflow",
              {
                "pl-4": !this.menuOnly,
              },
            ],
          },
          () => this.$slots!.actions!()
        )
      );
    } else {
      const slot: any = this.$slots!.actions!().flatMap((x) => {
        if (x.type !== Comment) {
          if (x.children) {
            return x.children;
          }
          return x;
        } else {
          return [];
        }
      });
      if (slot) {
        const result = [];
        const outsideElements = [];
        let firstElement: VNode | null = null,
          loadingElement: VNode | null = null;
        for (const ele of slot) {
          if (!ele.type.name) {
            continue;
          }
          if (isActionButton(ele)) {
            if (isVisible(ele)) {
              if (
                firstElement === null ||
                hasPriority(this.priorityAction, ele)
              ) {
                firstElement = cloneNodeWithKey(ele) as any;
              }
            }
            if (loadingElement === null && isLoading(ele)) {
              loadingElement = cloneNodeWithKey(ele) as any;
            }
          } else if (!doNotExpose(ele)) {
            if (firstElement === null) {
              firstElement = ele;
            }
          }
          if (!this.priorityAction && firstElement && loadingElement) {
            break;
          }
        }
        if (firstElement) {
          outsideElements.push(firstElement);
        }
        if (
          loadingElement &&
          firstElement &&
          firstElement.key != loadingElement.key
        ) {
          outsideElements.push(
            h("div", { style: "display: none" }, [loadingElement])
          );
        }
        result.push(
          h(
            "div",
            { class: ["base-allowedaction-overflow__premenu"] },
            outsideElements
          )
        );
        if (this.hasContextMenu) {
          result.push(
            h(
              CommonMenuPopover as any,
              {
                loading: this.loading,
                buttonColor: this.buttonColor,
                buttonId: this.buttonId,
                appendedLabel: this.appendedLabel,
                disabled: this.disabled,
                hideOnAnyLoading: true,
                down: false,
                class: [
                  "base-allowedaction-overflow",
                  {
                    "pl-4": !this.menuOnly,
                  },
                ],
              },
              () => slot
            )
          );
        }
        return h("div", { class: ["d-flex"] }, result);
      }
    }
    return null;
  },
});
