
import i18n from "@/i18n";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "base-select-multiple",
  props: {
    /**
     * Ist Pflichtangabe
     */
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array as PropType<any>,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: " ",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    selectAllLabel: {
      type: String,
      default: i18n.global.t("common.labels.selectAll"),
    },
  },
  setup() {
    const menuProps: any = {
      "content-class": "base-select-multiple__menu",
    };
    return {
      menuProps,
    };
  },
  computed: {
    icon() {
      if (this.allSelected) return "mdi-checkbox-marked";
      if (this.value.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allSelected() {
      return this.items.length == this.value.length;
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit("input", []);
        } else {
          this.$emit("input", this.items.map((x: any) => x.value).slice());
        }
      });
    },
  },
});
