import LoadingService from "@/service/LoadingService";
import { INITIALIZE_CLIENT, LEAVE_AWARD_PROCEDURE } from "@/store/actions";
import { GET_ALL_AWARD_PROCEDURES } from "@/store/modules/awardProcedure.actions";
import { RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import { ROUTE_DASHBOARD } from "@/router/routes";

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard-chunk" */ "@/views/dashboard.vue");

export default {
  path: "/",
  name: ROUTE_DASHBOARD,
  component: Dashboard,
  async beforeEnter(to, from, next) {
    await LoadingService.syncLoadAction(INITIALIZE_CLIENT);
    LoadingService.addCleanAction(LEAVE_AWARD_PROCEDURE);
    LoadingService.addLoadAction(GET_ALL_AWARD_PROCEDURES);
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.dashboard"),
  },
} as RouteRecordRaw;
