
import { defineComponent, PropType } from "vue";

import { AwardProcedureState } from "@/model/AwardProcedureState";
import { Lockable } from "@/model/Lockable";
import { CallForTendersState } from "@/model/CallForTendersState";
import { CallForCompetitionState } from "@/model/CallForCompetitionState";
import i18n from "@/i18n";
import { Stateable } from "@/model/Stateable";
import { ChipStyling, ChipSize } from "@evo/evonum";
import { ShipmentState } from "@/model/ShipmentState";
import { ParticipationState } from "@/model/ParticipationState";

/**
 * Anzeige des Status mit Hilfe von [evonum-chip](#!/evonum-chip).
 * Der Zustand kann entweder sein gesperrt oder nicht.
 */

export default defineComponent({
  name: "common-chip_state",
  props: {
    /**
     * Entität, welche das Feld state und ein Lockable sein muss.
     */
    entity: {
      type: Object as () => Lockable & Stateable<any>,
    },
    state: {
      type: String as PropType<
        | "DRAFT"
        | "ACTIVE"
        | "FINISHED"
        | "COMPLETED"
        | "ABORTED"
        | "PUBLISHED"
        | "INTENDED"
        | "INVITED"
        | "REGISTERED"
        | "WITHDRAWN"
        | "REQUESTS_FOR_PARTICIPATION_OPENED"
        | "OFFERS_OPENED"
      >,
    },
    locked: {
      type: Boolean,
    },
    size: {
      type: String as PropType<keyof typeof ChipSize>,
      default: "MEDIUM",
    },
  },
  computed: {
    _state(): string {
      return this.entity?.state ?? this.state;
    },
    _locked(): boolean {
      return this.entity?.locked ?? this.locked;
    },
    withdrawnStyling(): ChipStyling {
      return {
        color: "var(--warning-400)",
        textColor: "var(--b_grey-700)",
        size: this.size as ChipSize,
      };
    },
    draftStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    activeStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    finishedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    completedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    abortedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    publishedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    intendedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    invitedStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    registeredStyling(): ChipStyling {
      return {
        locked: this._locked,
        size: this.size as ChipSize,
      };
    },
    chipProps(): { text: string; styling: ChipStyling } | null {
      switch (this._state) {
        case AwardProcedureState.Draft:
        case CallForTendersState.Draft:
        case ShipmentState.Draft:
          return {
            text: i18n.global.t("common.states.draft") as string,
            styling: this.draftStyling,
          };
        case AwardProcedureState.Active:
        case CallForTendersState.Active:
          return {
            text: i18n.global.t("common.states.active") as string,
            styling: this.activeStyling,
          };
        case CallForTendersState.Opened:
        case CallForCompetitionState.Opened:
        case CallForTendersState.Finished:
          return {
            text: i18n.global.t("common.states.finished") as string,
            styling: this.finishedStyling,
          };
        case CallForTendersState.Completed:
          return {
            text: i18n.global.t("common.states.completed") as string,
            styling: this.completedStyling,
          };
        case ShipmentState.Published:
          return {
            text: i18n.global.t("common.states.published") as string,
            styling: this.publishedStyling,
          };
        case ParticipationState.Intended:
          return {
            text: i18n.global.t("common.states.intended") as string,
            styling: this.intendedStyling,
          };
        case ParticipationState.Invited:
          return {
            text: i18n.global.t("common.states.invited") as string,
            styling: this.invitedStyling,
          };
        case ParticipationState.Registered:
          return {
            text: i18n.global.t("common.states.registered") as string,
            styling: this.registeredStyling,
          };
        case "WITHDRAWN":
          return {
            text: i18n.global.t("common.states.withdrawn") as string,
            styling: this.withdrawnStyling,
          };
        case "ABORTED": //wird aktuell nicht verwendet
          return {
            text: i18n.global.t("common.states.aborted") as string,
            styling: this.abortedStyling,
          };
        default:
          return null;
      }
    },
  },
});
