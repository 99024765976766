import {
  UPDATE_CONVERSATIONS,
  UPDATE_CURRENT_CONVERSATION,
  SAVE_MESSAGE,
  SEND_MESSAGE,
  CHECK_LOCK,
  UPDATE_LOCK,
  DISMISS_CONVERSATION_NOTIFICATION,
  MARK_MESSAGES_AS_READ,
  ADD_MESSAGE_ATTACHMENT,
  REMOVE_MESSAGE_ATTACHMENT,
  REMOVE_LOCAL_ATTACHMENT,
  DELETE_MESSAGE,
  OPEN_CURRENT_CONVERSATION,
  name,
} from "@/store/modules/conversation.actions";
import {
  SET_ALL_CONVERSATIONS,
  SET_ATTACHMENTS,
  SET_CURRENT_CONVERSATION,
  SET_LOCAL_ATTACHMENTS,
  SET_LOCK,
} from "@/store/modules/conversation.mutations";
import { ConversationStoreState } from "@/store/modules/conversation.types";
import { Module } from "vuex";
import { Paginationable, RootState } from "@/store/types";
import Client from "@/utils/EvoClient";
import PaginationMixin from "@/store/mixins/pagination";
import GetFileMetadata, {
  FileMetaData,
} from "@/utils/EvoClient/unique/GetFileMetadata";
import {
  AddMessageAttachmentDto,
  RemoveMessageAttachmentDto,
  MarkMessagesAsReadDto,
  SaveMessageDto,
  DeleteMessageDto,
} from "@/utils/EvoClient/dto";
import { conversationSorter } from "@/model/Conversation";
import { Lock } from "@/model/Lock";
import uploadToConversation from "@/utils/EvoClient/uploads/UploadToConversation";
import {
  RESET_LOADING_FOR_ACTION,
  SET_LOADING_META_FOR_ACTION,
  START_LOADING_FOR_ACTION,
} from "./loading.actions";
import { ServerValidationError } from "@/utils/errors";
import { SET_ERRORS_FOR_COMPONENT } from "./notification.actions";
import { alphanumericalSort } from "@/utils/sorts";
import { Attachment } from "@/model/Attachment";
import router from "@/router";
import { ROUTE_CONVERSATION_DETAILS } from "@/router/routes";
import FilterMixin from "@/store/mixins/filter";

//Die Id muss herleitbar sein aus dem Item und einzigartig sein
function getLocalId(
  currentAttachments: Record<string, boolean>,
  attachment: FileMetaData
): string {
  if (currentAttachments[attachment.filename]) {
    let result = attachment.filename;
    do {
      result = result + "​";
    } while (currentAttachments[result]);
    return result;
  } else {
    return attachment.filename;
  }
}

const store = {
  state: {
    all: [],
    current: null,
    local: {
      attachments: [],
    },
  } as ConversationStoreState,
  getters: {
    currentMessageAttachments(state) {
      let attachments = [] as Attachment[];
      if (state.local.attachments.length > 0) {
        //Merge Logik, lokale sollen überschrieben werden
        //hierdurch werden die Upload Items zu echten
        //allerdings bleiben jene, die Fehler haben, erhalten
        //damit sie wahrgenommen werden können (ihre filenames sind leicht anders wegen getLocalId)
        const result = {} as Record<string, any>;
        state.local.attachments
          .concat(state.current?.draft?.attachments || [])
          .forEach((attachment) => {
            result[attachment.filename] = attachment;
          });
        attachments = Object.values(result);
      } else {
        attachments = state.current?.draft?.attachments || [];
      }
      if (state.local.attachments.length > 0) {
        return attachments
          .slice()
          .sort((a, b) => alphanumericalSort(a.filename, b.filename));
      } else {
        //Backend Sortiert schon, wenn wir nicht reinsplicen passt das
        return attachments;
      }
    },
    hasUnfinishedAttachmentDownloads(state, getters) {
      return getters.currentMessageAttachments.some((x: any) => x.uploading);
    },
  },

  actions: {
    async [REMOVE_MESSAGE_ATTACHMENT](
      { dispatch },
      dto: RemoveMessageAttachmentDto
    ) {
      await Client.RemoveMessageAttachment(dto);
      await dispatch(UPDATE_CURRENT_CONVERSATION, {
        conversationId: dto.conversationId,
        refreshLocal: true,
      });
    },
    async [ADD_MESSAGE_ATTACHMENT](
      { dispatch, commit, getters, state },
      { _files, conversationId }: { _files: File[]; conversationId: string }
    ) {
      const fileMetaData: (FileMetaData & { localId: string })[] = [];
      const dtos: AddMessageAttachmentDto[] = [];
      let existentAttachments = {} as Record<string, boolean>;
      if (state.current?.draft?.attachments) {
        existentAttachments = getters.currentMessageAttachments.reduce(
          (prev: Record<string, boolean>, curr: Attachment) => {
            prev[curr.filename] = true;
            return prev;
          },
          {} as Record<string, boolean>
        );
      }
      for (const file of _files) {
        const metaData = GetFileMetadata(file, { noTitle: true });
        const localId = getLocalId(existentAttachments, metaData);
        fileMetaData.push({
          ...metaData,
          localId,
        });
        existentAttachments[localId] = true;
        dtos.push({
          filename: metaData.filename,
          conversationId,
          mediaType: metaData.mediatype,
        });
        dispatch(START_LOADING_FOR_ACTION, metaData.filename);
      }

      commit(
        SET_LOCAL_ATTACHMENTS,
        fileMetaData.map((file) => ({
          id: file.localId,
          uploading: true,
          ...file,
          filename: file.localId,
        }))
      );

      for (let i = 0; i < dtos.length; i++) {
        const dto = dtos[i];
        const attachmentId = fileMetaData[i].localId;
        try {
          dto.uploadReceipt = await uploadToConversation(
            _files[i],
            dto.conversationId,
            ({ percent, loadedBytes, totalBytes }) => {
              dispatch(SET_LOADING_META_FOR_ACTION, {
                id: attachmentId,
                meta: { percent, loadedBytes, totalBytes },
              });
            }
          );

          await Client.AddMessageAttachment(dto);
          if (i < dtos.length - 1) {
            //Damit es nicht ein kurzes Flackern von Grün auf echtes Element gibt
            dispatch(SET_LOADING_META_FOR_ACTION, {
              id: attachmentId,
              meta: { success: true },
            });
          }
        } catch (err) {
          if (err instanceof ServerValidationError) {
            dispatch(SET_ERRORS_FOR_COMPONENT, {
              id: attachmentId,
              errors: err.getPrintableErrors(),
              action: "addMessageAttachment",
            });
          } else {
            throw err;
          }
        }
      }
      await dispatch(UPDATE_CURRENT_CONVERSATION, {
        conversationId,
        refreshLocal: false,
      });
      dispatch(RESET_LOADING_FOR_ACTION);
    },
    async [MARK_MESSAGES_AS_READ]({ dispatch }, args: MarkMessagesAsReadDto) {
      await Client.MarkMessagesAsRead(args);
      await dispatch(UPDATE_CURRENT_CONVERSATION, {
        conversationId: args.conversationId,
      });
    },
    async [SEND_MESSAGE]({ dispatch, state }, args: SaveMessageDto) {
      if (args.text != null && args.subject != null) {
        await dispatch(SAVE_MESSAGE, args);
      }
      await Client.SendMessage(args);
      await dispatch(UPDATE_LOCK, { ...state.current, locked: true });
    },
    async [SAVE_MESSAGE]({ dispatch, state }, args: SaveMessageDto) {
      await Client.SaveMessage(args);
      await dispatch(UPDATE_CURRENT_CONVERSATION, {
        conversationId: state.current!.id,
      });
    },
    async [DELETE_MESSAGE]({ dispatch, state }, args: DeleteMessageDto) {
      await Client.DeleteMessage(args);
      await dispatch(UPDATE_CURRENT_CONVERSATION, {
        conversationId: state.current!.id,
      });
    },
    async [UPDATE_CONVERSATIONS]({ getters, commit }) {
      const conversations = await Client.GetAllConversations({
        pagination: getters[`${name}:pagination`],
        filter: [...getters[`${name}:filter`]],
        includes: [...getters[`${name}:includes`]],
      });

      commit(SET_ALL_CONVERSATIONS, conversations);
    },
    async [OPEN_CURRENT_CONVERSATION](_, { conversationId }) {
      await router.push({
        name: ROUTE_CONVERSATION_DETAILS,
        query: { editing: "true" },
        params: {
          conversationId,
        },
      });
    },
    async [UPDATE_CURRENT_CONVERSATION](
      { commit },
      { conversationId, refreshLocal = true, updateDraft = true } = {
        refreshLocal: true,
        updateDraft: true,
      }
    ) {
      let conversation = null;
      if (conversationId != null) {
        conversation = await Client.GetConversation(conversationId);
        conversation.messages = await Client.GetConversationMessages(
          conversationId
        );
        if (updateDraft && conversation.hasDraft) {
          conversation.draft = await Client.GetConversationDraft(
            conversationId
          );
        }
      }
      commit(SET_CURRENT_CONVERSATION, conversation);
      if (refreshLocal) {
        commit(SET_LOCAL_ATTACHMENTS, []);
      }
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetConversationLock(id, {
        additionalFields: ["draft", "hasDraft"],
      });
    },
    async [REMOVE_LOCAL_ATTACHMENT]({ commit, state }, { attachmentId }) {
      commit(
        SET_LOCAL_ATTACHMENTS,
        state.local.attachments.filter(
          (attachment) => attachment.id != attachmentId
        )
      );
    },
    async [UPDATE_LOCK]({ commit }, lock: Lock) {
      commit(SET_LOCK, lock);
    },
    async [DISMISS_CONVERSATION_NOTIFICATION](_, id: string) {
      return await Client.DismissConversationNotification({
        conversationId: id,
      });
    },
  },
  mutations: {
    [SET_ALL_CONVERSATIONS](state, all) {
      state.all = all;
    },
    [SET_CURRENT_CONVERSATION](state, current) {
      state.current = current;
    },
    [SET_LOCAL_ATTACHMENTS](state, attachments) {
      state.local.attachments = attachments;
    },
    [SET_ATTACHMENTS](state, attachments) {
      if (state.current!.draft == undefined) {
        state.current!["draft"] = { attachments: [] };
      }
      state.current!.draft!.attachments = attachments;
    },
    [SET_LOCK](state, lock: Lock & { draft: any; hasDraft: boolean }) {
      state.current = { ...state.current!, ...lock };
    },
  },
} as Module<ConversationStoreState, RootState>;

const paginated = PaginationMixin<ConversationStoreState>(store, {
  actionWhichUpdatesAll: UPDATE_CONVERSATIONS,
  mutationWhichUpdatesAll: SET_ALL_CONVERSATIONS,
  defaultSort: conversationSorter[0].value,
  defaultPageSize: 10,
  prefix: name,
  getTotalUnfilteredCount: Client.GetAllConversations,
});

export default FilterMixin<ConversationStoreState & Paginationable>(paginated, {
  prefix: name,
});
