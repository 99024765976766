
// eslint-disable-next-line local-rules/no-base-import
import { EvonumDialog } from "@evo/evonum";
import { h } from "vue";

export default {
  render(props: any) {
    if (props.hasDialog) {
      return h(
        EvonumDialog,
        {
          dialogLabel: props.dialogLabel,
          width: props.width,
          closeFocusId: props.closeFocusId,
        },
        props.$slots
      );
    } else {
      return h(
        "div",
        {
          class: "partial-allowedaction-button-dialog-wrapper",
        },
        [props.$slots.button({ attributes: {}, listeners: {} })]
      );
    }
  },
  props: {
    width: Number,
    hasDialog: Boolean,
    closeFocusId: String,
    dialogLabel: String,
  },
};
