import { App } from "vue";

const requireComponents = require.context(".", false, /.vue$/);

export default function registerComponents(app: App) {
  requireComponents.keys().forEach((fileName) => {
    // Get the component config
    const componentConfig = requireComponents(fileName);
    // Get the PascalCase version of the component name
    const componentName = fileName
      .replace("./", "")
      .replace(".vue", "")
      .split("-")
      // Upper case
      .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      // Concatenated
      .join("");

    // Globally register the component
    app.component(componentName, componentConfig.default || componentConfig);
  });
}
// For each matching file name...
