import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_coach_mark_action = _resolveComponent("base-coach-mark_action")!
  const _component_base_dialog_card_confirmation = _resolveComponent("base-dialog-card_confirmation")!
  const _component_partial_allowedaction_button_dialog_wrapper = _resolveComponent("partial-allowedaction-button-dialog-wrapper")!
  const _component_base_command_action = _resolveComponent("base-command-action")!
  const _component_base_disabler = _resolveComponent("base-disabler")!

  return (_openBlock(), _createBlock(_component_base_disabler, {
    actionable: _ctx.actionable,
    action: _ctx.action,
    allowedValue: _ctx.allowedValue,
    onActionPossibleChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('actionPossibleChanged', $event))),
    onCanPerformActionChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('canPerformActionChanged', $event))),
    stayRendered: _ctx.stayRendered || _ctx.isMenu
  }, {
    default: _withCtx(({ canPerformAction }) => [
      _createVNode(_component_base_command_action, {
        args: _ctx.args,
        onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loading = $event)),
        action: _ctx.action,
        ref: "commandAction",
        minLoadTime: _ctx.minLoadTime,
        runId: _ctx.customRunId || _ctx.action,
        onRegisterLoadId: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('register-load-id', $event))),
        onRunExit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('run-exit'))),
        onPollingStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('polling-start'))),
        onSyncSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('sync-success', $event))),
        loadingOnOwnTrigger: _ctx.loadingOnOwnTrigger
      }, {
        default: _withCtx(({ run }) => [
          _createVNode(_component_partial_allowedaction_button_dialog_wrapper, {
            hasDialog: _ctx.hasDialog,
            width: _ctx.dialogWidth,
            closeFocusId: _ctx._closeFocusId,
            dialogLabel: _ctx.actionTitle
          }, {
            button: _withCtx(({ attributes, listeners }) => [
              (
              (!_ctx.isMenu || _ctx.stayRendered) &&
              !canPerformAction &&
              !_ctx.noCoachmark &&
              !_ctx.overrideActionDisable
            )
                ? (_openBlock(), _createBlock(_component_base_coach_mark_action, {
                    key: 0,
                    actionable: _ctx.actionable,
                    action: _ctx.action,
                    allowedValue: _ctx.allowedValue,
                    i18nVariant: _ctx.i18nVariant
                  }, null, 8, ["actionable", "action", "allowedValue", "i18nVariant"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "button", _normalizeProps(_guardReactiveProps({
              disabled:
                _ctx.forceDisabled ||
                (!_ctx.enable && !canPerformAction && !_ctx.overrideActionDisable) ||
                _ctx.disableButtonOnLoad,
              bindings: { ..._ctx.$attrs, ...attributes },
              listeners: { click: run, ...listeners, ..._ctx.extListeners },
              loading: _ctx.showSpinner,
              label: _ctx.actionTitle,
            })), () => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.textOrPrimary), _mergeProps({
                  disabled: 
                _ctx.forceDisabled ||
                (!_ctx.enable && !canPerformAction && !_ctx.overrideActionDisable) ||
                _ctx.disableButtonOnLoad
              ,
                  loading: _ctx.showSpinner,
                  ref: "button"
                }, _toHandlers({ click: run, ...listeners, ..._ctx.extListeners }), { ..._ctx.$attrs, ...attributes }, {
                  filled: _ctx.isMenu ? undefined : _ctx.isFilled,
                  class: "base-allowed-action-button",
                  "aria-label": _ctx.ariaLabel
                }), {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", { text: _ctx.actionTitle }, () => [
                      _createTextVNode(_toDisplayString(_ctx.actionTitle), 1)
                    ])
                  ]),
                  _: 2
                }, 1040, ["disabled", "loading", "filled", "aria-label"]))
              ])
            ]),
            default: _withCtx(({ close, shown }) => [
              _renderSlot(_ctx.$slots, "dialog", _normalizeProps(_guardReactiveProps({ close, run, shown })), () => [
                (_ctx.hasDialog)
                  ? (_openBlock(), _createBlock(_component_base_dialog_card_confirmation, _mergeProps({
                      key: 0,
                      onConfirmed: run,
                      i18nProps: _ctx.dialogProps
                    }, { close }, {
                      action: _ctx.action,
                      i18nVariant: _ctx.i18nVariant
                    }), {
                      default: _withCtx(({ lines }) => [
                        _renderSlot(_ctx.$slots, "dialogContent", { lines: lines })
                      ]),
                      _: 2
                    }, 1040, ["onConfirmed", "i18nProps", "action", "i18nVariant"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["hasDialog", "width", "closeFocusId", "dialogLabel"])
        ]),
        _: 2
      }, 1032, ["args", "action", "minLoadTime", "runId", "loadingOnOwnTrigger"])
    ]),
    _: 3
  }, 8, ["actionable", "action", "allowedValue", "stayRendered"]))
}