export const UPDATE_TENDER_DOCUMENTS =
  "Aktualisiert die Dokumente der aktuellen Lieferung";
export const UPDATE_TENDER_DOCUMENTS_FOR_SECTION =
  "Aktualisiert die Dokumente einer bestimmten Section";
export const ADD_TENDER_DOCUMENT =
  "Fügt ein Dokument zur aktuellen Lieferung hinzu";
export const INSERT_UPLOAD_INTO_POSITION =
  "Fügt einen Ladebalken in die aktuelle Lieferung ein";
export const REMOVE_UPLOAD_FROM_POSITION =
  "Entfernt einen Ladebalken aus der aktuellen Lieferung";
export const DELETE_TENDER_DOCUMENT =
  "Entfernt ein Dokument aus der aktuellen Lieferung";
export const RENAME_TENDER_DOCUMENT =
  "Ändert den Titel eines Dokuments aus der aktuellen Lieferung";
export const MOVE_TENDER_DOCUMENT =
  "Ein Dokument innerhalb der aktuellen Lieferung verschieben";
export const IS_ADDING_DOCUMENTS =
  "Setzt, ob die laufende Action Dokumente hinzufügt";
export const RESET_TENDER_DOCUMENT_STORE =
  "Entfernt alle Daten aus dem Tender Document-Store";
export const name = "tenderDocuments-document";
