import { AwardingClient } from "@/utils/EvoClient";
import { RenameDocumentFromShipmentDto } from "../dto";

const ENDPOINT = "c/rename-tender-document";

export default async function RenameTenderDocument(
  dto: RenameDocumentFromShipmentDto
): Promise<void> {
  return await AwardingClient.post(ENDPOINT, dto);
}
