import { FilterOperator } from "@/model/FilterGroup";
import { Paginationable } from "@/store/types";

//URLSearchParams unterstützt kein paramter Ohne Wert (?filter[api]) sondern nur mit (?filter[api]=true)
//Deswegen nehmen wir bei Parametern, die --NO_VALUE-- als Wert haben, den Value aus dem Ergebnis weg.
const NO_VALUE_PARAM_PLACEHOLDER = "-----NO_VALUE_PLACEHOLDER-----";
const NO_VALUE_PARAM_PLACEHOLDER_REPLACE_REGEX = new RegExp(
  `=${NO_VALUE_PARAM_PLACEHOLDER}`,
  "g"
);

export default class UrlBuilder {
  private queryParams = new URLSearchParams();
  private result = "";
  constructor(private base: string) {
    this.result = base;
  }

  static fromBaseUrl(base: string) {
    return new UrlBuilder(base);
  }

  /*
   Filter die Entitäten nach ihren Eigenschaften.
   Diese können auch bzgl Relationships sein.
   Beispiel:
    Mit participants?filter[callsForTenders]=id1 werden nur diejenigen 
    participants ausgegeben, die eine callsForTenders-Relationship zu 
    einem callForTenders haben, welcher die id "id1" hat. Die Relationship 
    selbst heißt 'callsForTenders
    Mit participants?filter[offers.callForTenders]=id1 werden nur diejenigen 
    participants ausgegeben, die mindestens ein offer in der offers-Relationship 
    haben, welches eine callForTenders-Relationship zu einem callForTenders mit der Id "id1" hat.
  */
  addFilter(
    name: string,
    value?: string,
    { operator }: { operator?: FilterOperator } = {}
  ) {
    this.addParams(
      `filter${getFilterOperator(operator)}[${name}]`,
      value ?? NO_VALUE_PARAM_PLACEHOLDER
    );
    return this;
  }

  /*
  Filter die Relationships der angefragten Entität, nicht jedoch die Entitäten selbst!
  Beispiel:
    Mit participants?filter[offers][callForTenders]=id1 werden alle Participants ausgegeben. 
    Allerdings nur diejenigen 'offers'-Relationships,  welche eine callForTenders-Relationship 
    zu einem callForTenders mit der Id "id1" haben.
   */
  addRelationshipFilter(relationship: string, name: string, value: string) {
    this.addParams(`filter[${relationship}][${name}]`, value);
    return this;
  }

  addIncludes(include: string) {
    const includes = [include];
    if (this.queryParams.has("include")) {
      includes.unshift(this.queryParams.get("include")!);
    }
    this.queryParams.set("include", includes.join(","));
    return this;
  }

  addLockQuery(
    type: string,
    { additionalFields = [] }: { additionalFields?: string[] } = {}
  ) {
    additionalFields.push(
      "locked",
      "notification",
      "actions",
      "state",
      "pendingChanges"
    );
    this.addParams(`fields[${type}]`, additionalFields.join(","));
    return this;
  }

  addParams(key: string, value?: string) {
    this.queryParams.append(key, value ?? NO_VALUE_PARAM_PLACEHOLDER);
    return this;
  }

  addParamsList(list: Record<string, string> = {}) {
    Object.keys(list).forEach((X) => {
      this.addParams(X, list[X]);
    });
    return this;
  }

  addPagination(
    { size = 25, number = 0 }: Paginationable["pagination"]["page"] = {
      size: 25,
      number: 0,
    }
  ) {
    this.addParams("page[size]", size.toString());
    this.addParams("page[number]", number.toString());
    return this;
  }

  addFields(type: string, fields: string) {
    const fieldsArray = [fields];
    if (this.queryParams.has(`fields[${type}]`)) {
      fieldsArray.unshift(this.queryParams.get(`fields[${type}]`)!);
    }
    this.queryParams.set(`fields[${type}]`, fieldsArray.join(","));
    return this;
  }

  addSort(sort = "") {
    if (sort) {
      this.addParams("sort", sort);
    }
    return this;
  }

  private getParams(): string {
    if (!this.queryParams.keys().next().done) {
      return "?" + this.queryParams.toString();
    } else {
      return "";
    }
  }

  valueOf() {
    return this.toString();
  }

  toString() {
    return (
      encodeURI(this.result) +
      this.getParams().replace(NO_VALUE_PARAM_PLACEHOLDER_REPLACE_REGEX, "")
    );
  }
}

function getFilterOperator(operator?: FilterOperator) {
  switch (operator) {
    case FilterOperator.NOT:
      return ":not";
    case FilterOperator.OR:
      return ":or";
    default:
      return "";
  }
}
