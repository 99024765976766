export const UPDATE_NOTICES = "Lädt alle notices";
export const UPDATE_CURRENT_NOTICE = "Aktualisiert den/das aktuelle notice";
export const CREATE_NOTICE = "Führe CREATE_NOTICE Kommando aus";
export const EDIT_NOTICE = "Führe EDIT_NOTICE Kommando aus";

export const VALIDATE_NOTICE = "Führe VALIDATE_NOTICE Kommando aus";
export const DELETE_NOTICE = "Führe DELETE_NOTICE Kommando aus";
export const PUBLISH_NOTICE = "Führe PUBLISH_NOTICE Kommando aus";

export const CHECK_LOCK = "Prüfe ob die Notice ein Lock hat";
export const UPDATE_LOCK = "Setze das Notice Lock";

export const DISMISS_NOTICE_NOTIFICATION = "Notice Notification bestätigen";

export const RESET_NOTICE_STORE = "Entfernt alle Daten aus dem Notice-Store";
