import {
  SET_CALLS_FOR_COMPETITION,
  SET_CURRENT_CALL_FOR_COMPETITION,
  SET_LOCK,
} from "./callForCompetition.mutations";
import {
  CHECK_LOCK,
  CREATE_CALL_FOR_COMPETITION,
  DELETE_CALL_FOR_COMPETITION,
  EDIT_CALL_FOR_COMPETITION,
  PUBLISH_CALL_FOR_COMPETITION,
  UPDATE_CALLS_FOR_COMPETITION,
  UPDATE_LOCK,
  CHANGE_REQUEST_FOR_PARTICIPATION_PERIOD,
  COMPLETE_CALL_FOR_COMPETITION,
  RESET_CALL_FOR_COMPETITION_STORE,
  OPEN_REQUESTS_FOR_PARTICIPATION,
} from "./callForCompetition.actions";
import { RootState } from "@/store/types";
import { Module } from "vuex";
import {
  CallForCompetitionStoreState,
  CreateCallForCompetitionArgs,
} from "./callForCompetition.types";
import Client from "@/utils/EvoClient";
import { CallForCompetition } from "@/model/CallForCompetition";
import i18n from "@/i18n";
import StoreNotificationService from "@/service/StoreNotificationService";
import {
  DeleteCallForCompetitionDto,
  DismissCallForCompetitionNotificationDto,
  EditCallForCompetitionDto,
  ChangeRequestForParticipationPeriodDto,
  PublishCallForCompetitionDto,
  CompleteCallForCompetitionDto,
  OpenRequestsForParticipationDto,
} from "@/utils/EvoClient/dto";
import router from "@/router";
import { Lock } from "@/model/Lock";
import { DISMISS_NOTIFICATION } from "@/store/modules/callForCompetition.actions";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "./awardProcedure.actions";
import { UPDATE_PARTICIPANTS } from "@/store/modules/participant.actions";
import { unref } from "vue";

export default {
  state: {
    all: [],
    currentId: "",
  } as CallForCompetitionStoreState,
  getters: {
    callForCompetition(state) {
      return state.all[0];
    },
  },
  actions: {
    async [OPEN_REQUESTS_FOR_PARTICIPATION](
      { dispatch },
      dto: OpenRequestsForParticipationDto
    ) {
      await Client.OpenRequestsForParticipation(dto);
      await dispatch(UPDATE_CALLS_FOR_COMPETITION, {
        currentCallForCompetitionId: dto.callForCompetitionId,
      });
    },
    [RESET_CALL_FOR_COMPETITION_STORE]({ commit }) {
      commit(SET_CALLS_FOR_COMPETITION, []);
      commit(SET_CURRENT_CALL_FOR_COMPETITION, null);
    },
    async [CREATE_CALL_FOR_COMPETITION](
      { rootState, commit, dispatch },
      {
        awardProcedureId,
        requestForParticipationPeriod,
      }: CreateCallForCompetitionArgs
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const cfcid = await Client.CreateCallForCompetition({
        awardProcedureId,
        requestForParticipationPeriod,
      });
      await dispatch(UPDATE_CALLS_FOR_COMPETITION, {
        awardProcedureId,
        currentCallForCompetitionId: cfcid,
      });
      commit(SET_CURRENT_CALL_FOR_COMPETITION, cfcid);
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetCallForCompetitionLock(id);
    },
    async [UPDATE_LOCK]({ commit }, lock: Lock) {
      commit(SET_LOCK, lock);
    },
    async [UPDATE_CALLS_FOR_COMPETITION](
      { rootState, commit, dispatch },
      { awardProcedureId, currentCallForCompetitionId } = {}
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const callsForCompetition = await Client.GetAllCallsForCompetition({
        filter: [{ field: "awardProcedure", value: awardProcedureId }],
      });

      //Andere Mechanik als bei Verfahren, da wir keine getrennte Liste haben und sofort die current zusätzliche Laden sollten, optional
      //Das Problem kommt daher, da die Listenresource nicht alle Informationen der Ressourcen über die Liste haben
      const cfcid =
        currentCallForCompetitionId || callsForCompetition[0]
          ? callsForCompetition[0].id
          : null;
      if (cfcid) {
        commit(SET_CURRENT_CALL_FOR_COMPETITION, cfcid);
        await dispatch(UPDATE_PARTICIPANTS, {
          includeRequestsForParticipation: true,
        });
      } else {
        commit(SET_CURRENT_CALL_FOR_COMPETITION, "");
      }
      commit(SET_CALLS_FOR_COMPETITION, callsForCompetition);
      return callsForCompetition;
    },
    async [DISMISS_NOTIFICATION](_, callForCompetitionId: string) {
      const result = await Client.DismissCallForCompetitionNotification({
        callForCompetitionId,
      } as DismissCallForCompetitionNotificationDto);
      return result;
    },
    async [DELETE_CALL_FOR_COMPETITION](
      { dispatch },
      args: DeleteCallForCompetitionDto
    ) {
      await Client.DeleteCallForCompetition(args);
      const message = i18n.global.t(
        "award-procedures.details.notifications.call-for-competition-deleted"
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "call-for-competition-details",
        message
      );
      //Da wir auf der gleichen Route bleiben
      //ist die Ladereihenfolge wichtig
      const currentRoute = unref(router.currentRoute);
      await dispatch(
        GET_AWARD_PROCEDURE_AS_CURRENT,
        currentRoute.params.awardProcedureId
      );
      await Promise.all(
        currentRoute.meta!.loadRoute!({ dispatch }, currentRoute.params)
      );
    },
    async [EDIT_CALL_FOR_COMPETITION](
      { dispatch },
      args: EditCallForCompetitionDto
    ) {
      await Client.EditCallForCompetition(args);
      await dispatch(UPDATE_CALLS_FOR_COMPETITION, {
        currentCallForCompetitionId: args.callForCompetitionId,
      });
    },
    async [PUBLISH_CALL_FOR_COMPETITION](
      { dispatch },
      args: PublishCallForCompetitionDto
    ) {
      await Client.PublishCallForCompetition(args);
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [COMPLETE_CALL_FOR_COMPETITION](
      { dispatch },
      args: CompleteCallForCompetitionDto
    ) {
      await Client.CompleteCallForCompetition(args);
      //await StoreNotificationService.setOffersMovedNotification(); Falls wir eine extra move Notification brauchen
      await dispatch(UPDATE_CALLS_FOR_COMPETITION, {
        currentCallForCompetitionId: args.callForCompetitionId,
      });
    },
    async [CHANGE_REQUEST_FOR_PARTICIPATION_PERIOD](
      { dispatch },
      args: ChangeRequestForParticipationPeriodDto
    ) {
      await Client.ChangeRequestForParticipationPeriod(args);
      await dispatch(UPDATE_LOCK, { locked: true });
    },
  },
  mutations: {
    [SET_CURRENT_CALL_FOR_COMPETITION](state, id: string) {
      state.currentId = id;
    },
    [SET_CALLS_FOR_COMPETITION](
      state,
      callsForCompetition: CallForCompetition[]
    ) {
      state.all = callsForCompetition;
    },
    [SET_LOCK](state, lock: Lock) {
      state.all = state.all.map((x) => {
        if (x.id == state.currentId) {
          return { ...x, ...lock };
        } else {
          return x;
        }
      });
    },
  },
} as Module<CallForCompetitionStoreState, RootState>;
