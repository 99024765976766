import { Regulation } from "@/model/Regulation";

export function calculateFullNameOfRegulation(regulation: Regulation): string {
  return `${regulation.shortName} (${regulation.name})`;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function* fibonacci(): IterableIterator<number> {
  const fibonaccis = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144];
  let index = 0;
  while (true) {
    index = fibonaccis.length == index + 1 ? index : index + 1;
    yield fibonaccis[index];
  }
}

export function waitForXBeforeResolve<T>(
  promise: Promise<T>,
  ms: number
): Promise<T> {
  return new Promise((res, rej) => {
    const currTime = Date.now();
    promise
      .then((result: any) => {
        //wir halten die Antwort "im Vorrat"
        setTimeout(() => res(result), ms - (Date.now() - currTime));
      })
      .catch((err) => rej(err));
  });
}
