import { AwardingClient } from "@/utils/EvoClient";
import { DeleteAwardUnitDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/delete-award-unit";

export default async function deleteAwardUnit(
  dto: DeleteAwardUnitDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
