import { AwardingClient } from "@/utils/EvoClient";
import { DeleteShipmentDto } from "../dto";

const ENDPOINT = "c/delete-shipment";

export default async function deleteShipment(
  dto: DeleteShipmentDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
