import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Error404 from "@/views/error-pages/404.vue";
import Error500 from "@/views/error-pages/500.vue";
import ErrorNoRoute from "@/views/error-pages/noroute.vue";
import Store from "@/store";
import {
  SET_IS_FIRST_LOAD,
  RESET_DIRTY,
  RESET_EDIT_MODE,
  RESET_FILTER,
  RESET_PAGINATION,
  SET_IS_NAVIGATING,
} from "@/store/actions";
import LoadingService from "@/service/LoadingService";
import ErrorService from "@/service/ErrorHandlerService";
import i18n from "@/i18n";
import {
  AccessDeniedError,
  ConsistencyError,
  ServerValidationError,
} from "@/utils/errors";
import { RESET_LOADING_FOR_ACTION } from "@/store/modules/loading.actions";

import Dashboard from "./configs/dashboard";
import Users from "./configs/users";
import Authorities from "./configs/authorities";
import AwardProcedureDetails from "./configs/award-procedures";
import { DISMISS_ALL_ERRORS } from "@/store/modules/notification.actions";
import DirtyService from "@/service/DirtyService";
import {
  ROUTE_AUTHORITIES_SELECTION,
  ROUTE_AWARD_UNIT_DETAILS,
  ROUTE_AWARD_UNIT_LIST,
  ROUTE_CALL_FOR_COMPETITION_DETAILS,
  ROUTE_CALL_FOR_TENDERS_DETAILS,
  ROUTE_CONVERSATION_DETAILS,
  ROUTE_CONVERSATION_LIST,
  ROUTE_OFFER_DETAILS,
  ROUTE_PARTICIPANT_DETAILS,
  ROUTE_PARTICIPANT_LIST,
  ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS,
  ROUTE_TABLE_OF_OFFERS_DETAILS,
  ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS,
  ROUTE_USERS_REGISTER,
} from "@/router/routes";
import { GET_AUTHORITY_AS_CURRENT } from "@/store/modules/users.actions";

export interface MenuItem {
  name: string;
  icon: string;
  exact: boolean;
  route: RouteLocationNormalized;
  visible: boolean;
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/404",
    name: "Error404",
    component: Error404,
    props: true,
    meta: {
      title: i18n.global.t("route-titles.error"),
      notRequireAuthority: true,
    },
  },
  {
    path: "/500",
    name: "Error500",
    component: Error500,
    meta: {
      title: i18n.global.t("route-titles.error"),
      notRequireAuthority: true,
    },
  },
  Dashboard,
  Users,
  Authorities,
  AwardProcedureDetails,
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorNoRoute",
    component: ErrorNoRoute,
    props: true,
    meta: {
      title: i18n.global.t("route-titles.error"),
      notRequireAuthority: true,
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

const NO_RESET_PAGINATION_PAIRS_MAP = new Map([
  [ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS, ROUTE_TABLE_OF_OFFERS_DETAILS],
  [ROUTE_OFFER_DETAILS, ROUTE_CALL_FOR_TENDERS_DETAILS],
  [ROUTE_CONVERSATION_DETAILS, ROUTE_CONVERSATION_LIST],
  [ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS, ROUTE_CALL_FOR_COMPETITION_DETAILS],
  [ROUTE_PARTICIPANT_DETAILS, ROUTE_PARTICIPANT_LIST],
  [ROUTE_AWARD_UNIT_DETAILS, ROUTE_AWARD_UNIT_LIST],
  ["NO_NAME", "NO_NAME"],
]);

function isSpecialSubview(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized
): boolean {
  return (
    NO_RESET_PAGINATION_PAIRS_MAP.get(from.name?.toString() || "NO_NAME") ==
      to.name ||
    NO_RESET_PAGINATION_PAIRS_MAP.get(to.name?.toString() || "NO_NAME") ==
      from.name
  );
}

router.beforeEach(async (to: any, from: any, next: any) => {
  if (
    to.query.authority &&
    to.query.authority != Store.state.users.authority?.id
  ) {
    await Store.dispatch(GET_AUTHORITY_AS_CURRENT, {
      authorityId: to.query.authority,
    });
  }
  if (
    !to.query.authority &&
    Store.state.users.authority &&
    requiresAuthority(to)
  ) {
    next({
      ...to,
      query: {
        ...to.query,
        authority: Store.state.users.authority.id,
      },
    });
    return;
  }
  if (needsToChooseAuthority(to)) {
    next({ name: ROUTE_AUTHORITIES_SELECTION });
    return;
  }
  if (DirtyService.isDirty()) {
    const beforeTime = Date.now();
    const doNavigate = window.confirm(
      i18n.global.t("common.navigation.unsavedChangesWarning").toString()
    );
    if (!doNavigate && Date.now() - beforeTime > 80) {
      next(false);
      return;
    }
  }

  LoadingService.reset();
  await Store.dispatch(SET_IS_NAVIGATING, true);
  LoadingService.addCleanAction(RESET_LOADING_FOR_ACTION);
  LoadingService.addCleanAction(RESET_DIRTY);
  LoadingService.addCleanAction(RESET_EDIT_MODE);
  LoadingService.addCleanAction(DISMISS_ALL_ERRORS);
  LoadingService.startProgressbar();
  if (!isSpecialSubview(from, to) || isFirstLoad(from)) {
    await Store.dispatch(RESET_PAGINATION);
    await Store.dispatch(RESET_FILTER);
  }
  next();
});

router.beforeResolve(async (to: any, from, next) => {
  try {
    await LoadingService.waitForAll();
    await Store.dispatch(SET_IS_NAVIGATING, false);
    ErrorService.resetGlobalError();
    next();
  } catch (err: any) {
    await Store.dispatch(SET_IS_NAVIGATING, false);
    LoadingService.failProgressbar();
    if (err instanceof AccessDeniedError) {
      next({ name: ROUTE_USERS_REGISTER });
    } else if (err instanceof ConsistencyError) {
      next({ name: "ErrorNoRoute" });
    } else if (err.code == 404) {
      if (isAwardProcedureNotFound(to, err)) {
        next({ name: "Error404", params: { errors: err.errors } });
      } else {
        to.params.routeError = err;
        next();
      }
    } else if (isFirstLoad(from)) {
      next({ name: "Error500" });
    } else {
      next(false);
    }
  }
});

router.afterEach(async () => {
  LoadingService.stopProgressbar();
  await LoadingService.runAllCleanActions();
  await Store.dispatch(SET_IS_FIRST_LOAD, window.history.state?.back == null);
});

function isAwardProcedureNotFound(
  to: RouteLocationNormalized,
  err: unknown
): boolean {
  if (to.params.awardProcedureId) {
    const error = err as ServerValidationError;
    return error.errors[0].detail.includes(to.params.awardProcedureId);
  }
  return false;
}

function isFirstLoad(from: RouteLocationNormalized) {
  return from.name == undefined && from.path == "/"; //Beides kann nicht sein, da unsere / Route einen Namen hat.
}

function requiresAuthority(to: RouteLocationNormalized) {
  return !to.meta.notRequireAuthority;
}

function needsToChooseAuthority(to: RouteLocationNormalized) {
  return (
    Store.state.users.authority == null &&
    requiresAuthority(to) &&
    Store.state.users.user?.authorities &&
    Store.state.users.user?.authorities.length > 0
  );
}

export default router;
