
import { defineComponent } from "vue";
import CommonChipState from "@/components/common-chip_state.vue";

export default defineComponent({
  name: "partial-dialog-card-state-change-display",
  props: {
    from: String,
    to: String,
  },
  components: {
    CommonChipState,
  },
});
