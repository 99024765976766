import { AwardingClient } from "@/utils/EvoClient";
import { DeleteNoticeDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/delete-notice";

export default async function deleteNotice(
  dto: DeleteNoticeDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
