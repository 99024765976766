import { AwardingClient } from "@/utils/EvoClient";
import { DismissNoticeNotificationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-notice-notification";

export default async function dismissNoticeNotification(
  dto: DismissNoticeNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
