import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createBlock(_component_v_pagination, _mergeProps({ class: "evo-pagination text-subtitle-2" }, _ctx.$attrs, {
    key: _ctx.length,
    "total-visible": _ctx.actualVisible,
    length: _ctx.length,
    "next-aria-label": _ctx.$t('aria.pagination.forwardOnePage'),
    previousAriaLabel: _ctx.$t('aria.pagination.backOnePage'),
    size: "44",
    ref: "input"
  }), null, 16, ["total-visible", "length", "next-aria-label", "previousAriaLabel"]))
}