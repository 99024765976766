import { AwardingClient } from "@/utils/EvoClient";
import { EditInternalDocumentationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-internal-documentation";

export default async function editInternalDocumentation(
  dto: EditInternalDocumentationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
