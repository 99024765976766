import { AwardingClient } from "@/utils/EvoClient";
import { OpenRequestsForParticipationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/open-requests-for-participation";

export default async function openRequestsForParticipation(
  dto: OpenRequestsForParticipationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
