import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_select, _mergeProps({
    items: _ctx.items,
    clearable: "",
    "item-color": "var(--evo-interactive)",
    color: "var(--evo-interactive)",
    "aria-required": _ctx.required,
    class: ["base-select-multiple", {
      'base-select-multiple--required': _ctx.required,
    }],
    dense: "",
    value: _ctx.value,
    onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('input', $event))),
    disabled: _ctx.disabled,
    filled: "",
    "menu-props": _ctx.menuProps,
    multiple: "",
    autofocus: _ctx.autofocus,
    label: _ctx.label,
    placeholder: _ctx.placeholder
  }, _ctx.$attrs), _createSlots({
    selection: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "selection", _normalizeProps(_guardReactiveProps(slotProps)))
    ]),
    "no-data": _withCtx(() => [
      _renderSlot(_ctx.$slots, "no-data", {}, () => [
        _createVNode(_component_v_list_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              size: "23",
              color: "var(--evo-interactive)",
              width: "2",
              "aria-label": _ctx.$t('aria.common.loading')
            }, null, 8, ["aria-label"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 2
  }, [
    (_ctx.selectAll)
      ? {
          name: "prepend-item",
          fn: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
              onClick: _ctx.toggle
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_action, {
                  onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["down"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      color: _ctx.value.length > 0 ? 'var(--evo-interactive)' : ''
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.icon), 1)
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectAllLabel), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_divider)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["items", "aria-required", "class", "value", "disabled", "menu-props", "autofocus", "label", "placeholder"]))
}