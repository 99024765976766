import { AwardProcedure } from "@/model/AwardProcedure";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "award-procedures";

export type PossibleAwardProceduresFilters = ["state"];
export type PossibleAwardProceduresIncludes = [
  "regulation",
  "procedureType",
  "typeOfService",
];
export type PossibleAwardProceduresSorts = [
  "title",
  "-title",
  "state",
  "-state",
  "fileNumber",
  "-fileNumber",
];

export default ListQueryFunctionGenerator<
  AwardProcedure[],
  PossibleAwardProceduresFilters,
  PossibleAwardProceduresIncludes,
  PossibleAwardProceduresSorts
>(ENDPOINT);
