
import { defineComponent } from "vue";
import ActionableMixin from "@/mixins/actionable";
import VariableMixin from "@/mixins/variantable";

export default defineComponent({
  name: "base-allowedaction-group-button",
  mixins: [ActionableMixin, VariableMixin],
  inheritAttrs: false,
  props: {
    buttonLike: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: { required: true },
    disabled: {
      type: Boolean,
    },
    groupValue: {
      type: String,
    },
  },
});
