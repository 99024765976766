import { FilterOperator, OptionType } from "@/model/FilterGroup";
import { OfferAdmissionStatusValues } from "@/model/OfferAdmissionStatus";
import { Participant } from "@/model/Participant";
import { ParticipantAdmissionStatusValues } from "@/model/ParticipantAdmissionStatus";
import { RootState } from "@/store/types";
import { CommonFilters } from "../query";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "participants";

export const supportedFilter = {
  awardProcedure: CommonFilters.awardProcedure,
  "offers.callForTenders": {
    field: "offers.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
  },
  "offers.awardUnits": {
    field: "offers.awardUnits",
    value: [] as string[],
    type: OptionType.FILTER,
  },
  "not.offers.callForTenders": {
    field: "offers.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
    operator: FilterOperator.NOT,
  },
  "offers::callForTenders": {
    field: "offers::callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.RELATIONSHIPFILTER,
  },
  admissionStatus: {
    field: "admissionStatus",
    value: ParticipantAdmissionStatusValues.Admitted,
    type: OptionType.FILTER,
  },
  "offers.admissionStatus": {
    field: "offers.admissionStatus",
    value: OfferAdmissionStatusValues.AdmittedToRanking,
    type: OptionType.FILTER,
  },
  "offers.inTableOfOffers": {
    field: "offers.inTableOfOffers",
    value: "true",
    type: OptionType.FILTER,
  },
  "isInvitedOrInvitable.callForTenders": {
    field: "isInvitedOrInvitable.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
  },
  "isInvitedOrInvitable.awardUnit": {
    field: "isInvitedOrInvitable.awardUnit",
    value: null,
    type: OptionType.FILTER,
  },
  isQualified: {
    field: "isQualified",
    value: (state: RootState) =>
      state.callsForTenders.current!.awardUnits!.map((au) => au.id).join(","),
    operator: FilterOperator.OR,
    type: OptionType.FILTER,
  },
  "isInvited.callForTenders": {
    field: "isInvited.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
  },
  "not.isInvited.callForTenders": {
    field: "isInvited.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    operator: FilterOperator.NOT,
    type: OptionType.FILTER,
  },
  "not.isInvited.awardUnit": {
    field: "isInvited.awardUnit",
    value: null,
    operator: FilterOperator.NOT,
    type: OptionType.FILTER,
  },
  "isInvited.awardUnit": {
    field: "isInvited.awardUnit",
    value: null,
    type: OptionType.FILTER,
  },
  "isInvitable.callForTenders": {
    field: "isInvitable.callForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
  },
  "isInvitable.awardUnit": {
    field: "isInvitable.awardUnit",
    value: null,
    type: OptionType.FILTER,
  },
  callsForTenders: {
    field: "callsForTenders",
    value: (state: RootState) => state.callsForTenders.current!.id,
    type: OptionType.FILTER,
  },
  "requestsForParticipation.awardUnits": {
    field: "requestsForParticipation.awardUnits",
    value: [] as string[],
    type: OptionType.FILTER,
  },
} as const;

export const supportedIncludes = {
  offers: {
    value: "offers",
    type: OptionType.INCLUDE,
  },
  requestsForParticipation: {
    value: "requestsForParticipation",
    type: OptionType.INCLUDE,
  },
  awardProcedure: {
    value: "awardProcedure",
    type: OptionType.INCLUDE,
  },
};

export type PossibleParticipantsSorts = [
  "name",
  "-name",
  "admissionStatus",
  "-admissionStatus",
];

export default ListQueryFunctionGenerator<
  Participant[],
  (keyof typeof supportedFilter)[],
  (keyof typeof supportedIncludes)[],
  PossibleParticipantsSorts
>(ENDPOINT, {
  defaultFilter: [supportedFilter.awardProcedure],
  addIdsOfRelationshipsToResult: [
    "awardProcedure",
    "offers.callForTenders",
    "requestsForParticipation.awardUnits",
    "offers.awardUnits",
  ],
});
