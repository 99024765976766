import {
  UPDATE_REQUESTS_FOR_PARTICIPATION,
  UPDATE_CURRENT_REQUEST_FOR_PARTICIPATION,
  OPEN_REQUEST_FOR_PARTICIPATION,
  MARK_REQUEST_FOR_PARTICIPATION_ADMISSION_STATUS,
} from "@/store/modules/requestForParticipation.actions";
import {
  SET_ALL_REQUESTS_FOR_PARTICIPATION,
  SET_CURRENT_REQUEST_FOR_PARTICIPATION,
} from "@/store/modules/requestForParticipation.mutations";
import { RequestForParticipationStoreState } from "@/store/modules/requestForParticipation.types";
import { Module } from "vuex";
import { Paginationable, RootState } from "@/store/types";
import PaginationMixin from "@/store/mixins/pagination";
import { requestForParticipationSorter } from "@/model/RequestForParticipation";
import Client from "@/utils/EvoClient";
import { UPDATE_CALLS_FOR_COMPETITION } from "@/store/modules/callForCompetition.actions";
import FilterMixin from "../mixins/filter";
import { supportedFilter } from "@/utils/EvoClient/query/GetAllRequestsForParticipation";

export const name = "requestforparticipation";

const store = {
  state: {
    all: [],
    current: null,
  } as RequestForParticipationStoreState,
  actions: {
    async [MARK_REQUEST_FOR_PARTICIPATION_ADMISSION_STATUS](
      { dispatch, state },
      args
    ) {
      await Client.MarkRequestForParticipationAdmissionStatus(args);
      await Promise.all([
        dispatch(UPDATE_CALLS_FOR_COMPETITION),
        dispatch(UPDATE_CURRENT_REQUEST_FOR_PARTICIPATION, state.current?.id),
      ]);
    },
    async [UPDATE_REQUESTS_FOR_PARTICIPATION](
      { commit, getters, rootState },
      callForCompetitionId: string = rootState.callsForCompetition.currentId
    ) {
      let filter = getters[`${name}:filter`] || null;
      if (callForCompetitionId && filter == null) {
        filter = [
          {
            ...supportedFilter.callForCompetition,
            value: callForCompetitionId,
          },
        ];
      }
      const requestsForParticipation =
        await Client.GetAllRequestsForParticipation({
          filter,
          pagination: getters[`${name}:pagination`],
        });
      commit(SET_ALL_REQUESTS_FOR_PARTICIPATION, requestsForParticipation);
    },
    async [UPDATE_CURRENT_REQUEST_FOR_PARTICIPATION](
      { commit },
      requestForParticipationId: string
    ) {
      let requestForParticipation = null;
      if (requestForParticipationId != null) {
        requestForParticipation = await Client.GetRequestForParticipation(
          requestForParticipationId
        );
      }
      commit(SET_CURRENT_REQUEST_FOR_PARTICIPATION, requestForParticipation);
    },
  },
  mutations: {
    [SET_ALL_REQUESTS_FOR_PARTICIPATION](state, all) {
      state.all = all;
    },
    [SET_CURRENT_REQUEST_FOR_PARTICIPATION](state, current) {
      state.current = current;
    },
  },
} as Module<RequestForParticipationStoreState, RootState>;

const paginated = PaginationMixin<RequestForParticipationStoreState>(store, {
  actionWhichUpdatesAll: UPDATE_REQUESTS_FOR_PARTICIPATION,
  mutationWhichUpdatesAll: SET_ALL_REQUESTS_FOR_PARTICIPATION,
  defaultSort: requestForParticipationSorter[0].value,
  prefix: name,
});

export default FilterMixin<RequestForParticipationStoreState & Paginationable>(
  paginated,
  {
    prefix: name,
  }
);
