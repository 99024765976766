import { Module } from "vuex";
import { RootState } from "@/store/types";
import { TenderDocumentStoreState } from "@/store/modules/tenderDocument.types";
import {
  Document,
  DocumentPosition,
  PositionValues,
  RawDocument,
} from "@/model/Document";
import {
  UPLOAD_INTO_POSITION,
  SET_ALL_DOCUMENTS,
  REMOVE_FROM_POSITION,
  UPDATE_DOCUMENTS_FOR_SECTION,
} from "@/store/modules/tenderDocument.mutations";
import {
  ADD_TENDER_DOCUMENT,
  INSERT_UPLOAD_INTO_POSITION,
  REMOVE_UPLOAD_FROM_POSITION,
  UPDATE_TENDER_DOCUMENTS,
  DELETE_TENDER_DOCUMENT,
  RENAME_TENDER_DOCUMENT,
  MOVE_TENDER_DOCUMENT,
  IS_ADDING_DOCUMENTS,
  RESET_TENDER_DOCUMENT_STORE,
  name,
} from "@/store/modules/tenderDocument.actions";
import Client from "@/utils/EvoClient";
import {
  AddDocumentToShipmentDto,
  AddDocumentToShipmentPositionDto,
  DocumentIdable,
  MoveDocumentToShipmentPositionDto,
  RenameDocumentFromShipmentDto,
  SectionIdable,
  ShipmentIdable,
} from "@/utils/EvoClient/dto";
import uploadFile from "@/utils/EvoClient/uploads/UploadToShipment";
import {
  RESET_LOADING_META_FOR_ACTION,
  SET_LOADING_META_FOR_ACTION,
  START_LOADING_FOR_ACTION,
  STOP_LOADING_FOR_ACTION,
} from "./loading.actions";
import { withFiles } from "@/model/BaseCommandAction";
import { UPDATE_CURRENT_SHIPMENT } from "./shipment.actions";
import { SET_CURRENT_SHIPMENT } from "./shipment.mutations";
import { SET_ALL_SECTIONS } from "./section.mutations";
import GetFileMetadata from "@/utils/EvoClient/unique/GetFileMetadata";
import { DISABLE_FILE_DRAG } from "../actions";
import { SET_ERRORS_FOR_COMPONENT } from "./notification.actions";
import { ServerValidationError } from "@/utils/errors";

export type InsertUploadIntoPositionArgs = AddDocumentToShipmentPositionDto;

export type MoveDocumentIntoPositionArgs = {
  loadId: string;
  position: DocumentPosition;
  siblingId?: string;
} & DocumentIdable &
  SectionIdable &
  ShipmentIdable;

export default {
  state: {
    all: [],
    current: null,
    isAddingDocuments: false,
  } as TenderDocumentStoreState,
  getters: {
    [`${name}:hasErrors`](state, _, rootState) {
      return state.all.some(
        (x) => rootState.notifications.errors[x.filename]?.length
      );
    },
  },
  actions: {
    [RESET_TENDER_DOCUMENT_STORE]({ commit, getters }) {
      commit(SET_ALL_DOCUMENTS, { documents: [], getters });
    },
    async [UPDATE_TENDER_DOCUMENTS](
      { commit, rootState, getters },
      {
        shipmentId = rootState.shipments.current!.id,
        updateShipment = false,
        updateSections = false,
        updateTenderDocuments = true,
      }: {
        shipmentId: string;
        updateSections: boolean;
        updateShipment: boolean;
        updateTenderDocuments: boolean;
      }
    ) {
      const loadPromises = {} as Record<string, Promise<any>>;

      if (updateShipment) {
        loadPromises.shipment = Client.GetShipment(shipmentId);
      }
      if (updateSections) {
        loadPromises.sections = Client.GetAllSections({
          endpointArgs: shipmentId,
        });
      }
      if (updateTenderDocuments) {
        loadPromises.tenderDocuments = Client.GetAllDocumentsForShipment({
          endpointArgs: shipmentId,
        });
      }

      await Promise.all(Object.values(loadPromises));

      loadPromises.shipment?.then((value) => {
        commit(SET_CURRENT_SHIPMENT, value, { root: true });
      });
      loadPromises.sections?.then((value) => {
        commit(SET_ALL_SECTIONS, value || [], { root: true });
      });
      loadPromises.tenderDocuments?.then((value) => {
        commit(SET_ALL_DOCUMENTS, { documents: value || [], getters });
      });
    },
    async [ADD_TENDER_DOCUMENT]({ dispatch }, dto: AddDocumentToShipmentDto) {
      await Client.AddTenderDocument(dto);
      dispatch(UPDATE_CURRENT_SHIPMENT, {
        updateTenderDocuments: false,
        shipmentId: dto.shipmentId,
      });
    },
    async [DELETE_TENDER_DOCUMENT](
      { rootState, dispatch },
      documentId: string
    ) {
      if (rootState.shipments.current!.id) {
        await Client.DeleteTenderDocument({
          shipmentId: rootState.shipments.current!.id,
          documentId,
        });
        dispatch(UPDATE_TENDER_DOCUMENTS, {
          updateTenderDocuments: true,
          updateShipment: true,
          updateSections: true,
          shipmentId: rootState.shipments.current!.id,
        });
      }
    },
    async _UPLOAD_INTO_POSITION({ commit }, { dto }) {
      //private action
      commit(UPLOAD_INTO_POSITION, { id: dto.filename, dto });
    },
    async _UPLOAD_SINGLE_FILE({ dispatch }, { dto, file, isLast }) {
      //private action
      dispatch(START_LOADING_FOR_ACTION, dto.filename);
      try {
        dto.uploadReceipt = await uploadFile(
          file,
          dto.shipmentId,
          ({ percent, loadedBytes, totalBytes }) => {
            dispatch(SET_LOADING_META_FOR_ACTION, {
              id: dto.filename,
              meta: { percent, loadedBytes, totalBytes },
            });
          }
        );

        await dispatch(ADD_TENDER_DOCUMENT, dto);
        if (!isLast) {
          //Damit es nicht ein kurzes Flackern von Grün auf echtes Element gibt
          dispatch(SET_LOADING_META_FOR_ACTION, {
            id: dto.filename,
            meta: { success: true },
          });
        }
      } catch (err) {
        if (err instanceof ServerValidationError) {
          dispatch(SET_ERRORS_FOR_COMPONENT, {
            id: dto.filename,
            errors: err.getPrintableErrors(),
            action: "addTenderDocument",
          });
        } else {
          throw err;
        }
      } finally {
        dispatch(STOP_LOADING_FOR_ACTION, dto.filename);
      }
    },
    async [INSERT_UPLOAD_INTO_POSITION](
      { commit, dispatch, state },
      { siblingId, position, shipmentId, sectionId, _files }
    ) {
      dispatch(DISABLE_FILE_DRAG);
      commit(IS_ADDING_DOCUMENTS, true);
      const dtos = GetUploadDtosForFiles(
        {
          _files,
          siblingId,
          position,
          shipmentId,
          sectionId,
        },
        state.all
      ).reverse(); //Damit es von oben nach unten in der richtigen Reihenfolge (wir attachen von unten nach oben)
      for (const dto of dtos) {
        dispatch("_UPLOAD_INTO_POSITION", { dto });
      }
      for (const [index, dto] of dtos.entries()) {
        await dispatch("_UPLOAD_SINGLE_FILE", {
          dto,
          file: _files[_files.length - index - 1],
          isLast: index == dtos.length - 1,
        });
      }
      await dispatch(UPDATE_TENDER_DOCUMENTS, {
        shipmentId: shipmentId,
      });
      for (const dto of dtos) {
        dispatch(RESET_LOADING_META_FOR_ACTION, dto.filename);
      }
    },
    async [REMOVE_UPLOAD_FROM_POSITION]({ commit }, loadId: string) {
      commit(REMOVE_FROM_POSITION, loadId);
    },
    async [RENAME_TENDER_DOCUMENT](
      { dispatch, rootState },
      dto: RenameDocumentFromShipmentDto
    ) {
      await Client.RenameTenderDocument(dto);
      await dispatch(UPDATE_TENDER_DOCUMENTS, {
        shipmentId: rootState.shipments.current!.id,
      });
    },
    async [MOVE_TENDER_DOCUMENT](
      { dispatch, rootState },
      args: MoveDocumentIntoPositionArgs
    ) {
      const dto = {
        documentId: args.documentId,
        siblingId: args.siblingId,
        shipmentId: args.shipmentId,
        position: args.position,
        sectionId: args.sectionId,
      } as MoveDocumentToShipmentPositionDto;
      try {
        await Client.MoveTenderDocument(dto);
      } finally {
        await dispatch(UPDATE_TENDER_DOCUMENTS, {
          shipmentId: rootState.shipments.current!.id,
          updateShipment: true,
          updateSections: true,
        });
      }
    },
  },
  mutations: {
    [SET_ALL_DOCUMENTS](
      state,
      { documents, getters }: { documents: RawDocument[]; getters: any }
    ) {
      let result: any[] = [];
      if (state.isAddingDocuments && getters[`${name}:hasErrors`]) {
        //ES sind Fehler aufgetreten, daher mergeverhalten
        let frontendIndex = 0;
        for (
          let backendIndex = 0;
          backendIndex < documents.length;
          backendIndex++
        ) {
          while (getters.hasError(state.all[frontendIndex]?.filename)) {
            //behalte alle Fehler. Das funktioniert, da das hier nur bei hinzufügen verwendet wird
            result.push(state.all[frontendIndex]);
            frontendIndex++;
          }
          result.push(new Document(documents[backendIndex]));
          frontendIndex++;
        }
        //Falls nach allen fehlerfreien Dokumenten noch Fehler passiert sind
        while (getters.hasError(state.all[frontendIndex]?.filename)) {
          result.push(state.all[frontendIndex]);
          frontendIndex++;
        }
      } else {
        result = documents.map((x) => new Document(x));
      }
      state.all = result;
      state.isAddingDocuments = false;
    },
    [UPDATE_DOCUMENTS_FOR_SECTION](
      state,
      { documents, sectionId }: { documents: RawDocument[]; sectionId: string }
    ) {
      const allWithoutSection = state.all.filter(
        (x) => x.sectionId !== sectionId
      );
      state.all = allWithoutSection.concat(
        ...documents.map((x) => new Document(x))
      );
    },
    [IS_ADDING_DOCUMENTS](state, isAdding) {
      state.isAddingDocuments = isAdding;
    },
    [REMOVE_FROM_POSITION](state, id) {
      const index = state.all.findIndex((x) => x.id == id);
      state.all.splice(index, 1);
    },
    [UPLOAD_INTO_POSITION](
      state,
      { id, dto }: { id: string; dto: AddDocumentToShipmentDto }
    ) {
      const raw = {
        id,
        title: dto.title,
        fileSize: dto.fileSize,
        filename: dto.filename,
        mediaType: dto.mediaType,
        sectionId: dto.sectionId,
        actions: {},
      } as RawDocument;
      const doc = new Document(raw);
      doc.uploading = true;
      if (dto.position == PositionValues.First) {
        state.all.unshift(doc);
      } else if (dto.position == PositionValues.Last) {
        state.all.push(doc);
      } else if (dto.position == PositionValues.Before) {
        const index = state.all.findIndex((x) => x.id == dto.siblingId);
        state.all.splice(index, 0, doc);
      } else if (dto.position == PositionValues.After) {
        const index = state.all.findIndex((x) => x.id == dto.siblingId);
        state.all.splice(index + 1, 0, doc);
      }
    },
  },
} as Module<TenderDocumentStoreState, RootState>;

function GetUploadDtosForFiles(
  pos: InsertUploadIntoPositionArgs & withFiles,
  existentFiles: { filename: string }[]
): AddDocumentToShipmentDto[] {
  const result: AddDocumentToShipmentDto[] = [];
  const existentFileNames = existentFiles.reduce(
    (prev, curr) => {
      prev[curr.filename] = true;
      return prev;
    },
    {} as Record<string, boolean>
  );

  for (const file of pos._files) {
    const meta = GetFileMetadata(file);
    result.push({
      filename:
        meta.filename in existentFileNames
          ? meta.filename + "​"
          : meta.filename,
      mediaType: meta.mediatype,
      fileSize: meta.fileSize,
      position: pos.position,
      siblingId: pos.siblingId,
      shipmentId: pos.shipmentId,
      title: meta.title,
      sectionId: pos.sectionId,
    });
  }
  return result;
}
