import { Actionable } from "@/model/Actionable";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    /**
     * Das Objekt welches den Typ Actionable erfüllt.
     * Das bedeutet, dass es AllowedActions mit einem Status und möglicherweise Constraints besitzt.
     */
    actionable: {
      type: Object as PropType<Actionable>,
    },
    /**
     * Name der relevanten Action.
     */
    action: {
      type: String,
      required: false,
    },
    /**
     * Schränkt auf einen allowedValue ein
     */
    allowedValue: {
      type: [String, Object],
      required: false,
    },
  },
});
