function isValidMediaType(mediatype: string | undefined) {
  return mediatype && mediatype.length > 0 && mediatype.includes("/");
}

export default function GetFileMetaData(
  file: File,
  { noTitle }: { noTitle: true }
): FileMetaData;

export default function GetFileMetaData(file: File): FileMetaDataWithTitle;

export default function GetFileMetaData(file: File, { noTitle }: any = {}) {
  const result: FileMetaData & { title?: string } = {
    filename: file.name || "",
    fileSize: file.size || 0,
    mediatype: isValidMediaType(file.type)
      ? file.type
      : "application/octet-stream",
  };
  if (!noTitle) {
    result.title = file.name || "";
    if (result.title.lastIndexOf(".") > 0) {
      // falls mit '.' anfängt, nehm einfach auch den ganzen Title
      result.title = result.title.substr(0, result.title.lastIndexOf("."));
    }
  }
  return result;
}

export function isFile(file: DataTransferItem) {
  if (file.kind == "file") {
    const fileObj = file.getAsFile()!;
    if (file.type.length == 0 && fileObj.size % 4096 == 0) {
      //Ordner sind immer ein Multiple von 4096 Byte groß oder 0.
      //Wenn es eine Datei ist, die zufälligerweiße auch genauso groß ist und keinen
      //well-known mime-type hat, haben wir hier potentiell pech.
      return false;
    }
    return true;
  }
  return false;
}

export type FileMetaData = {
  filename: string;
  fileSize: number;
  mediatype: string;
};

export type FileMetaDataWithTitle = FileMetaData & { title: string };
