import { Module } from "vuex";
import { ParticipantStoreState } from "@/store/modules/participant.types";
import {
  CREATE_PARTICIPANT,
  MARK_PARTICIPANT_ADMISSION_STATUS,
  UPDATE_CURRENT_PARTICIPANT,
  UPDATE_PARTICIPANTS,
  EDIT_PARTICIPANT,
  DELETE_PARTICIPANT,
  MARK_PARTICIPANT_QUALIFICATION_STATUS,
  name,
} from "@/store/modules/participant.actions";
import {
  SET_ALL_PARTICIPANTS,
  SET_CURRENT_PARTICIPANT,
} from "@/store/modules/participant.mutations";
import { Participant, participantSorter } from "@/model/Participant";
import Client from "@/utils/EvoClient";
import {
  DeleteParticipantDto,
  EditParticipantDto,
  MarkParticipantStatusDto,
  CreateParticipantDto,
  MarkParticipantQualificationStatusDto,
} from "@/utils/EvoClient/dto";
import PaginationMixin from "@/store/mixins/pagination";
import { Paginationable, RootState } from "../types";
import i18n from "@/i18n";
import StoreNotificationService from "@/service/StoreNotificationService";
import router from "@/router";
import { ROUTE_PARTICIPANT_LIST } from "@/router/routes";
import FilterMixin from "@/store/mixins/filter";
import { supportedFilter } from "@/utils/EvoClient/query/GetAllParticipants";

const store = {
  state: {
    all: [],
    current: null,
  } as ParticipantStoreState,
  actions: {
    async [CREATE_PARTICIPANT]({ dispatch }, args: CreateParticipantDto) {
      await Client.CreateParticipant(args);
      await dispatch(UPDATE_PARTICIPANTS);
    },
    async [EDIT_PARTICIPANT]({ dispatch }, args: EditParticipantDto) {
      await Client.EditParticipant(args);
      await Promise.all([
        dispatch(UPDATE_CURRENT_PARTICIPANT, args.participantId),
        dispatch(UPDATE_PARTICIPANTS),
      ]);
    },
    async [DELETE_PARTICIPANT](_, args: DeleteParticipantDto) {
      await Client.DeleteParticipant(args);
      const message = i18n.global.t(
        "award-procedures.details.notifications.participant-deleted"
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "participant-list",
        message
      );
      await router.push({ name: ROUTE_PARTICIPANT_LIST });
    },
    //Konvention: Private Action, daher nicht mit [] Action
    async participants_loadParticipants({ getters }) {
      return Client.GetAllParticipants({
        pagination: getters[`${name}:pagination`],
        filter: [...getters[`${name}:filter`]],
        includes: [...getters[`${name}:includes`]],
      });
    },

    //Konvention: Private Action, daher nicht mit [] Action
    async participants_loadParticipantsIncludedOffers({ getters }) {
      return Client.GetAllParticipants({
        pagination: getters[`${name}:pagination`],
        filter: [
          supportedFilter.callsForTenders,
          supportedFilter["offers::callForTenders"],
        ],
        includes: ["offers"],
      });
    },
    //Konvention: Private Action, daher nicht mit [] Action
    async participants_loadParticipantsIncludeRequestsForParticipation({
      getters,
    }) {
      return Client.GetAllParticipants({
        pagination: getters[`${name}:pagination`],
        filter: [...getters[`${name}:filter`]],
        includes: ["requestsForParticipation"],
      });
    },

    async [UPDATE_PARTICIPANTS](
      { commit, dispatch },
      {
        filterGroup,
        includeOffers,
        includeRequestsForParticipation,
        clearOverrides = true,
      } = {
        clearOverrides: true,
      }
    ) {
      let participants;
      if (filterGroup) {
        await dispatch(`${name}:setFilter`, {
          filter: filterGroup.defaultFilter,
          filterGroup: filterGroup.name,
          clearOverrides: clearOverrides,
        });
      }
      if (includeOffers) {
        participants = await dispatch(
          "participants_loadParticipantsIncludedOffers"
        );
      } else if (includeRequestsForParticipation) {
        participants = await dispatch(
          "participants_loadParticipantsIncludeRequestsForParticipation"
        );
      } else {
        participants = await dispatch("participants_loadParticipants");
      }
      commit(SET_ALL_PARTICIPANTS, participants);
    },

    async [MARK_PARTICIPANT_ADMISSION_STATUS](
      { dispatch },
      participantDto: MarkParticipantStatusDto
    ) {
      await Client.MarkParticipantStatus(participantDto);
      await Promise.all([
        dispatch(UPDATE_CURRENT_PARTICIPANT, participantDto.participantId),
      ]);
    },
    async [MARK_PARTICIPANT_QUALIFICATION_STATUS](
      { dispatch },
      participantDto: MarkParticipantQualificationStatusDto
    ) {
      await Client.MarkParticipantQualificationStatus(participantDto);
      await Promise.all([
        dispatch(UPDATE_CURRENT_PARTICIPANT, participantDto.participantId),
      ]);
    },
    async [UPDATE_CURRENT_PARTICIPANT]({ commit }, participantId: string) {
      let participant = null;
      if (participantId != null) {
        participant = await Client.GetParticipant(participantId);
      }
      commit(SET_CURRENT_PARTICIPANT, participant);
    },
  },
  mutations: {
    [SET_ALL_PARTICIPANTS](state, particpants: Participant[]) {
      state.all = particpants;
    },
    [SET_CURRENT_PARTICIPANT](state, particpant: Participant) {
      state.current = particpant;
    },
  },
} as Module<ParticipantStoreState, RootState>;

const paginated = PaginationMixin<ParticipantStoreState>(store, {
  actionWhichUpdatesAll: UPDATE_PARTICIPANTS,
  mutationWhichUpdatesAll: SET_ALL_PARTICIPANTS,
  prefix: name,
  defaultSort: participantSorter[0].value,
});

export default FilterMixin<ParticipantStoreState & Paginationable>(paginated, {
  prefix: name,
});
