import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"justify-content":"space-around","align-items":"center","height":"100%","width":"100%"}
}
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = {
  class: "text-center mt-12",
  style: {"max-width":"30em","margin":"auto"}
}
const _hoisted_4 = { class: "text-h4 text-error-600" }
const _hoisted_5 = { class: "text-body-1 mt-4 text-b_grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_svg = _resolveComponent("evonum-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_main_three_columns = _resolveComponent("main-three-columns")!

  return (_openBlock(), _createBlock(_component_main_three_columns, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_evonum_svg, {
            src: "/warning.svg",
            "data-cy": "error-noroute-seite",
            style: {"margin":"auto","display":"block","width":"min(100vw, 400px)","min-height":"200px"},
            aspectWidth: 650,
            aspectHeight: 450,
            alt: ""
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("error-pages.noroute.headline")), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("error-pages.noroute.text")), 1),
            _createVNode(_component_router_link, {
              to: _ctx.routes.dashboard
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("error-pages.noroute.backToStartpage")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}