import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "base-notify-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_notification = _resolveComponent("evonum-notification")!
  const _component_base_error_boundary_error_list = _resolveComponent("base-error-boundary-error-list")!
  const _component_base_notification_from_store = _resolveComponent("base-notification-from-store")!

  return (_ctx.notification || _ctx.hasErrors || _ctx.notificationFromStore)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.actionNotification)
          ? (_openBlock(), _createBlock(_component_evonum_notification, {
              notification: _ctx.actionNotification,
              key: _ctx.actionNotification.message,
              dismisser: _ctx.dismisser
            }, null, 8, ["notification", "dismisser"]))
          : _createCommentVNode("", true),
        (!_ctx.noErrorList && _ctx.errorBoundaryId != '')
          ? (_openBlock(), _createBlock(_component_base_error_boundary_error_list, {
              key: 1,
              style: {"margin-bottom":"16px !important"}
            }))
          : _createCommentVNode("", true),
        (_ctx.notificationFromStore && _ctx.notificationSlotId)
          ? (_openBlock(), _createBlock(_component_base_notification_from_store, {
              key: 2,
              notificationSlotId: _ctx.notificationSlotId
            }, null, 8, ["notificationSlotId"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}