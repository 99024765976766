import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Participant } from "@/model/Participant";

// prettier-ignore
const ENDPOINT = (participantId: string) => `participants/${participantId}`;

export default async function getParticipant(
  participantId: string
): Promise<Participant> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(participantId))
      .addIncludes("conversation")
      .addFields("conversation", "actions")
      .toString()
  );
  return deserializer.deserialize(result.data, {
    relations: ["awardProcedure", "conversation"],
  });
}
