import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"display":"inline","margin-right":"4px"},
  class: "text-caption"
}
const _hoisted_2 = {
  class: "text-body-2",
  style: {"margin-right":"4px"}
}
const _hoisted_3 = {
  key: 0,
  class: "font-weight-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_button_icon = _resolveComponent("evonum-button-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    color: "var(--evo-error)",
    variant: "flat",
    style: _normalizeStyle([_ctx.dynamicStyle, {"padding-right":"50px"}]),
    role: _ctx.silent ? 'none' : 'alert',
    class: "text-subtitle-2 mb-0 text-grey-75",
    dense: _ctx.$vuetify.display.mobile,
    dark: true,
    icon: "mdi-alert",
    modelValue: _ctx.hasError,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasError) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: {"position":"relative"},
        "data-cy": "partial-global-error-interior",
        class: _normalizeClass(_ctx.dynamicClass)
      }, [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("common.errors.headlineError")), 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.error.message), 1),
        (_ctx.error.code)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(_ctx.error.code) + ")", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_evonum_button_icon, {
          style: {"position":"absolute","right":"0px","top":"50%","display":"inline","z-index":"300","transform":"translateY(-50%)"},
          onKeydown: _withKeys(_ctx.focusDialogIfOpened, ["tab"]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hasError = false)),
          id: _ctx.alertId || undefined,
          small: "",
          icon: "mdi-close-circle-outline"
        }, null, 8, ["onKeydown", "id"])
      ], 2)
    ]),
    _: 1
  }, 8, ["style", "role", "dense", "modelValue"]))
}