import { AwardingClient } from "@/utils/EvoClient";
import deserializer, {
  unresolvingDeserializer,
} from "@/utils/EvoClient/deserializer";
import { AwardProcedure } from "@/model/AwardProcedure";
import { calculateFullNameOfRegulation } from "@/utils/functions";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (awardProcedureId: string) => `award-procedures/${awardProcedureId}`;
export const POSSIBLE_INCLUDES =
  "procedureType,regulation,typeOfService,specification";

export default async function getVergabeverfahren(
  id: string,
  includeFromBackend = false
): Promise<AwardProcedure> {
  let deserialize: AwardProcedure;
  if (includeFromBackend) {
    deserialize = await resolveFromBackend(id);
  } else {
    deserialize = await resolveFromLocal(id);
  }
  if (deserialize.regulation) {
    deserialize.regulation.vollerName = calculateFullNameOfRegulation(
      deserialize.regulation
    );
  }
  return deserialize;
}

async function resolveFromBackend(
  awardProcedureId: string
): Promise<AwardProcedure> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(awardProcedureId))
      .addIncludes(POSSIBLE_INCLUDES)
      .toString()
  );
  return unresolvingDeserializer.deserialize(result.data);
}

async function resolveFromLocal(
  awardProcedureId: string
): Promise<AwardProcedure> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(awardProcedureId)).toString()
  );
  return deserializer.deserialize(result.data);
}
