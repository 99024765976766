import { App } from "vue";
import commonDummyComponent from "./components/common-dummy-component.vue";

export default async function (app: App) {
  let simulationComponent: any;
  if (window.aiwe.APP_SIMULATION) {
    simulationComponent = await import(
      /* webpackChunkName: "simulation-chunk" */ "./components/simulation.vue"
    );
  } else {
    simulationComponent = commonDummyComponent;
  }
  app.component(
    // eslint-disable-next-line vue/multi-word-component-names
    "simulation",
    simulationComponent.default || simulationComponent
  );
}
