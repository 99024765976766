import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = {
  class: "text-center mt-12",
  style: {"max-width":"35em","margin":"auto"},
  "data-cy": "partial-router-view-error-details"
}
const _hoisted_3 = { class: "text-h4 text-error-600" }
const _hoisted_4 = { class: "text-body-1 mt-4 text-b_grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_svg = _resolveComponent("evonum-svg")!
  const _component_common_content_wrapper = _resolveComponent("common-content-wrapper")!

  return (_openBlock(), _createBlock(_component_common_content_wrapper, { backTo: _ctx.parent }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_evonum_svg, {
          src: "/warning.svg",
          "data-cy": "partial-router-view-error",
          center: "",
          style: {"margin":"auto","display":"block","width":"min(100vw, 400px)","min-height":"200px"},
          aspectWidth: 650,
          aspectHeight: 480
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.detail), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["backTo"]))
}