import { Lockable } from "@/model/Lockable";
import { Actionable } from "@/model/Actionable";
import { Participant } from "./Participant";
import { Message } from "./Message";
import { Sorter } from "@/model/Sorter";
import i18n from "@/i18n";
import { FilterGroup } from "@/model/FilterGroup";
import { Notificationable } from "./Notificationable";
import { Attachment } from "./Attachment";
import {
  supportedFilter,
  defaultFilter,
} from "@/utils/EvoClient/query/GetAllConversations";

export type Conversation = {
  id: string;
  empty: boolean;
  name: string;
  participant: Participant;
  hasDraft: boolean;
  lastMessageSentAt: string;
  numberOfNewMessages: number;
  draft?: {
    subject?: string;
    text?: string;
    attachments: Attachment[];
  };
  lastSubject: string;
  messages: Message[];
} & Lockable &
  Notificationable &
  Actionable;

const SORT_OPTIONS = [
  i18n.global.t("common.sortOptions.dateTimeDesc") as any,
  i18n.global.t("common.sortOptions.dateTimeAsc") as any,
  i18n.global.t("common.sortOptions.participantDesc") as any,
  i18n.global.t("common.sortOptions.participantAsc") as any,
];

export const conversationSorter = [
  {
    title: SORT_OPTIONS[0],
    value: "-lastMessageSentAt",
  },
  {
    title: SORT_OPTIONS[1],
    value: "lastMessageSentAt",
  },
  {
    title: SORT_OPTIONS[2],
    value: "participant.name",
  },
  {
    title: SORT_OPTIONS[3],
    value: "-participant.name",
  },
] as Sorter;

export const filter: FilterGroup = {
  name: "KONVERSATIONEN",
  get defaultFilter() {
    return this.filters[0];
  },
  filters: [
    {
      key: "CONVERSATION_ALL",
      label: i18n.global.t("conversations.list.filters.all") as string,
      value: defaultFilter,
    },
    {
      key: "CONVERSATION_UNREAD",
      label: i18n.global.t("conversations.list.filters.unread") as string,
      value: [supportedFilter["messages.read"], supportedFilter.awardProcedure],
    },
    {
      key: "CONVERSATION_DRAFT",
      label: i18n.global.t("conversations.list.filters.has-draft") as string,
      value: [supportedFilter.hasDraft, supportedFilter.awardProcedure],
    },
  ],
};
