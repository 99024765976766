export const UPDATE_SECTIONS =
  "Aktualisiert die Abschnitte der aktuellen Lieferung";
export const CREATE_SECTION =
  "Fügt einen Abschnitt zur aktuellen Lieferung hinzu";
export const REMOVE_SECTION_FROM_POSITION =
  "Entfernt temporären Abschnitt  aus der aktuellen Lieferung";
export const DELETE_SECTION =
  "Entfernt einen Abschnitt aus der aktuellen Lieferung";
export const RENAME_SECTION =
  "Ändert den Titel eines Abschnitts aus der aktuellen Lieferung";
export const MOVE_SECTION =
  "Einen Abschnitt innerhalb der aktuellen Lieferung verschieben";
export const RESET_SECTION_STORE = "Entfernt alle Daten aus dem Section-Store";
