export const LOGIN = "Anmelden";
export const REFRESH_TOKEN = "Token erneuern";
export const LOGOUT = "Abmelden";

export const REGISTER_WITH_DATA =
  "Benutzer für Nutzung in neuer Vergabestelle registrieren";
export const REGISTER_WITH_CODE =
  "Benutzer für Nutzung in vorhandener Vergabestelle registrieren";
export const EDIT_USER = "Daten des Benutzers editieren";
export const INVITE_USER = "Benutzer zu einer Vergabestelle einladen";
export const EDIT_AUTHORITY = "Daten der Vergabestelle editieren";
export const GET_USER_AS_CURRENT = "Daten des aktuellen Benutzers laden";
export const GET_AUTHORITY_AS_CURRENT =
  "Daten der aktuellen Vergabestelle laden";
export const GET_UNASSIGNED_USERS_OF_AUTHORITY =
  "Benutzer der Vergabestelle laden";
export const GET_ROLES_OF_CONTEXT_TYPE = "Rollen des Kontexttyps laden";
export const GET_CURRENT_USERS_OF_CONTEXT = "Benutzer des Kontextes laden";
export const GET_CONTEXT = "Kontext laden";
export const GRANT_USER_ROLE = "Gebe Mitarbeiter eine Rolle";

export const LOAD_PERMISSIONS_VIEW =
  "Läd alle nötigen Ressourcen für die Permissionsseite";
export const REMOVE_USER_FROM_CONTEXT = "Entfernt die Rolle eines Mitarbeiters";
export const JOIN_ORGANISATION = "Trete einer weiteren Vergabestelle bei";
