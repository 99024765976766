import { Conversation } from "@/model/Conversation";
import { OptionType } from "@/model/FilterGroup";
import { CommonFilters } from "../query";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "conversations";

export const supportedFilter = {
  awardProcedure: CommonFilters.awardProcedure,
  nonEmpty: {
    field: "nonEmpty",
    type: OptionType.FILTER,
  },
  "messages.read": {
    field: "messages.read",
    value: "false",
    type: OptionType.FILTER,
  },
  hasDraft: {
    field: "hasDraft",
    type: OptionType.FILTER,
  },
} as const;

export const defaultFilter = [
  supportedFilter.nonEmpty,
  supportedFilter.awardProcedure,
];

export type PossibleConversationsIncludes = ["awardProcedure", "participant"];
export type PossibleConversationsSorts = [
  "lastMessageSentAt",
  "-lastMessageSentAt",
  "participant.name",
  "-participant.name",
];

export default ListQueryFunctionGenerator<
  Conversation[],
  (keyof typeof supportedFilter)[],
  PossibleConversationsIncludes,
  PossibleConversationsSorts
>(ENDPOINT, { defaultFilter });
