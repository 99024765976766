import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (callForCompetitionId: string) => `calls-for-competition/${callForCompetitionId}`;

export default async function getCallForCompetitionLock(
  callForCompetitionId: string
): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(callForCompetitionId))
      .addLockQuery("callForCompetition")
      .toString()
  );
  return deserializer.deserialize(result.data);
}
