import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_text_field = _resolveComponent("evonum-text-field")!
  const _component_base_dialog_card_confirmation = _resolveComponent("base-dialog-card_confirmation")!

  return (_openBlock(), _createBlock(_component_base_dialog_card_confirmation, _mergeProps({
    confirmButtonLabel: _ctx.confirmButtonLabel,
    heading: _ctx.heading,
    close: _ctx._close,
    onConfirmed: _ctx.confirmed
  }, _ctx.$attrs, {
    isValid: _ctx.isValid,
    "data-cy": "base-dialog-card-rename",
    optionalComponents: _ctx.optionalComponents
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_evonum_text_field, {
        "no-bottom-padding": "",
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        label: _ctx.textFieldLabel,
        "data-cy": "base-dialog-card-rename-text",
        rules: _ctx.rules.title,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.isValid && _ctx.confirmed() && _ctx._close()), ["enter"])),
        "data-input-focus-target": ""
      }, null, 8, ["modelValue", "label", "rules"])
    ]),
    _: 3
  }, 16, ["confirmButtonLabel", "heading", "close", "onConfirmed", "isValid", "optionalComponents"]))
}