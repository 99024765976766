import { RouteRecordRaw } from "vue-router";
import {
  ROUTE_AUTHORITIES_DETAILS,
  ROUTE_AUTHORITIES_INVITATION,
  ROUTE_AUTHORITIES_SELECTION,
} from "@/router/routes";
import {
  GET_AUTHORITY_AS_CURRENT,
  GET_USER_AS_CURRENT,
} from "@/store/modules/users.actions";
import { UPDATE_COUNTRIES } from "@/store/modules/common.actions";
import LoadingService from "@/service/LoadingService";
import i18n from "@/i18n";

const AuthorityFrame = () =>
  import(
    /* webpackChunkName: "users-chunk" */ "@/views/authorities/authority-frame.vue"
  );

const AuthorityDetails = () =>
  import(
    /* webpackChunkName: "users-chunk" */ "@/views/authorities/authority-details.vue"
  );

const AuthoritySelection = () =>
  import(
    /* webpackChunkName: "users-chunk" */ "@/views/authorities/authority-selection.vue"
  );

const Invitation = () =>
  import(
    /* webpackChunkName: "users-chunk" */ "@/views/authorities/invitation.vue"
  );

export default {
  path: "/authorities",
  name: "Authority",
  beforeEnter(to, from, next) {
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.authorities"),
  },
  component: AuthorityFrame,
  children: [
    {
      path: "selection",
      name: ROUTE_AUTHORITIES_SELECTION,
      component: AuthoritySelection,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(GET_USER_AS_CURRENT);
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.change-authority"),
        notRequireAuthority: true,
      },
    },
    {
      path: ":authorityId/invitations",
      name: ROUTE_AUTHORITIES_INVITATION,
      component: Invitation,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(GET_AUTHORITY_AS_CURRENT, {
          authorityId: to.params.authorityId,
        });
        next();
      },
    },
    {
      path: ":authorityId",
      name: ROUTE_AUTHORITIES_DETAILS,
      component: AuthorityDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(GET_AUTHORITY_AS_CURRENT, {
          authorityId: to.params.authorityId,
        });
        LoadingService.addLoadAction(UPDATE_COUNTRIES);
        next();
      },
    },
  ],
} as RouteRecordRaw;
