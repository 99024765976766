
import { PropType, defineComponent } from "vue";
import ViewportMixin from "@/mixins/viewport";
import LabelableMixin from "@/mixins/labelable";

export enum VARIANT {
  DEFAULT = "DEFAULT",
  EMPHASIZE = "EMPHASIZE",
}

/**
 * Konfigurierbarer initialer Screen mit Bild, einer Überschrift und einem Beschreibungstext.
 * Das Bild nutzt bei **DESKTOP** die angegebene Breite. Bei **MOBILE** wird es automatisch
 * in der Höhe unter Berücksichtigung des Seitenverhältnisses  auf 150px beschränkt. Analog
 * wird bei **SMALEST** die Höhe auf 75px beschränkt.
 */
export default defineComponent({
  name: "base-initial-screen",
  mixins: [ViewportMixin, LabelableMixin],
  props: {
    /**
     * Bild bestehend URL und Standardbreite.
     */
    heroImg: {
      type: Object,
      default: function () {
        return {
          url: undefined,
          width: "250px",
        };
      },
    },
    noInitialFocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Darstellungsvariante
     */
    variant: {
      type: String as PropType<keyof typeof VARIANT>,
      default: VARIANT.DEFAULT,
    },
  },
  computed: {
    imageStyle(): Record<string, string> {
      return {
        maxWidth: this.heroImg.width ? this.heroImg.width : "250px",
      };
    },
    textClasses(): string {
      switch (this.variant) {
        case VARIANT.EMPHASIZE:
          return "text-b_grey-700 text-body-2";
        default:
          return "text-body-2 text-grey-700";
      }
    },
    headingClasses(): string {
      switch (this.variant) {
        case VARIANT.EMPHASIZE:
          return "text-h4 text-b_grey-700";
        default:
          return "text-h4 text-grey-700";
      }
    },
  },
});
