import { RootState } from "@/store/types";
import { Module } from "vuex";
import Client from "@/utils/EvoClient";
import { ShipmentStoreState } from "./shipment.types";
import { Shipment } from "@/model/Shipment";
import { SET_ALL_SHIPMENTS, SET_CURRENT_SHIPMENT } from "./shipment.mutations";
import {
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  DELETE_SHIPMENT,
  UPDATE_CURRENT_SHIPMENT,
  UPDATE_ALL_SHIPMENTS,
  PUBLISH_SHIPMENT,
  CHECK_LOCK,
  UPDATE_LOCK,
  DISMISS_NOTIFICATION,
  ENABLE_SECTIONS,
  DISABLE_SECTIONS,
  RESET_SHIPMENT_STORE,
} from "./shipment.actions";
import {
  DismissShipmentNotificationDto,
  EditShipmentDto,
  DeleteShipmentDto,
  PublishShipmentDto,
  ShipmentIdable,
} from "@/utils/EvoClient/dto";
import { GET_AWARD_PROCEDURE_AS_CURRENT } from "./awardProcedure.actions";
import { UPDATE_TENDER_DOCUMENTS } from "@/store/modules/tenderDocument.actions";
import { Lock } from "@/model/Lock";
import { SET_LOCK } from "@/store/modules/shipment.mutations";
import { UPDATE_SECTIONS } from "@/store/modules/section.actions";
import i18n from "@/i18n";
import StoreNotificationService from "@/service/StoreNotificationService";
import router from "@/router";
import { ROUTE_SHIPMENT_LIST } from "@/router/routes";

type EnableSectionsArgs = {
  title: string;
} & ShipmentIdable;

const store = {
  state: {
    all: [],
    current: null,
  } as ShipmentStoreState,
  actions: {
    [RESET_SHIPMENT_STORE]({ commit }) {
      commit(SET_ALL_SHIPMENTS, []);
      commit(SET_CURRENT_SHIPMENT, null);
    },
    async [CREATE_SHIPMENT](
      { rootState, dispatch },
      { awardProcedureId, updateAwardProcedure = false }
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const id = await Client.CreateShipment({
        awardProcedureId,
      });
      if (updateAwardProcedure) {
        //createShipment Action hängt an Award Procedure
        await dispatch(GET_AWARD_PROCEDURE_AS_CURRENT, awardProcedureId);
      }
      await dispatch(UPDATE_ALL_SHIPMENTS, {
        awardProcedureId,
      });
      return id;
    },
    async [EDIT_SHIPMENT]({ dispatch }, args: EditShipmentDto) {
      await Client.EditShipment(args);
      await dispatch(UPDATE_CURRENT_SHIPMENT, { shipmentId: args.shipmentId });
    },
    async [DELETE_SHIPMENT](
      _,
      { id, shipmentTitle }: { id: string; shipmentTitle: string }
    ) {
      await Client.DeleteShipment({
        shipmentId: id,
      } as DeleteShipmentDto);
      const message = i18n.global.t(
        "award-procedures.details.notifications.shipment-deleted",
        { shipmentTitle: shipmentTitle }
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "shipment-list",
        message
      );
      await router.push({ name: ROUTE_SHIPMENT_LIST });
    },
    async [UPDATE_ALL_SHIPMENTS](
      { rootState, commit },
      { awardProcedureId } = {}
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;
      const shipments = await Client.GetAllShipments({
        filter: [{ field: "awardProcedure", value: awardProcedureId }],
      });
      commit(SET_ALL_SHIPMENTS, shipments);
    },
    async [UPDATE_CURRENT_SHIPMENT](
      { commit, dispatch, state },
      { updateTenderDocuments = true, shipmentId } = {
        updateTenderDocuments: true,
      }
    ) {
      shipmentId = shipmentId || state.current!.id;
      let shipment = null;
      if (shipmentId != null) {
        shipment = await Client.GetShipment(shipmentId);
      }
      commit(SET_CURRENT_SHIPMENT, shipment);
      if (shipmentId != null && updateTenderDocuments) {
        await dispatch(UPDATE_SECTIONS, shipmentId);
        await dispatch(UPDATE_TENDER_DOCUMENTS, { shipmentId });
      }
    },
    async [PUBLISH_SHIPMENT]({ dispatch }, shipmentId: string) {
      await Client.PublishShipment({
        shipmentId: shipmentId,
      } as PublishShipmentDto);
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetShipmentLock(id);
    },
    async [UPDATE_LOCK]({ commit }, lock: Lock) {
      commit(SET_LOCK, lock);
    },
    async [DISMISS_NOTIFICATION](_, shipmentId: string) {
      const result = await Client.DismissShipmentNotification({
        shipmentId,
      } as DismissShipmentNotificationDto);
      return result;
    },
    async [ENABLE_SECTIONS]({ dispatch }, data: EnableSectionsArgs) {
      await Client.EnableSections(data);
      dispatch(UPDATE_TENDER_DOCUMENTS, {
        updateTenderDocuments: true,
        updateShipment: true,
        updateSections: true,
        shipmentId: data.shipmentId,
      });
    },
    async [DISABLE_SECTIONS]({ dispatch }, { shipmentId }) {
      await Client.DisableSections({ shipmentId });
      dispatch(UPDATE_TENDER_DOCUMENTS, {
        updateTenderDocuments: true,
        updateShipment: true,
        updateSections: true,
        shipmentId: shipmentId,
      });
    },
  },
  mutations: {
    [SET_CURRENT_SHIPMENT](state, shipment: Shipment) {
      state.current = shipment;
    },
    [SET_ALL_SHIPMENTS](state, shipments: Shipment[]) {
      state.all = shipments;
    },
    [SET_LOCK](state, lock: Lock) {
      state["current"] = { ...state.current, ...lock } as any;
    },
  },
} as Module<ShipmentStoreState, RootState>;

export default store;
