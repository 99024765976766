export const RESET_SHIPMENT_STORE =
  "Entfernt alle Daten aus dem Shipment-Store";
export const CREATE_SHIPMENT =
  "Erzeugt eine Lieferung für die Auftragsunterlagen";
export const EDIT_SHIPMENT =
  "Editiert eine Lieferung für die Auftragsunterlagen";
export const DELETE_SHIPMENT = "Löschen eine Lieferung";
export const UPDATE_ALL_SHIPMENTS =
  "Aktualisiert alle Lieferungen der Auftragsunterlagen";
export const UPDATE_CURRENT_SHIPMENT =
  "Aktualisiert die aktuelle Lieferung der Auftragsunterlagen";
export const PUBLISH_SHIPMENT =
  "Versendet eine Lieferung für die Auftragsunterlagen";

export const CHECK_LOCK = "Prüfe ob das Shipment ein Lock hat";
export const UPDATE_LOCK = "Setze das Shipment Lock";

export const DISMISS_NOTIFICATION = "Shipment Notification bestätigen";

export const ENABLE_SECTIONS =
  "Aktiviert Abschnitte für eine Lieferung für die Auftragsunterlagen";
export const DISABLE_SECTIONS =
  "Deaktiviert Abschnitte für eine Lieferung für die Auftragsunterlagen";
