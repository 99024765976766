import EvoState from "../types";
import { Module } from "vuex";
import { LOADING_ARGS, LoadingState } from "@/store/modules/loading.types";
import {
  RESET_LOADING_FOR_ACTION,
  RESET_LOADING_META_FOR_ACTION,
  SET_LOADING_META_FOR_ACTION,
  START_LOADING_FOR_ACTION,
  STOP_LOADING_FOR_ACTION,
} from "@/store/modules/loading.actions";
import {
  UPDATE_LOADING,
  RESET_LOADING,
  UPDATE_LOADING_META,
  RESET_LOADING_META,
} from "@/store/modules/loading.mutations";

export default {
  state: {
    all: {},
    meta: {},
  } as LoadingState,
  actions: {
    async [START_LOADING_FOR_ACTION]({ commit }, id: string) {
      commit(UPDATE_LOADING, { id, value: true });
    },
    async [STOP_LOADING_FOR_ACTION]({ commit }, id: string) {
      commit(UPDATE_LOADING, { id, value: false });
    },
    async [RESET_LOADING_FOR_ACTION]({ commit }) {
      commit(RESET_LOADING);
    },
    [SET_LOADING_META_FOR_ACTION]({ commit }, { id, meta }) {
      commit(UPDATE_LOADING_META, { id, meta });
    },
    [RESET_LOADING_META_FOR_ACTION]({ commit }, id: string) {
      commit(RESET_LOADING_META, id);
    },
  },
  getters: {
    isAnyLoading(state) {
      return Object.keys(state.all).some((x) => state.all[x] == true) || false;
    },
  },
  mutations: {
    [UPDATE_LOADING](state, { id, value }: LOADING_ARGS) {
      state.all[id] = value;
    },
    [RESET_LOADING](state) {
      state.all = {};
    },
    [UPDATE_LOADING_META](state, { id, meta }) {
      state.meta[id] = Object.assign({}, state.meta[id], meta);
    },
    [RESET_LOADING_META](state, id) {
      delete state.meta[id];
    },
  },
} as Module<LoadingState, EvoState>;
