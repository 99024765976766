import { AwardingClient } from "@/utils/EvoClient";
import { DeleteSectionDto } from "../dto";

const ENDPOINT = "c/delete-section";

export default async function DeleteSection(
  dto: DeleteSectionDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
