import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-4" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4 text-subtitle-2"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dialog_card_confirmation = _resolveComponent("base-dialog-card_confirmation")!

  return (_openBlock(), _createBlock(_component_base_dialog_card_confirmation, _mergeProps({
    confirmButtonLabel: _ctx.$t('common.labels.forceChanges')
  }, _ctx.$attrs, {
    close: _ctx.close,
    action: _ctx.action,
    i18nDialogName: "force",
    optionalComponents: _ctx.optionalComponents,
    "data-cy": "partial-command-action-force-dialog-confirmation"
  }), {
    "post-default": _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogErrors, (error, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error.detail), 1))
        }), 128))
      ]),
      (_ctx.postListText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.postListText[0]), 1))
        : _createCommentVNode("", true),
      (_ctx.postListText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.postListText[1]), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["confirmButtonLabel", "close", "action", "optionalComponents"]))
}