import { RootState } from "@/store/types";
import { computed } from "vue";
import { useStore } from "vuex";

export function useRoles() {
  const store = useStore<RootState>();
  return {
    roles: computed(() => store.state.users.roles),
    context: computed(() => store.state.users.context),
    unassignedUsers: computed(() => store.state.users.unassignedUsers),
  };
}

export function useAuthorities() {
  const store = useStore<RootState>();
  return {
    currentAuthority: computed(() => store.state.users.authority),
    userAuthorities: computed(() => store.state.users.user?.authorities || []),
  };
}
