import { RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import LoadingService from "@/service/LoadingService";
import {
  UPDATE_CURRENT_CONVERSATION,
  UPDATE_CONVERSATIONS,
} from "@/store/modules/conversation.actions";
import Breadcrumbs from "@/router/breadcrumbs";
import {
  ROUTE_CONVERSATION_DETAILS,
  ROUTE_CONVERSATION_LIST,
} from "@/router/routes";

const Conversations = () =>
  import(
    /* webpackChunkName: "conversations-chunk" */ "@/views/conversations/conversation-frame.vue"
  );

const ConversationList = () =>
  import(
    /* webpackChunkName: "conversations-chunk" */ "@/views/conversations/conversation-list.vue"
  );

const ConversationDetails = () =>
  import(
    /* webpackChunkName: "conversations-chunk" */ "@/views/conversations/conversation-details.vue"
  );

export default {
  path: "conversations",
  component: Conversations,
  beforeEnter(to, from, next) {
    LoadingService.addLoadAction(UPDATE_CONVERSATIONS, {
      awardProcedureId: to.params.awardProcedureId,
    });
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.conversations"),
  },
  children: [
    {
      path: "",
      name: ROUTE_CONVERSATION_LIST,
      component: ConversationList,
      meta: {
        breadCrumbs: Breadcrumbs.Conversations,
        title: i18n.global.t("route-titles.conversations"),
      },
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CONVERSATIONS, {
          awardProcedureId: to.params.awardProcedureId,
        });
        next();
      },
    },
    {
      path: ":conversationId",
      name: ROUTE_CONVERSATION_DETAILS,
      component: ConversationDetails,
      beforeEnter(to, from, next) {
        LoadingService.addLoadAction(UPDATE_CURRENT_CONVERSATION, {
          conversationId: to.params.conversationId,
        });
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.Conversations,
        title: i18n.global.t("route-titles.history"),
        parent: ROUTE_CONVERSATION_LIST,
      },
    },
  ],
} as RouteRecordRaw;
