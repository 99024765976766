import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-cy"]
const _hoisted_2 = {
  "data-cy": "base-coach-mark-action-message",
  class: "text-body-2"
}
const _hoisted_3 = { class: "base-coach-mark__errors mt-3 ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_coach_mark = _resolveComponent("base-coach-mark")!

  return (_ctx.hasAction && _ctx.hasConstraints)
    ? (_openBlock(), _createBlock(_component_base_coach_mark, {
        key: 0,
        ariaLabel: 
      _ctx.$t('aria.coachMarks.label', {
        action: _ctx.$t(`common.actions.${_ctx.action}${_ctx.i18nVariant}.longTitle`),
      })
    ,
        announceContent: _ctx.announceContent,
        heading: _ctx.$t(`common.actions.${_ctx.action}${_ctx.i18nVariant}.longTitle`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            "data-cy": `coachmark-${_ctx.action}${_ctx.i18nVariant}`
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(`common.actions.${_ctx.action}${_ctx.i18nVariant}.coachmarkHint`)), 1),
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActionConstraints, (constraint, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "text-body-2 text-b_grey-700",
                  key: index
                }, _toDisplayString(constraint.title), 1))
              }), 128))
            ])
          ], 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["ariaLabel", "announceContent", "heading"]))
    : _createCommentVNode("", true)
}