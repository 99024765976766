import { AwardingClient } from "@/utils/EvoClient";
import { CompleteCallForTendersDto } from "../dto";

const ENDPOINT = "c/complete-call-for-tenders";

export default async function completeCallForTenders(
  cftDTO: CompleteCallForTendersDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, cftDTO);
}
