import { AwardingClient } from "@/utils/EvoClient";
import { ValidateNoticeDto } from "../dto";

const ENDPOINT = "c/validate-notice";

export default async function validateNotice(
  dto: ValidateNoticeDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
