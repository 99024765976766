import { RequestInterceptor } from "@/utils/EvoClient/interceptors/types";
import store from "@/store";
import { RawAxiosRequestHeaders } from "axios";

export default {
  intercept(request: { headers?: RawAxiosRequestHeaders }) {
    if (store.state.users.tokenData) {
      if (!request.headers) {
        request.headers = {};
      }
      request.headers["Authorization"] = "Bearer " + window.keycloak.token;
      if (store.state.users.authority?.id) {
        request.headers["AI-Authority-Id"] = store.state.users.authority.id;
      }
    } else {
      if (request.headers) {
        delete request.headers["Authorization"];
        delete request.headers["AI-Authority-Id"];
      }
    }
  },
} as RequestInterceptor;
