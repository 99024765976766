import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_button_icon = _resolveComponent("evonum-button-icon")!
  const _component_evonum_card = _resolveComponent("evonum-card")!
  const _component_evonum_keyboard_trap = _resolveComponent("evonum-keyboard-trap")!
  const _component_evonum_icon = _resolveComponent("evonum-icon")!
  const _component_evonum_button_chip = _resolveComponent("evonum-button-chip")!
  const _component_common_menu_popover = _resolveComponent("common-menu-popover")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex",
    id: _ctx.attachId
  }, [
    _createVNode(_component_common_menu_popover, {
      sheetAriaLabel: _ctx.$t('common.list.filter'),
      left: false,
      contentId: _ctx.contentId,
      "focus-first": "",
      down: ""
    }, {
      content: _withCtx(({ on, close }) => [
        _createVNode(_component_evonum_keyboard_trap, null, {
          default: _withCtx(() => [
            _createVNode(_component_evonum_card, _mergeProps({
              "no-outer-margin": "",
              "no-content-padding": "",
              style: [{
              width: _ctx.isMobile ? undefined : '320px',
            }, {"--evonum-card-content-top-margin":"20px","overflow":"hidden"}],
              heading: _ctx.heading,
              transparentBackground: _ctx.isMobile,
              "no-border": "",
              class: "partial-card-list-filters__content",
              "data-cy": "partial-card-list-filters-content",
              headingLevel: 3,
              headingClass: "text-h6"
            }, _toHandlers(on)), {
              actions: _withCtx(() => [
                _createVNode(_component_evonum_button_icon, {
                  icon: "mdi-filter-off-outline",
                  class: "ml-4",
                  "data-cy": "partial-card-list-filters-reset-button",
                  "aria-label": _ctx.$t('common.list.filter-reset'),
                  onClick: _ctx.resetFilter,
                  disabled: _ctx.isDefaultFilterSet,
                  style: {"color":"var(--b_grey-700)"}
                }, null, 8, ["aria-label", "onClick", "disabled"]),
                _createVNode(_component_evonum_button_icon, {
                  icon: "mdi-close",
                  onClick: close,
                  "data-cy": "partial-card-list-filters-close-button",
                  "aria-label": _ctx.$t('common.labels.close'),
                  style: {"color":"var(--b_grey-700)"}
                }, null, 8, ["onClick", "aria-label"])
              ]),
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {
                  setFilter: _ctx.setFilter,
                  resetFilter: _ctx.resetFilter,
                  isMobile: _ctx.isExtraSmall,
                  filterKey: _ctx.currentFilterKey
                })
              ]),
              _: 2
            }, 1040, ["style", "heading", "transparentBackground"])
          ]),
          _: 2
        }, 1024)
      ]),
      activator: _withCtx(({ props, buttonId, isActive }) => [
        _createVNode(_component_evonum_button_chip, _mergeProps(props, {
          "data-cy": "partial-card-list-filters-button",
          filled: !_ctx.isDefaultFilterSet,
          color: _ctx.isDefaultFilterSet ? undefined : 'var(--evo-interactive)',
          dark: !_ctx.isDefaultFilterSet,
          outlined: _ctx.isDefaultFilterSet,
          "aria-label": _ctx.ariaLabel,
          disabled: _ctx.disabled,
          id: buttonId
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "label", {
              currentFilter: _ctx.currentFilter,
              isDefaultFilterSet: _ctx.isDefaultFilterSet
            }, () => [
              _createTextVNode(_toDisplayString(_ctx.currentFilter.label), 1)
            ]),
            _createVNode(_component_evonum_icon, {
              xSmall: "",
              class: "ml-2",
              style: {"vertical-align":"middle"},
              icon: isActive ? 'chevronUp' : 'chevronDown'
            }, null, 8, ["icon"])
          ]),
          _: 2
        }, 1040, ["filled", "color", "dark", "outlined", "aria-label", "disabled", "id"])
      ]),
      _: 3
    }, 8, ["sheetAriaLabel", "contentId"])
  ], 8, _hoisted_1))
}