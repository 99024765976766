import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Lock } from "@/model/Lock";
import UrlBuilder from "../urlBuilder";

// prettier-ignore
const ENDPOINT = (noticeId: string) => `notices/${noticeId}`;

export default async function getNoticeLock(noticeId: string): Promise<Lock> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(noticeId)).addLockQuery("notice").toString()
  );
  return deserializer.deserialize(result.data);
}
