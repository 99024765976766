import { AwardingClient } from "@/utils/EvoClient";
import { DismissAwardUnitNotificationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-award-unit-notification";

export default async function dismissAwardUnitNotification(
  dto: DismissAwardUnitNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
