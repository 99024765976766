
import CommonContentWrapper from "@/components/common-content-wrapper.vue";
import { defineComponent } from "vue";
import { PrintableError } from "@/utils/errors";
import { PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  name: "partial-router-view-error",
  components: {
    CommonContentWrapper,
  },
  props: {
    errorTitle: String,
    errorDetail: String,
    errors: {
      type: Array as PropType<PrintableError[]>,
      default: () => [],
    },
  },
  computed: {
    parent(): RouteLocationRaw {
      if (this.$route.meta?.parent) {
        return { name: this.$route.meta?.parent as any };
      } else {
        let path = this.$route.path;
        if (path.endsWith("/")) {
          path = path.substring(0, path.length - 1);
        }
        path = path.substring(0, path.lastIndexOf("/"));
        return { path };
      }
    },
    title(): string {
      if (this.errorTitle) {
        return this.errorTitle;
      } else {
        return (
          this.errors[0].title ||
          (this.$t("error-pages.404.headline") as string)
        );
      }
    },
    detail(): string {
      if (this.errorDetail) {
        return this.errorDetail;
      } else {
        return (
          this.errors[0].detail || (this.$t("error-pages.404.text") as string)
        );
      }
    },
  },
});
