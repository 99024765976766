import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import UrlBuilder from "../urlBuilder";
import { Notice } from "@/model/Notice";

// prettier-ignore
const ENDPOINT = (noticeId: string) => `notices/${noticeId}`;

export default async function getNotice(noticeId: string): Promise<Notice> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(noticeId)).toString()
  );
  return deserializer.deserialize(result.data);
}
