import { App } from "vue";

const requireComponents = require.context("./assets/icons", false, /.svg$/);

export default function registerComponents(app: App) {
  requireComponents.keys().forEach((fileName) => {
    // Get the component config
    const componentConfig = requireComponents(fileName);
    // Get the PascalCase version of the component name
    let componentName = fileName.replace("./", "").replace(".svg", "");
    componentName = componentName.replaceAll(/-[a-z]/g, (match) =>
      match.toUpperCase().replace("-", "")
    );
    // Globally register the component
    app.component(componentName, componentConfig.default || componentConfig);
  });
}
