import { SET_CURRENT_TABLE_OF_OFFERS } from "./tableOfOffers.mutations";
import {
  RESET_TABLE_OF_OFFERS_STORE,
  UPDATE_TABLE_OF_OFFERS,
} from "./tableOfOffers.actions";
import { RootState } from "@/store/types";
import { Module } from "vuex";
import Client from "@/utils/EvoClient";
import { TableOfOffersStoreState } from "./tableOfOffers.types";
import { TableOfOffers } from "@/model/TableOfOffers";
import { UPDATE_OFFERS } from "./offer.actions";

const store = {
  state: {
    all: [],
    current: null,
  } as TableOfOffersStoreState,
  actions: {
    [RESET_TABLE_OF_OFFERS_STORE]({ commit }) {
      commit(SET_CURRENT_TABLE_OF_OFFERS, null);
    },
    async [UPDATE_TABLE_OF_OFFERS](
      { rootState, commit, dispatch },
      { awardProcedureId } = { awardProcedureId: "" }
    ) {
      awardProcedureId =
        awardProcedureId || rootState.awardProcedures.current!.id;

      const tableOfOffers = await Client.GetTableOfOffers({
        params: {
          awardProcedureId,
        },
      });
      if (tableOfOffers) {
        commit(SET_CURRENT_TABLE_OF_OFFERS, tableOfOffers);
        await dispatch(UPDATE_OFFERS, {
          tableOfOffersId: tableOfOffers.id,
        });
      } else {
        commit(SET_CURRENT_TABLE_OF_OFFERS, null);
      }
      return tableOfOffers;
    },
  },
  mutations: {
    [SET_CURRENT_TABLE_OF_OFFERS](state, table: TableOfOffers) {
      state.current = table;
    },
  },
} as Module<TableOfOffersStoreState, RootState>;

export default store;
