
import { defineComponent } from "vue";
import TheHeader from "@/components/the-header.vue";
import ViewportMixin from "@/mixins/viewport";
import PartialGlobalErrorInterior from "@/components/partials/partial-global-error-interior.vue";
import PartialSkipNavigationLink from "@/components/partials/partial-skip-navigation-link.vue";

/**
 * Ein dreispaltiges Layout für alle Views ausserhalb einer Vergabe.
 */
export default defineComponent({
  name: "main-three-columns",
  mixins: [ViewportMixin],
  components: {
    TheHeader,
    PartialGlobalErrorInterior,
    PartialSkipNavigationLink,
  },
  data() {
    return {
      currentTimeout: undefined as undefined | number,
      showSplashscreen: false,
    };
  },
  computed: {
    hasLeftAside() {
      return this.$utils.hasSlotContent(this.$slots.leftaside);
    },
    hasRightAside() {
      return this.$utils.hasSlotContent(this.$slots.rightaside);
    },
    isEnoughSpaceForRightColumn(): boolean {
      return this.isDesktop;
    },
    isEnoughSpaceForLeftColumn(): boolean {
      return !this.isMobile;
    },
  },
});
