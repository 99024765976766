
import { defineComponent } from "vue";
import LoadingMixin from "@/mixins/loading";

export default defineComponent({
  name: "common-menu-popover",
  mixins: [LoadingMixin],
  emits: ["close"],
  props: {
    toggleScrollLock: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeMenu() {
      (this.$refs["menu"] as any).closeMenu();
    },
  },
});
