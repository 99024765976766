import { RegisterWithInvitationDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "users/c/register-with-invitation";

export default async function registerWithInvitation(
  registerDto: RegisterWithInvitationDto
): Promise<string> {
  return (await AwardingClient.post(ENDPOINT, registerDto)).data.id;
}
