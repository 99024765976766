import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Conversation } from "@/model/Conversation";

// prettier-ignore
const ENDPOINT = (conversationId: string) => `conversations/${conversationId}`;

export default async function getConversation(
  conversationId: string
): Promise<Conversation> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(conversationId))
      .addIncludes("participant")
      .addParams(`fields[participant]`, "name")
      .toString()
  );

  return await deserializer.deserialize(result.data);
}
