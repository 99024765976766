import { AwardingClient } from "@/utils/EvoClient";
import GetFileMetaData from "@/utils/EvoClient/unique/GetFileMetadata";
export type ProgressCallback = ({
  percent,
  loadedBytes,
  totalBytes,
}: {
  percent: number;
  loadedBytes: number;
  totalBytes: number;
}) => void;

export default async function uploadToFileService(
  file: File,
  endpoint: string,
  progressCb: ProgressCallback
): Promise<any> {
  const { mediatype } = GetFileMetaData(file, { noTitle: true });
  const tokenresult = await AwardingClient.get(endpoint);
  const uploadUri = tokenresult.data.uploadUri;
  const url = new URL(uploadUri);
  let fileServiceEndpoint = uploadUri.slice(url.origin.length + 1); //1 wegen führendem '/'
  if (fileServiceEndpoint.indexOf("awarding") != -1) {
    fileServiceEndpoint = fileServiceEndpoint.slice("awarding/".length);
  }
  return (
    await AwardingClient.post(fileServiceEndpoint, file, {
      headers: {
        "Content-Type": mediatype,
      },
      onUploadProgress: (
        progressEvent: any = { total: 1, loaded: 1, bytes: 1 }
      ) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) /
            (progressEvent.total ?? progressEvent.loaded)
        );
        progressCb({
          percent: percentCompleted,
          loadedBytes: progressEvent.loaded,
          totalBytes: progressEvent.total ?? progressEvent.loaded,
        });
      },
    })
  ).data.receiptToken;
}
