import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex",
  style: {"justify-content":"space-between","align-items":"center","height":"32px","margin-bottom":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_chip_state = _resolveComponent("common-chip-state")!
  const _component_evonum_icon = _resolveComponent("evonum-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_common_chip_state, {
      style: {"margin-right":"10px !important"},
      entity: { state: _ctx.from, locked: false }
    }, null, 8, ["entity"]),
    _createVNode(_component_evonum_icon, { style: {} }, {
      default: _withCtx(() => [
        _createTextVNode("chevronRight")
      ]),
      _: 1
    }),
    _createVNode(_component_common_chip_state, {
      style: {"margin-left":"10px !important"},
      entity: { state: _ctx.to, locked: false }
    }, null, 8, ["entity"])
  ]))
}