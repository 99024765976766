import { AwardingClient } from "@/utils/EvoClient";
import { Specification } from "@/model/Specification";
import { deserializeSpecification } from "@/utils/EvoClient/deserializer";
import UrlBuilder from "../urlBuilder";

const ENDPOINT = "specifications";

export default async function getSpecifications(): Promise<Specification[]> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT)
      .addIncludes("procedureType,regulation,typeOfService")
      .toString()
  );
  return deserializeSpecification(result.data);
}
