import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { AwardUnit } from "@/model/AwardUnit";

// prettier-ignore
const ENDPOINT = (awardUnitId: string) => `award-units/${awardUnitId}`;

export default async function getAwardUnit(
  awardUnitId: string
): Promise<AwardUnit> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(awardUnitId)).toString()
  );
  return deserializer.deserialize(result.data, {
    relations: ["awardProcedure"],
  });
}
