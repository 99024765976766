import { AwardingClient } from "@/utils/EvoClient";
import { PublishAwardProcedureDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/publish-award-procedure";

export default async function publishAwardProcedure(
  dto: PublishAwardProcedureDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
