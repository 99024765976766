import { AwardingClient } from "@/utils/EvoClient";
import { CreateAwardProcedureDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/create-award-procedure";

export default async function createAwardProcedure(
  capDTO: CreateAwardProcedureDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, capDTO)) as AxiosResponse).data
    .id;
}
