import i18n from "@/i18n";

const EXACT_MIMETYPE_TO_HUMAN_READABLE = {
  "application/x.gaeb90": i18n.global.t("common.fileFormats.gaeb90"),
  "application/x.gaeb2000": i18n.global.t("common.fileFormats.gaeb2000"),
  "application/x.gaebxml": i18n.global.t("common.fileFormats.gaebdaxml"),
  "application/x.aidf": i18n.global.t("common.fileFormats.aiDocument"),
  "application/pdf": i18n.global.t("common.fileFormats.pdfFile"),
  "application/msword": i18n.global.t("common.fileFormats.microsoftWord"),
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    i18n.global.t("common.fileFormats.microsoftWord"),
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    i18n.global.t("common.fileFormats.microsoftWord"),
  "application/vnd.ms-excel": i18n.global.t(
    "common.fileFormats.microsoftExcel"
  ),
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    i18n.global.t("common.fileFormats.microsoftExcel"),
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    i18n.global.t("common.fileFormats.microsoftExcel"),
  "application/vnd.ms-powerpoint": i18n.global.t(
    "common.fileFormats.microsoftPowerpoint"
  ),
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    i18n.global.t("common.fileFormats.microsoftPowerpoint"),
  "application/vnd.openxmlformats-officedocument.presentationml.template":
    i18n.global.t("common.fileFormats.microsoftPowerpoint"),
  "application/vnd.visio": i18n.global.t("common.fileFormats.microsoftVisio"),
  "application/zip": i18n.global.t("common.fileFormats.zipFile"),
  "application/x-zip-compressed": i18n.global.t("common.fileFormats.zipFile"),
  "application/x-7z-compressed": i18n.global.t("common.fileFormats.7zFile"),
  "application/x-tar": i18n.global.t("common.fileFormats.tarFile"),
  "application/x-rar-compressed": i18n.global.t("common.fileFormats.rarFile"),
  "application/vnd.rar": i18n.global.t("common.fileFormats.rarFile"),
  "application/vnd.oasis.opendocument.presentation": i18n.global.t(
    "common.fileFormats.openofficeImpress"
  ),
  "application/vnd.oasis.opendocument.text": i18n.global.t(
    "common.fileFormats.openofficeWriter"
  ),
  "application/vnd.oasis.opendocument.spreadsheet": i18n.global.t(
    "common.fileFormats.openofficeCalc"
  ),
} as Record<string, string>;

const EXACT_MIMETYPE_TO_ICON = {
  "application/pdf": "mdi-file-pdf-box",
  "application/msword": "mdi-file-word-box",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "mdi-file-word-box",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    "mdi-file-word-box",
  "application/vnd.ms-excel": "mdi-file-excel-box",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "mdi-file-excel-box",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    "mdi-file-excel-box",
  "application/vnd.ms-powerpoint": "mdi-file-powerpoint-box",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "mdi-file-powerpoint-box",
  "application/vnd.openxmlformats-officedocument.presentationml.template":
    "mdi-file-powerpoint-box",
  "application/zip": "mdi-zip-box",
  "application/x-zip-compressed": "mdi-zip-box",
  "application/x-7z-compressed": "mdi-zip-box",
  "application/x-tar": "mdi-zip-box",
  "application/x-rar-compressed": "mdi-zip-box",
  "application/vnd.rar": "mdi-zip-box",
  "application/vnd.oasis.opendocument.presentation": "mdi-file-powerpoint-box",
  "application/vnd.oasis.opendocument.text": "mdi-file-word-box",
  "application/vnd.oasis.opendocument.spreadsheet": "mdi-file-excel-box",
} as Record<string, string>;

const PARTIAL_MIMETYPE_TO_HUMAN_READABLE = {
  image: i18n.global.t("common.fileFormats.image"),
  video: i18n.global.t("common.fileFormats.video"),
  audio: i18n.global.t("common.fileFormats.audio"),
} as Record<string, string>;

const PARTIAL_MIMETYPE_TO_ICON = {
  image: "mdi-image",
  video: "mdi-video-box",
  audio: "mdi-volume-medium",
} as Record<string, string>;

const DEFAULT_ICON = "mdi-file";

const GENERIC = i18n.global.t("common.fileFormats.genericFile");
const GENERIC_ENDING = "-" + GENERIC;

export function getHumanReadableByMediaType(
  mediaType: string,
  extension: string
) {
  if (EXACT_MIMETYPE_TO_HUMAN_READABLE[mediaType] != undefined) {
    return EXACT_MIMETYPE_TO_HUMAN_READABLE[mediaType];
  }
  const potentialKey = Object.keys(PARTIAL_MIMETYPE_TO_HUMAN_READABLE).find(
    (x) => mediaType.startsWith(x)
  );
  if (potentialKey) {
    return PARTIAL_MIMETYPE_TO_HUMAN_READABLE[potentialKey];
  }
  return `${extension.toUpperCase()}${
    extension.length > 0 ? GENERIC_ENDING : GENERIC
  }`;
}

export function getIconByMediaType(mediaType: string) {
  if (EXACT_MIMETYPE_TO_ICON[mediaType] != undefined) {
    return EXACT_MIMETYPE_TO_ICON[mediaType];
  }
  const potentialKey = Object.keys(PARTIAL_MIMETYPE_TO_ICON).find((x) =>
    mediaType.startsWith(x)
  );
  if (potentialKey) {
    return PARTIAL_MIMETYPE_TO_ICON[potentialKey];
  }
  return DEFAULT_ICON;
}
