import { AwardingClient } from "@/utils/EvoClient";
import { MarkRequestForParticipationAdmissionStatusDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/mark-request-for-participation-admission-status";

export default async function markRequestForParticipationAdmissionStatus(
  dto: MarkRequestForParticipationAdmissionStatusDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
