import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex",
  style: {"justify-content":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_coach_mark_action = _resolveComponent("base-coach-mark_action")!
  const _component_evonum_checkbox = _resolveComponent("evonum-checkbox")!
  const _component_base_command_action = _resolveComponent("base-command-action")!
  const _component_evonum_card_list_item_field = _resolveComponent("evonum-card-list-item-field")!
  const _component_base_disabler = _resolveComponent("base-disabler")!

  return (_openBlock(), _createBlock(_component_base_disabler, {
    actionable: _ctx.actionable,
    actions: [_ctx.uncheckedAction, _ctx.checkedAction],
    stayRendered: true,
    allowedValue: _ctx.allowedValue,
    onCanPerformActionChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('canPerformActionChanged', $event)))
  }, {
    default: _withCtx(({ canPerformAction, activeAction }) => [
      _createElementVNode("div", null, [
        (canPerformAction || _ctx.noReadOnly)
          ? (_openBlock(), _createBlock(_component_base_command_action, {
              key: 0,
              action: activeAction,
              minLoadTime: 500,
              args: _ctx.commandArgs[activeAction]
            }, {
              default: _withCtx(({ isLoading, run }) => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_base_coach_mark_action, {
                    action: _ctx.commandAction,
                    "data-cy": "base-allowedactions-checkbox-coachmark",
                    actionable: _ctx.actionable,
                    allowedValue: _ctx.allowedValue
                  }, null, 8, ["action", "actionable", "allowedValue"]),
                  _createVNode(_component_evonum_checkbox, _mergeProps({
                    asSwitch: _ctx.asSwitch,
                    loading: isLoading,
                    "data-cy": "base-allowedactions-checkbox",
                    currentValue: _ctx.currentValue,
                    label: _ctx.label,
                    readonly: _ctx.disabled || !canPerformAction,
                    disabled: _ctx.disabled || !canPerformAction,
                    onClick: run
                  }, _ctx.$attrs), null, 16, ["asSwitch", "loading", "currentValue", "label", "readonly", "disabled", "onClick"])
                ])
              ]),
              _: 2
            }, 1032, ["action", "args"]))
          : (_openBlock(), _createBlock(_component_evonum_card_list_item_field, {
              key: 1,
              "data-cy": "base-allowedactions-checkbox-readonly",
              heading: _ctx.readonlyLabel || _ctx.label,
              text: _ctx.currentValue ? _ctx.checkedText : _ctx.uncheckedText,
              style: {"flex-basis":"180px"}
            }, null, 8, ["heading", "text"]))
      ])
    ]),
    _: 1
  }, 8, ["actionable", "actions", "allowedValue"]))
}