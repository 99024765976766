
import { defineComponent } from "vue";
import { NotificationDismisser } from "@/service/types";
import { Notification } from "@/model/Notification";
import { PropType } from "vue";
import { useErrorBoundaryInjections } from "@/composables/injections";
import { isActionableAllowed } from "@/utils/actions";
import ActionableMixin from "@/mixins/actionable";

/**
 * Zeigt Notifications und/oder Fehler aus der ErrorBoundary an.
 */
export default defineComponent({
  name: "base-notify-block",
  setup() {
    const { errorBoundaryId } = useErrorBoundaryInjections();
    return { errorBoundaryId };
  },
  props: {
    /**
     * Notification.
     */
    notification: {
      type: Object as PropType<Notification>,
    },
    /**
     * Dismisser für Notification.
     */
    dismisser: {
      type: [Object, Function, undefined] as PropType<
        NotificationDismisser | (() => Promise<void>) | undefined
      >,
    },
    /**
     * Id der Stelle, derer Notifications angezeigt werden sollen für Clientseitige Notifications
     */
    notificationSlotId: {
      type: String,
    },
    /**
     * Zeige keine Errorliste an
     */
    noErrorList: {
      type: Boolean,
    },
  },
  mixins: [ActionableMixin],
  computed: {
    hasErrors(): boolean {
      return (
        this.$store.state.notifications.errors[(this as any).errorBoundaryId]
          ?.length > 0
      );
    },
    actionNotification(): Notification | undefined {
      if (!this.notification) {
        return undefined;
      } else if (!this.actionable || !this.action || !this.actionable.actions) {
        return this.notification;
      }
      return {
        ...this.notification,
        dismissibleByUser:
          this.notification.dismissibleByUser &&
          isActionableAllowed(this.actionable, this.action),
      };
    },
    notificationFromStore(): Notification | undefined {
      if (!this.notificationSlotId) {
        return undefined;
      }
      return this.$store.state.notifications.notifications[
        this.notificationSlotId
      ];
    },
  },
});
