
import { defineComponent, PropType, Ref, toValue } from "vue";

import { Sorter } from "@/model/Sorter";
import PartialCardListFilters from "@/components/partials/partial-card-list-filters.vue";
import PartialCardListFiltersFilter from "@/components/partials/partial-card-list-filters-filter.vue";
import { DynamicFilterValue } from "@/model/FilterGroup";
import { shallowRef } from "vue";
import { useStore } from "vuex";
import deepCopy from "@/utils/deepCopy";

/**
 * Eine bereits in einer [base-card](#basecard) gewrappte Variante der Pagination Liste.
 * Sollte in den meisten Fällen die richtige Variante im Vergleich zur eigentlichen
 * Pagination Liste sein.
 */

export default defineComponent({
  name: "base-card-list_wrapped-pagination",
  props: {
    /**
     * Ein Array an Sortern für die Pagination
     */
    sorter: {
      type: Array as PropType<Sorter>,
    },
    /**
     * Der Pagination Prefix
     */
    prefixName: {
      type: String,
    },
    /**
     * Sind Items in der Liste
     */
    hasItems: {
      type: Boolean,
    },
    /**
     * Wieviele Items sind in der Liste
     */
    numberOfItems: {
      type: Number,
    },
    /**
     * Überschrift der Liste
     */
    heading: {
      type: String,
    },
    /**
     * Der Aria Label der Liste
     */
    listLabel: {
      type: String,
    },
    /**
     * Parameter für die Pagination, falls nötig
     */
    listArgs: {},
    /**
     * Die Überschrift der Empty Card
     */
    listEmptyHeading: {
      type: String,
    },
    /**
     * Tag des Titels, der angezeigt wird, wenn keine Daten vorhanden sind.
     */
    listEmptyHeadingTag: {
      type: String,
    },
    /**
     * Der Text der Empty Card
     */
    listEmptyText: {
      type: String,
    },
    /**
     * Ein Bild für die Empty Liste
     */
    listEmptyImage: {
      type: String,
    },
    listEmptyImageAspectHeight: {
      type: Number,
    },
    listEmptyImageAspectWidth: {
      type: Number,
    },
    /**
     * Soll die Leere Karte nicht transparent sein?
     */
    noUseTransparentOnEmpty: {
      default: false,
      type: Boolean,
    },
    filterGroups: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    filterDisabled: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    filteredEmptyText: {
      type: String,
    },
    filteredEmptyHeading: {
      type: String,
    },
    /**
     * Zusätzliche Arguments für Filter.
     * Wird in SetFilter Methode verwendet
     * Key ist das Field des Filters
     * z.B. bei filter["isQualified"] ist es "isQualified"
     */
    filterArgs: {
      type: Object as PropType<{ [key: string]: DynamicFilterValue }>,
    },
    counter: {
      type: String,
    },
    updateAction: {
      type: String,
    },
    noFilter: {
      type: Boolean,
    },
    freezeItems: {
      type: Boolean,
    },
    editing: {
      type: Boolean,
    },
  },
  components: {
    PartialCardListFilters,
    PartialCardListFiltersFilter,
  },
  setup(props, { emit }) {
    const store = useStore();
    let c_filterMap: Ref<any> = shallowRef(null);
    let c_filtering: Ref<any> = shallowRef(null);
    let c_activeFilterGroups: Ref<any> = shallowRef(null);
    let c_counter = shallowRef<string | undefined>(undefined);
    let c_numberOfItems = shallowRef<number | undefined>(undefined);
    function refreshFilter() {
      emit("refresh-cache");
      c_filterMap.value = deepCopy(
        store.getters[`${props.prefixName}:filterMap`]
      );
      c_filtering.value = !!store.getters[`${props.prefixName}:isFilterSet`];
      c_activeFilterGroups.value = deepCopy(
        store.getters[`${props.prefixName}:activeFilterGroups`]
      );
      c_counter.value = props.counter;
      c_numberOfItems.value = props.numberOfItems;
    }
    refreshFilter();
    return {
      c_filterMap,
      c_counter,
      c_numberOfItems,
      c_filtering,
      c_activeFilterGroups,
      refreshFilter,
    };
  },
  methods: {
    updateList(): Promise<void> {
      //Führt einen Backend-Call mit der registrierten Update-Action aus (z.B. UPDATE_PARTICIPANTS)
      //Aktualisiert die Listenanzeige
      return (this.$refs["list"] as any).updateList();
    },
    refreshCache(): void {
      //Aktualisiert die Listenanzeige gegen den Store, ohne die Action auszulösen (z.B. wenn die Action bereits woanders ausgelöst wurde)
      return (this.$refs["list"] as any).refreshCache();
    },
  },
  computed: {
    filtering(): boolean {
      return this.c_filtering;
    },
    noToolbar(): boolean {
      return !this.filtering && !this.hasItems;
    },
    showTransparentEmptyCard(): boolean {
      return !this.noUseTransparentOnEmpty && this.noToolbar;
    },
  },
});
