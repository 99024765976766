import i18n from "@/i18n";
import { Sorter } from "./Sorter";
import { Participant } from "@/model/Participant";
import {
  RequestForParticipantAdmissionStatus,
  RequestForParticipantAdmissionStatusValues,
} from "./RequestForParticipationAdmissionStatus";
import Bimap from "@/utils/bimap";
import { Actionable } from "./Actionable";
import { Document } from "./Document";
import { OpenableHistory } from "@/model/History";
import { FilterGroup } from "./FilterGroup";
import { supportedFilter } from "@/utils/EvoClient/query/GetAllRequestsForParticipation";
import { OpenableState } from "@/model/OpenableState";

export type RequestForParticipation = {
  id: string;
  callForCompetitionId: string;
  participant: Participant;
  document?: Document;
  awardUnitsIds?: string[];
  admissionStatus: RequestForParticipantAdmissionStatus;
} & Actionable &
  OpenableHistory &
  OpenableState;

export const LOCALIZED_REQUEST_FOR_PARTICIPATION_ADMISSION_STATUS_BIMAP =
  new Bimap<string, string>(
    new Map([
      [
        RequestForParticipantAdmissionStatusValues.NotMarked,
        i18n.global.t(
          "requests-for-participation.details.admission-status.not-marked"
        ),
      ],
      [
        RequestForParticipantAdmissionStatusValues.Admitted,
        i18n.global.t(
          "requests-for-participation.details.admission-status.admitted"
        ),
      ],
      [
        RequestForParticipantAdmissionStatusValues.Rejected,
        i18n.global.t(
          "requests-for-participation.details.admission-status.rejected"
        ),
      ],
    ])
  );

const SORT_OPTIONS = [
  i18n.global.t("common.sortOptions.participantDesc") as any,
  i18n.global.t("common.sortOptions.participantAsc") as any,
];

export const requestForParticipationSorter = [
  { title: SORT_OPTIONS[0], value: "participant.name,submittedAt" },
  { title: SORT_OPTIONS[1], value: "-participant.name,submittedAt" },
] as Sorter;

export const filterGroup: FilterGroup = {
  name: "LOSE",
  heading: i18n.global.t(
    "calls-for-competition.details.filters.heading"
  ) as any,
  get defaultFilter() {
    return this.filters[0];
  },
  filters: [
    {
      key: "LOSE_ALL",
      label: i18n.global.t(
        "calls-for-competition.details.filters.call-for-competition.label"
      ) as any,
      value: [supportedFilter.callForCompetition],
    },
    {
      key: "LOSE_FILTERED",
      label: i18n.global.t(
        "calls-for-competition.details.filters.award-units.label"
      ) as any,
      value: [supportedFilter.awardUnits],
    },
  ],
};
