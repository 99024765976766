import getAllNotices from "@/utils/EvoClient/query/GetAllNotices";
import getAllShipments from "@/utils/EvoClient/query/GetAllShipments";
import getAllCallsForTenders from "@/utils/EvoClient/query/GetAllCallsForTenders";
import getAllCallsForCompetition from "@/utils/EvoClient/query/GetAllCallsForCompetition";
import getAllAwardUnits from "@/utils/EvoClient/query/GetAllAwardUnits";
import { NoticeType } from "@/model/NoticeType";
import { UpdateTotalsOnlyPagination } from "@/store/mixins/pagination";

export default async function getAwardProcedureStatus(
  awardProcedureId: string
): Promise<any> {
  const allRequestPromises = [] as any;
  const apFilter = {
    field: "awardProcedure" as const,
    value: awardProcedureId,
  };
  allRequestPromises.push(
    getAllNotices({
      filter: [apFilter, { field: "noticeType", value: NoticeType.Contract }],
    })
  );
  allRequestPromises.push(getAllShipments({ filter: [apFilter] }));
  allRequestPromises.push(getAllCallsForTenders({ filter: [apFilter] }));
  allRequestPromises.push(
    getAllCallsForCompetition({
      filter: [apFilter],
    })
  );
  allRequestPromises.push(
    getAllAwardUnits({
      filter: [apFilter],
      pagination: UpdateTotalsOnlyPagination,
    })
  );

  const [
    notices,
    shipments,
    callsForTenders,
    callsForCompetition = [],
    awardUnits = [],
  ]: any = await Promise.all(allRequestPromises);
  const result: any = {
    notices: {
      amount: notices.length,
      lastState: notices[0]?.state,
      lastId: notices[0]?.id,
      lastShowDetailsAction: notices[0]?.actions.showNoticeDetails,
    },
    shipments: {
      amount: shipments.length,
      lastState: shipments[shipments.length - 1]?.state,
      lastId: shipments[shipments.length - 1]?.id,
      lastOnlyForParticipants:
        shipments[shipments.length - 1]?.onlyForParticipants,
      lastShowDetailsAction:
        shipments[shipments.length - 1]?.actions.showShipmentDetails,
    },
    callsForTenders: {
      amount: callsForTenders.length,
      lastState: callsForTenders[0]?.state,
      lastId: callsForTenders[0]?.id,
    },
  };

  if (awardUnits[0]) {
    result.awardUnits = {
      lastState: awardUnits[0].state || "DRAFT",
      lastId: awardUnits[0].id,
      lastShowDetailsAction: awardUnits[0].actions.showAwardUnitDetails,
    };
  } else {
    result.awardUnits = {};
  }

  result.callsForCompetition = {
    amount: callsForCompetition.length,
    lastState: callsForCompetition[0]?.state,
    lastId: callsForCompetition[0]?.id,
  };
  return result;
}
