import { AwardingClient } from "@/utils/EvoClient";
import { PublishShipmentDto } from "../dto";

const ENDPOINT = "c/publish-shipment";

export default async function PublishShipment(
  dto: PublishShipmentDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, dto);
}
