export const RESET_CALL_FOR_TENDERS_STORE =
  "Entfernt alle Daten aus dem Call for Tenders-Store";
export const CREATE_CALL_FOR_TENDERS = "Erzeugt einen Call for Tenders";
export const UPDATE_CALLS_FOR_TENDERS =
  "Aktualisiert die Calls for Tender Liste";
export const UPDATE_CURRENT_CALL_FOR_TENDERS =
  "Aktualisiert die aktuelle Angebotseinholung";
export const EDIT_CALL_FOR_TENDERS = "Editiert einen Call for Tenders";
export const DELETE_CALL_FOR_TENDERS = "Löscht einen Call for Tenders";
export const CHANGE_SUBMISSION_PERIOD = "Ändert die Angebotsfrist";
export const PUBLISH_CALL_FOR_TENDERS = "Veröffentliche ein Call For Tenders";
export const COMPLETE_CALL_FOR_TENDERS = "Schließe einen Call For Tenders ab";

export const CHECK_LOCK = "Prüfe das Call For Tenders ein Lock";
export const UPDATE_LOCK = "Setze das Call For Tenders Lock";

export const DISMISS_NOTIFICATION = "Call-For-Tenders Notification bestätigen";
export const ADD_INVITEE = "Einen Teilnehmer zu dieser Einholung auffordern";
export const REMOVE_INVITEE = "Einen Teilnehmer nicht mehr auffordern";
export const INVITE_ALL_ADMITTED_PARTICIPANTS =
  "Alle zugelassenen Teilnehmer zu dieser Einholung auffordern";
export const CHANGE_CALL_FOR_TENDERS =
  "Editiert einen Call for Tenders nach Start";
export const OPEN_OFFERS = "Öffne alle Offers gleichzeitig";
export const INVITE_TO_CALL_FOR_TENDERS =
  "Lade einen Participant in ein laufenden Call For Tenders ein";
