import { UPDATE_COUNTRIES } from "@/store/modules/common.actions";
import { SET_COUNTRIES } from "@/store/modules/common.mutations";
import { CommonStoreState } from "@/store/modules/common.types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import Client from "@/utils/EvoClient";

export const name = "common";

const store = {
  state: {
    countries: [],
  } as CommonStoreState,
  actions: {
    async [UPDATE_COUNTRIES]({ commit }) {
      const countries = await Client.GetStatic("country");
      commit(SET_COUNTRIES, countries);
    },
  },
  mutations: {
    [SET_COUNTRIES](state, rawData) {
      state.countries =
        rawData?.list.map((x: any) => {
          return { id: x.id, name: x.label };
        }) || [];
    },
  },
} as Module<CommonStoreState, RootState>;

export default store;
