import DirtyService from "@/service/DirtyService";
import deepCopy from "@/utils/deepCopy";
import { getUniqueId } from "@/utils/ids";
import { DefineComponent } from "vue";
interface ChangeDetectionMixin {
  watch: {
    isUnchanged: (...args: any) => void;
  };
  methods: {
    setDirty: (dirty: boolean) => void;
    changeDetectionFilter?: (dto: any) => (string | number | symbol)[];
    activateChangeDetection: (x?: { updateOriginal?: boolean }) => void;
    deactivateChangeDetection: (x?: { isUnchanged?: boolean }) => void;
  };
  data?: () => { isUnchanged: boolean; isChangeDetectionActive: boolean };
  created?: () => void;
}

interface ChangeDetectionOptions {
  /** Das Prop, welches den aktuellen Stand wiederspiegelt. */
  changingProp?: string;
  /** Ein Prop, gegen das der aktuelle Stand auf Änderungen geprüft wird.  */
  originalProp?: string;
  /** Ob das mixin die Vergleichskopie im created-hook anlegen soll  */
  createOriginalProp?: boolean;
  /** Ob die zu vergleichenden Werte primitives sind (keine Array oder Objekte). */
  primitives?: boolean; //
  /** Ob ich selbst eine changeDetectionFilter Function bereitstelle */
  providesChangeDetectionFilter?: boolean;
  /** Ob die ChangeDetection sofort active ist, oder erst aktvieviert werden muss */
  activeAtStart?: boolean;
}

type VueMixinTyping = DefineComponent<
  void,
  void,
  ReturnType<Required<ChangeDetectionMixin>["data"]>,
  {},
  ChangeDetectionMixin["methods"],
  {}
>;

export default function mixin(
  args?: {
    id: string; //eine auf dieser Seite eindeutige ID
  } & ChangeDetectionOptions
): VueMixinTyping;

export default function mixin(
  args?: ChangeDetectionOptions //hier wird eine eigene eindeutige ID erzeugt
): VueMixinTyping;

export default function mixin(
  {
    changingProp = "dto",
    originalProp = "unchangedDto",
    createOriginalProp = false,
    primitives = false,
    providesChangeDetectionFilter = false,
    activeAtStart = false,
    id,
  }: {
    id?: string;
  } & ChangeDetectionOptions = {
    changingProp: "dto",
    originalProp: "unchangedDto",
    createOriginalProp: false,
    primitives: false,
    providesChangeDetectionFilter: false,
    activeAtStart: false,
  }
) {
  const _id = id || getUniqueId();
  const changeDetector = primitives
    ? function (this: any) {
        if (this.isChangeDetectionActive) {
          this.isUnchanged = primitiveComparer(
            this[changingProp],
            this[originalProp]
          );
        }
      }
    : function (this: any) {
        if (this.isChangeDetectionActive) {
          this.isUnchanged = comparer.call(this);
        }
      };
  const dataObject: any = {
    isUnchanged: true,
    isChangeDetectionActive: activeAtStart,
  };
  const result: ChangeDetectionMixin = {
    methods: {
      setDirty(dirty) {
        DirtyService.setDirty(_id, dirty);
      },
      activateChangeDetection(
        this: any,
        { updateOriginal }: { updateOriginal?: boolean } = {}
      ) {
        if (updateOriginal) {
          this[originalProp] = deepCopy(this[changingProp]);
        }
        this.isChangeDetectionActive = true;
      },
      deactivateChangeDetection(
        this: any,
        { isUnchanged }: { isUnchanged?: boolean } = {}
      ) {
        this.isChangeDetectionActive = false;
        this.isUnchanged = isUnchanged ?? this.isUnchanged;
      },
    },
    watch: {
      isUnchanged(this: any, val: boolean) {
        DirtyService.setDirty(_id, !val);
      },
      [changingProp]: { handler: changeDetector, deep: true },
      [originalProp]: { handler: changeDetector, deep: true },
    },
  };
  if (createOriginalProp) {
    dataObject[originalProp] = {};
    result.created = function (this: any) {
      this[originalProp] = deepCopy(this[changingProp]);
    };
  }
  if (!providesChangeDetectionFilter) {
    result.methods.changeDetectionFilter = defaultFilter;
  }
  result.data = function () {
    return { ...dataObject };
  };
  return result as unknown as VueMixinTyping;

  function comparer(this: any): boolean {
    //schnell, aber bei deep objects ungenau
    if (this[changingProp] == null || this[originalProp] == null) {
      console.warn("Change detection: One of the two props isn't set");
    }
    //das funktioniert nicht, wenn das originalProp mehr props als das changing prop hat
    //da wir aber nicht mit leeren Objekten anfangen, geht das
    return this.changeDetectionFilter(this[changingProp]).every((x: any) => {
      return primitiveComparer(this[changingProp][x], this[originalProp][x]);
    });
  }
}

//--------

function primitiveComparer(
  changing: string | null | undefined | number | boolean | Array<any>,
  original: string | null | undefined | number | boolean | Array<any>
): boolean {
  if (Array.isArray(changing) && Array.isArray(original)) {
    return (
      changing.length === original.length &&
      changing.every((x, i) => original[i] === x)
    );
  }
  if (changing === original) {
    return true;
  }
  if (changing === null) {
    return original === undefined || original === "";
  } else if (original === null) {
    return changing === undefined || changing === "";
  }
  return false;
}

function defaultFilter(dto: any) {
  return Object.keys(dto);
}
