import uploadFile, {
  ProgressCallback,
} from "@/utils/EvoClient/unique/UploadFile";

// prettier-ignore
const ENDPOINT = (conversationId: string) => `conversations/${conversationId}/draft/attachments/upload-uri`;

export default async function uploadToConversation(
  file: File,
  conversationId: string,
  progressCb: ProgressCallback
): Promise<any> {
  return await uploadFile(file, ENDPOINT(conversationId), progressCb);
}
