import { TableOfOffers } from "./../../../model/TableOfOffers";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import UrlBuilder from "../urlBuilder";
import { QueryArgs, QueryResponse } from "@/utils/EvoClient/query";

// prettier-ignore
const ENDPOINT = (awardProcedureId: string) => `award-procedures/${awardProcedureId}/table-of-offers`;

export default async function getTableOfOffers(
  args: QueryArgs
): Promise<QueryResponse<TableOfOffers>> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(args.params!.awardProcedureId)).toString()
  );
  return deserializer.deserialize(result.data);
}
