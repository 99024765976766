import { AwardingClient } from "@/utils/EvoClient";
import { ChangeBasicDataDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/change-basic-data";

export default async function changeBasicData(
  dto: ChangeBasicDataDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
