import { ErrorInterceptor } from "./types";
import {
  ServerValidationError,
  InternalServerError,
  GatewayError,
  NetworkError,
  AccessDeniedError,
} from "@/utils/errors";

export default {
  intercept,
} as ErrorInterceptor;

function intercept(err: any) {
  if (err.response && err.response.status === 403) {
    throw new AccessDeniedError(err.response.data.errors[0].key, err.url);
  } else if (
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    err.response.status < 500 &&
    err.response.status >= 400
  ) {
    throw new ServerValidationError(
      "Serverside Validation Error",
      err.response.data.errors,
      err.response.status,
      err.response.config.url || "unknown_url"
    );
  } else if (err.response && err.response.status == 404) {
    console.warn("404 not handled yet");
  } else if (err.response && err.response.status == 500) {
    throw new InternalServerError(err.response.data.errors[0].detail, err.url);
  } else if (err.response && err.response.status == 503) {
    throw new GatewayError(err.response.status, err.url);
  } else if (isNetworkError(err)) {
    throw new NetworkError(-1, err.url); //handled in Router
  } else {
    console.warn("Not handled yet: " + JSON.stringify(err));
  }
}

function isNetworkError(error: any): boolean {
  /**
   * siehe https://github.com/axios/axios/issues/383#issuecomment-234079506,
   * Einfach gesagt: Alle Fehler die keinen explizten Axios-Fehler sind, und keine Antwort haben sind per definition network fehler
   * Das ist allerdings noch sehr in Flux.
   */

  return !!error.isAxiosError && !error.response;
}
