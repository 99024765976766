import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_content_wrapper = _resolveComponent("evonum-content-wrapper")!

  return (_openBlock(), _createBlock(_component_evonum_content_wrapper, {
    backTo: _ctx.backTo,
    hasNavigatedThroughApp: _ctx.navigatedThroughApp,
    "no-sticky-back-button": _ctx.isLowHeight
  }, {
    title: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["backTo", "hasNavigatedThroughApp", "no-sticky-back-button"]))
}