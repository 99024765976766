import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_select = _resolveComponent("evonum-select")!

  return (_openBlock(), _createBlock(_component_evonum_select, _mergeProps({
    class: "evonum-select-small flex-grow-0",
    clearable: false,
    variant: _ctx.filled ? undefined : 'underlined',
    "hide-details": true,
    id: _ctx.uniqueId,
    modelValue: _ctx.modelValue,
    items: _ctx.items,
    label: _ctx.prepend ? '' : _ctx.label,
    ref: "input"
  }, _ctx.$attrs), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)))
        ])
      }
    }),
    (_ctx.prepend)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _createElementVNode("label", {
              style: {"min-width":"0"},
              for: _ctx.uniqueId,
              class: "evonum-select-small__label text-caption text-b_grey-500 font-weight-medium"
            }, _toDisplayString(_ctx.label), 9, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["variant", "id", "modelValue", "items", "label"]))
}