import { Actionable } from "@/model/Actionable";
import i18n from "@/i18n";
import { Sorter } from "@/model/Sorter";
import { Notificationable } from "./Notificationable";
import { Stateable } from "./Stateable";
import { AwardUnitState } from "./AwardUnitState";
import { History } from "./History";

export type AwardUnit = {
  id: string;
  lotNumber?: number;
  title?: string;
  description?: string;
  pendingChanges?: {
    title?: AwardUnit["title"];
    description?: AwardUnit["description"];
  };
} & Actionable &
  Stateable<AwardUnitState> &
  Notificationable &
  History;

const SORT_OPTIONS = [
  i18n.global.t("common.sortOptions.lotNumberDesc") as any,
  i18n.global.t("common.sortOptions.lotNumberAsc") as any,
];

export const awardUnitSorter = [
  { title: SORT_OPTIONS[0], value: "lotNumber" },
  { title: SORT_OPTIONS[1], value: "-lotNumber" },
] as Sorter;
