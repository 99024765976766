import { DialogTextLineOptions } from "@/model/BaseDialogCardConfirmation";
import {
  COMPLETE_AWARD_PROCEDURE,
  CANCEL_AWARD_PROCEDURE,
  CREATE_AWARD_PROCEDURE,
  DELETE_AWARD_PROCEDURE,
  EDIT_AWARD_PROCEDURE,
  EDIT_INTERNAL_DOCUMENTATION,
  CHANGE_BASIC_DATA,
  PUBLISH_AWARD_PROCEDURE,
} from "@/store/modules/awardProcedure.actions";
import {
  CREATE_AWARD_UNIT,
  DELETE_AWARD_UNIT,
  EDIT_AWARD_UNIT,
  CHANGE_AWARD_UNIT,
} from "@/store/modules/awardUnit.actions";
import {
  SAVE_MESSAGE,
  SEND_MESSAGE,
  MARK_MESSAGES_AS_READ,
  ADD_MESSAGE_ATTACHMENT,
  REMOVE_MESSAGE_ATTACHMENT,
  DELETE_MESSAGE,
  OPEN_CURRENT_CONVERSATION,
} from "@/store/modules/conversation.actions";
import {
  CREATE_NOTICE,
  EDIT_NOTICE,
  DELETE_NOTICE,
  PUBLISH_NOTICE,
  VALIDATE_NOTICE,
} from "@/store/modules/notice.actions";
import {
  CHANGE_SUBMISSION_PERIOD,
  COMPLETE_CALL_FOR_TENDERS,
  CREATE_CALL_FOR_TENDERS,
  EDIT_CALL_FOR_TENDERS,
  DELETE_CALL_FOR_TENDERS,
  PUBLISH_CALL_FOR_TENDERS,
  ADD_INVITEE,
  REMOVE_INVITEE,
  INVITE_ALL_ADMITTED_PARTICIPANTS,
  CHANGE_CALL_FOR_TENDERS,
  OPEN_OFFERS,
  INVITE_TO_CALL_FOR_TENDERS,
} from "@/store/modules/callForTenders.actions";
import {
  CREATE_CALL_FOR_COMPETITION,
  DELETE_CALL_FOR_COMPETITION,
  EDIT_CALL_FOR_COMPETITION,
  PUBLISH_CALL_FOR_COMPETITION,
  CHANGE_REQUEST_FOR_PARTICIPATION_PERIOD,
  COMPLETE_CALL_FOR_COMPETITION,
  OPEN_REQUESTS_FOR_PARTICIPATION,
} from "@/store/modules/callForCompetition.actions";
import {
  MARK_OFFER_ADMISSION_STATUS,
  MARK_OFFER_AWARD_STATUS,
} from "@/store/modules/offer.actions";
import {
  CREATE_SECTION,
  DELETE_SECTION,
  MOVE_SECTION,
  RENAME_SECTION,
} from "@/store/modules/section.actions";
import {
  CREATE_SHIPMENT,
  DELETE_SHIPMENT,
  DISABLE_SECTIONS,
  EDIT_SHIPMENT,
  ENABLE_SECTIONS,
  PUBLISH_SHIPMENT,
} from "@/store/modules/shipment.actions";
import {
  INSERT_UPLOAD_INTO_POSITION,
  DELETE_TENDER_DOCUMENT,
  RENAME_TENDER_DOCUMENT,
  MOVE_TENDER_DOCUMENT,
} from "@/store/modules/tenderDocument.actions";
import {
  CREATE_PARTICIPANT,
  MARK_PARTICIPANT_ADMISSION_STATUS,
  EDIT_PARTICIPANT,
  DELETE_PARTICIPANT,
  MARK_PARTICIPANT_QUALIFICATION_STATUS,
} from "@/store/modules/participant.actions";
import {
  OPEN_REQUEST_FOR_PARTICIPATION,
  MARK_REQUEST_FOR_PARTICIPATION_ADMISSION_STATUS,
} from "@/store/modules/requestForParticipation.actions";
import { AwardProcedureState } from "@/model/AwardProcedureState";
import { defineComponent } from "vue";
import Delete from "@/assets/images/delete.svg";
import SendMessage from "@/assets/images/send-message.svg";
import PublishNotice from "@/assets/images/publish-notice.svg";
import ChangePeriod from "@/assets/images/change-period.svg";
import PublishAwardProcedure from "@/assets/images/publish-award-procedure.svg";
import CompleteCallForCompetition from "@/assets/images/complete-call-for-competition.svg";
import CompleteCallForTenders from "@/assets/images/complete-call-for-tenders.svg";
import PublishCallForTenders from "@/assets/images/publish-call-for-tenders.svg";
import CompleteAwardProcedure from "@/assets/images/complete-award-procedure.svg";
import EnableSections from "@/assets/images/enable_sections.svg";
import ChangeBasicData from "@/assets/images/change-basic-data.svg";
import { NoticeState } from "@/model/NoticeState";
import { ShipmentState } from "@/model/ShipmentState";
import { CallForCompetitionState } from "@/model/CallForCompetitionState";
import { CallForTendersState } from "@/model/CallForTendersState";
import { RootState } from "@/store/types";
import {
  EDIT_AUTHORITY,
  EDIT_USER,
  INVITE_USER,
  REGISTER_WITH_CODE,
  REGISTER_WITH_DATA,
  GRANT_USER_ROLE,
  REMOVE_USER_FROM_CONTEXT,
  JOIN_ORGANISATION,
} from "@/store/modules/users.actions";
import { OfferState } from "@/model/OfferState";

type FileChoiceInfo = {
  multiple: boolean;
};

type Component = {
  component: ReturnType<typeof defineComponent> | string;
  args: any;
};

type DialogOptions = {
  lineOptions?: DialogTextLineOptions[];
};

type Force = {
  /** Entweder nicht gesetzt, oder Eigenschaftenname, oder eine Funktion
   * mit Hilfe des Stores den Eigenschaftennamen ermittelt.
   */
  postListText?: ((state: RootState) => string) | string;
} & DialogOptions;

type Confirmation = {
  width?: number;
  components?: {
    preDefault?: Component;
    aside?: Component;
  };
} & DialogOptions;

export type ActionMetaInfo = {
  storeAction: string;
  async: boolean;
} & OptionalInfo;

type OptionalInfo = {
  fileChoice?: FileChoiceInfo;
  confirmation?: Confirmation;
  force?: Force | boolean; //Actions müssen im Store alles was ihnen übergeben wird als Object an den Client reichen, da hier die Force flagge eingeschleust wird
  handlesErrorsOnItsOwn?: boolean; //soll die base-command-action die Fehler nicht Handeln?
  overrideDirty?: boolean; //setzt den override für die dirty Prüfung während der Action, so dass Navigieren funktioniert.
};

function createMeta(
  storeAction: string,
  async: boolean,
  {
    fileChoice = undefined,
    confirmation = undefined,
    force = false,
    overrideDirty = true,
  }: OptionalInfo = {
    fileChoice: undefined,
    confirmation: undefined,
    force: undefined,
    overrideDirty: true,
  }
): ActionMetaInfo {
  return {
    storeAction,
    async,
    overrideDirty,
    fileChoice,
    confirmation,
    force,
  };
}

function createConfirmationConfiguration(
  fatLine = 3,
  lineOptions: DialogTextLineOptions[] = [
    { line: fatLine, class: "text-subtitle-2" },
  ]
): Confirmation {
  return {
    width: 800,
    lineOptions: lineOptions,
  };
}

function createForceConfiguration(
  { lineOptions = [], postListText = undefined }: Partial<Force> = {
    lineOptions: [],
    postListText: undefined,
  }
): Force {
  return {
    lineOptions: lineOptions,
    postListText,
  };
}

function createStateChangeConfirmation({
  from,
  to,
  picture,
  defaultConfig = createConfirmationConfiguration(),
}: {
  from?: string;
  to?: string;
  picture: ReturnType<typeof defineComponent>;
  defaultConfig?: Confirmation;
}): Confirmation {
  const result: Confirmation = {
    ...defaultConfig,
    components: {
      aside: {
        component: picture,
        args: {
          style: {
            maxHeight: "200px",
            minWidth: 0,
          },
        },
      },
    },
  };
  if (from && to) {
    result.components!.preDefault = {
      component: "PartialDialogCardStateChangeDisplay",
      args: { from, to, style: "margin-bottom: 20px" },
    };
  }
  return result;
}

const metaInfo: Map<string, ActionMetaInfo> = new Map();

//group:award-procedure

metaInfo.set("createAwardProcedure", createMeta(CREATE_AWARD_PROCEDURE, false));

metaInfo.set(
  "editAwardProcedure",
  createMeta(EDIT_AWARD_PROCEDURE, false, {
    force: createForceConfiguration(),
  })
);

metaInfo.set(
  "editInternalDocumentation",
  createMeta(EDIT_INTERNAL_DOCUMENTATION, false)
);

metaInfo.set(
  "deleteAwardProcedure",
  createMeta(DELETE_AWARD_PROCEDURE, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "completeAwardProcedure",
  createMeta(COMPLETE_AWARD_PROCEDURE, true, {
    confirmation: createStateChangeConfirmation({
      from: AwardProcedureState.Active,
      to: AwardProcedureState.Completed,
      picture: CompleteAwardProcedure,
    }),
  })
);

metaInfo.set(
  "cancelAwardProcedure",
  createMeta(CANCEL_AWARD_PROCEDURE, true, {
    confirmation: createStateChangeConfirmation({
      picture: CompleteCallForCompetition,
    }),
  })
);

metaInfo.set(
  "publishAwardProcedure",
  createMeta(PUBLISH_AWARD_PROCEDURE, true, {
    confirmation: createStateChangeConfirmation({
      from: AwardProcedureState.Draft,
      to: AwardProcedureState.Active,
      picture: PublishAwardProcedure,
    }),
  })
);

metaInfo.set(
  "changeBasicData",
  createMeta(CHANGE_BASIC_DATA, true, {
    confirmation: createStateChangeConfirmation({
      picture: ChangeBasicData,
      defaultConfig: createConfirmationConfiguration(4),
    }),
  })
);

//group:award-unit

metaInfo.set("createAwardUnit", createMeta(CREATE_AWARD_UNIT, false));

metaInfo.set("editAwardUnit", createMeta(EDIT_AWARD_UNIT, false));

metaInfo.set(
  "changeAwardUnit",
  createMeta(CHANGE_AWARD_UNIT, true, {
    confirmation: createStateChangeConfirmation({
      picture: ChangeBasicData,
      defaultConfig: createConfirmationConfiguration(4),
    }),
  })
);

metaInfo.set(
  "deleteAwardUnit",
  createMeta(DELETE_AWARD_UNIT, false, {
    force: createForceConfiguration(),
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

//group:call-for-competition

metaInfo.set(
  "openRequestsForParticipation",
  createMeta(OPEN_REQUESTS_FOR_PARTICIPATION, false, {
    confirmation: createStateChangeConfirmation({
      picture: CompleteCallForCompetition,
    }),
    force: createForceConfiguration({
      postListText(state) {
        return state.awardProcedures.current!.lotDivision
          ? "postListText:LotDivision"
          : "postListText";
      },
    }),
  })
);

metaInfo.set(
  "createCallForCompetition",
  createMeta(CREATE_CALL_FOR_COMPETITION, false)
);

metaInfo.set(
  "editCallForCompetition",
  createMeta(EDIT_CALL_FOR_COMPETITION, false)
);

metaInfo.set(
  "deleteCallForCompetition",
  createMeta(DELETE_CALL_FOR_COMPETITION, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "publishCallForCompetition",
  createMeta(PUBLISH_CALL_FOR_COMPETITION, true, {
    confirmation: createStateChangeConfirmation({
      from: CallForCompetitionState.Draft,
      to: CallForCompetitionState.Active,
      picture: PublishCallForTenders,
    }),
  })
);

metaInfo.set(
  "changeRequestForParticipationPeriod",
  createMeta(CHANGE_REQUEST_FOR_PARTICIPATION_PERIOD, true, {
    confirmation: createStateChangeConfirmation({
      picture: ChangePeriod,
      defaultConfig: createConfirmationConfiguration(4),
    }),
  })
);

metaInfo.set(
  "completeCallForCompetition",
  createMeta(COMPLETE_CALL_FOR_COMPETITION, false, {
    confirmation: createStateChangeConfirmation({
      from: CallForCompetitionState.Finished,
      to: CallForCompetitionState.Completed,
      picture: CompleteCallForCompetition,
    }),
  })
);

//group:call-for-tenders

metaInfo.set(
  "inviteToCallForTenders",
  createMeta(INVITE_TO_CALL_FOR_TENDERS, true, {
    confirmation: createStateChangeConfirmation({
      picture: ChangeBasicData,
    }),
  })
);

metaInfo.set(
  "openOffers",
  createMeta(OPEN_OFFERS, false, {
    confirmation: createStateChangeConfirmation({
      picture: CompleteCallForTenders,
    }),
  })
);

metaInfo.set(
  "changeCallForTenders",
  createMeta(CHANGE_CALL_FOR_TENDERS, false)
);

metaInfo.set(
  "createCallForTenders",
  createMeta(CREATE_CALL_FOR_TENDERS, false)
);

metaInfo.set(
  "editCallForTenders",
  createMeta(EDIT_CALL_FOR_TENDERS, false, {
    force: createForceConfiguration(),
  })
);

metaInfo.set(
  "deleteCallForTenders",
  createMeta(DELETE_CALL_FOR_TENDERS, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "publishCallForTenders",
  createMeta(PUBLISH_CALL_FOR_TENDERS, true, {
    confirmation: createStateChangeConfirmation({
      from: CallForTendersState.Draft,
      to: CallForTendersState.Active,
      picture: PublishCallForTenders,
    }),
    force: createForceConfiguration(),
  })
);

metaInfo.set(
  "changeSubmissionPeriod",
  createMeta(CHANGE_SUBMISSION_PERIOD, true, {
    confirmation: createStateChangeConfirmation({
      picture: ChangePeriod,
      defaultConfig: createConfirmationConfiguration(4),
    }),
  })
);

metaInfo.set(
  "inviteAllAdmittedParticipants",
  createMeta(INVITE_ALL_ADMITTED_PARTICIPANTS, false, {
    confirmation: createStateChangeConfirmation({
      picture: CompleteCallForTenders,
    }),
  })
);

metaInfo.set("addInvitee", createMeta(ADD_INVITEE, false));

metaInfo.set("removeInvitee", createMeta(REMOVE_INVITEE, false));

metaInfo.set(
  "completeCallForTenders",
  createMeta(COMPLETE_CALL_FOR_TENDERS, false, {
    confirmation: createStateChangeConfirmation({
      from: CallForTendersState.Finished,
      to: CallForTendersState.Completed,
      picture: CompleteCallForTenders,
    }),
    force: createForceConfiguration(),
  })
);

//group:notice

metaInfo.set("createNotice", createMeta(CREATE_NOTICE, false));

metaInfo.set(
  "deleteNotice",
  createMeta(DELETE_NOTICE, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "publishNotice",
  createMeta(PUBLISH_NOTICE, true, {
    confirmation: createStateChangeConfirmation({
      from: NoticeState.Draft,
      to: NoticeState.Published,
      picture: PublishNotice,
    }),
  })
);

metaInfo.set("editNotice", createMeta(EDIT_NOTICE, false));
metaInfo.set("validateNotice", createMeta(VALIDATE_NOTICE, true));
//group:offer

metaInfo.set(
  "markOfferAdmissionStatus",
  createMeta(MARK_OFFER_ADMISSION_STATUS, false, {
    force: createForceConfiguration(),
  })
);
metaInfo.set(
  "markOfferAwardStatus",
  createMeta(MARK_OFFER_AWARD_STATUS, false)
);

metaInfo.set(
  "markParticipantAdmissionStatus",
  createMeta(MARK_PARTICIPANT_ADMISSION_STATUS, false, {
    force: createForceConfiguration(),
  })
);

//group:participant

metaInfo.set(
  "markParticipantQualificationStatus",
  createMeta(MARK_PARTICIPANT_QUALIFICATION_STATUS, false, {
    force: createForceConfiguration(),
  })
);

metaInfo.set("createParticipant", createMeta(CREATE_PARTICIPANT, false));

metaInfo.set("editParticipant", createMeta(EDIT_PARTICIPANT, false));

metaInfo.set(
  "deleteParticipant",
  createMeta(DELETE_PARTICIPANT, false, {
    force: createForceConfiguration(),
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "markRequestForParticipationAdmissionStatus",
  createMeta(MARK_REQUEST_FOR_PARTICIPATION_ADMISSION_STATUS, false)
);

//group:request-for-participation

//group:section

metaInfo.set("createSection", createMeta(CREATE_SECTION, false));

metaInfo.set("renameSection", createMeta(RENAME_SECTION, false));

metaInfo.set("moveSection", createMeta(MOVE_SECTION, false));

metaInfo.set(
  "deleteSection",
  createMeta(DELETE_SECTION, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
      defaultConfig: createConfirmationConfiguration(4),
    }),
  })
);

//group:shipment

metaInfo.set("createShipment", createMeta(CREATE_SHIPMENT, false));

metaInfo.set("editShipment", createMeta(EDIT_SHIPMENT, false));

metaInfo.set(
  "deleteShipment",
  createMeta(DELETE_SHIPMENT, false, {
    confirmation: createStateChangeConfirmation({
      picture: Delete,
    }),
  })
);

metaInfo.set(
  "publishShipment",
  createMeta(PUBLISH_SHIPMENT, true, {
    confirmation: createStateChangeConfirmation({
      defaultConfig: createConfirmationConfiguration(5, [
        {
          line: 3,
          component: "partial-dialog-card-confirmation-icon-switcher",
          iconChooser: (dialogProps: any) =>
            dialogProps.onlyForParticipants ? "userStar" : "worldEast",
          class: "d-flex ellipsing-flex align-center text-subtitle-2",
        },
        { line: 5, class: "text-subtitle-2" },
      ]),
      from: ShipmentState.Draft,
      to: ShipmentState.Published,
      picture: PublishAwardProcedure,
    }),
  })
);

metaInfo.set(
  "enableSections",
  createMeta(ENABLE_SECTIONS, false, {
    confirmation: {
      width: 800,
    },
  })
);

metaInfo.set(
  "disableSections",
  createMeta(DISABLE_SECTIONS, false, {
    confirmation: createStateChangeConfirmation({
      picture: EnableSections,
    }),
  })
);

//group:tender-document

metaInfo.set(
  "addTenderDocument",
  createMeta(INSERT_UPLOAD_INTO_POSITION, false, {
    fileChoice: { multiple: true },
    handlesErrorsOnItsOwn: true,
  })
);

metaInfo.set("renameTenderDocument", createMeta(RENAME_TENDER_DOCUMENT, false));

metaInfo.set("moveTenderDocument", createMeta(MOVE_TENDER_DOCUMENT, false));

metaInfo.set(
  "deleteTenderDocument",
  createMeta(DELETE_TENDER_DOCUMENT, false, {
    confirmation: createStateChangeConfirmation({
      defaultConfig: createConfirmationConfiguration(3, [
        {
          line: 2,
          component: "partial-dialog-card-confirmation-icon-switcher",
          iconChooser: () => "mdi-file",
          class: "d-flex ellipsing-flex align-center",
        },
        { line: 3, class: "text-subtitle-2" },
      ]),
      picture: Delete,
    }),
  })
);

//group: conversation

// :TODO: Keine Actions für Navigation, Link nutzen.
// So umbauen, dass Link auch aus dem Kontextmenü funktioniert.
metaInfo.set(
  "showConversationDetails",
  createMeta(OPEN_CURRENT_CONVERSATION, false)
);

metaInfo.set("saveMessage", createMeta(SAVE_MESSAGE, false));

metaInfo.set(
  "sendMessage",
  createMeta(SEND_MESSAGE, true, {
    confirmation: createStateChangeConfirmation({
      picture: SendMessage,
    }),
  })
);

metaInfo.set("deleteMessage", createMeta(DELETE_MESSAGE, false));

metaInfo.set("markMessagesAsRead", createMeta(MARK_MESSAGES_AS_READ, false));

metaInfo.set(
  "addMessageAttachment",
  createMeta(ADD_MESSAGE_ATTACHMENT, false, {
    fileChoice: { multiple: true },
    handlesErrorsOnItsOwn: true,
  })
);

metaInfo.set(
  "removeMessageAttachment",
  createMeta(REMOVE_MESSAGE_ATTACHMENT, false, {
    handlesErrorsOnItsOwn: true,
  })
);

//group: user

metaInfo.set("registerWithAuthority", createMeta(REGISTER_WITH_DATA, false));
metaInfo.set("joinOrganisation", createMeta(JOIN_ORGANISATION, false));
metaInfo.set("registerWithCode", createMeta(REGISTER_WITH_CODE, false));
metaInfo.set("editUser", createMeta(EDIT_USER, false));
metaInfo.set("editAuthority", createMeta(EDIT_AUTHORITY, false));
metaInfo.set("inviteUser", createMeta(INVITE_USER, false));
metaInfo.set("grantUserRole", createMeta(GRANT_USER_ROLE, false));
metaInfo.set(
  "removeUserFromContext",
  createMeta(REMOVE_USER_FROM_CONTEXT, false)
);

export default {
  getInfo(action: string): ActionMetaInfo {
    if (metaInfo.has(action)) {
      return metaInfo.get(action)!;
    } else {
      return {
        storeAction: "unknown action " + action,
        async: false,
      };
    }
  },
};
