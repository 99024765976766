import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { User } from "@/model/User";

// prettier-ignore
const ENDPOINT = () => `users/user`;

export default async function getUser({
  contextId,
}: { contextId?: string } = {}): Promise<User> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT())
      .addIncludes("authorities")
      .addIncludes("userRoles")
      .toString()
  );
  const deserialized = await deserializer.deserialize(result.data, {
    relations: ["authorities", "userRoles.role", "userRoles.context"],
  });
  //In Backend Filter ummünzen, sobald Backend-Filter implementiert
  if (contextId) {
    deserialized.userRoles = deserialized.userRoles.filter(
      (userRole: { contextId: string }) => userRole?.contextId === contextId
    );
  }
  return deserialized;
}
