import { AwardingClient } from "@/utils/EvoClient";
import { EditCallForTendersDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-call-for-tenders";

export default async function editCallForTenders(
  ecftDTO: EditCallForTendersDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, ecftDTO)) as AxiosResponse).data
    .id;
}
