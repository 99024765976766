// Um die Routen testen zu können, müssen diese auch in cypress-tests/tests/cypress/support/routes.js hinterlegt werden

declare module "vue-router" {
  interface RouteMeta extends Record<string | number | symbol, any> {
    title?: string;
    notRequireAuthority?: boolean;
    loadRoute?(
      dispatcher: {
        dispatch(action: string, ...args: any): unknown;
      },
      ...args: unknown[]
    ): Promise<unknown>[];
  }
}

export const ROUTE_DASHBOARD = "route-dashboard";
export const ROUTE_AWARD_PROCEDURE_DETAILS = "route-award-procedure-details";
export const ROUTE_AWARD_UNIT_DETAILS = "route-award-unit-details";
export const ROUTE_AWARD_UNIT_LIST = "route-award-unit-list";
export const ROUTE_CALL_FOR_TENDERS_LIST = "route-call-for-tenders-list";
export const ROUTE_CALL_FOR_TENDERS_DETAILS = "route-call-for-tenders-details";
export const ROUTE_OFFER_DETAILS = "route-offer-details";
export const ROUTE_CALL_FOR_COMPETITION_DETAILS =
  "route-call-for-competition-details";
export const ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS =
  "route-request-for-participation-details";
export const ROUTE_CONVERSATION_LIST = "route-conversation-list";
export const ROUTE_CONVERSATION_DETAILS = "route-conversation-details";
export const ROUTE_NOTICE_LIST = "route-notice-list";
export const ROUTE_NOTICE_DETAILS = "route-notice-details";
export const ROUTE_PARTICIPANT_LIST = "route-participant-list";
export const ROUTE_PARTICIPANT_DETAILS = "route-participant-details";
export const ROUTE_SHIPMENT_LIST = "route-shipment-list";
export const ROUTE_SHIPMENT_DETAILS = "route-shipment-details";
export const ROUTE_TABLE_OF_OFFERS_DETAILS = "route-table-of-offers-details";
export const ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS =
  "route-table-of-offers-offer-details";

export const ROUTE_USERS_REGISTER = "route-users-register";
export const ROUTE_USERS_EDIT_USER = "route-users-edit";
export const ROUTE_AUTHORITIES_DETAILS = "route-authorities-details";
export const ROUTE_AUTHORITIES_INVITATION = "route-authorities-invitation";
export const ROUTE_AUTHORITIES_SELECTION = "route-authorities-selection";
export const ROUTE_PERMISSONS = "route-permissions";
