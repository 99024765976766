import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";
import { Conversation } from "@/model/Conversation";
import { convertAttachments } from "@/model/Attachment";

// prettier-ignore
const ENDPOINT = (conversationId: string) =>`conversations/${conversationId}/draft`

export default async function getConversation(
  conversationId: string
): Promise<Conversation["draft"]> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(conversationId))
      .addIncludes("attachments")
      .toString()
  );
  const deserialized = await deserializer.deserialize(result.data);
  deserialized.attachments = convertAttachments(deserialized.attachments);
  return deserialized;
}
