import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";

// prettier-ignore
const ENDPOINT = (id: string) => `static/${id}`;

export default async function getStatic(
  id: string
): Promise<Record<string, any>> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT(id)).toString()
  );
  return result.data;
}
