
import { defineComponent } from "vue";
import ViewportMixin from "@/mixins/viewport";
import { DynamicFilterValue, FilterOption } from "@/model/FilterGroup";
import { getUniqueId } from "@/utils/ids";
import { deepEqual } from "fast-equals";
import CommonMenuPopover from "@/components/common-menu-popover.vue";

export default defineComponent({
  name: "partial-card-list-filters",
  mixins: [ViewportMixin],
  props: [
    "filterGroup",
    "prefixName",
    "filterMap",
    "activeFilterGroups",
    "filterArgs",
    "disabled",
  ],
  components: {
    CommonMenuPopover,
  },
  data() {
    return {
      bottomSheetActive: false,
      contentId: getUniqueId(),
      attachId: getUniqueId(),
    };
  },
  computed: {
    isDefaultFilterSet(): boolean {
      return (
        this.currentFilterKey === undefined ||
        this.currentFilterKey == this.filterGroup.defaultFilter.key
      );
    },
    ariaLabel(): string | undefined {
      return this.isDefaultFilterSet
        ? `${this.heading}, Nicht ausgewählt`
        : undefined;
    },
    currentFilterKey() {
      return this.activeFilterGroups[this.filterGroup.name];
    },
    heading(): string {
      return this.filterGroup.heading || this.$t("common.list.filter");
    },
    currentFilter(): FilterOption {
      return {
        value: this.filterMap[this.currentFilterKey],
        key: this.currentFilterKey,
        label: (
          this.filterGroup.filters.find(
            (x: FilterOption) => x.key == this.currentFilterKey
          ) || this.filterGroup.defaultFilter
        ).label,
      };
    },
  },
  methods: {
    setFilter(
      key: string,
      { values }: { values?: Record<string, DynamicFilterValue> } = {}
    ): void {
      const selectedFilter = overrideDefaultFilterValues(
        this.filterGroup.filters.find((x: any) => x.key == key),
        values
      );
      this.$store.dispatch(`${this.prefixName}:setFilter`, {
        filter: {
          key: selectedFilter.key,
          value: selectedFilter.value,
          exclusive: selectedFilter.exclusive,
        },
        filterGroup: this.filterGroup.name,
      });
      this.$emit("filterChanged");
    },
    resetFilter() {
      this.setFilter(this.filterGroup.defaultFilter.key);
    },
  },
  watch: {
    isExtraSmall(val) {
      if (!val) {
        this.bottomSheetActive = false;
      }
    },
    filterArgs: {
      handler(val, oldval) {
        if (!deepEqual(val, oldval)) {
          this.setFilter(this.currentFilterKey, { values: val });
        }
      },
      deep: true,
    },
  },
});

function overrideDefaultFilterValues(
  filter: FilterOption,
  fields?: Record<string, DynamicFilterValue>
): FilterOption {
  if (fields) {
    let value = filter.value.map((val: any) => {
      return {
        ...val,
        value: fields[val.field] !== undefined ? fields[val.field] : val.value,
      };
    });
    return {
      ...filter,
      value,
    };
  } else {
    return filter;
  }
}
