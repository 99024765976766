
import { defineComponent } from "vue";
import ViewportMixin from "@/mixins/viewport";
import ids from "@/utils/ids";

export default defineComponent({
  name: "partial-skip-navigation-link",
  mixins: [ViewportMixin],
  props: {
    targetId: {
      type: String,
      default: "main",
    },
    content: {
      type: String,
    },
  },
  methods: {
    focusMainId() {
      this.$utils.focusId(this.targetId);
    },
    focusMenuOnMobile(event: KeyboardEvent) {
      if (this.$store.state.isNavMenuVisible && this.isMobile) {
        //Overlay is shown, daher Focus zum letzten Menü item beim Rückwärts gehen
        let lmi = document.querySelector("#" + ids.LAST_MENU_ITEM);
        if (lmi) {
          event.preventDefault();
          event.stopPropagation();
          (lmi as any).focus();
        }
      }
    },
  },
});
