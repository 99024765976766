import { AwardingClient } from "@/utils/EvoClient";
import { EditParticipantDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-participant";

export default async function editParticipant(
  dto: EditParticipantDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dto)) as AxiosResponse).data.id;
}
