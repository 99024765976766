import { CallForCompetition } from "@/model/CallForCompetition";
import ListQueryFunctionGenerator from "./ListQueryFunctionGenerator";

const ENDPOINT = "calls-for-competition";
export type PossibleCallsForCompetitionFilters = ["awardProcedure"];
export type PossibleCallsForCompetitionIncludes = ["awardProcedure"];
export default ListQueryFunctionGenerator<
  CallForCompetition[],
  PossibleCallsForCompetitionFilters,
  PossibleCallsForCompetitionIncludes
>(ENDPOINT);
