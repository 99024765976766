import { RouteRecordRaw } from "vue-router";
import LoadingService from "@/service/LoadingService";
import i18n from "@/i18n";
import Breadcrumbs from "@/router/breadcrumbs";
import Store from "@/store";
import {
  UPDATE_CURRENT_PARTICIPANT,
  UPDATE_PARTICIPANTS,
} from "@/store/modules/participant.actions";
import {
  ROUTE_PARTICIPANT_DETAILS,
  ROUTE_PARTICIPANT_LIST,
} from "@/router/routes";
import {
  UPDATE_AWARD_UNITS,
  name as awardUnitPrefix,
} from "@/store/modules/awardUnit.actions";
import { RESET_PAGINATION } from "@/store/actions";
import { UPDATE_COUNTRIES } from "@/store/modules/common.actions";

const ParticipantFrame = () =>
  import(
    /* webpackChunkName: "participants-chunk" */ "@/views/participants/participant-frame.vue"
  );
const ParticipantList = () =>
  import(
    /* webpackChunkName: "participants-chunk" */ "@/views/participants/participant-list.vue"
  );
const ParticipantDetails = () =>
  import(
    /* webpackChunkName: "participants-chunk" */ "@/views/participants/participant-details.vue"
  );

export default {
  path: "participants",
  component: ParticipantFrame,
  async beforeEnter(to, from, next) {
    LoadingService.addLoadAction(
      UPDATE_PARTICIPANTS,
      to.params.awardProcedureId
    );
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.participants"),
  },
  children: [
    {
      path: "",
      name: ROUTE_PARTICIPANT_LIST,
      component: ParticipantList,
      async beforeEnter(to, from, next) {
        to.meta!.loadRoute!(LoadingService, to.params);
        LoadingService.addLoadAction(UPDATE_COUNTRIES);
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.participants"),
        breadCrumbs: Breadcrumbs.Participants,
        loadRoute(dispatcher, { awardProcedureId }) {
          //Da wir das Listen neu Laden auch außerhalb des Routers ausführen müssen
          //wenn wir auf der Liste Elemente löschen
          //Lagern wir das Laden allgemein aus
          return [
            dispatcher.dispatch(UPDATE_PARTICIPANTS, awardProcedureId),
            //resettet die Subnavigation der Award Units
            dispatcher.dispatch(`${awardUnitPrefix}:${RESET_PAGINATION}`),
          ];
        },
      },
    },
    {
      path: ":participantId",
      name: ROUTE_PARTICIPANT_DETAILS,
      component: ParticipantDetails,
      beforeEnter(to, from, next) {
        LoadingService.addCheck(() => {
          if (Store.state.participants.current != null) {
            return (
              Store.state.awardProcedures.current!.id ==
              Store.state.participants.current.awardProcedureId
            );
          }
          return true;
        });
        LoadingService.addLoadAction(
          UPDATE_CURRENT_PARTICIPANT,
          to.params.participantId
        );
        LoadingService.addLoadAction(UPDATE_COUNTRIES);
        LoadingService.addLoadAction(UPDATE_AWARD_UNITS);
        next();
      },
      meta: {
        breadCrumbs: Breadcrumbs.Participants,
        parent: ROUTE_PARTICIPANT_LIST,
      },
    },
  ],
} as RouteRecordRaw;
