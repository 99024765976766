import { AwardingClient } from "@/utils/EvoClient";
import { DismissAwardProcedureNotificationDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/dismiss-award-procedure-notification";

export default async function dismissAwardProcedureNotification(
  dapnDTO: DismissAwardProcedureNotificationDto
): Promise<string> {
  return ((await AwardingClient.post(ENDPOINT, dapnDTO)) as AxiosResponse).data
    .id;
}
