import {
  DISMISS_ERRORS,
  DISMISS_GLOBAL_ERROR,
  SET_GLOBAL_ERROR,
} from "@/store/modules/notification.actions";
import Store from "@/store";
import { GlobalDisplayError } from "@/store/modules/notification.types";
export default {
  handleError(id: string) {
    Store.dispatch(DISMISS_ERRORS, id);
  },
  setGlobalError(message: string, code?: string | number) {
    Store.dispatch(SET_GLOBAL_ERROR, {
      timestamp: Date.now(),
      code: code,
      message,
    } as GlobalDisplayError);
  },
  resetGlobalError() {
    Store.dispatch(DISMISS_GLOBAL_ERROR);
  },
};
