import { MarkOfferAwardStatusDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "c/mark-offer-award-status";

export default async function markOfferAwardStatus(
  offerDto: MarkOfferAwardStatusDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, offerDto);
}
