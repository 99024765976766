import { SpecificationsState } from "./specifications.types";
import EvoState from "../types";
import { Module } from "vuex";
import { GET_SPECIFICATIONS } from "./specifications.actions";
import {
  SET_SPECIFICATIONS,
  SET_TYPE_OF_SERVICES,
  SET_REGULATIONS,
  SET_PROCEDURE_TYPES,
} from "./specifications.mutations";
import SpecificationList from "@/model/SpecificationList";
import { ProcedureType } from "@/model/ProcedureType";
import { Regulation } from "@/model/Regulation";
import { TypeOfService } from "@/model/TypeOfService";
import Client from "@/utils/EvoClient";

export default {
  state: {
    typeOfServiceValues: [],
    regulationValues: [],
    procedureTypeValues: [],
    initialized: false,
    list: new SpecificationList([]),
  } as SpecificationsState,
  actions: {
    async [GET_SPECIFICATIONS]({ commit }) {
      const specifications = new SpecificationList(
        await Client.GetSpecifications()
      );

      commit(SET_SPECIFICATIONS, specifications);
      commit(SET_TYPE_OF_SERVICES, specifications.typeOfServices());
      commit(SET_REGULATIONS, specifications.regulations());
      commit(SET_PROCEDURE_TYPES, specifications.procedureTypes());
    },
  },
  mutations: {
    [SET_SPECIFICATIONS](state, specificationList: SpecificationList) {
      state.initialized = true;
      state.list = specificationList;
    },
    [SET_TYPE_OF_SERVICES](state, typeOfServices: TypeOfService[]) {
      state.typeOfServiceValues = typeOfServices;
    },
    [SET_REGULATIONS](state, regulations: Regulation[]) {
      state.regulationValues = regulations;
    },
    [SET_PROCEDURE_TYPES](state, procedureTypes: ProcedureType[]) {
      state.procedureTypeValues = procedureTypes;
    },
  },
} as Module<SpecificationsState, EvoState>;
