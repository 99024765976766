import { MarkParticipantStatusDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "c/mark-participant-admission-status";

export default async function markParticipantStatus(
  participantDto: MarkParticipantStatusDto
): Promise<void> {
  await AwardingClient.post(ENDPOINT, participantDto);
}
