import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "partial-card-list-item-dragdrop-loading__filename text-subtitle-2 text-b_grey-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_countdown = _resolveComponent("evonum-countdown")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ style: {"flex":"1 0 0"} }, _ctx.$attrs, {
    class: "partial-card-list-item-dragdrop-loading",
    "data-cy": "base-upload-status"
  }), [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex--mobile-wrap partial-card-list-item-dragdrop-loading__status-text", {
        'ellipsing-flex': !_ctx.isMobile,
      }]),
      style: {"justify-content":"space-between","align-items":"center"}
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.headline), 1),
      _createElementVNode("span", {
        class: _normalizeClass([{ 'text-subtitle-2': !_ctx.isMobile, 'text-caption': _ctx.isMobile }, "partial-card-list-item-dragdrop-loading__upload-counter text-subtitle-2 text-grey-700"]),
        style: {"min-width":"min-content !important"}
      }, _toDisplayString(_ctx.percentUploaded
            ? _ctx.$t("common.upload.xOfxBytes", {
                total: _ctx.totalUploaded,
                loaded: _ctx.bytesUploaded,
              })
            : _ctx.$t("common.upload.waitingForUpload")), 3)
    ], 2),
    _createVNode(_component_evonum_countdown, {
      style: {"width":"100%","height":"21px"},
      class: "text-caption partial-card-list-item-dragdrop-loading__countdown",
      center: "",
      color: _ctx.success ? 'var(--success-400)' : 'var(--interactive-400)',
      progressbarAriaLabel: _ctx.$t('aria.common.downloadprogress'),
      currentValue: _ctx.percentUploaded
    }, null, 8, ["color", "progressbarAriaLabel", "currentValue"])
  ], 16))
}