import { RouteRecordRaw } from "vue-router";
import i18n from "@/i18n";
import Breadcrumbs from "@/router/breadcrumbs";
import LoadingService from "@/service/LoadingService";
import {
  GET_USER_AS_CURRENT,
  LOAD_PERMISSIONS_VIEW,
} from "@/store/modules/users.actions";
import { ROUTE_PERMISSONS } from "@/router/routes";

const Permissions = () =>
  import(
    /* webpackChunkName: "permissions-chunk" */ "@/views/permissions/permissions-frame.vue"
  );

const PermissionsOverview = () =>
  import(
    /* webpackChunkName: "permissions-chunk" */ "@/views/permissions/permissions-details.vue"
  );

export default {
  path: "permissions",
  component: Permissions,
  async beforeEnter(to, from, next) {
    await LoadingService.syncLoadAction(GET_USER_AS_CURRENT);
    next();
  },
  meta: {
    title: i18n.global.t("route-titles.permissions"),
  },
  children: [
    {
      path: "",
      name: ROUTE_PERMISSONS,
      component: PermissionsOverview,
      async beforeEnter(to, from, next) {
        to.meta!.loadRoute!(
          {
            dispatch: (...args) => LoadingService.addLoadAction(...args),
          },
          to.params
        );
        next();
      },
      meta: {
        title: i18n.global.t("route-titles.permissions"),
        breadCrumbs: Breadcrumbs.Permissions,
        loadRoute(dispatcher) {
          const promises = [dispatcher.dispatch(LOAD_PERMISSIONS_VIEW)];
          return promises;
        },
      },
    },
  ],
} as RouteRecordRaw;
