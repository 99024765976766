
import { defineComponent } from "vue";
import VariantableMixin from "@/mixins/variantable";
import { DialogTextLineOptions } from "@/model/BaseDialogCardConfirmation";
import { PropType } from "vue";
import PartialDialogCardStateChangeDisplay from "@/components/partials/partial-dialog-card-state-change-display.vue";
import PartialDialogCardConfirmationIconSwitcher from "@/components/partials/partial-dialog-card-confirmation-icon-switcher.vue";
import { ActionMetaInfo } from "@/service/ActionMetaService";

/**
 * Eine [evonum-dialog-card](#!/evonum-dialog-card) für Bestätigungen von Aktions.
 * Die notwendigen Komponenten werden über den *ActionMetaService* konfiguriert
 * und durch den Typ *Confirmation* beschrieben.
 * Wird in den [base-allowedaction-button](#!/base-allowedaction-button)s verwendet.
 *
 * @mixin Variantable
 */
export default defineComponent({
  name: "base-dialog-card_confirmation",
  mixins: [VariantableMixin],
  components: {
    PartialDialogCardStateChangeDisplay,
    PartialDialogCardConfirmationIconSwitcher,
  },
  props: {
    /**
     * Steuerflag, ob der zu betätigende Inhalt valide ist.
     */
    isValid: {
      type: Boolean,
      default: true,
    },
    /**
     * Funktion, die aufgerufen wird, wenn der Dialog geschlossen wird.
     */
    close: {
      type: Function as PropType<() => void>,
      default: () => ({}),
    },
    /**
     * Beschriftung des Bestätigen-Buttons, welcher den Default
     * *common.actions.${action}.confirmation.confirmationButtonLabel* unter Berücksichtigung der Variante überschreibt.
     */
    confirmButtonLabel: String,
    /**
     * Beschriftung des Titels, welcher den Default
     * *common.actions.${action}.confirmation.headline* unter Berücksichtigung der Variante überschreibt.
     */
    heading: String,
    /**
     * Id des zugehörigen Formulars.
     */
    formId: String,
    /**
     * Für welche Action ist das hier Confirmation?
     */
    action: String,
    /**
     * Props, welchen allen I18n Zeilen zur Verfügung gestellt werden.
     * Können mit {name} in de.json verwendet werden.
     */
    i18nProps: {
      type: Object,
      default: () => {},
    },
    /**
     * Überlagerung der Aside und PreDefault-Komponenten
     */
    optionalComponents: {},
    /**
     * Die Locales welchen Dialogs sollen für die Zeilen verwendet werden?
     */
    i18nDialogName: {
      type: String,
      default: "confirmation",
    },
  },
  methods: {
    cancelDialog(): void {
      /**
       * Wird beim Abbrechen der Bestätigung ausgelöst.
       */
      this.$emit("cancelled");
      this.close();
    },
    confirmDialog(): void {
      /**
       * Wird beim Bestätigen ausgelöst.
       */
      this.$emit("confirmed");
      this.close();
    },
  },
  computed: {
    confirmationLines(): DialogTextLineOptions[] {
      const conf = this.$actionmeta.getInfo(this.action!)[
        this.i18nDialogName as keyof ActionMetaInfo
      ] as { lineOptions: any[] };
      if (conf && conf.lineOptions) {
        const lineOptions = [] as DialogTextLineOptions[];
        const i18nBase = `common.actions.${this.action}${this.i18nVariant}.${this.i18nDialogName}.text`;
        let lines = this.$tm(i18nBase);
        if (Array.isArray(lines)) {
          for (let i = 1; i <= lines.length; i++) {
            let override = conf.lineOptions.find((x) => x.line == i);
            lineOptions.push(
              Object.assign(
                {},
                {
                  line: i,
                  content: `${i18nBase}.${i - 1}`,
                  class: "",
                  tag: "p",
                },
                override
              )
            );
          }
          return lineOptions;
        } else {
          return [{ line: 1, content: i18nBase }];
        }
      }

      return [];
    },
    confirmationComponents(): any {
      return this.optionalComponents
        ? this.optionalComponents
        : this.$actionmeta.getInfo(this.action!).confirmation?.components ||
            null;
    },
    confirmationHeading(): string {
      return this.$t(
        `common.actions.${this.action}${this.i18nVariant}.${this.i18nDialogName}.headline`,
        this.i18nProps
      ).toString();
    },
    confirmationButtonText(): string {
      if (
        this.$te(
          `common.actions.${this.action}${this.i18nVariant}.confirmation.confirmationButtonLabel`
        )
      ) {
        return this.$t(
          `common.actions.${this.action}${this.i18nVariant}.confirmation.confirmationButtonLabel`
        ).toString();
      }
      return this.$t(
        `common.actions.${this.action}${this.i18nVariant}.title`
      ).toString();
    },
  },
});
