import { Notice } from "@/model/Notice";
import ListQueryFunctionGenerator, {
  LiteralFilterArray,
} from "./ListQueryFunctionGenerator";

const ENDPOINT = "notices";
const wantedFields = [
  "actions",
  "noticeType",
  "createdAt",
  "notification",
  "publishedAt",
  "state",
  "locked",
]; //alle Außer NoticeData, da riesig und in Liste nicht nötig

export type PossibleNoticesFilters = ["awardProcedure", "noticeType"];
export type PossibleNoticesIncludes = ["awardProcedure"];
export type PossibleNoticesSorts = [
  "createdAt",
  "-createdAt",
  "publishedAt",
  "-publishedAt",
];
export type PossibleNoticeFiltersArray =
  LiteralFilterArray<PossibleNoticesFilters>;

export default ListQueryFunctionGenerator<Notice[], PossibleNoticesFilters>(
  ENDPOINT,
  {
    alwaysFields: [{ type: "notice", value: wantedFields }],
  }
);
