import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-flow":"column","height":"100%"} }
const _hoisted_2 = {
  class: "bg-blue-700 evo-app-header",
  style: {"top":"0px","z-index":"100"},
  "data-cy": "main-header"
}
const _hoisted_3 = ["id", "aria-label"]
const _hoisted_4 = {
  id: "main",
  role: "main",
  style: {"height":"100%"},
  class: "d-flex px-0",
  tabindex: "-1"
}
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_global_error_interior = _resolveComponent("partial-global-error-interior")!
  const _component_partial_skip_navigation_link = _resolveComponent("partial-skip-navigation-link")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_evonum_col = _resolveComponent("evonum-col")!
  const _component_evonum_row = _resolveComponent("evonum-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_initial_focus = _resolveDirective("initial-focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_partial_global_error_interior, { silent: true }),
      _createVNode(_component_partial_skip_navigation_link),
      _withDirectives((_openBlock(), _createBlock(_component_the_header, null, {
        left: _withCtx(() => [
          _renderSlot(_ctx.$slots, "left-header")
        ]),
        center: _withCtx(() => [
          _renderSlot(_ctx.$slots, "center-header")
        ]),
        right: _withCtx(() => [
          _renderSlot(_ctx.$slots, "right-header")
        ]),
        _: 3
      })), [
        [_directive_initial_focus, true]
      ])
    ]),
    _createVNode(_component_v_container, {
      style: {"align-items":"stretch","max-width":"100%","display":"flex"},
      "data-cy": "main-container",
      class: "fill-height pa-0 flex-grow-1",
      fluid: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_evonum_row, {
          "no-gutters": "",
          class: "flex-nowrap main-layout"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_evonum_col, {
              class: "aside-background border-grey-200 px-0",
              cols: "3",
              style: {"border-right":"1px solid var(--evo-border-color)","min-height":"100%"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("aside", {
                  id: _ctx.$ids.NAVBAR,
                  "aria-label": _ctx.$t('aria.common.navigationSidebar'),
                  style: {"height":"100%"},
                  role: "complementary"
                }, [
                  _renderSlot(_ctx.$slots, "leftaside")
                ], 8, _hoisted_3)
              ]),
              _: 3
            }, 512), [
              [_vShow, _ctx.isEnoughSpaceForLeftColumn]
            ]),
            _createVNode(_component_evonum_col, null, {
              default: _withCtx(() => [
                _createElementVNode("main", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default")
                ])
              ]),
              _: 3
            }),
            _withDirectives(_createVNode(_component_evonum_col, {
              class: "aside-background border-grey-200 px-0",
              cols: "3",
              style: {"border-left":"1px solid","min-height":"100%"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("aside", {
                  "aria-label": _ctx.$t('aria.common.sidebarRight'),
                  role: "complementary"
                }, [
                  _renderSlot(_ctx.$slots, "rightaside")
                ], 8, _hoisted_5)
              ]),
              _: 3
            }, 512), [
              [_vShow, _ctx.isEnoughSpaceForRightColumn]
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}