
import { defineComponent } from "vue";
import { PrintableError } from "@/utils/errors";
import { DISMISS_ERRORS } from "@/store/modules/notification.actions";

/**
 * Anzeige der Fehler aus einer [base-error-boundary](#!/base-error-boundary).
 */
export default defineComponent({
  name: "base-error-boundary-error-list",
  inject: ["errorBoundaryId"],
  methods: {
    dismiss(): void {
      this.$store.dispatch(DISMISS_ERRORS, (this as any).errorBoundaryId);
    },
  },
  computed: {
    errors(): PrintableError[] {
      return (
        this.$store.state.notifications.errors[(this as any).errorBoundaryId] ||
        []
      );
    },
  },
});
