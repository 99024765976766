import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_router_view_error = _resolveComponent("partial-router-view-error")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.errors.length > 0)
    ? (_openBlock(), _createBlock(_component_partial_router_view_error, {
        key: 0,
        errors: _ctx.errors,
        errorTitle: _ctx.useErrorTitle ? undefined : _ctx.$t('error-pages.404.headline')
      }, null, 8, ["errors", "errorTitle"]))
    : (_openBlock(), _createBlock(_component_router_view, _normalizeProps(_mergeProps({ key: 1 }, _ctx.$attrs)), null, 16))
}