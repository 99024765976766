import LoadService from "@/service/LoadingService";
import App from "./App.vue";
import addImports from "./main-imports";
import "./main-styles";
import router from "./router";
import { addGlobalProperties } from "@/evovue";
import store from "./store";
import Keycloak from "keycloak-js";
import { LOGIN } from "@/store/modules/users.actions";
import { createApp, defineComponent } from "vue";
import registerSimulation from "./register-simulation";

if (history.scrollRestoration) {
  //schaltet automatische Scroll-Restore aus, da wir das übernehmen
  history.scrollRestoration = "manual";
}

const keyCloakOptions = {
  url: window.aiwe.KC_URL || process.env.VUE_APP_DEFAULT_KEYCLOAK_URL,
  clientId: window.aiwe.KC_CLIENT || "awarding",
  realm: window.aiwe.KC_REALM || "aiag",
};

const keycloak = new Keycloak(keyCloakOptions);
window.keycloak = keycloak;

if (window.Cypress != null) {
  console.log("Disabling KeyCloak integration as cypress detected");
  keycloak.init = () => Promise.resolve(true) as any;
  // @ts-ignore
  keycloak.username = window.username;
  // @ts-ignore
  keycloak.password = window.password;
  // @ts-ignore
  keycloak.token = window.token;
  // @ts-ignore
  keycloak.tokenParsed = window.tokenParsed || {
    preferred_username: "fallback@ai-ag.de",
  };
  keycloak.updateToken =
    // @ts-ignore
    window.updateToken || (() => Promise.resolve(true) as any);
}

keycloak
  .init({ onLoad: "login-required" })
  .then(async (authed) => {
    if (!authed) {
      window.location.reload();
    } else {
      const app = createApp(App as ReturnType<typeof defineComponent>);
      await registerSimulation(app);
      app.use(store);
      addGlobalProperties(app);
      addImports(app);
      await store.dispatch(LOGIN, keycloak.tokenParsed);
      app.use(router);
      app.mount("#app");
      LoadService.initialize(app.config.globalProperties);
      if (window.Cypress != null) {
        app.config.errorHandler = function (err: any) {
          if (err.name == "i18nError") {
            //Hack, um Cypress zum anhalten zu bewegen
            window.Cypress.cy.contains(err.message);
          }
        };
      }
    }
  })
  .catch((error) => {
    console.error(error);
  });

declare global {
  interface Window {
    Cypress: any;
    keycloak: Keycloak;
    app: any;
    aiwe: any;
  }
}
