import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_coach_mark = _resolveComponent("evonum-coach-mark")!

  return (_openBlock(), _createBlock(_component_evonum_coach_mark, {
    onCoachmarkShown: _ctx.menuShown,
    onCoachmarkHidden: _ctx.menuHidden,
    "aria-label": _ctx.ariaLabel,
    right: _ctx.right,
    small: _ctx.small,
    heading: _ctx.heading,
    text: _ctx.text
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onCoachmarkShown", "onCoachmarkHidden", "aria-label", "right", "small", "heading", "text"]))
}