import { AwardingClient } from "@/utils/EvoClient";
import { EditNoticeDto } from "../dto";
import { AxiosResponse } from "axios";

const ENDPOINT = "c/edit-notice";

export default async function editNotice(dto: EditNoticeDto): Promise<string> {
  return (
    (await AwardingClient.post(ENDPOINT, {
      ...dto,
      validateExternal: true,
    })) as AxiosResponse
  ).data.id;
}
