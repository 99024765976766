
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-avatar",
  props: {
    participantName: {
      type: String,
      default: "",
    },
    highlighted: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    firstTwoLettersOfName(): string {
      return this.participantName?.slice(0, 2);
    },
  },
});
