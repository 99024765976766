
import { defineComponent } from "vue";
import { PropType } from "vue";
import PartialRouterViewError from "@/components/partials/partial-router-view-error.vue";
import { PrintableError } from "@/utils/errors";

export default defineComponent({
  name: "base-router-view",
  props: {
    errors: {
      type: Array as PropType<PrintableError[]>,
      default: () => [],
    },
    /** Verwendet den Titel, der im Error selbst steht */
    useErrorTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PartialRouterViewError,
  },
});
