import { RegisterWithAuthorityDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "users/c/register";

export default async function registerWithAuthority(
  registerDto: RegisterWithAuthorityDto
): Promise<string> {
  return (await AwardingClient.post(ENDPOINT, registerDto)).data.id;
}
