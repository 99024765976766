import {
  ROUTE_AWARD_PROCEDURE_DETAILS,
  ROUTE_AWARD_UNIT_DETAILS,
  ROUTE_CALL_FOR_COMPETITION_DETAILS,
  ROUTE_CALL_FOR_TENDERS_DETAILS,
  ROUTE_CALL_FOR_TENDERS_LIST,
  ROUTE_CONVERSATION_DETAILS,
  ROUTE_CONVERSATION_LIST,
  ROUTE_DASHBOARD,
  ROUTE_NOTICE_DETAILS,
  ROUTE_NOTICE_LIST,
  ROUTE_OFFER_DETAILS,
  ROUTE_PARTICIPANT_DETAILS,
  ROUTE_PARTICIPANT_LIST,
  ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS,
  ROUTE_SHIPMENT_DETAILS,
  ROUTE_SHIPMENT_LIST,
  ROUTE_TABLE_OF_OFFERS_DETAILS,
  ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS,
} from "@/router/routes";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      routes: {
        dashboard: { name: ROUTE_DASHBOARD },
        awardProcedureDetails: { name: ROUTE_AWARD_PROCEDURE_DETAILS },
        awardUnitDetails: { name: ROUTE_AWARD_UNIT_DETAILS },
        callForTendersList: { name: ROUTE_CALL_FOR_TENDERS_LIST },
        callForTendersDetails: { name: ROUTE_CALL_FOR_TENDERS_DETAILS },
        offerDetails: { name: ROUTE_OFFER_DETAILS },
        callForCompetitionDetails: { name: ROUTE_CALL_FOR_COMPETITION_DETAILS },
        requestForParticipationDetails: {
          name: ROUTE_REQUEST_FOR_PARTICIPATION_DETAILS,
        },
        conversationList: { name: ROUTE_CONVERSATION_LIST },
        conversationDetails: { name: ROUTE_CONVERSATION_DETAILS },
        noticeList: { name: ROUTE_NOTICE_LIST },
        noticeDetails: { name: ROUTE_NOTICE_DETAILS },
        participantList: { name: ROUTE_PARTICIPANT_LIST },
        participantDetails: { name: ROUTE_PARTICIPANT_DETAILS },
        shipmentList: { name: ROUTE_SHIPMENT_LIST },
        shipmentDetails: { name: ROUTE_SHIPMENT_DETAILS },
        tableOfOffersDetails: { name: ROUTE_TABLE_OF_OFFERS_DETAILS },
        tableOfOffersOfferDetails: {
          name: ROUTE_TABLE_OF_OFFERS_OFFER_DETAILS,
        },
      },
    };
  },
});
