import { EditAuthorityDto } from "./../dto";
import { AwardingClient } from "@/utils/EvoClient";

const ENDPOINT = "users/c/edit-authority";

export default async function editAuthorityDto(
  dto: EditAuthorityDto
): Promise<string> {
  return (await AwardingClient.post(ENDPOINT, dto)).data.id;
}
