import { AwardingClient } from "@/utils/EvoClient";
import { DeleteMessageDto } from "../dto";

const ENDPOINT = "c/delete-message";

export default async function deleteMessage(
  dto: DeleteMessageDto
): Promise<void> {
  return await AwardingClient.post(ENDPOINT, dto);
}
