
import { INITIAL_FOCUS_ATTRIBUTE_SELECTOR } from "@/directives";
import ViewportMixin from "@/mixins/viewport";
import { SET_NAV_MENU_VISIBLE } from "./store/actions";
import TheGlobalError from "@/components/the-global-error.vue";
import PartialSkipNavigationLink from "@/components/partials/partial-skip-navigation-link.vue";
import { RouteLocation } from "vue-router";
import DirtyService from "@/service/DirtyService";
import { defineComponent } from "vue";
import { useEvonum } from "@evo/evonum";

function focusWillBeSetByRoute(to: RouteLocation) {
  return to.query.editing == "true";
}

export default defineComponent({
  name: "App",
  mixins: [ViewportMixin],
  components: {
    TheGlobalError,
    PartialSkipNavigationLink,
  },
  setup() {
    const { classes } = useEvonum({ createAttachArea: false });
    return {
      classes,
    };
  },
  methods: {
    setFocusOnRouteChange() {
      this.$utils.focusQuerySelector(INITIAL_FOCUS_ATTRIBUTE_SELECTOR);
    },
    announceRouteChange(title: string) {
      this.$utils.announceAssertive(
        this.$t("aria.common.pageLoaded", { title }) as string
      );
    },
    closeMenuOnMobile() {
      if (this.isMobile) {
        this.$store.dispatch(SET_NAV_MENU_VISIBLE, false);
      }
    },
  },
  computed: {
    theme(): string {
      return "dark";
    },
    showScrollbar(): boolean {
      return !(this.isMobile && this.$store.state.isNavMenuVisible);
    },
    fileDragActive(): boolean {
      return this.$store.state.isFileDrag;
    },
  },
  data() {
    return {
      unloadHandler: null as any,
    };
  },
  created() {
    this.unloadHandler = (e: BeforeUnloadEvent) => {
      if (DirtyService.isDirty()) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", this.unloadHandler);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.unloadHandler);
  },
  watch: {
    $route: function (to) {
      this.$nextTick(function () {
        const title = to.meta?.title || "AI VM webEdition";
        if (!focusWillBeSetByRoute(to)) {
          this.setFocusOnRouteChange();
        }
        this.announceRouteChange(title);
        document.title = title;
        this.closeMenuOnMobile();
      });
    },
  },
});
