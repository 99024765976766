//IDs, die über Komponentengrenzen hinweg gereicht werden, können hier hinterlegt werden

export default {
  VERGABEBUTTON: "neue-vergabe-button",
  VERGABEDIALOGATTACH: "vergabeuebersicht-section",
  MAIN_SECTION: "main",
  NAVBAR: "main-navbar",
  GLOBAL_ERROR: "global-error-close",
  SHOWN_MAIN_DIALOG: "shown-main-dialog",
  LAST_MENU_ITEM: "last-menu-item",
  GLOBAL_FILE_UPLOAD: "file-upload-input",
  STOP_MOVE_BUTTON: "stop-move-button",
};

function* idCreatorGenerator(): IterableIterator<string> {
  let idNumber = 1;

  while (true) {
    yield `dynamic-id-${idNumber}`;
    idNumber++;
  }
}

const idCreator = idCreatorGenerator();

export function getUniqueId(): string {
  return idCreator.next().value;
}
