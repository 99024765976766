//Wird unter this.$utils angehangen
import DateService from "./dates";
import { Comment, Text, Slot, VNode } from "vue";

function swapInnerHtmlOfSelector(selector: string, html: string): void {
  const target = document.querySelector(selector) as HTMLElement;
  setTimeout(() => (target.textContent = html));
}

/* eslint-disable */
export function noop(...args: any[]): any {
  return null;
}
/* eslint-enable */

export default {
  focusQuerySelector(
    selector: string,
    root: { querySelector: (q: string) => HTMLElement | null } = document
  ): void {
    setTimeout(() => {
      const focusTarget = root.querySelector(selector);
      if (focusTarget) {
        window.manualFocus = true;
        focusTarget.focus();
        window.manualFocus = false;
      }
    });
  },
  hasSlotContent(slot: Slot | undefined, slotProps = {}): boolean {
    if (!slot) return false;

    return slot(slotProps).some((vnode: VNode) => {
      if (vnode.type === Comment) return false;

      if (Array.isArray(vnode.children) && !vnode.children.length) return false;

      return (
        vnode.type !== Text ||
        (typeof vnode.children === "string" && vnode.children.trim() !== "")
      );
    });
  },
  announceAssertive(message: string): void {
    swapInnerHtmlOfSelector("#assertiveAnnouncerTarget", message);
  },
  announcePolite(message: string): void {
    swapInnerHtmlOfSelector("#politeAnnouncerTarget", message);
  },
  getKeyboardFocusableElements(
    element: Pick<Document, "querySelectorAll"> = document,
    { addDisabled = false }: { addDisabled?: boolean } = { addDisabled: false }
  ) {
    return [
      ...element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
      ),
    ].filter(
      (el) =>
        (!el.hasAttribute("disabled") || addDisabled) &&
        (!el.getAttribute("aria-hidden") || addDisabled)
    ) as HTMLElement[];
  },
  focusId(
    id: string,
    root: { getElementById: (q: string) => HTMLElement | null } = document
  ): void {
    setTimeout(() => {
      const focusTarget = root.getElementById(id);
      if (focusTarget) {
        window.manualFocus = true;
        focusTarget.focus();
        window.manualFocus = false;
      }
    });
  },
  date: DateService,
  noop: noop,
};
