
import { defineComponent } from "vue";
import { GlobalDisplayError } from "@/store/modules/notification.types";
import ErrorHandlerService from "@/service/ErrorHandlerService";
import PartialGlobalErrorInterior from "@/components/partials/partial-global-error-interior.vue";

export default defineComponent({
  name: "the-global-error",
  components: {
    PartialGlobalErrorInterior,
  },
  computed: {
    hasError: {
      get(): boolean {
        return this.error.timestamp != -1;
      },
      set(val: boolean): void {
        if (!val) {
          ErrorHandlerService.resetGlobalError();
        }
      },
    },
    error(): GlobalDisplayError {
      return this.$store.state.notifications.globalError;
    },
  },
});
