
import { defineComponent } from "vue";
import { PrintableError } from "@/utils/errors";
import Attention from "@/assets/images/attention.svg";
import { PropType } from "vue";

export default defineComponent({
  name: "partial-command-action-force-dialog-confirmation",
  props: {
    close: Function as PropType<() => void>,
    action: String,
    dialogErrors: {
      type: Array as PropType<PrintableError[]>,
      required: true,
    },
  },
  computed: {
    postListText(): any {
      const conf = this.$actionmeta.getInfo(this.action!);
      if (
        conf.force &&
        typeof conf.force != "boolean" &&
        conf.force.postListText
      ) {
        let postListTextVariant;
        if (typeof conf.force.postListText === "string") {
          postListTextVariant = conf.force.postListText;
        } else {
          postListTextVariant = conf.force.postListText(this.$store.state);
        }
        return this.$tm(
          `common.actions.${this.action}.force.${postListTextVariant}`
        );
      }
      return "";
    },
    optionalComponents(): any {
      return {
        aside: {
          component: Attention,
          args: {
            style: {
              maxHeight: "200px",
              minWidth: 0,
            },
          },
        },
      };
    },
  },
});
